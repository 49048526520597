import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  clearUnlockedPart,
  getCar,
  getCarDetails,
  getCarGroups,
  getUnlockedPart,
} from "../../store/actions/car";
import InspectionReport from "../../wrappers/InspectionReportProto";

function InspectionRepoClone(props) {
  const {
    carId,
    hideAppBar,
    showAppBar,
    getCarGroups,
    getCarDetails,
    getCar,
    carData,
    previouslyUnlocked,
  } = props;
  const [loadingCar, setLoadingCar] = useState(true);
  const [numOfPoints, setNumOfPoints] = useState(0);
  const [carInfo, setCarInfo] = useState(null);
  const [reportGroups, setReportGroups] = useState({});
  const [recallGroupsToggle, setRecallGroupsToggle] = useState(false);
  const handleCallback = (childData) => {
    setNumOfPoints(childData);
  };
  var carCard = carData.card; // could've been named "card" and declared in the line above but had to name it carCard to avoid confusion upon searching
  let query = "";
  const fieldTypes = {
    for_details: 1,
  };
  for (let key in fieldTypes) {
    if (fieldTypes.hasOwnProperty(key)) {
      query = query + key + "=" + fieldTypes[key] + "&";
    }
  }

  useEffect(() => {
    setLoadingCar(true);
    getCar({
      id: carId,
      callback: (car) => {
        previouslyUnlocked.current = car.unlocked;
      },
    });
    // setTimeout(() => {
    // }, 400);
  }, [carId, getCarDetails, query, getCarGroups, recallGroupsToggle]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
  }, [carId, getCarDetails, query, getCarGroups]);

  return (
    <>
      <InspectionReport
        carInfo={carData}
        detailsLoadingCar={loadingCar} // to reload inspection reports use effect that fets the tables data so that the tables would load
        hideAppBar={hideAppBar}
        showAppBar={showAppBar}
        handleCallback={handleCallback}
        carCard={carCard}
        setRecallGroupsToggle={setRecallGroupsToggle}
        previouslyUnlocked={previouslyUnlocked}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  unlocked_part: state.car.unlocked_part,
});

const mapDispatchToProps = (dispatch) => ({
  getCar(payload) {
    dispatch(getCar(payload));
  },
  getCarDetails(payload) {
    dispatch(getCarDetails(payload));
  },
  getCarGroups(payload) {
    dispatch(getCarGroups(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InspectionRepoClone)
);
