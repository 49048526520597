import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

import Included from "../../icons/included.svg";
import IncludedDisabled from "../../icons/includedDisabled.svg";
import NotIncluded from "../../icons/notIncluded.svg";
import NotIncludedDisabled from "../../icons/notIncludedDisabled.svg";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    [theme.breakpoints.down("sm")]: {
      background: "#F6FCFF",
      border: "1px solid #C4C4C4",
      boxSizing: "border-box",
      borderRadius: "5px",
      marginTop: "18.54px",
      // paddingRight: "8px",
      paddingInline: "16.5px",
    },
  },
  planDisc: {
    color: "#636363",
    textAlign: "center",
    marginBlock: "16px",
    width: "235px",
    marginInline: "auto",
    height: "48px",

    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
      height: "36px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.5px",
      height: "40.5px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
      height: "48px",
    },
  },
  mainHeader: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.44px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  },
  noDiscriptionDiv: {
    borderTop: "1px solid #C8CACC",
    // marginLeft: "15px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #C8CACC",
    padding: "9.5px 0",
    [theme.breakpoints.up("md")]: {
      padding: "7.125px 0 7.125px 10px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "7.98px 0 7.98px 12.5px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "9.5px 0 9.5px 15px",
    },
  },
  withDiscriptionDiv: {
    display: "flex",
    // justifyContent: "space-between",
    borderBottom: "1px solid #C8CACC",
    padding: "12px 0",
    [theme.breakpoints.up("md")]: {
      padding: "9px 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10.08px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "12px 0",
    },
  },
  titleDiscriptionDiv: {
    marginRight: "10px",
    [theme.breakpoints.up("md")]: {
      marginRight: "7.5px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "8.4px",
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: "10px",
    },
  },
  rowTitle: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#07303E",
    alignSelf: "center",
    // display: "list-item", //stupid location
    // listStyleType: "disc", //stupid location
    // listStylePosition: "inside", //stupid location
    [theme.breakpoints.up("md")]: {
      // fontSize: "10.5px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      // fontSize: "11.76px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  numericDataTypography: {
    fontWeight: 500,
    color: "#07303E",
    fontWeight: 600,
    fontSize: "20px",
    borderLeft: "solid",
    paddingLeft: "8px",

    [theme.breakpoints.up("md")]: {
      // fontSize: "10.5px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      // fontSize: "11.76px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  discriptionTypography: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#636363",
    // marginLeft: "12px", //stupid
    [theme.breakpoints.up("md")]: {
      // fontSize: "9px",
      // fontSize: "12px",
      fontSize: "10px",
      // marginLeft: "9px", //stupid
    },
    [theme.breakpoints.up("lg")]: {
      // fontSize: "10.08px",
      // fontSize: "12px",
      fontSize: "13px",
      // marginLeft: "10.08px", //stupid
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "12px",
      // marginLeft: "12px", //stupid
    },
  },
  checkIconDiv: {
    minWidth: "45px",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      minWidth: "33.75px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "37.8px",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "45px",
    },
  },
  checkIcon: {
    // margin: "auto",
    margin: "3px auto auto",
  },
  totalPriceDiv: {
    // display: "flex",
    justifyContent: "space-between",
    padding: "15.5px 0",
    [theme.breakpoints.up("md")]: {
      padding: "11.625px 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "13.02px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "15.5px 0",
    },
  },
  totalPriceDiv2: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "15px",
  },
  totalPriceTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#07303E",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      // fontSize: "10.5px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      // fontSize: "11.76px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  totalPriceTitle2: {
    marginLeft: "15px",
  },
  totalPriceValue: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15.12px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },
}));

const PlanBenefits = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { plan, disabled, disableClass } = props;

  return (
    <div className={classes.mainDiv}>
      <Typography
        // className={`${classes.mainHeader} ${disabled && disableClass}`}
        // style={{
        //   color: "#636363",
        //   textAlign: "center",
        //   marginBlock: "16px",
        //   width: "235px",
        //   marginInline: "auto",
        //   height: "48px",
        // }}
        className={classes.planDisc}
      >
        {console.log("llollo", plan)}
        {plan.id == 1
          ? t("One car and that’s it. Nothing more, nothing less.")
          : plan.id == 3
          ? t("Two cars in mind? That’s fine. Unlock them both.")
          : plan.id == 4
          ? t("Want the best car? Unlock all your options.")
          : ""}
      </Typography>
      <div className={classes.noDiscriptionDiv}>
        <Typography className={classes.rowTitle}>
          {t("Number of Cars")}
        </Typography>
        <Typography className={classes.numericDataTypography}>{`${
          plan.plan_car_count
        } ${t("Car")}`}</Typography>
      </div>
      {/* <div className={classes.noDiscriptionDiv}>
        <Typography className={classes.rowTitle}>{t("Price/Car")}</Typography>
        <Typography className={classes.numericDataTypography}>{`${
          plan.price_per_car
        } ${t("JD")}`}</Typography>
      </div> */}
      {/* <div className={classes.withDiscriptionDiv}>
        <div className={classes.checkIconDiv}>
          <img
            src={
              plan.refill_at_same_price
                ? disabled
                  ? IncludedDisabled
                  : Included
                : disabled
                ? NotIncludedDisabled
                : NotIncluded
            }
            className={classes.checkIcon}
            // width={"19px"}
          />
        </div>
        <div className={classes.titleDiscriptionDiv}>
          <Typography className={classes.rowTitle}>
            {t("Re-fill at same price")}
          </Typography>
          <Typography className={classes.discriptionTypography}>{`${t(
            "Plan credits depleted? Re-fill your credits at the lowest price of"
          )} ${plan.price_per_car}${t("JD/Car")}`}</Typography>
        </div>
      </div> */}

      <div className={classes.withDiscriptionDiv}>
        <div className={classes.checkIconDiv}>
          <img
            src={
              plan.markabte_advisor
                ? disabled
                  ? IncludedDisabled
                  : Included
                : disabled
                ? NotIncludedDisabled
                : NotIncluded
            }
            className={classes.checkIcon}
            // width={"19px"}
          />
        </div>
        <div className={classes.titleDiscriptionDiv}>
          <Typography className={classes.rowTitle}>
            {t("Markabte Advisor")}
          </Typography>
          <Typography className={classes.discriptionTypography}>
            {t("Feeling lost? Our advisors will help you out with anything.")}
          </Typography>
        </div>
      </div>
      <div className={classes.withDiscriptionDiv}>
        <div className={classes.checkIconDiv}>
          <img
            src={
              plan.free_history_report
                ? disabled
                  ? IncludedDisabled
                  : Included
                : disabled
                ? NotIncludedDisabled
                : NotIncluded
            }
            className={classes.checkIcon}
            // width={"19px"}
          />
        </div>
        <div className={classes.titleDiscriptionDiv}>
          <Typography className={classes.rowTitle}>
            {t("1 Free History Report")}
          </Typography>
          <Typography className={classes.discriptionTypography}>
            {t(
              "Still unsure? Get 1 free Carseer history report of a car of your choice."
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.totalPriceDiv}>
        <div>
          <Typography
            className={`${classes.totalPriceTitle} ${classes.totalPriceTitle2}`}
            style={{}}
          >
            {t("Save")} {Math.round((1 - plan.price_per_car / 50) * 100)}%
          </Typography>
        </div>
        <div style={{}} className={classes.totalPriceDiv2}>
          <Typography className={classes.totalPriceTitle}>
            {t("Total Price")}
          </Typography>
          <Typography className={classes.totalPriceValue}>
            {`${plan.plan_car_count * plan.price_per_car}${t("JD")}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

PlanBenefits.defaultProps = {
  plan: {
    id: 1,
    title: "Free Plan",
    price_per_car: 50,
    plan_car_count: 1,
    refill_at_same_price: true,
    markabte_advisor: false,
    free_history_report: false,
    expiration_period: "180 00:00:00",
  },
};

PlanBenefits.propTypes = {
  plan: PropTypes.object,
};

export default PlanBenefits;
