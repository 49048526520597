// import { GET_APPOINTMENT_INTERVAL } from "../constants";
import {
  CALL_DEALERSHIP_ANONYMOUSLY,
  GET_DEALER_HOURS,
  REQUEST_RUNNER_CALL,
  REQUEST_WHATSAPPMESSAGE,
} from "../constants";

// export function getApptInterval(payload) {
//   //payload:{ dealershipId: "?dealerspip__id=4" }
//   return {
//     type: GET_APPOINTMENT_INTERVAL,
//     payload: payload,
//   };
// }

export function callDealershipAnonymously(payload) {
  return {
    type: CALL_DEALERSHIP_ANONYMOUSLY,
    payload: payload,
  };
}

export function getDealerHours(payload) {
  return {
    type: GET_DEALER_HOURS,
    payload: payload,
  };
}

export function requestRunnerCall(payload) {
  return {
    type: REQUEST_RUNNER_CALL,
    payload: payload,
  };
}
export function requestWhatsAppMessage(payload) {
  return {
    type: REQUEST_WHATSAPPMESSAGE,
    payload: payload,
  };
}
