import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Hidden,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Visa from "../../images/paymentMethods/Visa.png";
import PointCheckout from "../../images/paymentMethods/PointCheckout.png";
import ZainCash from "../../images/paymentMethods/ZainCash.png";
import MasterCard from "../../images/paymentMethods/MasterCard.png";
import Efawateercom from "../../images/paymentMethods/Efawateercom.png";
import EfawateercomDirectPay from "../../images/paymentMethods/EfawateercomDirectPay.png";
import CheckSign from "../icons/CheckSign";
import PaymentSummary from "./PaymentSummary";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    overflowX: "clip",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "55px",
    },
  },
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
    // borderBottom: "1px solid #D8D8D8",
    marginBottom: "19px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "24px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
    // paddingBottom: "8px",
    // marginBottom: "19px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      fontWeight: 500, //?
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  closeIcon: {
    color: "#07303E",
    cursor: "pointer",
    width: "27.5px",
    height: "27.5px",
  },
  gridContainer: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "24px",
    },
  },
  gridXsSpacing: {},
  gridItem: {
    display: "flex",
    position: "relative",
  },
  methodButton: {
    border: "1px solid #ADB5BA",
    padding: 0,
    flexGrow: 1,
    height: "60px",
    overflow: "hidden",
  },
  methodButtonSelected: {
    border: "2px solid #186985",
  },
  checkDiv: {
    position: "absolute",
    backgroundColor: "#186985",
    width: "28px",
    height: "28px",
    transform: "translate(-50%,-50%) rotate(45deg)",
    top: 0,
    left: 0,
  },
  checkSign: {
    transform: "rotate(-45deg) translate(5px,7.5px)",
  },
}));

const PaymentMethod = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { withSummary, handleClose, summaryProps, setSelectedMethodGetter } =
    props;

  const [selectedMethod, setSelectedMethod] = useState(null);

  const paymentMethods = [
    { provider: "Visa", img: Visa },
    { provider: "PointCheckout", img: PointCheckout },
    { provider: "ZainCash", img: ZainCash },
    { provider: "MasterCard", img: MasterCard },
    { provider: "Efawateercom", img: Efawateercom },
    { provider: "EfawateercomDirectPay", img: EfawateercomDirectPay },
  ];

  return (
    <div className={classes.mainDiv}>
      <div
        className={classes.titleDiv}
        style={withSummary ? { borderBottom: "1px solid #D8D8D8" } : {}}
      >
        <Typography className={classes.title}>{t("Payment Method")}</Typography>
        <Hidden smDown>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </Hidden>
      </div>
      {withSummary && <PaymentSummary {...summaryProps} />}
      <Grid container spacing={1} className={classes.gridContainer}>
        {paymentMethods.map((method, index) => (
          <Grid item xs={4} className={classes.gridItem}>
            <Button
              id={index}
              variant="outlined"
              className={clsx({
                [classes.methodButton]: 1,
                [classes.methodButtonSelected]: selectedMethod == index,
              })}
              onClick={() => {
                setSelectedMethod(index);
                setSelectedMethodGetter(paymentMethods[index]);
              }}
            >
              <img src={method.img} />
              {selectedMethod == index && (
                <div className={classes.checkDiv}>
                  <CheckSign
                    size={11}
                    fill={"white"}
                    className={classes.checkSign}
                  />
                </div>
              )}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

PaymentMethod.defaultProps = {
  withSummary: false,
  handleClose: () => {},
};

PaymentMethod.propTypes = {
  withSummary: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default PaymentMethod;
