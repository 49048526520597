import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { listPlans } from "../../store/actions/subscriptionPlans";
import PlanBenefits from "./PlanBenefits";
import ChoosePlan from "./Free/ChoosePlan";
const useStyles = makeStyles((theme) => ({
  mainDiv: {},
}));

const SubscriptionPlans = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { listPlans, plans } = props;

  // const [plans, setPlans] = useState([]);

  // useEffect(() => {
  //   listPlans({ callback: (results) => setPlans(results) });
  // }, []);

  return (
    <div className={classes.mainDiv}>
      {/* <PlanBenefits plan={plans[1]} /> */}
      <ChoosePlan plans={plans.filter((plan) => !plan.free_plan)} />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // listPlans(payload) {
  //   dispatch(listPlans(payload));
  // },
});

SubscriptionPlans.defaultProps = {
  plans: [],
};

SubscriptionPlans.propTypes = {
  plans: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);
