import React, { useEffect, useState, useRef, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  CarFeatures,
  getCar,
  getCarDetailImages,
  getCarDetails,
  getCarGroups,
} from "../../store/actions/car";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import theme from "../../theme";
import GPS from "../../components/icons/gps";
import GPSOutlined from "../../components/icons/GPSOutlined";
import CountrySpecs from "../../components/icons/CountrySpecs";
import DriveType from "../../components/icons/DriveType";
import FuelType from "../../components/icons/FuelType";
import Odometer from "../../components/icons/Odometer";
import NegotiableWebEng from "../../components/icons/NegotiableWebEng";
import NegotiableWebAr from "../../components/icons/NegotiableWebAr";
import FinalpriceWebEng from "../../components/icons/FinalpriceWebEng";
import FinalpriceWebAr from "../../components/icons/FinalpriceWebAr";
import Skeleton from "@material-ui/lab/Skeleton";
import { distanceWithCommas, numberWithCommas } from "../../helpers/functions";
import CarImages from "./carImages/CarImages";
import VHinfo from "./VHinfo";
import CarFeatureList from "./CarFeatureList";
import UnlockedSection from "./UnlockedSection";
import InspectionRepoClone from "./InspectionRepoClone";
import BookAppointmentActionBar from "../BookAppointmentActionBar";
import HandleActions from "./carImages/HandleActions";
import LoadingScreen from "../LoadingScreen";
import useGeneralStyles from "./generalStyles";
function GeneralCarInfoContainer(props) {
  const {
    carCard,
    cars,
    t,
    carImages,
    history,
    hideAppBar,
    getCarDetailImages,
    carId,
    carData,
    CarFeatures,
    unlocked,
    showAppBar,
    sellerReport,
  } = props;
  const [hideCTA, setHideCTA] = useState(false);
  const [images, setImages] = useState([]);
  const [exteriorImages, setExteriorImages] = useState([]);
  const [interiorImages, setInteriorImages] = useState([]);
  const [CarInfo, setCarInfo] = useState([]);
  const inspectionReportRef = useRef(); // reference to the div node that contains the bigger image and stepper in the Big screen, used for exiting the "dialog" bach to the normal vehicle detail page
  const previouslyUnlocked = useRef(null);
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const mainDivRef = useRef();

  const classes = useGeneralStyles();
  var actions = HandleActions({});

  return (
    <div className={classes.container}>
      <Grid container justify="center" style={{ padding: "0" }}>
        <Grid item xs={12}>
          {/* <div>{renderFirstTab(activeStep)}</div> */}

          <div
            ref={mainDivRef}
            style={{
              backgroundColor: "white",
            }}
          >
            <div className={classes.mainDetailDiv2}>
              <div className={classes.mainDetailAppointmentDiv}>
                <div className={classes.mainDetailDiv}>
                  <Hidden smDown>
                    {carData?.card?.year ? (
                      <div style={{ display: "flex" }}>
                        <Typography
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#07303E",
                          }}
                        >
                          {`${carData?.card?.year}`}
                        </Typography>
                        {"\u00A0"}
                        <Typography
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#07303E",
                          }}
                        >
                          {`${carData?.card?.make}`}
                        </Typography>
                        {"\u00A0"}
                        <Typography
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#07303E",
                          }}
                        >
                          {`${carData?.card?.model}`}
                        </Typography>
                        {"\u00A0"}
                        <Typography
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#07303E",
                          }}
                        >
                          {`${carData?.card?.trim}`}
                        </Typography>
                      </div>
                    ) : (
                      <Skeleton className={classes.webNameSkeleton} />
                    )}
                    {carData?.card?.year ? (
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#186985",
                          display: "flex",
                          marginBottom: "14px",
                        }}
                      >
                        <GPS
                          style={{ alignSelf: "center" }}
                          size="17.94"
                          fill="#186985"
                        />
                        {`${t(carData?.card?.city)}`}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.webLocationSkeleton} />
                    )}
                  </Hidden>
                  {/* ////////////////////////////////////////////// */}
                  {/* <Suspense fallback={<LoadingScreen />}> */}
                  <CarImages
                    hideAppBar={hideAppBar}
                    t={t}
                    carId={carId}
                    classes={classes}
                    images={images}
                    exteriorImages={exteriorImages}
                    interiorImages={interiorImages}
                    showAppBar={showAppBar}
                    setImages={setImages}
                    setExteriorImages={setExteriorImages}
                    setInteriorImages={setInteriorImages}
                    setCarInfo={setCarInfo}
                    carData={carData}
                  />
                  {/* </Suspense> */}
                  <Hidden mdUp>
                    {carData?.card?.year ? (
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: "30px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <Typography
                            classes={{ root: classes.CarDetailsTitle }}
                          >
                            {`${carData?.card?.year}`}
                          </Typography>
                          {"\u00A0"}
                          <Typography
                            classes={{ root: classes.CarDetailsTitle }}
                          >
                            {`${carData?.card?.make}`}
                          </Typography>
                          {"\u00A0"}

                          <Typography
                            classes={{ root: classes.CarDetailsTitle }}
                          >
                            {`${carData?.card?.model}`}
                          </Typography>
                          {"\u00A0"}

                          <Typography
                            classes={{ root: classes.CarDetailsTitle }}
                          >
                            {`${carData?.card?.trim}`}
                          </Typography>
                        </div>
                        <div className={classes.carid}>ID #{carId}</div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: "30px",
                        }}
                      >
                        <Skeleton className={classes.mobileNameSkeleton} />
                        <Skeleton
                          className={classes.mobileNameSkeleton}
                          style={{ width: "70px" }}
                        />
                      </div>
                    )}
                  </Hidden>
                  <Hidden smDown>
                    <Divider
                      component="div"
                      classes={{ root: classes.Divider }}
                      style={{ margin: "17px 0 0 0" }}
                    />
                  </Hidden>
                  {/* ///////////////////////////////////////////////////////// */}
                  <div className={classes.cardContent}>
                    <div className={classes.cardContentDiv}>
                      <Hidden smDown>
                        <div style={{ display: "inline-grid" }}>
                          <Odometer
                            className={classes.iconComponent}
                            style={{ justifySelf: "center" }}
                            size="25"
                            fill="#07303E"
                          />
                          {carData?.card?.milage ? (
                            <Typography color="#07303E">
                              {distanceWithCommas(carData?.card?.milage)}
                            </Typography>
                          ) : (
                            <Skeleton
                              className={classes.iconTypographySkeleton}
                            />
                          )}
                        </div>
                      </Hidden>
                      <div className={classes.iconDiv}>
                        <DriveType
                          className={classes.iconComponent}
                          style={{ justifySelf: "center" }}
                          size={!small ? "25" : "19"}
                          fill="#07303E"
                        />
                        {carData?.card?.drive_type ? (
                          <Typography
                            color="#07303E"
                            className={classes.iconTypography}
                          >
                            {carData?.card?.drive_type}
                          </Typography>
                        ) : (
                          <Skeleton
                            className={classes.iconTypographySkeleton}
                          />
                        )}
                      </div>
                      <div className={classes.iconDiv}>
                        <FuelType
                          className={classes.iconComponent}
                          style={{ justifySelf: "center" }}
                          size={!small ? "25" : "19"}
                          fill="#07303E"
                        />
                        {carData?.card?.fuel_type ? (
                          <Typography
                            color="#07303E"
                            className={classes.iconTypography}
                          >
                            {carData?.card?.fuel_type}
                          </Typography>
                        ) : (
                          <Skeleton
                            className={classes.iconTypographySkeleton}
                          />
                        )}
                      </div>
                      <div className={classes.iconDiv}>
                        <CountrySpecs
                          className={classes.iconComponent}
                          style={{ justifySelf: "center" }}
                          size={!small ? "25" : "19"}
                          fill="#07303E"
                        />
                        {carData?.card?.country_specs ? (
                          <Typography
                            color="#07303E"
                            className={classes.iconTypography}
                          >
                            {carData?.card?.country_specs}
                          </Typography>
                        ) : (
                          <Skeleton
                            className={classes.iconTypographySkeleton}
                          />
                        )}
                      </div>
                    </div>
                    {small ? (
                      <hr
                        style={{
                          height: "1px",
                          margin: "0 -16px 20px",
                          width: "calc(100% + 32px)",
                          border: "none",
                          backgroundImage:
                            "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
                        }}
                      />
                    ) : (
                      <Divider
                        component="div"
                        classes={{ root: classes.Divider }}
                        style={{ margin: "0" }}
                      />
                    )}
                    <Hidden mdUp>
                      <div style={{ display: "flex", marginBottom: "12px" }}>
                        <Odometer
                          className={classes.secondIconDiv}
                          size="19"
                          fill="#07303E"
                        />
                        <Typography
                          color="#07303E"
                          style={{ fontSize: "14px" }}
                        >
                          {t("Odometer")}
                        </Typography>
                        {carData?.card?.milage ? (
                          <Typography
                            color="#07303E"
                            className={classes.secondIconTypography}
                          >
                            {distanceWithCommas(carData?.card?.milage)}
                          </Typography>
                        ) : (
                          <Skeleton className={classes.mobSecondIconSkeleton} />
                        )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <GPSOutlined
                          className={classes.secondIconDiv}
                          size="19"
                          fill="#07303E"
                        />
                        <Typography
                          color="#07303E"
                          style={{ fontSize: "14px" }}
                        >
                          {t("Location")}
                        </Typography>
                        {carData?.card?.city ? (
                          <Typography
                            color="#07303E"
                            className={classes.secondIconTypography}
                          >
                            {`${t(carData?.card?.city)}`}
                          </Typography>
                        ) : (
                          <Skeleton className={classes.mobSecondIconSkeleton} />
                        )}
                      </div>
                      <hr
                        style={{
                          height: "1px",
                          margin: "20px -16px 28px",
                          width: "calc(100% + 32px)",
                          border: "none",
                          backgroundImage:
                            "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
                        }}
                      />
                    </Hidden>
                  </div>
                  {/* //////////////////////////////////////////// */}
                  <Hidden smDown>
                    <div className={classes.priceDiv}>
                      <div className={classes.priceNegotiableDiv}>
                        {carData?.card?.price ? (
                          <Typography className={classes.priceTypography}>
                            {t("english JD")}
                            {`${numberWithCommas(carData?.card?.price)}`}
                            {t("arabic JD")}
                          </Typography>
                        ) : (
                          <Skeleton
                            className={classes.priceTypographySkeleton}
                          />
                        )}
                        <div className={classes.carid}>ID #{carId}</div>
                      </div>
                    </div>
                    <div className={classes.priceDiv}>
                      <div className={classes.priceNegotiableDiv}>
                        {carData?.card?.price ? (
                          (carData?.card?.installments_period !== "N/A" ||
                            carData?.card?.down_payment !== "N/A") && (
                            <Typography
                              className={classes.priceinstallmentsTypography}
                            >
                              {t("english JD")}
                              {t(
                                numberWithCommas(
                                  carData?.card?.installments_amount
                                )
                              )}
                              {t("arabic JD")}
                              {t("/ Month .")}{" "}
                              <Typography
                                className={classes.installmentsSecondTypography}
                              >
                                {carData?.card?.installments_period}{" "}
                                {t(" months . JD")}{" "}
                                {carData?.card?.down_payment / 1000}{" "}
                                {t("K down")}
                              </Typography>
                            </Typography>
                          )
                        ) : (
                          <Skeleton
                            className={classes.priceTypographySkeleton}
                          />
                        )}
                        {carData?.card?.price_status === "Negotiable" ||
                        carData?.card?.price_status === "قابل للتفاوض" ? ( // probably wrong should send default name along with the translated name from backend to filter on the default
                          localStorage.getItem("language") === "en" ? (
                            <NegotiableWebEng
                              fill={"#07303E"}
                              size={small ? 37.03 : 56.2}
                            />
                          ) : (
                            <NegotiableWebAr
                              fill={"#07303E"}
                              size={small ? 37.03 : 56.2}
                            />
                          )
                        ) : localStorage.getItem("language") === "en" ? (
                          <FinalpriceWebEng
                            fill={"#07303E"}
                            size={small ? 37.03 : 56.2}
                          />
                        ) : (
                          <FinalpriceWebAr
                            fill={"#07303E"}
                            size={small ? 37.03 : 56.2}
                          />
                        )}
                      </div>
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <div className={classes.priceDiv}>
                      <div className={classes.priceNegotiableDiv}>
                        {carData?.card?.price ? (
                          <Typography className={classes.priceTypography}>
                            {t("english JD")}
                            {`${numberWithCommas(carData?.card?.price)}`}
                            {t("arabic JD")}
                          </Typography>
                        ) : (
                          <Skeleton
                            className={classes.priceTypographySkeleton}
                          />
                        )}
                        {carData?.card?.price_status === "Negotiable" ||
                        carData?.card?.price_status === "قابل للتفاوض" ? ( // probably wrong should send default name along with the translated name from backend to filter on the default
                          localStorage.getItem("language") === "en" ? (
                            <NegotiableWebEng
                              fill={"#07303E"}
                              size={small ? 37.03 : 56.2}
                            />
                          ) : (
                            <NegotiableWebAr
                              fill={"#07303E"}
                              size={small ? 37.03 : 56.2}
                            />
                          )
                        ) : localStorage.getItem("language") === "en" ? (
                          <FinalpriceWebEng
                            fill={"#07303E"}
                            size={small ? 37.03 : 56.2}
                          />
                        ) : (
                          <FinalpriceWebAr
                            fill={"#07303E"}
                            size={small ? 37.03 : 56.2}
                          />
                        )}
                      </div>
                      {carData?.card?.installments_amount ? (
                        carData?.card?.installments_amount !== "N/A" ||
                        carData?.card?.installments_period !== "N/A" ||
                        (carData?.card?.down_payment !== "N/A" && ( // probably wrong should send default name along with the translated name from backend to filter on the default
                          <Typography
                            className={classes.installmentsFirstTypography}
                          >
                            {t("english JD")}
                            {t(
                              numberWithCommas(
                                carData?.card?.installments_amount
                              )
                            )}
                            {t("arabic JD")}
                            {t("/ Month .")}{" "}
                            <Typography
                              className={classes.installmentsSecondTypography}
                            >
                              {carData?.card?.installments_period}{" "}
                              {t(" months . JD")}{" "}
                              {carData?.card?.down_payment / 1000} {t("K down")}
                            </Typography>
                          </Typography>
                        ))
                      ) : (
                        <Skeleton
                          className={classes.installmentsTypographySkeleton}
                        />
                      )}
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <Divider
                      light
                      style={{
                        height: "9px",
                        width: "calc(100% + 32px)",
                        margin: "0 -16px",
                      }}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    {!hideCTA ? (
                      <BookAppointmentActionBar
                        carCard={carData?.card}
                        numberWithCommas={numberWithCommas}
                        carImages={images}
                        imrsz={actions.imageLocalization}
                        sellerReport={sellerReport}
                      />
                    ) : null}
                  </Hidden>
                  <Hidden mdUp>
                    <Divider
                      light
                      style={{
                        height: "9px",
                        width: "calc(100% + 32px)",
                        margin: "0 -16px",
                      }}
                    />
                  </Hidden>
                  <Suspense fallback={<div>Loading...</div>}>
                    <UnlockedSection
                      classes={classes}
                      carId={carId}
                      unlocked={unlocked}
                      previouslyUnlocked={previouslyUnlocked}
                    />
                  </Suspense>
                  {/* <VehicleInfo /> */}
                  <Hidden mdUp>
                    <Divider
                      light
                      style={{
                        height: "9px",
                        width: "calc(100% + 32px)",
                        margin: "0 -16px",
                      }}
                    />
                  </Hidden>

                  {/* ///////////////////////////////////////////////// */}
                  <Suspense fallback={<div>Loading...</div>}>
                    <VHinfo
                      mediumDown={mediumDown}
                      classes={classes}
                      t={t}
                      CarInfo={CarInfo}
                    />
                  </Suspense>
                  {/* <VehicleFeatures /> */}
                  {/* ////////////////////////////////////////////////////// */}
                  <Suspense fallback={<div>Loading...</div>}>
                    <CarFeatureList
                      classes={classes}
                      t={t}
                      small={small}
                      carId={carId}
                    />
                  </Suspense>
                  {/* ///////////////////////////////////////////////////////////////// */}
                </div>
                <Hidden smDown>
                  {!hideCTA ? (
                    <BookAppointmentActionBar
                      imrsz={actions.imageLocalization}
                      carCard={carData?.card}
                      numberWithCommas={numberWithCommas}
                      carImages={images}
                      sellerReport={sellerReport}
                    />
                  ) : null}
                </Hidden>
              </div>
              <div
                className={classes.mainInspectionDiv}
                ref={inspectionReportRef}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <InspectionRepoClone
                    carId={carId}
                    hideAppBar={hideAppBar}
                    showAppBar={showAppBar}
                    carData={carData}
                    previouslyUnlocked={previouslyUnlocked}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  unlocked: state.car.car.unlocked,
});

const mapDispatchToProps = (dispatch) => ({
  getCarDetailImages(payload) {
    dispatch(getCarDetailImages(payload));
  },
  CarFeatures(payload) {
    dispatch(CarFeatures(payload));
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralCarInfoContainer)
);
