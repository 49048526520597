import { put, takeLatest, takeEvery, takeLeading } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  FETCH_CAR_REQUEST,
  FETCH_CAR_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST_FAILURE,
  FETCH_CAR_FIELDS_REQUEST,
  FETCH_CAR_FIELDS_REQUEST_SUCCEED,
  FETCH_CAR_FIELDS_REQUEST_FAILURE,
  FETCH_CAR_FORMS_REQUEST,
  FETCH_CAR_FORMS_REQUEST_SUCCEED,
  FETCH_CAR_FORMS_REQUEST_FAILURE,
  FETCH_CAR_FEATURES_REQUEST,
  FETCH_CAR_FEATURES_REQUEST_SUCCEED,
  FETCH_CAR_FEATURES_REQUEST_FAILURE,
  FETCH_CAR_FAULTS_REQUEST,
  FETCH_CAR_FAULTS_REQUEST_SUCCEED,
  FETCH_CAR_FAULTS_REQUEST_FAILURE,
  FETCH_CAR_VISUALIZATION_REQUEST,
  FETCH_CAR_VISUALIZATION_REQUEST_FAILURE,
  FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
  LIST_CARS_REQUEST,
  LIST_CARS_REQUEST_SUCCEED,
  LIST_CARS_REQUEST_FAILURE,
  LIST_CARS_REQUEST_END_OF_RESULTS,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_DETAILS_REQUEST_SUCCEED,
  FETCH_CAR_DETAILS_REQUEST_FAILURE,
  FETCH_CAR_GROUPS_REQUEST,
  FETCH_CAR_GROUPS_REQUEST_SUCCEED,
  FETCH_CAR_GROUPS_REQUEST_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  UPDATE_CAR_FORM_REQUEST,
  UPDATE_CAR_FORM_REQUEST_SUCCEED,
  UPDATE_CAR_FORM_REQUEST_FAILURE,
  CLEAR_SOLD_CARS,
  FETCH_FIELD_OPTIONS_REQUEST,
  FETCH_FIELD_OPTIONS_REQUEST_SUCCEED,
  FETCH_FIELD_OPTIONS_REQUEST_FAILURE,
  REPORT_CAR_PURCHASE_REQUEST,
  REPORT_CAR_PURCHASE_REQUEST_SUCCEED,
  REPORT_CAR_PURCHASE_REQUEST_FAILURE,
  FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE,
  FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED,
  FETCH_CAR_DETAILS_IMAGES_REQUEST,
  GET_SIMPLE_CAR_DATA,
  GET_SIMPLE_CAR_DATA_FAILURE,
  GET_SIMPLE_CAR_DATA_SUCCEED,
  GET_VH_INFO_SUCCEED,
  GET_VH_INFO_FAILURE,
  GET_VH_INFO,
  GET_CAR_FEATURES_SUCCEED,
  GET_CAR_FEATURES,
  FETCH_CAR_VISUALIZATION_REQUEST_IDs,
  UNLOCKED_PART,
  UNLOCKED_PART_SUCCEED,
} from "../constants";

import API_URLS from "../../api";
export function* getCar(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.DETAILS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarWatcher() {
  yield takeLatest(FETCH_CAR_REQUEST, getCar);
}

export function* getCarList(action) {
  // bug i think
  try {
    let data = {
      url: API_URLS(action.payload.filter).CAR.LIST,
      method: "GET",
      contentType: "application/x-www-form-urlencoded",
    };
    const response = yield requestAPI(data);
    yield put({
      type: LIST_CARS_REQUEST_SUCCEED,
      payload: response /*.results*/,
    });
    try {
      action.payload.callback(
        // bug i think not a function
        response.used_search,
        response.unused_search,
        response.filter_props
      );
    } catch (e) {
      console.log(e);
    }

    //here
    // response.used_search.length > 0 ? alert("weeeeeew") : alert("zero");
    if (action.payload.filter.includes("page=1")) {
      yield put({
        type: CLEAR_SOLD_CARS,
      });
    }
  } catch (e) {
    if (e?.response?.status === 404) {
      yield put({ type: LIST_CARS_REQUEST_END_OF_RESULTS });
    } else {
      yield put({ type: LIST_CARS_REQUEST_FAILURE, error: new Error(e) });
      // yield put({
      //   type: CLEAR_SOLD_CARS,
      // });
    }
  }
}

export function* getCarListWatcher() {
  // yield takeLatest(LIST_CARS_REQUEST, getCarList);
  yield takeEvery(LIST_CARS_REQUEST, getCarList);
}
export function* getCarDetail(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.PAGE,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_DETAILS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_DETAILS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarDetailsWatcher() {
  yield takeLatest(FETCH_CAR_DETAILS_REQUEST, getCarDetail);
}

export function* getCarDetailImages(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.DETAILS_IMAGES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED,
      payload: response,
    });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarDetailsImagesWatcher() {
  yield takeLatest(FETCH_CAR_DETAILS_IMAGES_REQUEST, getCarDetailImages);
}

export function* getCarGroups(action) {
  try {
    const query = action.payload.carId + "/?" + action.payload.query;
    let data = {
      url: API_URLS(query).CAR.CAR_GROUPED_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_GROUPS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    console.log("tttttttttttttttteeeee", e);
    yield put({ type: FETCH_CAR_GROUPS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarGroupsWatcher() {
  yield takeEvery(FETCH_CAR_GROUPS_REQUEST, getCarGroups);
}

export function* getCarPriceForm(action) {
  try {
    const { formId, carId } = action.payload;
    let data = {
      url: API_URLS(`?car_id=${carId}&form=${formId}`).CAR.CAR_FORMS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FORMS_REQUEST_SUCCEED,
      payload: response.results,
    });
  } catch (e) {
    yield put({ type: FETCH_CAR_FORMS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarPriceFormWatcher() {
  yield takeLatest(FETCH_CAR_FORMS_REQUEST, getCarPriceForm);
}

export function* updateCarPriceForm(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.UPDATE_FORM,
      method: "POST",
      body: action.payload.body,
    };
    const response = yield requestAPI(data);
    yield put({ type: UPDATE_CAR_FORM_REQUEST_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Your changes have been saved",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({ type: UPDATE_CAR_FORM_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateCarPriceFormWatcher() {
  yield takeLatest(UPDATE_CAR_FORM_REQUEST, updateCarPriceForm);
}
export function* getCarVisualization(action) {
  try {
    const { query } = action.payload;
    let data = {
      url: API_URLS(query).CAR.CAR_VISUALIZATION,
      method: "GET",
    };
    const response = yield requestAPI(data);
    let points = {};
    for (let i = 0; i < response.results.length; i++) {
      points[response.results[i].field.side] = response.results[i];
    }
    let first = action.payload.first;

    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
      payload: { points: points, first: first },
    });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarVisualizationWatcher() {
  yield takeLatest(FETCH_CAR_VISUALIZATION_REQUEST, getCarVisualization);
}

export function* getCarVisualizationIDs(action) {
  try {
    const { query } = action.payload;
    let data = {
      url: API_URLS(query).CAR.CAR_VISUALIZATION_IDs,
      method: "GET",
    };
    const response = yield requestAPI(data);
    let points = {};
    for (let i = 0; i < response.results.length; i++) {
      points[response.results[i].side] = response.results[i];
    }
    let first = action.payload.first;

    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
      payload: { points: points, first: true },
    });
    action.payload.callback(response);
  } catch (e) {
    console.log("llllllll", e);
  }
}

export function* getCarVisualizationIDsWatcher() {
  yield takeLatest(FETCH_CAR_VISUALIZATION_REQUEST_IDs, getCarVisualizationIDs);
}

export function* getCarFaults(action) {
  try {
    const { carId, side, position } = action.payload;
    let data = {
      url: API_URLS(
        `car_id=${carId}&field__side=${side}&field__position=${position}`
      ).CAR.CAR_FAULTS,
      method: "GET",
    };
    const response = yield requestAPI(data);

    yield put({
      type: FETCH_CAR_FAULTS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_CAR_FAULTS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarFaultsWatcher() {
  yield takeLatest(FETCH_CAR_FAULTS_REQUEST, getCarFaults);
}

export function* fetchFieldOptions(action) {
  try {
    let data = {
      url: API_URLS(action.payload.query).CAR_FORM.FIELD_OPTIONS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_FIELD_OPTIONS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({
      type: FETCH_FIELD_OPTIONS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}
export function* fetchFieldOptionsWatcher() {
  yield takeLatest(FETCH_FIELD_OPTIONS_REQUEST, fetchFieldOptions);
}

export function* reportCarPurchase(action) {
  try {
    let data = {
      url: API_URLS().CAR.REPORT_CAR_PURCHASE,
      method: "POST",
      body: {
        user: action.payload.userId,
        car: action.payload.selectedCar,
        front_image: action.payload.front_image,
        back_image: action.payload.back_image,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: REPORT_CAR_PURCHASE_REQUEST_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Your changes have been saved",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({
      type: REPORT_CAR_PURCHASE_REQUEST_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* reportCarPurchaseWatcher() {
  yield takeLatest(REPORT_CAR_PURCHASE_REQUEST, reportCarPurchase);
}

export function* simpleCarData(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.GET_SIMPLE_CAR_DATA,
      method: "GET",
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: GET_SIMPLE_CAR_DATA_FAILURE,
      error: new Error(e),
    });
  }
}

export function* simpleCarDataWatcher() {
  yield takeLatest(GET_SIMPLE_CAR_DATA, simpleCarData);
}

export function* VHCarInfo(action) {
  try {
    let data = {
      url:
        API_URLS().CAR.GET_VHinfo + "?field_id__car_id=" + action.payload.carId,
      method: "GET",
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
    yield put({ type: GET_VH_INFO_SUCCEED, payload: response });
  } catch (e) {
    yield put({
      type: GET_VH_INFO_FAILURE,
      error: new Error(e),
    });
  }
}

export function* VHCarInfoWatcher() {
  yield takeLatest(GET_VH_INFO, VHCarInfo);
}

export function* CarFeatures(action) {
  try {
    let data = {
      url:
        API_URLS(action.payload.carId).CAR.GET_CAR_FEATURES +
        "?carId=" +
        action.payload.carId,
      method: "GET",
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
    yield put({ type: GET_CAR_FEATURES_SUCCEED, payload: response });
  } catch (e) {
    console.log("lllllllllllll", e);
  }
}

export function* CarFeaturesWatcher() {
  yield takeLatest(GET_CAR_FEATURES, CarFeatures);
}

export function* getUnlockedPart(action) {
  try {
    const { carId } = action.payload;
    let data = {
      url: API_URLS(`car=${carId}`).CAR.CAR_UNLOCKED_PART,
      method: "GET",
    };

    const response = yield requestAPI(data);
    yield put({
      type: UNLOCKED_PART_SUCCEED,
      payload: response,
    });
    // action.payload.callback(response.results);
  } catch (e) {
    // yield put({ type: FETCH_CAR_FAULTS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getUnlockedPartWatcher() {
  yield takeLatest(UNLOCKED_PART, getUnlockedPart);
}
