import React from "react";

const EmailShare = (props) => {
  const { size, fill, style } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M24.7542 18.8483L37 26.5899V10.7798L24.7542 18.8483Z"
          fill="#186985"
        />
        <path
          d="M0.675659 10.7798V26.5899L12.9215 18.8483L0.675659 10.7798Z"
          fill="#186985"
        />
        <path
          d="M34.7298 6.17571H2.946C1.81313 6.17571 0.914106 7.02025 0.743835 8.10771L18.8379 20.0289L36.9319 8.10771C36.7617 7.02025 35.8626 6.17571 34.7298 6.17571Z"
          fill="#186985"
        />
        <path
          d="M22.6745 20.2196L19.4621 22.3355C19.2714 22.4604 19.0557 22.5217 18.8377 22.5217C18.6198 22.5217 18.4041 22.4604 18.2134 22.3355L15.001 20.2173L0.74823 29.2326C0.923041 30.311 1.81753 31.1487 2.94585 31.1487H34.7296C35.858 31.1487 36.7524 30.311 36.9273 29.2326L22.6745 20.2196Z"
          fill="#186985"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="36.3243"
            height="36.3243"
            fill="white"
            transform="translate(0.675659 0.500017)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailShare;
