import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Divider,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import PaymentSummary from "./PaymentSummary";

import Discount from "../../icons/Discount.svg";

const useStyles = makeStyles((theme) => ({
  mainDiv: {},
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
    borderBottom: "1px solid #D8D8D8",
    marginBottom: "19px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "24px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
    // paddingBottom: "8px",
    // borderBottom: "1px solid #D8D8D8",
    // marginBottom: "19px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      fontWeight: 500, //?
      // marginBottom: "24px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  closeIcon: {
    color: "#07303E",
    cursor: "pointer",
    width: "27.5px",
    height: "27.5px",
  },
  discountTitleDiv: {
    display: "flex",
    marginBottom: "12px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "18px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  discountTitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#07303E",
    minWidth: "max-content",
    [theme.breakpoints.up("md")]: {
      fontSize: "15.75px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  Divider: {
    height: 1,
    backgroundColor: "#D8D8D8",
    margin: "auto 0 auto 9.5px",
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      margin: "auto 0 auto 11px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  discountCodeRoot: {
    width: "100%",
    marginBottom: "13px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "19px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  discountIcon: {
    width: "13px",
    [theme.breakpoints.up("md")]: {
      width: "14px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  discountSubmit: {
    fontSize: "14px",
    fontWeight: 600,
    color: "rgba(24, 105, 133)",
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "13.5px", //?
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  disabledButton: {
    color: "rgba(24, 105, 133, 0.4)!important",
  },
  outlinedInput: {
    padding: "12px 0",
    fontSize: "14px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      padding: "15px 0",
      fontSize: "13.5px",
      fontWeight: 400, //?
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  placeHolder: {
    color: "#AAB6BE",
  },
  startAdornment: {
    paddingLeft: "12px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "15px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  endAdornment: {
    paddingRight: "12px",
    [theme.breakpoints.up("md")]: {
      paddingRight: "15px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  billTable: {
    paddingBottom: "4px",
    // borderBottom: "1px solid #D8D8D8",
    // marginBottom: "16px",
    [theme.breakpoints.up("md")]: {
      //!
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  rowDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "13.5px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  rowTypography: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#636363",
    [theme.breakpoints.up("md")]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  finalRowTypography: {
    fontSize: "15px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "16.88px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  finalDivider: {
    height: 1,
    backgroundColor: "#D8D8D8",
    flexGrow: 1,
    marginBottom: "16px",
  },
}));

const PaymentBill = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    withSummary,
    planType,
    noOfCars,
    pricePerCar,
    discountAmount,
    handleClose,
    summaryProps,
  } = props;

  const [discountCode, setDiscountCode] = useState("");

  const totalAmount = noOfCars * pricePerCar - discountAmount;

  return (
    <div className={classes.mainDiv}>
      <div className={classes.titleDiv}>
        <Typography className={classes.title}>
          {t("Payment Summary")}
        </Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </div>
      {withSummary && <PaymentSummary {...summaryProps} />}
      <div className={classes.discountTitleDiv}>
        <Typography className={classes.discountTitle}>
          {t("Discount Code")}
        </Typography>
        <Divider component="div" classes={{ root: classes.Divider }} />
      </div>
      <TextField
        value={discountCode}
        onChange={(event) => {
          setDiscountCode(event.target.value);
        }}
        variant="outlined"
        placeholder={t("Enter Discount Code")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={Discount} className={classes.discountIcon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                disabled={!discountCode.length}
                className={classes.discountSubmit}
                classes={{ disabled: classes.disabledButton }}
              >
                {t("Submit")}
              </Button>
            </InputAdornment>
          ),
          classes: {
            input: clsx({
              [classes.outlinedInput]: 1,
              [classes.placeHolder]: !discountCode.length,
            }),
          },
        }}
        classes={{
          root: classes.discountCodeRoot,
          inputAdornedEnd: classes.endAdornment,
          inputAdornedStart: classes.startAdornment,
        }}
      />
      <div className={classes.billTable}>
        <div className={classes.rowDiv}>
          <Typography className={classes.rowTypography}>
            {t("Package Type")}
          </Typography>
          <Typography className={classes.rowTypography}>{planType}</Typography>
        </div>
        <div className={classes.rowDiv}>
          <Typography className={classes.rowTypography}>
            {t("Number of Cars")}
          </Typography>
          <Typography className={classes.rowTypography}>{`${noOfCars} ${t(
            "carsPaymentMethod"
          )}`}</Typography>
        </div>
        <div className={classes.rowDiv}>
          <Typography className={classes.rowTypography}>
            {t("Price per car")}
          </Typography>
          <Typography className={classes.rowTypography}>{`${pricePerCar} ${t(
            "JDS"
          )}`}</Typography>
        </div>
        <div className={classes.rowDiv}>
          <Typography className={classes.rowTypography}>
            {t("Discount Amount")}
          </Typography>
          <Typography className={classes.rowTypography}>{`${discountAmount} ${t(
            "JDS"
          )}`}</Typography>
        </div>
        <div className={classes.rowDiv}>
          <Typography className={classes.finalRowTypography}>
            {t("Total Amount")}
          </Typography>
          <Typography
            className={classes.finalRowTypography}
          >{`${totalAmount}.00 ${t("JDS")}`}</Typography>
        </div>
      </div>
      <Hidden mdUp>
        <Divider component="div" classes={{ root: classes.finalDivider }} />
      </Hidden>
    </div>
  );
};

PaymentBill.defaultProps = {
  withSummary: true,
  planType: "Confident",
  noOfCars: 1,
  pricePerCar: 50,
  discountAmount: 0,
  handleClose: () => {},
};

PaymentBill.propTypes = {
  withSummary: PropTypes.bool,
  planType: PropTypes.string,
  noOfCars: PropTypes.number,
  pricePerCar: PropTypes.number,
  discountAmount: PropTypes.number,
  handleClose: PropTypes.func,
};

export default PaymentBill;
