import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { createSession, requestPay } from "../../store/actions/payment";
import { subscribePlan } from "../../store/actions/auth";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Hidden,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  CircularProgress,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import infoIcon from "../../icons/Information.svg";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    overflowX: "clip",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "55px",
    },
  },
  errorDiv: {
    backgroundColor: "#FAE4E4",
    color: "#DD4B4B",
    // padding: "16px",
    margin: "-24px -16px 8px",
    // border: "1px solid #DD4B4B",
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      margin: "-32px -32px 16px",
      paddingInline: "32px",
    },
  },
  errorTypography: {
    color: "#DD4B4B",
    fontSize: "14px",
    fontWeight: 600,
  },
  errorIcon: {
    color: "#DD4B4B!important",
  },
  loadingDiv: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    // backgroundColor: "rgba(0,0,0,0.7)",
    width: "100%",
    height: "100%",
    // pointerEvents: "none",
    // touchAction: "none",
  },
  loadingInnerDiv: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "5px",
    marginBottom: "19px",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "8px",
      marginBottom: "24px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      fontWeight: 500, //?
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  closeIcon: {
    color: "#07303E",
    cursor: "pointer",
    width: "27.5px",
    height: "27.5px",
  },
  textField: {
    marginBottom: "15.71px",
  },
  twoTextFields: {
    marginBottom: "15.71px",
    flexBasis: "48.3%",
    minWidth: "48.3%",
  },
  twoFieldsDiv: { display: "flex", justifyContent: "space-between" },
  inputRoot: {
    height: "42px",
  },
  textFieldInput: {
    all: "initial",
    // padding: "0 16px",
    height: "42px",
    width: "500px",
    maxWidth: "100vw",
    margin: "0 16px",
    color: "#07303E",
    // direction: "rtl",
  },
  labelRoot: {
    fontSize: "14px",
    color: "#858484",
  },
  labelOutlined: {
    transform: "translate(16px, 14px) scale(1)",
  },
  labelShrink: {
    transform: "translate(16px, -6px) scale(0.75)!important",
  },
  disclaimerTypography: {
    fontSize: "13px",
    fontWeight: "400",
    color: "#626465",
    paddingBottom: "16px",
    borderBottom: "1px solid #D8D8D8",
    marginBottom: "16px",
    display: "flex",
  },
  paymentDetails: {
    backgroundColor: "#E2F0F5",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  paymentDetailsTextsDiv: {},
  firstTypography: {
    fontSize: "13px",
    fontWeight: 600,
    color: "#07303E",
    marginBottom: "4px",
  },
  secondTypography: {
    fontSize: "10px",
    fontWeight: 500,
    color: "#999999",
  },
  paymentAmountTypography: {
    fontSize: "22px",
    fontWeight: 700,
    color: "#07303E",
  },
  buttonsDiv: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
      padding: "4px 0",
    },
  },
  buttons: {
    margin: "6px 0",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.5,
    paddingTop: "13px",
    paddingBottom: "13px",
    borderRadius: "5.625px",
    textTransform: "none",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  tooltipAdornment: {
    paddingRight: 0,
    justifyContent: "space-between",
  },
  tooltip: {
    backgroundColor: "white",
    color: "#626465",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    fontWeight: 500,
    padding: "15px",
    maxWidth: "213.76px",
    borderRadius: "0",
    margin: "12px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      padding: "8px",
      maxWidth: "218px",
      margin: "8px 0",
    },
  },
  tooltipArrow: {
    "&::before": {
      backgroundColor: "white",
      border: "1px solid #000000",
      width: "95%",
    },
  },
  inValid: {
    color: "#f44336",
    borderColor: "#f44336",
  },
  valid: {
    color: "#37C534",
    borderColor: "#37C534",
  },
}));

const CardInfo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    handleClose,
    validityPeriod,
    amount,
    createSession,
    requestPay,
    pay,
    user,
    plan,
    refill,
    setCredits,
    setOpen,
    setChargeOptionsOpen,
    setDrawerTab,
    setInterestedState,
    setFirstPopup,
    subscribePlan,
    setLoading,
    validation,
    setValidation,
    handleCompletion,
  } = props;

  const PaymentSession = global.PaymentSession;
  const [initializing, setInitializing] = useState(true);
  const [newSession, setNewSession] = useState(true);

  const [sessionId, setSessionId] = useState("");
  const [orderId, setOrderId] = useState("");

  // const [validation, setValidation] = useState({
  //   nameOnCard: null,
  //   number: null,
  //   expiryMonth: null,
  //   expiryYear: null,
  //   securityCode: null,
  // });
  const [errorOpen, setErrorOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const MASTER_CARD_SESSION_JS_SRC =
    "https://mepspay.gateway.mastercard.com/form/version/63/merchant/9800086200/session.js?debug=true";

  useEffect(() => {
    const prevScript = document.querySelector(
      `script[src="${MASTER_CARD_SESSION_JS_SRC}"]`
    );

    if (prevScript) {
      prevScript.remove();
    }

    const script = document.createElement("script");
    script.src = MASTER_CARD_SESSION_JS_SRC;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      console.log("LLLLLLLLLLLRemove", script);

      document.body.removeChild(script);
    };
  }, [newSession]);

  const checkForSession = () => {
    // lind of stupid used recursion to check for the arrival of global variable PaymentSession scince i cant read it's change in react
    // probably could read script end or something
    // also no end to recursion
    let x = global.PaymentSession;
    if (x) {
      setInitializing(false);
    } else {
      setTimeout(() => {
        checkForSession();
      }, 3000);
    }
  };

  useEffect(() => {
    createSession({
      amount: amount,
      item: plan?.title_en,
      callback: (response) => {
        setSessionId(response.session_id);
        setOrderId(response.order_id);
        checkForSession();
      },
    });
  }, [newSession]);

  const createNewSession = () => {
    setInitializing(true);
    Array.prototype.slice
      .call(document.getElementsByTagName("iframe"))
      .forEach(function (item) {
        item.remove();
        // or item.parentNode.removeChild(item); for older browsers (Edge-)
      });

    setValidation({
      nameOnCard: null,
      number: null,
      expiryMonth: null,
      expiryYear: null,
      securityCode: null,
    });
    setSessionId("");
    setOrderId("");
    setNewSession((prev) => {
      return !prev;
    });
    setLoading(false);
  };

  useNonInitialEffect(() => {
    if (sessionId !== "" && orderId !== "" && PaymentSession) {
      PaymentSession.configure({
        session: sessionId,
        fields: {
          // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
          card: {
            number: "#card-number",
            securityCode: "#security-code",
            expiryMonth: "#expiry-month",
            expiryYear: "#expiry-year",
            nameOnCard: "#cardholder-name",
          },
        },
        //SPECIFY YOUR MITIGATION OPTION HERE
        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: function (response) {
            console.log(response);
            // HANDLE INITIALIZATION RESPONSE
          },
          formSessionUpdate: function (response) {
            // HANDLE RESPONSE FOR UPDATE SESSION
            if (response.status) {
              if ("ok" == response.status) {
                console.log(
                  "Session updated with data: " + response.session.id
                );
                console.log(sessionId, response);

                //check if the security code was provided by the user
                if (response.sourceOfFunds.provided.card.securityCode) {
                  console.log("Security code was provided.");
                }

                //check if the user entered a Mastercard credit card
                if (
                  response.sourceOfFunds.provided.card.scheme == "MASTERCARD"
                ) {
                  console.log("The user entered a Mastercard credit card.");
                }
                //call Pay
                requestPay({
                  orderId,
                  transactionId: "1",
                  sessionId,
                  amount: amount,
                  scheme: response.sourceOfFunds.provided.card.scheme,
                  callback: (result) => {
                    console.log("Pay result", result);
                    /////////////////////////////////
                    if (result.status == "Success") {
                      subscribePlan({
                        plan: plan,
                        user_id: user.user_id,
                        refill: refill || false,
                        message:
                          localStorage.getItem("language") == "en"
                            ? plan?.plan_car_count > 1
                              ? plan?.plan_car_count +
                                " " +
                                "Credits have been added to your balance."
                              : "1 Credit has been added to your balance."
                            : `تم اضافة ${plan?.plan_car_count} رصيد مركبات الى رصيدك`,
                        callback: (response) => {
                          if (response.success) {
                            if (
                              window.location.href.includes(
                                "/subscription-plans"
                              )
                            ) {
                              window.history.back();
                              setCredits && setCredits(0);
                              setOpen(false);
                              setChargeOptionsOpen &&
                                setChargeOptionsOpen(false);
                            }
                          }
                          setOpen(false);
                          setChargeOptionsOpen && setChargeOptionsOpen(false);
                        },
                      });
                      handleCompletion();
                      setDrawerTab(0);
                      setInterestedState(false);
                      setFirstPopup(false);

                      ///////////////////////////////////
                      handleClose();
                    } else {
                      setErrorOpen(true);
                      setTimeout(() => {
                        setErrorOpen(false);
                      }, 4000);
                      createNewSession();
                    }
                  },
                });
              } else if ("fields_in_error" == response.status) {
                console.log("Session update failed with field errors.");
                if (response.errors.cardNumber) {
                  console.log("Card number invalid or missing.");
                }
                if (response.errors.expiryYear) {
                  console.log("Expiry year invalid or missing.");
                }
                if (response.errors.expiryMonth) {
                  console.log("Expiry month invalid or missing.");
                }
                if (response.errors.securityCode) {
                  console.log("Security code invalid.");
                }
              } else if ("request_timeout" == response.status) {
                console.log(
                  "Session update failed with request timeout: " +
                    response.errors.message
                );
              } else if ("system_error" == response.status) {
                console.log(
                  "Session update failed with system error: " +
                    response.errors.message
                );
              }
            } else {
              console.log("Session update failed: " + response);
            }
          },
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT",
          },
        },
      });
      ////////////////////////////
      PaymentSession.onValidityChange(
        [
          "card.number",
          "card.nameOnCard",
          "card.securityCode",
          "card.expiryYear",
          "card.expiryMonth",
        ],
        function (selector, result, role) {
          if (result.isValid) {
            console.log("The field value is valid", result, role);
            setValidation((prev) => {
              return Object.assign({}, prev, { [role]: true });
            });
          } else if (result.isIncomplete) {
            console.log("The field value is not yet valid", result, role);
            setValidation((prev) => {
              return Object.assign({}, prev, { [role]: null });
            });
          } else {
            console.log("The field value is invalid", result, role);
            setValidation((prev) => {
              return Object.assign({}, prev, { [role]: false });
            });
          }
        }
      );
    }

    // PaymentSession.onBlur( ["card.number", "card.nameOnCard", "card.securityCode", "card.expiryYear", "card.expiryMonth"],

    // function (selector, role)

    // { PaymentSession.validate('card', function (allresult) {

    // if (allresult.card[role].isValid) {

    // console.log("The field is valid");
    // document.querySelector(selector).style.borderColor = "green";

    // } else {

    // console.log("The field is invalid");

    // document.querySelector(selector).style.borderColor = "red";

    // }

    // });

    // });

    ////////////////////////////
  }, [sessionId, orderId, PaymentSession]);

  useNonInitialEffect(() => {
    if (PaymentSession) {
      PaymentSession.updateSessionFromForm("card");
    }
  }, [pay]);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <>
      {/* {errorOpen && ( */}
      <Slide direction="down" in={errorOpen} mountOnEnter unmountOnExit>
        <Alert
          className={classes.errorDiv}
          severity="error"
          classes={{ icon: classes.errorIcon }}
        >
          <Typography className={classes.errorTypography}>
            {t("Something went wrong, please try again.")}{" "}
          </Typography>
        </Alert>
      </Slide>
      {/* )} */}

      <div className={classes.mainDiv}>
        {initializing && (
          <div className={classes.loadingDiv}>
            <div className={classes.loadingInnerDiv}>
              <CircularProgress color={"primary"} />
            </div>
          </div>
        )}
        <div
          className={classes.titleDiv}
          style={{ borderBottom: "1px solid #D8D8D8" }}
        >
          <Typography className={classes.title}>
            {t("Card Information")}
          </Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div
          className={classes.fieldsDiv}
          style={
            initializing
              ? {
                  pointerEvents: "none",
                  touchAction: "none",
                }
              : {}
          }
        >
          <TextField
            disabled={initializing}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
                root: classes.inputRoot,
                notchedOutline: clsx({
                  [classes.valid]:
                    validation.nameOnCard && validation.nameOnCard != null,
                  [classes.inValid]:
                    !validation.nameOnCard && validation.nameOnCard != null,
                }),
              },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: clsx({
                  [classes.labelRoot]: 1,
                  [classes.valid]:
                    validation.nameOnCard && validation.nameOnCard != null,
                  [classes.inValid]:
                    !validation.nameOnCard && validation.nameOnCard != null,
                }),
                outlined: classes.labelOutlined,
                shrink: classes.labelShrink,
              },
            }}
            inputProps={{
              id: "cardholder-name",
              readOnly: true,
            }}
            label={t("Name on Card*")}
            fullWidth
            variant="outlined"
            className={classes.textField}
            type="text"
          />
          <TextField
            disabled={initializing}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
                root: classes.inputRoot,
                notchedOutline: clsx({
                  [classes.valid]:
                    validation.number && validation.number != null,
                  [classes.inValid]:
                    !validation.number && validation.number != null,
                }),
              },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: clsx({
                  [classes.labelRoot]: 1,
                  [classes.valid]:
                    validation.number && validation.number != null,
                  [classes.inValid]:
                    !validation.number && validation.number != null,
                }),
                outlined: classes.labelOutlined,
                shrink: classes.labelShrink,
              },
            }}
            inputProps={{
              id: "card-number",
              readOnly: true,
            }}
            label={t("Card Number*")}
            fullWidth
            variant="outlined"
            className={classes.textField}
          />
          <div className={classes.twoFieldsDiv}>
            <TextField
              disabled={initializing}
              InputProps={{
                classes: {
                  input: classes.textFieldInput,
                  root: classes.inputRoot,
                  notchedOutline: clsx({
                    [classes.valid]:
                      validation.expiryMonth && validation.expiryMonth != null,
                    [classes.inValid]:
                      !validation.expiryMonth && validation.expiryMonth != null,
                  }),
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: clsx({
                    [classes.labelRoot]: 1,
                    [classes.valid]:
                      validation.expiryMonth && validation.expiryMonth != null,
                    [classes.inValid]:
                      !validation.expiryMonth && validation.expiryMonth != null,
                  }),
                  outlined: classes.labelOutlined,
                  shrink: classes.labelShrink,
                },
              }}
              inputProps={{
                id: "expiry-month",
                readOnly: true,
              }}
              label={t("Expiry Month*")}
              fullWidth
              variant="outlined"
              className={classes.twoTextFields}
            />
            <TextField
              disabled={initializing}
              InputProps={{
                classes: {
                  input: classes.textFieldInput,
                  root: classes.inputRoot,
                  notchedOutline: clsx({
                    [classes.valid]:
                      validation.expiryYear && validation.expiryYear != null,
                    [classes.inValid]:
                      !validation.expiryYear && validation.expiryYear != null,
                  }),
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: clsx({
                    [classes.labelRoot]: 1,
                    [classes.valid]:
                      validation.expiryYear && validation.expiryYear != null,
                    [classes.inValid]:
                      !validation.expiryYear && validation.expiryYear != null,
                  }),
                  outlined: classes.labelOutlined,
                  shrink: classes.labelShrink,
                },
              }}
              inputProps={{
                id: "expiry-year",
                readOnly: true,
              }}
              label={t("Expiry Year*")}
              fullWidth
              variant="outlined"
              className={classes.twoTextFields}
            />
          </div>
          <TextField
            disabled={initializing}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: clsx({
                  [classes.labelRoot]: 1,
                  [classes.valid]:
                    validation.securityCode && validation.securityCode != null,
                  [classes.inValid]:
                    !validation.securityCode && validation.securityCode != null,
                }),
                outlined: classes.labelOutlined,
                shrink: classes.labelShrink,
              },
            }}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
                root: classes.inputRoot,
                notchedOutline: clsx({
                  [classes.valid]:
                    validation.securityCode && validation.securityCode != null,
                  [classes.inValid]:
                    !validation.securityCode && validation.securityCode != null,
                }),
                adornedEnd: classes.tooltipAdornment,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Hidden smDown>
                    <Tooltip
                      enterDelay={500}
                      leaveDelay={3000}
                      title={t(
                        "You can find this 3-digit code at the back of your card."
                      )}
                      arrow
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                    >
                      <IconButton aria-label="CVV CVC Tooltip">
                        <img src={infoIcon} alt="info icon" width="16" />
                      </IconButton>
                    </Tooltip>
                  </Hidden>
                  <Hidden mdUp>
                    <Tooltip
                      enterDelay={500}
                      leaveDelay={3000}
                      open={tooltipOpen}
                      onClose={handleTooltipClose}
                      title={t(
                        "You can find this 3-digit code at the back of your card."
                      )}
                      arrow
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                    >
                      <IconButton
                        aria-label="CVV CVC Tooltip"
                        onClick={() => setTooltipOpen(true)}
                      >
                        <img src={infoIcon} alt="info icon" width="16" />
                      </IconButton>
                    </Tooltip>
                  </Hidden>
                </InputAdornment>
              ),
            }}
            inputProps={{
              id: "security-code",
              readOnly: true,
            }}
            label={t("CVV/CVC*")}
            fullWidth
            variant="outlined"
            className={classes.textField}
          />
          <Typography className={classes.disclaimerTypography}>
            {t("*Card information are not stored in our databases.")}
          </Typography>
        </div>
        <div className={classes.paymentDetails}>
          <div className={classes.paymentDetailsTextsDiv}>
            <Typography className={classes.firstTypography}>
              {t("Total Package Fees")}
            </Typography>
            <Typography className={classes.secondTypography}>
              {/* {`${t("*Subscribtion valid for")} ${
                validityPeriod ? validityPeriod : " "
              } ${t("months")}`} */}
              {t("*Car Credits valid for 3 months")}
            </Typography>
          </div>
          <Typography className={classes.paymentAmountTypography}>
            {`${amount ? amount : " "} ${t("JD")}`}
          </Typography>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  createSession(payload) {
    dispatch(createSession(payload));
  },
  requestPay(payload) {
    dispatch(requestPay(payload));
  },
  subscribePlan(payload) {
    dispatch(subscribePlan(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardInfo);
