import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import { unlockCar, getCreditInfo } from "../../store/actions/auth";
import {
  callDealershipAnonymously,
  requestRunnerCall,
} from "../../store/actions/dealership";
import encBase64 from "crypto-js/enc-base64"; // keep this import the library seems to have a bug if it was not imported
import encUTF8 from "crypto-js/enc-utf8";
import ECB from "crypto-js/mode-ecb";
import AES from "crypto-js/aes";
import { getDealerHours } from "../../store/actions/dealership";
import { logConferenceCall } from "../../store/actions/metrics";
import ActionBarTest from "./ActionBarTest";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    boxSizing: "border-box",
    border: "0.929185px solid #ADB5BA",
    borderRadius: "3px", // removed to not messup the outline's border
    padding: "16px 18px 32px 18px",
    display: "flex",
    flexDirection: "column",
    margin: "23px 0",
    [theme.breakpoints.up("md")]: {
      border: "none",
      margin: 0,
    },
  },
}));

const CreditActionBarComponent = (props) => {
  const [disabledTimes, setDisabledTimes] = useState([]);

  const {
    user,
    unlocked,
    globalUnlocked,
    carCard,
    car,
    getCreditInfo,
    isVisible,
    dealership_phone_number,
    getDealerHours,
    dealershipId,
    sellerReport,
  } = props;

  const [dealershipPhoneNumber, setDealershipPhoneNumber] = useState("");

  useEffect(() => {
    var encrypted = dealership_phone_number;
    var key = "AAAAAAAAAAAAAAAA"; //key used in Python
    key = encUTF8.parse(key);
    if (encrypted) {
      var decrypted = AES.decrypt(encrypted, key, {
        mode: ECB,
      });
      setDealershipPhoneNumber(decrypted.toString(encUTF8));
    }
  }, [dealership_phone_number]);

  useEffect(() => {
    if (user) {
      getCreditInfo({ user_id: user.user_id });
    }
  }, []);

  useEffect(() => {
    getDealerHours({
      dealershipId: "?dealership__id=" + dealershipId,
      callback: (response) => {
        setDisabledTimes(response?.response[0].component_data);
      },
    });
  }, []);

  return (
    <div>
      <ActionBarTest
        isVisible={isVisible}
        unlocked={unlocked || globalUnlocked}
        carCard={carCard}
        sellerReport={sellerReport}
        isSampleReport={car.sample_report}
        disabledTimes={disabledTimes}
        dealershipPhoneNumber={dealershipPhoneNumber}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  unlocked: state.car.car.unlocked,
  globalUnlocked: state.car.car.global_unlocked,
  credits_info: state.auth.credits_info,
  sold: state.car.car.is_sold,
  carid: state.car.car.id,
  car: state.car.car,
  plans: state.auth.plans,
  dealership_phone_number: state?.car?.car?.phone,
  dealershipId: state.car.car.dealership_id,
});

const mapDispatchToProps = (dispatch) => ({
  unlockCar(payload) {
    dispatch(unlockCar(payload));
  },
  getCreditInfo(payload) {
    dispatch(getCreditInfo(payload));
  },
  callDealershipAnonymously(payload) {
    dispatch(callDealershipAnonymously(payload));
  },
  getDealerHours(payload) {
    dispatch(getDealerHours(payload));
  },
  requestRunnerCall(payload) {
    dispatch(requestRunnerCall(payload));
  },
  logConferenceCall(payload) {
    dispatch(logConferenceCall(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditActionBarComponent);
