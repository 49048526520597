import React from "react";

const HeartEllipseIcon = (props) => {
  const { size, fill, style, className, selected, mobile} = props;
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 43 43"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21.5" fill="white" fill-opacity={mobile?0:null}/>
      <path
        d="M22 32.3523C21.6583 32.3523 21.3289 32.2286 21.0722 32.0037C20.1027 31.1559 19.1679 30.3592 18.3432 29.6565L18.339 29.6528C15.9211 27.5923 13.8331 25.8129 12.3804 24.06C10.7564 22.1004 10 20.2424 10 18.2127C10 16.2407 10.6762 14.4213 11.9039 13.0896C13.1463 11.7421 14.851 11 16.7046 11C18.09 11 19.3587 11.438 20.4755 12.3017C21.0391 12.7377 21.5499 13.2712 22 13.8936C22.4503 13.2712 22.9609 12.7377 23.5247 12.3017C24.6415 11.438 25.9102 11 27.2956 11C29.149 11 30.8539 11.7421 32.0963 13.0896C33.324 14.4213 34 16.2407 34 18.2127C34 20.2424 33.2438 22.1004 31.6198 24.0598C30.1671 25.8129 28.0793 27.5921 25.6617 29.6524C24.8356 30.3563 23.8994 31.1543 22.9276 32.0041C22.6711 32.2286 22.3415 32.3523 22 32.3523ZM16.7046 12.4059C15.2484 12.4059 13.9106 12.9871 12.9374 14.0425C11.9497 15.1138 11.4057 16.5948 11.4057 18.2127C11.4057 19.9198 12.0402 21.4466 13.4627 23.163C14.8376 24.8221 16.8828 26.5649 19.2507 28.5829L19.2551 28.5866C20.0829 29.2921 21.0213 30.0919 21.998 30.9459C22.9805 30.0902 23.9204 29.2892 24.7499 28.5826C27.1176 26.5645 29.1625 24.8221 30.5375 23.163C31.9598 21.4466 32.5943 19.9198 32.5943 18.2127C32.5943 16.5948 32.0503 15.1138 31.0626 14.0425C30.0896 12.9871 28.7516 12.4059 27.2956 12.4059C26.2288 12.4059 25.2494 12.745 24.3846 13.4137C23.6139 14.0099 23.077 14.7636 22.7623 15.2909C22.6004 15.5621 22.3155 15.7239 22 15.7239C21.6845 15.7239 21.3996 15.5621 21.2377 15.2909C20.9232 14.7636 20.3863 14.0099 19.6154 13.4137C18.7506 12.745 17.7712 12.4059 16.7046 12.4059Z"
        fill={mobile?"white":"#07303E"}
      />
    </svg>
  );
};

export default HeartEllipseIcon;
