import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { listPlans } from "../../store/actions/subscriptionPlans";
import PlanBenefits from "./PlanBenefits";
import { planGems } from "../../helpers/planGems";
import whatWeInspect from "../../images/whatWeInspect.png";
import whatWeInspectAr from "../../images/whatWeInspectAr.png";
import PopupDrawer from "./PopupDrawer";
import { withRouter, useHistory } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../theme";
import ChoosePlan from "./Free/ChoosePlan";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "48.75px 56.25px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "54.6px 63px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "65px 75px",
    },
  },
  overflowDiv: {
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      height: "352.5px",
      margin: "-48.75px -56.25px 0",
    },
    [theme.breakpoints.up("lg")]: {
      height: "394.8px",
      margin: "-54.6px -63px 0",
    },
    [theme.breakpoints.up("xl")]: {
      height: "470px",
      margin: "-65px -75px 0",
    },
  },
  backgroundDiv: {
    position: "relative",
    background: "#186985",
    display: "flex",
    flexDirection: "column",
    "&::before": {
      boxShadow: "0 0 0 100px #186985",
      borderRadius: "100%",
      position: "absolute",
      content: "''",
      right: "-3%",
      left: "-3%",
      top: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "303.75px",
      "&::before": {
        height:
          "144px" /* You can change height to increase or decrease concave radius */,
      },
    },
    [theme.breakpoints.up("lg")]: {
      height: "340.2px",
      "&::before": {
        height: "159.6px",
      },
    },
    [theme.breakpoints.up("xl")]: {
      height: "405px",
      height: "340.2px",
      "&::before": {
        height: "192px",
      },
    },
  },
  header: {
    fontWeight: 600,
    color: "white",
    [theme.breakpoints.up("md")]: {
      fontSize: "22.5px",
      margin: "48.75px auto 12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "25.2px",
      margin: "54.6px auto 13.44px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "30px",
      margin: "65px auto 16px",
    },
  },
  subHeader: {
    fontWeight: 500,
    color: "white",
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15.12px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },
  subscriptionPlansDiv: {
    zIndex: 1,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginTop: "-173.625px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "-194.46px",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "-231.5px",
    },
  },
  planMainDiv: {
    backgroundColor: "#F9FAFD",
    border: "1px solid #C4C4C4",
    boxSizing: "border-box",
    boxShadow: "0px 3.28852px 5.26164px rgba(10, 47, 60, 0.2)",
    borderRadius: "5px",
    [theme.breakpoints.up("md")]: {
      padding: "12.375px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "13.86px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "16.5px",
    },
  },
  planHeaderDiv: {
    display: "flex",
    backgroundColor: "#F6FCFF",
    border: "1.5px solid #C8CACC",
    boxSizing: "border-box",
    borderRadius: "5px 5px 0 0",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      margin: "-13.125px -13.125px 0",
      padding: "15px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "-14.7px -14.7px 0",
      padding: "16.8px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "-17.5px -17.5px 0",
      padding: "20px",
    },
  },
  planIcon: {
    borderRight: "2px solid #C8CACC",
    padding: "0 8.5px 0 0",
    [theme.breakpoints.up("md")]: {
      width: "41.25px",
      height: "37.5px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "46.406px",
      height: "42.1875px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "55px",
      height: "50px",
    },
  },
  planTitle: {
    fontWeight: 500,
    color: "#07303E",
    lineHeight: 1.3,
    [theme.breakpoints.up("md")]: {
      fontSize: "16.5px",
      marginLeft: "8px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18.48px",
      marginLeft: "8px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "22px",
      marginLeft: "8px",
    },
  },
  priceDiv: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    // borderBottom: "1px solid #C8CACC",
    // [theme.breakpoints.up("md")]: {
    //   padding: "12px",
    //   marginBottom: "12px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   padding: "13.44px",
    //   marginBottom: "13.44px",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   padding: "16px",
    //   marginBottom: "16px",
    // },
  },
  priceTypographyDiv: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "3.375px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "3.78px",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "4.5px",
    },
  },
  priceTypography: {
    fontWeight: 700,
    color: "#07303E",
    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      fontSize: "34.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "38.64px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "46px",
      // fontSize: "42px",
    },
  },
  unitTypography: {
    // fontSize: "18px",
    fontWeight: 700,
    color: "#07303E",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15.12px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },
  perCarTypography: {
    fontWeight: 600,
    color: "#6B8495",
    lineHeight: 1,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.44px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  },
  choosePlanButton: {
    textTransform: "none",
    fontWeight: 600,
    borderRadius: "5px",
    lineHeight: 1,
    height: "45px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: "9px",
      margin: "12.375px 0",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
      padding: "10.08px",
      margin: "13.86px 0",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
      padding: "12px",
      margin: "16.5px 0",
    },
  },
  ////////////////////////////////
  wwiDiv: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginTop: "120.5px",
    },
    // [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {
      // marginTop: "120.5px",
    },
  },
  wwiTypo: {
    color: "#07303E",
    fontWeight: "600",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
      marginBottom: "50",
    },
    // [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {
      //       fontSize: "40px",
      // marginBottom: "50",
    },
  },
  wwiSubTitleDiv: {
    display: "flex",
    justifyContent: "center",
    // marginBottom: "56px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "56px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  wantToKnowMoreTypo: {
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    // [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  goToTypo: {
    color: "#0645AD",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    // [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  disabledBK: {
    background: "#DDDDDE",
    color: "#636363",
  },
  disabledTXT: {
    color: "#636363",
  },
  /////////////////////////////////////
}));

const WebSubscriptionsPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, listPlans, mobOpenPopup, setMobOpenPopup, history } = props;
  const locationPlans = props.location?.state?.plans;

  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const [selectedPlanOut, setSelectedPlanOut] = useState({});

  const largeUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xLargeUp = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
    if (locationPlans) {
      setPlans(locationPlans);
    } else {
      listPlans({
        callback: (results) => {
          setPlans(results.filter((plan) => !plan.free_plan));
        },
      });
    }
  }, []);

  return (
    <div className={classes.mainDiv}>
      <Hidden smDown>
        <div className={classes.overflowDiv}>
          <div className={classes.backgroundDiv}>
            <Typography className={classes.header}>
              {t("Markabte Subscription Plans")}
            </Typography>
            <Typography className={classes.subHeader}>
              {t("Choose subscription plan that fits your needs.")}
            </Typography>
          </div>
        </div>
        <div className={classes.subscriptionPlansDiv}>
          <Grid container spacing={xLargeUp ? 5 : largeUp ? 4 : 3}>
            {/* // 1=>5 */}
            {console.log("wertyuio", plans)}
            {plans.map((plan, index) => (
              <Grid item xs={4}>
                <div className={classes.planMainDiv}>
                  <div
                    style={{
                      justifyContent: "space-between",
                    }}
                    className={classes.planHeaderDiv}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={planGems[index]}
                        // width={"55px"}
                        // height={"50px"}
                        style={
                          {
                            // borderRight: "2px solid #C8CACC",
                            // padding: "0 8.5px 0 0",
                          }
                        }
                        className={classes.planIcon}
                      />
                      <Typography className={classes.planTitle}>
                        {localStorage.getItem("language") == "en"
                          ? `${plan.title_en}`
                          : `${plan.title_ar}`}
                      </Typography>
                    </div>
                    <div className={classes.priceDiv}>
                      <Typography className={classes.priceTypography}>
                        {plan?.price_per_car}
                      </Typography>
                      <div className={classes.priceTypographyDiv}>
                        <Typography className={classes.unitTypography}>
                          {t("JDS")}
                        </Typography>
                        <Typography className={classes.perCarTypography}>
                          {t("Per car")}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <PlanBenefits
                    plan={plan}
                    // disabled={isupgrade && currentPlanId >= index}
                    // disableClass={classes.disabledTXT}
                  />
                  <Button
                    color="primary"
                    variant={"outlined"}
                    className={classes.choosePlanButton}
                    fullWidth
                    // disabled={isupgrade && currentPlanId >= index}
                    // disabled={!user}
                    onClick={() => {
                      if (user) {
                        setSelectedPlan(index);
                        setOpen(true);
                      } else {
                        history.push({
                          pathname: "/signin",
                          state: { sellerVDP: "/subscription-plans" },
                        });
                      }
                    }}
                  >
                    {t("Continue to Payment")}
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <PopupDrawer open={open} setOpen={setOpen} plan={plans[selectedPlan]} />

        {/* ////////////////////////////////////////////////////////// */}
        <div className={classes.wwiDiv}>
          <Typography className={classes.wwiTypo}>
            {t("What We Inspect?")}
          </Typography>

          <div className={classes.wwiSubTitleDiv}>
            <Typography className={classes.wantToKnowMoreTypo}>
              {t("Want to know more?")}
            </Typography>
            <Typography
              style={{ cursor: "pointer" }}
              className={classes.goToTypo}
              onClick={() =>
                window.open("https://markabte.com/inspection-process", "_blank")
              }
            >
              &nbsp;
              {t("Go to inspection process >")}
            </Typography>
          </div>
          <div style={{ margin: "auto" }}>
            <img
              style={{
                width: "83.5vw",
                maxWidth: "1069px",
              }}
              src={
                localStorage.getItem("language") == "ar"
                  ? whatWeInspectAr
                  : whatWeInspect
              }
            />
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////// */}
      </Hidden>

      <Hidden mdUp>
        <ChoosePlan
          open={mobOpenPopup}
          setChoosePlanOpen={setMobOpenPopup}
          plans={plans}
          setSelectedPlanOut={setSelectedPlanOut}
          setPopupDrawerOpen={
            user
              ? setOpen
              : () =>
                  history.push({
                    pathname: "/signin",
                  })
          }
        />
        <PopupDrawer open={open} setOpen={setOpen} plan={selectedPlanOut} />
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  listPlans(payload) {
    dispatch(listPlans(payload));
  },
});

WebSubscriptionsPage.defaultProps = {
  // plans:[]
};

WebSubscriptionsPage.propTypes = {
  // plans:PropTypes.func
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebSubscriptionsPage)
);
