import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Grid,
  Hidden,
  DialogActions,
  Button,
} from "@material-ui/core";

// import BasicGem from "../../images/SubscriptionPlans/BasicGem.png";
// import StandardGem from "../../images/SubscriptionPlans/StandardGem.png";
// import PremiumGem from "../../images/SubscriptionPlans/PremiumGem.png";
import PlanBenefits from "../PlanBenefits";
import { planGems } from "../../../helpers/planGems";
import { Dialog } from "@material-ui/core";
import BackArrow from "../../icons/BackArrow";

const useStyles = makeStyles((theme) => ({
  // dialogPaper: { padding: "20px 16px" },
  mainDiv: {
    padding: "16px",
  },
  header: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
    marginBottom: "4px",
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#07303E",
    marginBottom: "16px",
  },
  gridItem: {
    flexGrow: 0,
    // maxWidth: "26.8%",
    // flexBasis: "26.8%",
    maxWidth: "33.3333%",
    flexBasis: "33.3333%",
    marginBlock: "8px",
  },
  buttonMainDiv: {
    // border: "1px solid #C4C4C4",
    borderRadius: "5px",
    maxHeight: "87%.2",
  },
  buttonMainDivSelected: {
    border: "1px solid #186985",
    borderRadius: "5px",
    background: "#F6FCFF",
    maxHeight: "87%.2",
  },
  buttonHeaderDiv: {
    // display: "flex",
    borderBottom: "1px solid #C4C4C4",
    padding: "5.5px 0",
    marginInline: "5.5px",
    justifyContent: "center",
    textAlign: "center",
  },
  buttonHeaderDivDisabled: {
    display: "flex",
    borderBottom: "1px solid #C4C4C4",
    padding: "5.5px",
    background: "#DDDDDE",
    justifyContent: "center",
  },
  buttonHeaderDivSelected: {
    borderBottom: "1px solid #07303E",
    // backgroundColor: "#07303E",
  },
  planTitle: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#07303E",
    marginLeft: "6px",
  },
  planTitleDisable: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#B0B0B0",
    marginLeft: "6px",
  },
  planTitleSelected: {
    color: "#07303E",
  },
  buttonPriceDiv: {
    display: "flex",
    justifyContent: "center",
    padding: "11px",
  },
  buttonPriceDivDisabled: {
    display: "flex",
    justifyContent: "center",
    padding: "11px",
    background: "#DDDDDE",
  },
  scalePrice: {
    transform: "scale(1.1)",
  },
  priceTypographyDiv: {
    display: "flex",
  },
  priceTypography: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#07303E",
    lineHeight: 1,
  },
  priceTypographyDisabled: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#B0B0B0",
    lineHeight: 1,
  },
  unitTypography: {
    fontSize: "10px",
    fontWeight: 700,
    color: "#07303E",
    alignSelf: "center",
  },
  unitTypographyDisabled: {
    fontSize: "10px",
    fontWeight: 700,
    color: "#B0B0B0",
    alignSelf: "center",
  },
  perCarTypography: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#6B8495",
  },
  perCarTypographyDisabled: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#B0B0B0",
  },

  mobileHeader: {
    display: "flex",
    backgroundColor: "#186985",
    width: "calc(100% - 20px)",
    padding: "10px",
    marginBottom: "7px",
  },
  arrowDiv: {
    cursor: "pointer",
    marginRight: "10px",
  },
  headerArrow: {
    alignSelf: "center",
  },
  navHeader: {
    fontSize: "14px",
    fontWeight: 500,
    color: "white",
  },
  dialogActions: {
    padding: "10px 16px",
    marginTop: "auto",
    boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
  },
  continueButton: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1,
    padding: "12px",
  },
}));

const ChoosePlan = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    open,
    setChoosePlanOpen,
    setSelectedPlanOut,
    setPopupDrawerOpen,
    plans,
    setChargeOptionsOpen,
    isUpgrade,
    credits_info,
    setPlan,
    userPlan,
    refillcloser,
  } = props;

  const [selectedPlan, setSelectedPlan] = useState(0);

  useEffect(() => {
    userPlan && setSelectedPlan(userPlan - 1);
    // setPlan(plans[selectedPlan]);
  }, [credits_info]);

  useEffect(() => {
    setPlan && setPlan(plans[selectedPlan]);
  }, [selectedPlan]);

  // const planGems = [BasicGem, StandardGem, PremiumGem];
  const handleClose = () => {
    // setChoosePlanOpen(false);
    setChargeOptionsOpen(false);
    setChoosePlanOpen(false);
    // refillcloser();
  };
  return (
    <Dialog
      open={open}
      // open={true}
      onClose={handleClose}
      fullScreen
      // classes={{ paperFullScreen: classes.dialogPaper }}
    >
      <Hidden mdUp>
        <div className={classes.mobileHeader}>
          <div onClick={handleClose} className={classes.arrowDiv}>
            <BackArrow
              size="10"
              fill="white"
              className={classes.headerArrow}
              style={
                localStorage.getItem("language") == "en"
                  ? {}
                  : { transform: "rotate(180deg)" }
              }
            />
          </div>
          <Typography className={classes.navHeader}>
            {t("Charge Car Credits")}
          </Typography>
        </div>
      </Hidden>
      <div className={classes.mainDiv}>
        <Typography className={classes.header}>
          {t("Car Credits Pricings")}
        </Typography>
        <Typography className={classes.subHeader}>
          {`${t("Choose the pricing that fits your needs")}:`}
        </Typography>
        <Grid
          container
          style={{
            border: "1.5px solid #C4C4C4",
            borderRadius: "5px",
            paddingInline: "8.8px",
          }}
          /*spacing={1}*/ justify={"space-between"}
        >
          {console.log("4444444448", plans)}
          {plans.map((plan, index) =>
            index > userPlan - 2 ? (
              <Grid item /*xs={4}*/ className={classes.gridItem}>
                <div
                  className={clsx({
                    [classes.buttonMainDiv]: 1,
                    [classes.buttonMainDivSelected]: selectedPlan == index,
                  })}
                  onClick={() => {
                    setSelectedPlan(index);
                    props.setSelectedPlanOut(plans[index]);
                    setPlan && setPlan(plans[index]);
                  }}
                >
                  <div
                    className={clsx({
                      [classes.buttonHeaderDiv]: 1,
                      // [classes.buttonHeaderDivSelected]: selectedPlan == index,
                    })}
                  >
                    <img src={planGems[index]} width={"40px"} height={"40px"} />
                    <Typography
                      className={clsx({
                        [classes.planTitle]: 1,
                        // [classes.planTitle]: selectedPlan == index,
                      })}
                    >
                      {plan?.title}
                    </Typography>
                  </div>
                  <div className={classes.buttonPriceDiv}>
                    <div
                      className={clsx({
                        [classes.scalePrice]: selectedPlan == index,
                      })}
                    >
                      <div className={classes.priceTypographyDiv}>
                        <Typography className={classes.priceTypography}>
                          {plan?.price_per_car}
                        </Typography>
                        <Typography className={classes.unitTypography}>
                          {t("JDS")}
                        </Typography>
                      </div>
                      <Typography className={classes.perCarTypography}>
                        {t("Per car")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid item /*xs={4}*/ className={classes.gridItem}>
                <div
                  className={clsx({
                    [classes.buttonMainDiv]: 1,
                    [classes.buttonMainDivSelected]: selectedPlan == index,
                  })}
                  // onClick={() => alert("hi")}
                >
                  <div
                    className={clsx({
                      [classes.buttonHeaderDivDisabled]: 1,
                      // [classes.buttonHeaderDivSelected]: selectedPlan == index,
                    })}
                  >
                    <img src={planGems[index]} width={"18px"} height={"15px"} />
                    <Typography
                      className={clsx({
                        [classes.planTitleDisable]: 1,
                        // [classes.planTitle]: selectedPlan == index,
                      })}
                    >
                      {plan?.title}
                    </Typography>
                  </div>
                  <div className={classes.buttonPriceDivDisabled}>
                    <div
                      className={clsx({
                        [classes.scalePrice]: selectedPlan == index,
                      })}
                    >
                      <div className={classes.priceTypographyDiv}>
                        <Typography className={classes.priceTypographyDisabled}>
                          {plan?.price_per_car}
                        </Typography>
                        <Typography className={classes.unitTypographyDisabled}>
                          {t("JDS")}
                        </Typography>
                      </div>
                      <Typography className={classes.perCarTypographyDisabled}>
                        {t("Per car")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )
          )}
        </Grid>
        <PlanBenefits plan={plans[selectedPlan]} />
      </div>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            setSelectedPlanOut(plans[selectedPlan]);
            handleClose();
            setPopupDrawerOpen(true);
          }}
        >
          {t("Continue to Payment")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChoosePlan.defaultProps = {
  open: false,
  plans: [],
  setChoosePlanOpen: () => {},
  setSelectedPlanOut: () => {},
  setPopupDrawerOpen: () => {},
  setChargeOptionsOpen: () => {},
  userPlan: 0,
};

ChoosePlan.propTypes = {
  open: PropTypes.bool,
  plans: PropTypes.array,
  setChoosePlanOpen: PropTypes.func,
  setSelectedPlanOut: PropTypes.func,
  setPopupDrawerOpen: PropTypes.func,
  setChargeOptionsOpen: PropTypes.func,
  userPlan: PropTypes.number,
};

export default ChoosePlan;
