import React from "react";
import { Redirect, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Language } from "@material-ui/icons";

const Promo = (props) => {
  const { size, fill, style, className, hood, roof, trunk, margin, user } =
    props;
  const { t } = useTranslation();

  return (
    <svg
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.8791 4.22514C80.3098 7.9529 85.5837 9.36597 90.4189 7.85302C97.7259 5.56649 105.427 10.0125 107.1 17.4838C108.207 22.4275 112.068 26.2883 117.012 27.3957C124.483 29.0689 128.93 36.7701 126.643 44.0771C125.13 48.9123 126.543 54.1862 130.271 57.6169C135.905 62.8017 135.905 71.6941 130.271 76.8788C126.543 80.3096 125.13 85.5835 126.643 90.4186C128.93 97.7256 124.483 105.426 117.012 107.1C112.068 108.207 108.208 112.068 107.1 117.012C105.427 124.483 97.7259 128.929 90.4189 126.643C85.5837 125.13 80.3098 126.543 76.8791 130.271C71.6943 135.904 62.8019 135.904 57.6172 130.271C54.1864 126.543 48.9125 125.13 44.0774 126.643C36.7703 128.929 29.0695 124.483 27.396 117.012C26.2889 112.068 22.4281 108.207 17.484 107.1C10.0128 105.427 5.56641 97.7256 7.85327 90.4186C9.36622 85.5835 7.95315 80.3096 4.2254 76.8788C-1.40847 71.6941 -1.40847 62.8017 4.2254 57.6169C7.95315 54.1862 9.36622 48.9123 7.85327 44.0771C5.56674 36.7701 10.0128 29.0692 17.484 27.3957C22.4278 26.2887 26.2886 22.4279 27.396 17.4838C29.0692 10.0125 36.7703 5.56616 44.0774 7.85302C48.9125 9.36597 54.1864 7.9529 57.6172 4.22514C62.8019 -1.40838 71.6943 -1.40838 76.8791 4.22514V4.22514Z"
        fill="#186985"
      />
      <path
        d="M130.272 57.6167C126.545 54.1859 125.131 48.912 126.644 44.0768C128.931 36.7698 124.485 29.069 117.014 27.3955C112.07 26.2884 108.209 22.4276 107.102 17.4835C105.503 10.3444 98.4004 5.97212 91.3967 7.59199C96.1169 8.69168 100.072 12.3809 101.214 17.4835C102.322 22.4273 106.182 26.2881 111.126 27.3955C118.597 29.0686 123.044 36.7698 120.757 44.0768C119.244 48.912 120.657 54.1859 124.385 57.6167C130.019 62.8014 130.019 71.6938 124.385 76.8785C120.657 80.3093 119.244 85.5832 120.757 90.4184C123.043 97.7254 118.597 105.426 111.126 107.1C106.182 108.207 102.322 112.068 101.214 117.012C100.071 122.114 96.1166 125.804 91.3967 126.903C98.4004 128.523 105.503 124.151 107.102 117.012C108.209 112.068 112.07 108.207 117.014 107.1C124.485 105.427 128.931 97.7254 126.644 90.4184C125.132 85.5832 126.545 80.3093 130.272 76.8785C135.906 71.6938 135.906 62.8014 130.272 57.6167V57.6167Z"
        fill="#0B536B"
      />
      <path
        d="M67.2502 119.719C96.2288 119.719 119.721 96.2269 119.721 67.2483C119.721 38.2697 96.2288 14.7779 67.2502 14.7779C38.2716 14.7779 14.7798 38.2697 14.7798 67.2483C14.7798 96.2269 38.2716 119.719 67.2502 119.719Z"
        fill="#FBFBFB"
      />
      <text
        textAnchor="middle"
        x="50%"
        y="40%"
        font-family="'Poppins', sans-serif"
        font-size="16"
        fill="#07303E"
      >
        {user?.first_name}
      </text>
      <text
        textAnchor="middle"
        x="50%"
        y="66%"
        font-family={
          localStorage.getItem("language") === "en"
            ? "'Poppins', sans-serif"
            : "'Cairo', sans-serif;"
        }
        font-size="14"
        fill="#636363"
      >
        {t("Free Plan")}
      </text>
    </svg>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  unseenCarListings: state.websockets.unseenCarListings,
});

export default withRouter(connect(mapStateToProps, null)(Promo));
