import {
  FETCH_CAR_REQUEST,
  FETCH_CAR_FIELDS_REQUEST,
  FETCH_CAR_FEATURES_REQUEST,
  FETCH_CAR_FAULTS_REQUEST,
  FETCH_CAR_VISUALIZATION_REQUEST,
  LIST_CARS_REQUEST,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_GROUPS_REQUEST,
  UPDATE_CAR_FORM_REQUEST,
  FETCH_CAR_FORMS_REQUEST,
  CLEAR_CAR_LIST,
  FETCH_FIELD_OPTIONS_REQUEST,
  REPORT_CAR_PURCHASE_REQUEST,
  FETCH_CAR_DETAILS_IMAGES_REQUEST,
  GET_SIMPLE_CAR_DATA,
  GET_VH_INFO,
  GET_CAR_FEATURES,
  FETCH_CAR_VISUALIZATION_REQUEST_IDs,
  UNLOCKED_PART,
  CLEAR_UNLOCKED_PART,
} from "../constants";

export function getCarsList(payload) {
  return {
    type: LIST_CARS_REQUEST,
    payload: payload,
  };
}

export function clearCarList() {
  return {
    type: CLEAR_CAR_LIST,
  };
}

export function updateCarForm(payload) {
  return {
    type: UPDATE_CAR_FORM_REQUEST,
    payload: payload,
  };
}

export function getCarDetails(payload) {
  return {
    type: FETCH_CAR_DETAILS_REQUEST,
    payload: payload,
  };
}

export function getCarDetailImages(payload) {
  return {
    type: FETCH_CAR_DETAILS_IMAGES_REQUEST,
    payload: payload,
  };
}

export function getCarGroups(payload) {
  return {
    type: FETCH_CAR_GROUPS_REQUEST,
    payload: payload,
  };
}

export function getCarForms(payload) {
  return {
    type: FETCH_CAR_FORMS_REQUEST,
    payload: payload,
  };
}
export function getCarFaults(payload) {
  return {
    type: FETCH_CAR_FAULTS_REQUEST,
    payload: payload,
  };
}
export function getCarVisualization(payload) {
  return {
    type: FETCH_CAR_VISUALIZATION_REQUEST,
    payload: payload,
  };
}

export function getCarVisualizationIDs(payload) {
  return {
    type: FETCH_CAR_VISUALIZATION_REQUEST_IDs,
    payload: payload,
  };
}

export function getCar(payload) {
  return {
    type: FETCH_CAR_REQUEST,
    payload: payload,
  };
}
export function getFieldOption(payload) {
  return {
    type: FETCH_FIELD_OPTIONS_REQUEST,
    payload: payload,
  };
}

export function reportCarPurchase(payload) {
  return {
    type: REPORT_CAR_PURCHASE_REQUEST,
    payload: payload,
  };
}

export function simpleCarData(payload) {
  return {
    type: GET_SIMPLE_CAR_DATA,
    payload: payload,
  };
}

export function VHCarInfo(payload) {
  return {
    type: GET_VH_INFO,
    payload: payload,
  };
}

export function CarFeatures(payload) {
  return {
    type: GET_CAR_FEATURES,
    payload: payload,
  };
}

export function getUnlockedPart(payload) {
  return {
    type: UNLOCKED_PART,
    payload: payload,
  };
}

export function clearUnlockedPart(payload) {
  return {
    type: CLEAR_UNLOCKED_PART,
    payload: payload,
  };
}
