import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  headerVisualization: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "0 0 15px",
      width: "200px",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "clamp(20px,2vw,24px)",
      margin: "0 0 36px 5px",
      width: "37%",
      transform: "none",
    },
  },
  headerVisualizationPaper: {
    borderRadius: 0,
    background: "#FFFFFF",
    boxSizing: "border-box",
    backgroundColor: "unset",
    boxShadow: "none",

    [theme.breakpoints.down("sm")]: {
      padding: "18px 20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "34px 20px",
    },
  },
  visualizationImageStyle: {
    width: "100%",
    objectFit: "cover",
    height: "auto",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    position: "relative",
    filter: "blur(5px) brightness(50%)",
    borderRadius: "2px",
  },
  reportGrid: {
    backgroundColor: "#f3f3f3",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 50px",
    },
  },
  reportPaper: {
    backgroundColor: "unset",
    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 15px",
      margin: "50px 17px 0 4px",
    },
  },
  firstGridItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "56px",
    },
  },
  visualizationDiv: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "60vw",
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      height: "min(28vw,calc(56.5vw - 342.67px))",
      maxWidth: "734px",
      maxHeight: "419px",
      margin: "auto",
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const VisualisationSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.reportGrid}>
      <Grid item sm={12} md={9} className={classes.firstGridItem}>
        <Paper className={classes.reportPaper}>
          <Paper className={classes.headerVisualizationPaper}>
            <Skeleton className={classes.headerVisualization}></Skeleton>
            <Skeleton className={classes.headerVisualization}></Skeleton>
            <div>
              <div className={classes.visualizationDiv}>
                <Skeleton className={classes.visualizationImageStyle} />
              </div>
            </div>
          </Paper>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default VisualisationSkeleton;
