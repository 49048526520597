import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import ForwardArrowIcon from "../components/icons/ForwardArrow.js";
import BackArrowIcon from "../components/icons/BackArrow.js";

import OpenListIcon from "../components/icons/VisOpenList.js";

import {
  getCarFaults,
  getCarVisualization,
  getCarVisualizationIDs,
} from "../store/actions/car";
import { toggleNotification } from "../store/actions/notification";
import { updatePoints } from "../store/actions/forms";

import Button from "../components/Button";
import DrawerLeft from "../components/CosmeticConditionDrawerProto";
import { useTranslation } from "react-i18next";
import theme from "../theme";
import HotSpot from "../components/HotSpot.js";

import DFAIcon from "../components/icons/DFAIcon.js";
import PRAIcon from "../components/icons/PRAIcon.js";
import DVIcon from "../components/icons/DVIcon.js";
import PVIcon from "../components/icons/PVIcon.js";
import THDIcon from "../components/icons/THDIcon.js";
import TVIcon from "../components/icons/TVIcon.js";

import Skeleton from "@material-ui/lab/Skeleton";
import { ListItemIcon } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { logVisitorView } from "../store/actions/accounts";
import Moment from "moment";

const positions = [
  {
    id: "ext",
    title: "Exterior",
    sides: [
      { id: "dfv", title: "Driver Front View" },
      { id: "prf", title: "Passenger Rear View" },
    ],
  },
  {
    id: "int",
    title: "Interior",
    sides: [
      { id: "fv", title: "Front View" },
      { id: "rf", title: "Rear View" },
      { id: "3rd", title: "3rd Row View" },
      { id: "tv", title: "Trunk View" },
    ],
  },
];

const sideIconsStyle = { height: "4.93vw", width: "9.86vw" };

const combinedPositions = {
  dfv: "ext",
  prf: "ext",
  fv: "int",
  rf: "int",
  "3rd": "int",
  tv: "int",
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "black",
    },
  },
  landStepperImg: {
    overflow: "hidden",
    display: "block",
    margin: "auto",
    width: !isIOS()
      ? "calc(calc(100vh - 122.5px) / 0.75)"
      : `calc(calc(${window.innerHeight}px - 103.5px) / 0.75)`,
    height: !isIOS()
      ? "calc(100vh - 122.5px)"
      : `calc(${window.innerHeight}px - 103.5px)`,
    objectFit: "cover",
  },
  portStepperImg: {
    overflow: "hidden",
    display: "block",
    margin: "auto",
    height: isIOS() ? "calc(100vw - 103.5px)" : "calc(100vw - 122.5px)",
    width: isIOS()
      ? "calc(calc(100vw - 103.5px) / 0.75)"
      : "calc(calc(100vw - 122.5px) / 0.75)",
    objectFit: "cover",
  },
  visImageDiv: {
    height: "100%",
    width: "100%",
    right: 0,
    position: "fixed",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "black",
      // backgroundColor: "yellow",
    },
  },
  imgBig: {
    display: "block",
    margin: "auto",
    width: "600px",
    height: "450px",
    objectFit: "cover",
    // position: "absolute",
    // transform: "translate(-50%,-55%)",
    // left: "50%",
    // bottom: 0,
    borderRadius: "4px",
  },
  fullland: {
    // styles for image in landscape orientation
    position: "fixed",
    top: "50%",
    left: "calc(50% - 7.8vw)",
    transform: "translate(-50%, -50%)",
    objectFit: "cover",
    width: "auto",
  },
  fullport: {
    // styles for image in portrait orientation
    position: "fixed",
    left: "0",
    transformOrigin: "top left", ///
    transform: "rotate(90deg) translate(0, -100%)",
    transition: "none",
    objectFit: "cover",
  },
  webExit: {
    // styles for X in landscape orientation
    position: "fixed",
    top: "0%", // this will work for web but not mobile fix later
    left: "100%", // this will work for web but not mobile fix later
    transform: "translate(-50%, -50%)",
  },
  MobExit: {
    position: "fixed",
    top: "32px",
    left: "32px",
    transform: "translate(-50%, -50%)",
  },
  closeIconGrid: {
    borderRadius: "50%",
    backgroundColor: "rgba(7, 48, 62, 0.8)",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(0, 0, 0, 0.49)",
    },
  },
  closeIcon: {
    fill: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18.1px",
      padding: "3px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22.8px",
      padding: "3.8px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "26.6px",
      padding: "3.8px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "31.4px",
      padding: "3.8px",
    },
  },
  portDiv: {
    left: "0",
    transform: "rotate(90deg) translate(0, -100%)",
    transformOrigin: "top left",
  },
  landDiv: {
    top: "50%",
    left: "calc(50% - 7.8vw)",
    transform: "translate(-50%, -50%)",
  },
  exampleWrapper: {
    position: "relative",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  views: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  popoverPaper: {
    minWidth: "395px",
    backgroundColor: "rgba(255,255,255,0)",
  },
  selectionButton: {
    borderLeft: "none!important",
    borderTopLeftRadius: "0px!important",
    borderTopRightRadius: "8px!important",
    borderBottomRightRadius: "8px!important",
    borderBottomLeftRadius: "0px!important",
    [theme.breakpoints.up("md")]: {
      width: "110px!important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "110px!important",
    },
    [theme.breakpoints.up("xl")]: {
      width: "126px!important",
    },
  },
  bigScreenImage: {
    objectFit: "cover",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      height: "419.95px",
      width: "559.93px",
      right: "140px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "472.33px",
      width: "629.77px",
      right: "130px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "540px",
      width: "720px",
      right: "126px",
    },
  },
  viewSelection: {
    width: "72.4138%",
    height: "15.9827%",
    position: "fixed",
    right: "0",
    backgroundColor: "white",
    zIndex: "2000",
    backgroundColor: "white",
    display: "flex",
    top: "84.0183%",
    backgroundColor: "#F3F4FA",
  },
  viewTitle: {
    color: "#07303E",
    textAlign: "center",
    fontWeight: "600",
    minWidth: "100%",
    padding: "3.5px 0",
    borderRadius: "0 0 8px 8px",
    [theme.breakpoints.up("md")]: {
      fontSize: "9.34px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "10.51px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "12px",
    },
  },
  choicesDiv: {
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      right: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      right: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      right: "0px",
    },
  },
  selectionDiv: {
    border: "1px solid #7D929C",
    borderRadius: "8px",
    marginBottom: "4.8124px",
    backgroundColor: "#F3F4FA",
    padding: 0,
    float: "right",
    [theme.breakpoints.up("md")]: {
      height: "65.98px",
      width: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "74.73px",
      width: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "86px",
      width: "117px",
    },
  },
  selectionLabel: {
    flexWrap: "wrap",
  },
  viewSelectionImage: {
    borderBottom: "1px solid #7D929C",
    [theme.breakpoints.up("md")]: {
      height: "41px",
      width: "fit-content",
      objectFit: "cover",
    },
    [theme.breakpoints.up("lg")]: {
      height: "46px",
    },
    [theme.breakpoints.up("xl")]: { height: "58px" },
  },
  viewSelectionImageWidth: {
    [theme.breakpoints.up("md")]: {
      height: "46px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "51px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "59px",
    },
  },

  imgDiv: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      height: "419.95px",
      width: "559.93px",
      right: "140px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "472.33px",
      width: "629.77px",
      right: "130px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "540px",
      width: "720px",
      right: "126px",
    },
  },
  dialogPaperWidthSm: {
    maxWidth: 700,
  },
  dialogPaper: {
    backgroundColor: "transparent",
    height: "100%",
    maxHeight: isIOS && "100vh",
    margin: 0,
    boxShadow: "none",
  },
  dialogContentRoot: {
    height: "60vh",
    overflow: "hidden",
  },
  landIosDialogContentRoot: {
    // width: "60vw",
    width: "calc(calc(100vh - 103.5px) / 0.75)",
    overflow: "hidden",
  },
  visualizationDialogDiv: {
    position: "relative",
    transform: "translate(0%,0%)",
    top: "50%",
    left: "0%",

    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },

    [theme.breakpoints.up("md")]: {
      width: "947.04px",
      height: "419.95px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1037px",
      height: "472.33px",
      right: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1165px",
      height: "540px",
    },
  },
  imageStepperTypography: {
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  stepperToggler: {
    display: "inline-flex",
    height: "33.04px",
    width: "33.06px",
    top: "100%",
    transform: "translate(0,-100%)",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  bigStepCounterDiv: {
    position: "absolute",
    height: "33.04px",
    width: "33.06px",
    top: "100%",
    transform: "translate(0,-100%)",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  landStepCounterDiv: {
    position: "absolute",
    top: "100%",
    transform: "translate(0,-100%)",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    height: "7.38vh",
    width: "6.25vw",
    display: "flex",
  },
  portStepCounterDiv: {
    position: "absolute",
    top: "100%",
    transform: "translate(0,-100%)",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    height: "7.38vw",
    width: "6.25vh",
    display: "flex",
  },
  stepCounter: {
    color: "white",
    padding: "8px 17px",
    fontSize: "14px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "5px 6px",
      margin: "auto",
    },
  },
  bigStepperBackArrow: {
    // position: "absolute",
    display: "flex",
    top: "70%",
    // transform: "translate(0,-50%)",
    cursor: "pointer",
    height: "3em",
    width: "3em",
    // transform: "translate(0,50%)",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  landStepperBackArrow: {
    // position: "absolute",
    display: "flex",
    top: "100%",
    // transform: "translate(0,15%)",
    background: "#fff",
    color: "#186985",
    cursor: "pointer",
    height: "1.9em",
    width: "1.9em",
    margin: "auto 0",
    borderRadius: "50%",
  },
  portStepperBackArrow: {
    // position: "absolute",
    display: "flex",
    // top: "50%",
    // transform: "translate(0,-50%)",
    cursor: "pointer",
    width: "5.66vh",
    height: "5.66vh",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
    // top: "100%",
    transform: !isIOS ? "translate(0,50%)" : "",
    margin: "auto 0",
  },
  bigStepperForwardkArrow: {
    // position: "absolute",
    display: "flex",
    top: "70%",
    left: "100%",
    // transform: "translate(-115%,50%)",
    cursor: "pointer",
    height: "3em",
    width: "3em",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  landStepperForwardkArrow: {
    // position: "absolute",
    display: "flex",
    top: "100%",
    left: "100%",
    // transform: "translate(-100%,15%)",
    margin: "auto 0",
    background: "#fff",
    color: "#186985",
    cursor: "pointer",
    height: "1.9em",
    width: "1.9em",
    borderRadius: "50%",
  },
  portStepperForwardkArrow: {
    // position: "absolute",
    display: "flex",
    // top: "70%",
    // left: "100%",
    // transform: "translate(-100%,-50%)",
    cursor: "pointer",
    width: "5.66vh",
    height: "5.66vh",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
    // top: "100%",
    left: "100%",
    transform: !isIOS ? "translate(-100%,15%)" : "",
    // : "translate(-100%,50%)",
    margin: "auto 0",
  },
  viewsIconsDiv: { left: "100%" },
  viewListDiv: {
    height: "38px",
    width: "38px",
    backgroundColor: "rgba(0,0,0, 0.75)", // transparent inner black circle
    border: "1px solid rgba(255, 255, 255, 0.75)", // transparent white ring
    transform: "translate(-50%,-50%)",
    position: "absolute",
    left: "32px",
    borderRadius: "50%",
    display: "flex",
    top: "calc(100% - 40px)",
    boxShadow: "0 0 0px 2px rgba(0,0,0, 0.75)", // transparent outer black ring
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  visButtonTextLand: {
    fontSize: "1.4vw",
    maxWidth: "100%",
    marginBottom: "auto",
    textTransform: "none",
  },
  visButtonTextPort: {
    fontSize: "1.4vh",
    maxWidth: "100%",
    marginBottom: "auto",
    textTransform: "none",
  },
  visButtonRootLand: {
    maxHeight: "none",
    height: "calc(19vh - 3px)",
    minHeight: "calc(12vw - 3px)",
  },
  visButtonRootPort: {
    maxHeight: "none",
    height: "calc(19vw - 3px)",
    minHeight: "calc(12vh - 3px)",
  },
  visButtonRootTHDLand: {
    maxHeight: "none",
    height: "calc(16vh - 3px)",
    minHeight: "calc(12vw - 3px)",
  },
  visButtonRootTHDPort: {
    maxHeight: "none",
    height: "calc(16vw - 3px)",
    minHeight: "calc(12vh - 3px)",
  },
  visButtonSelected: {
    background:
      "linear-gradient(180deg, rgba(127, 241, 248, 0) 0%, rgba(0, 228, 242, 0.63) 100%)",
  },
  visButtonWrapper: {
    width: "100%",
  },
  visStartIconExterior: {
    margin: "auto",
    display: "flex",
    maxWidth: "100%",
    padding: "5px 0",
  },
  visStartIconInterior: {
    margin: "auto",
    display: "flex",
    maxWidth: "100%",
    padding: "0",
    margin: "0 auto auto",
  },
  visLabel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  hotSpot: {
    cursor: "pointer",
  },
  hotSpotArabicShift: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(-100%,0)",
    },
  },
  openListIcon: { margin: "auto ", height: "17px", width: "20px" },
  arrowAnimation: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeOut}`,
  },
  arrowIcon: {
    margin: "auto",
    fill: "#07303E",
    [theme.breakpoints.up("md")]: {
      width: "19.53px",
      height: "30.48px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "21.97px",
      height: "34.27px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "25px",
      height: "39px",
    },
  },
  arrowAnimationExit: {
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes myEffect": {
    "0%": {
      width: "33px",
    },
    "50%": {
      width: "25px",
    },
    "100%": {
      width: "33px",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      width: "33px",
    },
    "50%": {
      width: "25px",
    },
    "100%": {
      width: "33px",
    },
  },
  stepperText: {
    // position: "absolute",
    display: "inline-flex",
    // top: "70%",
    left: "50%",
    width: !isIOS ? "80%" : "70%",
    // transform: !isIOS ? "translate(-50%,50%)" : "translate(-50%,35%)",
    cursor: "pointer",
    color: "#fff",
    justifyContent: "space-between",
    fontFamily: "Poppins",
  },
  visualizationDisclaimer: {
    color: "#636363",
    margin: "16px 0 34px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      margin: "16px 0 0",
    },
  },
}));

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function isIOSInApp() {
  return isIOS() && /AppName/i.test(navigator.userAgent);
}

//stepper inside a dialog component to shoe images of a faulty point
const Details = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const {
    onClose,
    open,
    option,
    isPortrait,
    selectedPoint,
    fromBigScreen,
    DrawerData,
    unlocked,
  } = props;
  const { images, option_score } = option;
  const [activeStep, setActiveStep] = useState(0);
  const [nextClicked, setNextClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [backClicked, setBackClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [imageLoaded, setImageLoaded] = useState(false);

  const maxSteps = images.length;

  // for the image stepper
  const handleNext = () => {
    if (activeStep != maxSteps - 1) {
      activeStep + 1 === maxSteps
        ? setActiveStep(0)
        : setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNextClicked(!nextClicked);
      setImageLoaded(false);
    }
  };

  const handleBack = () => {
    if (activeStep != 0) {
      activeStep === 0
        ? setActiveStep(maxSteps - 1)
        : setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setBackClicked(!backClicked);
      setImageLoaded(false);
    }
  };

  // image stepper component there are 3 styles one for big screen and 2 for mobile(portrait & landscape)
  const renderStepper = () => {
    return (
      <div
        style={
          fromBigScreen
            ? {
                width: "600px",
              }
            : isPortrait
            ? theme.direction === "ltr"
              ? isIOS()
                ? {
                    transform: "rotate(90deg)",
                  }
                : {
                    transform: "rotate(90deg)",
                    width: "calc(100vw - 32px)",
                  }
              : isIOS()
              ? {
                  transform: "rotate(-90deg)",
                  // height: "calc(100vw - 103.5px)",
                  // width: "calc(calc(100vw - 103.5px) / 0.75)",
                }
              : {
                  transform: "rotate(-90deg)",
                  width: "calc(100vw - 32px)",
                }
            : isIOS() && !isPortrait
            ? {}
            : {}
        }
      >
        <div
          style={
            isPortrait
              ? {
                  transformOrigin: "top left",
                  backgroundColor: "transparent",
                  // padding: !isIOS() ? "10px 0px" : "0",
                  color: "#07303E",
                  display: "flex",
                  overflow: "visible",
                  justifyContent: "space-between",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "transparent",
                  // padding: !isIOS() ? "10px 0px" : "0",
                  color: "#07303E",
                }
          }
        >
          <div style={{ color: "#fff", fontFamily: "Poppins", width: "250px" }}>
            <div style={{ display: "inline-flex", width: "300px" }}>
              <Grid
                item
                xs={1}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ListItemIcon style={{ minWidth: "0" }}>
                  {/* div margin auto 0 and center true are only used in drawer and not on points */}
                  <div style={{ margin: "auto 0" }}>
                    <HotSpot
                      size={16.57}
                      score={option_score}
                      number={props.selectedPoint + 1}
                      center={true}
                      unlocked={unlocked}
                    />
                  </div>
                </ListItemIcon>
              </Grid>
              <span
                style={{
                  fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
                }}
              >
                {option_score > 50 ? t("Minor Issue") : t("Major Issue")}
              </span>
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "1.25em",
                margin: "0 11px",
                fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
              }}
            >
              {DrawerData[selectedPoint]?.optionTitle}
            </div>
          </div>
          {/* <Typography className={classes.imageStepperTypography}>
            {DrawerData[selectedPoint] // so no problem occure when no drawerDrawe are present and you go back to a view with faults i think now || 0 is not needed
              ? DrawerData[selectedPoint].fieldTitle
              : null}{" "}
            - {DrawerData[selectedPoint]?.optionTitle}
          </Typography> */}
          <IconButton
            style={{
              padding: 0,
              backgroundColor: "#fff",
              borderRadius: "50%",
              color: "#07303E",
              marginTop: "10px",
              width: !fromBigScreen && !isIOS() && isPortrait ? "10vw" : "25px",
              height:
                !fromBigScreen && !isIOS() && isPortrait ? "10vw" : "25px",
            }}
            onClick={onClose}
          >
            <CloseIcon
              style={
                fromBigScreen
                  ? { fill: "#186985", fontSize: "2rem" }
                  : isPortrait
                  ? { fill: "#186985", fontSize: "clamp(1rem,6.3vw,2rem" }
                  : { fill: "#186985", fontSize: "clamp(1rem,6.3vh,2rem" }
              }
            />
          </IconButton>
        </div>
        <Paper
          style={
            fromBigScreen
              ? {
                  backgroundColor: "white",
                  width: "600px",
                  height: "450px",
                }
              : {
                  backgroundColor: "transparent",
                  position: "relative",
                  overflow: "visible",
                  height: isIOS()
                    ? isPortrait
                      ? ""
                      : `calc(${window.innerHeight}px - 103.5px)`
                    : "", /////fit-content??
                }
          }
        >
          {!imageLoaded && (
            <div
              className={
                fromBigScreen
                  ? classes.imgBig
                  : isPortrait
                  ? classes.portStepperImg
                  : classes.landStepperImg
              }
              style={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <CircularProgress color={"primary"} />
              </div>
              {/* <Skeleton
                style={{
                  transform: "none",
                }}
                className={
                  fromBigScreen
                    ? classes.imgBig
                    : isPortrait
                    ? classes.portStepperImg
                    : classes.landStepperImg
                }
              /> */}
            </div>
          )}
          <img
            style={
              fromBigScreen ? { borderRadius: "4px" } : isPortrait ? {} : {}
            }
            className={
              fromBigScreen
                ? classes.imgBig
                : isPortrait
                ? classes.portStepperImg
                : classes.landStepperImg
            }
            src={images[activeStep]}
            // alt={images[activeStep]}
            onLoad={() => {
              setImageLoaded(true);
            }}
          />
        </Paper>
        <div
          style={{
            display: "inline-flex",
            position: "relative",
            justifyContent: "space-between",
            width: "100%",
            marginTop: fromBigScreen && "15px",
          }}
        >
          {maxSteps !== 1 && (
            <div
              className={
                fromBigScreen
                  ? classes.bigStepperBackArrow
                  : isPortrait
                  ? classes.portStepperBackArrow
                  : classes.landStepperBackArrow
              }
              onClick={() => {
                localStorage.getItem("language") === "ar"
                  ? handleNext()
                  : handleBack();
              }}
            >
              {localStorage.getItem("language") === "en" ? (
                <BackArrowIcon
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: backClicked,
                  })}
                  size={fromBigScreen ? "27" : isPortrait ? "5vw" : "3vh"}
                  style={{
                    margin: "auto",
                    fill: 0 != activeStep ? "#186985" : "#B8B8B8",
                  }}
                />
              ) : (
                <ForwardArrowIcon
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: backClicked,
                  })}
                  size={fromBigScreen ? "20" : isPortrait ? "5vw" : "3vh"}
                  style={{
                    margin: "auto",
                    fill: activeStep + 1 != maxSteps ? "#186985" : "#B8B8B8",
                  }}
                />
              )}
            </div>
          )}

          <div
            className={classes.stepperText}
            style={{
              top: fromBigScreen ? "70%" : isPortrait ? "90%" : "90%",
              fontSize: fromBigScreen ? "21px" : "18px",
              justifyContent: maxSteps == 1 && "center",
              width: maxSteps == 1 && "100%",
              textAlign: maxSteps == 1 && "center",
            }}
          >
            {maxSteps > 1 && (
              <div
                style={{
                  lineHeight: "50px",
                  fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
                }}
              >
                {localStorage.getItem("language") === "en"
                  ? t("Previous")
                  : t("Next")}
              </div>
            )}
            <div style={{ lineHeight: "50px", fontSize: "0.8em" }}>
              {activeStep + 1} / {maxSteps}
            </div>
            {maxSteps > 1 && (
              <div
                style={{
                  lineHeight: "50px",
                  fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
                }}
              >
                {localStorage.getItem("language") === "en"
                  ? t("Next")
                  : t("Previous")}
              </div>
            )}
          </div>

          {maxSteps !== 1 && (
            <div
              className={
                fromBigScreen
                  ? classes.bigStepperForwardkArrow
                  : isPortrait
                  ? classes.portStepperForwardkArrow
                  : classes.landStepperForwardkArrow
              }
              onClick={() => {
                localStorage.getItem("language") === "ar"
                  ? handleBack()
                  : handleNext();
              }}
            >
              {localStorage.getItem("language") === "en" ? (
                <ForwardArrowIcon
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: nextClicked,
                  })}
                  size={fromBigScreen ? "20" : isPortrait ? "5vw" : "3vh"}
                  style={{
                    margin: "auto",
                    fill: activeStep + 1 != maxSteps ? "#186985" : "#B8B8B8",
                  }}
                />
              ) : (
                <BackArrowIcon
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: nextClicked,
                  })}
                  size={fromBigScreen ? "20" : isPortrait ? "5vw" : "3vh"}
                  style={{
                    margin: "auto",
                    fill: 0 != activeStep ? "#186985" : "#B8B8B8",
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div style={{ overflow: "visible", height: "100%" }}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.65)" } }}
        classes={{
          paperWidthSm: classes.dialogPaperWidthSm,
          paper: classes.dialogPaper,
        }}
        style={{
          zIndex: "2000",
          position: "fixed",
          top: 0,
          left: 0,
          maxWidth: "679",
          // overflow: "scroll",
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          classes={{
            root:
              !fromBigScreen && isIOS() && !isPortrait
                ? classes.landIosDialogContentRoot
                : classes.dialogContentRoot,
          }}
          style={{
            padding: 0,
            overflow: !isPortrait && "visible",
            display: "flex",
            alignItems: "center",
          }}
        >
          {renderStepper()}
        </DialogContent>
      </Dialog>
    </div>
  );
};

// component to render faulty points on the view image which includes on click rendering of Details component (view image with stepper)
const Point = (props) => {
  const {
    point,
    imageData,
    isPortrait,
    imgHeight,
    imgWidth,
    count,
    faults,
    fromBigScreen,
    DrawerData,
    setLastPointLoaded,
    lastPoint,
    unlocked,
    logVisitorView,
    side,
  } = props;

  function string_between_strings(startStr, endStr, str) {
    let pos = str.indexOf(startStr) + startStr.length;
    return str.substring(pos, str.indexOf(endStr, pos));
  }
  const classes = useStyles();
  const hotSpotDivRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState();
  // const [pointOpacity, setPointOpacity] = useState(0);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentId, setCurrentId] = useState(
    string_between_strings("_ga=", ";", document.cookie)
  ); // two years
  const [currentGid, setCurrentGid] = useState(
    string_between_strings("_gid=", ";", document.cookie)
  ); /// 24 hours
  const [startTime, setStartTime] = useState(
    Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
  );
  useEffect(() => {
    setStartTime(
      Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
    );
    setCurrentPath(window.location.pathname);
    setCurrentId(string_between_strings("_ga=", ";", document.cookie));
    setCurrentGid(string_between_strings("_gid=", ";", document.cookie));
  }, []);

  useEffect(() => {
    if (
      hotSpotDivRef?.current?.offsetTop !== 1 && // due to style["top"]=1 if NaN
      hotSpotDivRef?.current?.offsetTop // added so that lastPointLoaded is not set to true before the points actually load (ref?.current?.offsetTop !== undefined)
    ) {
      // setPointOpacity(1);
      if (lastPoint) {
        setLastPointLoaded(true);
      }
    }
  }, [hotSpotDivRef?.current?.offsetTop]);

  // to set the selected point and open the dialog
  let arrViews = {
    dfv: "Front Angle",
    prf: "Rear Angle ",
    fv: "Driver View  ",
    rf: "Passenger View ",
    "3rd": "3rd Row",
    tv: "Trunk View",
  };
  const handleClick = (event) => {
    logVisitorView({
      page_title: currentPath,
      start_time: startTime,
      session_one: currentId,
      session_two: currentGid,
      tag: "VIR" + arrViews[side] + " hotspot #" + event?.currentTarget?.textContent,
    });
    setAnchorEl(event?.currentTarget);
    setSelectedPoint(event?.currentTarget?.textContent - 1);
    document.getElementById("root").style.filter = "blur(16px)"; // adds blur to root element
  };
  // to close the dialog
  const handleClose = () => {
    setAnchorEl(null);
    document.getElementById("root").style.filter = "none"; // adds blur to root element
  };

  let style = { position: "absolute" }; // , opacity: pointOpacity };

  const open = Boolean(anchorEl);
  if (!point || !imageData) {
    style["display"] = "none";
    return null;
  } else {
    // i think the problem is here regarding the unchanging position of points when scaling the browser window
    // var currentWidth = fromBigScreen ? imageData.width : window.screen.width, //used to be imageData.width but it didnot rerender when orientation changed
    //   currentHeight = fromBigScreen ? imageData.height : window.screen.height; //used to be imageData.height but it didnot rerender when orientation changed
    var currentWidth = fromBigScreen
        ? imageData.width
        : isPortrait
        ? isIOS()
          ? window.innerWidth
          : window.screen.width
        : isIOS()
        ? window.innerHeight / 0.75
        : window.screen.height / 0.75,
      currentHeight = fromBigScreen
        ? imageData.height
        : isPortrait
        ? isIOS()
          ? window.innerWidth / 0.75
          : window.screen.width / 0.75
        : isIOS
        ? window.innerHeight
        : window.screen.height;
    let naturalWidth = imageData.naturalWidth,
      naturalHeight = imageData.naturalHeight;

    const bigScreenIconSize = currentWidth / 38.5; //
    const mobScreenIconSize = isPortrait
      ? currentHeight / 35 < 16
        ? 16
        : currentHeight / 35
      : currentWidth / 35 < 16
      ? 16
      : currentWidth / 35; //

    ///// change could be removed till here
    // example: nh=853,ch=603,nw=1280, cw=616 notIOS and Web
    const verticalImageRatio = isPortrait
      ? currentWidth / naturalHeight // in Portrait current values are flipped
      : currentHeight / naturalHeight; // 0.707
    const horizontalImageRatio = isPortrait
      ? currentHeight / naturalWidth // in Portrait current values are flipped
      : currentWidth / naturalWidth; // 0.48...
    let imageSingleSideLoss = 0;
    let imageSingleLossVertical = 0;
    const imageHorizontalCenter = currentWidth / 2; // 308
    const imageVerticalCenter = currentHeight / 2; //301.5

    if (verticalImageRatio > horizontalImageRatio) {
      isPortrait
        ? (imageSingleSideLoss =
            (naturalWidth * verticalImageRatio - currentHeight) / 2)
        : (imageSingleSideLoss =
            (naturalWidth * verticalImageRatio - currentWidth) / 2); // ((1280*0.707)-616)/2 = 144.48
    } else {
      isPortrait
        ? (imageSingleLossVertical =
            (naturalHeight * horizontalImageRatio - currentWidth) / 2)
        : (imageSingleLossVertical =
            (naturalHeight * horizontalImageRatio - currentHeight) / 2); // ((1280*0.707)-616)/2 = 144.48
    }
    // there is no loss vertical or horizontal since 4:3 but was kept to avoide bugs //
    imageSingleSideLoss = 0;
    imageSingleLossVertical = 0;
    ////////////////////////////// here

    style["top"] = fromBigScreen
      ? (point.x * currentHeight) / naturalHeight +
        (((point.x * currentHeight) / naturalHeight - imageVerticalCenter) /
          imageVerticalCenter) *
          imageSingleLossVertical -
        bigScreenIconSize / 2 // porbably could remove and be added to the landscape option down
      : isPortrait
      ? (point.x * currentWidth) / naturalHeight +
        (((point.x * currentWidth) / naturalHeight - imageHorizontalCenter) /
          imageHorizontalCenter) *
          imageSingleLossVertical -
        mobScreenIconSize / 2
      : (point.x * currentHeight) / naturalHeight +
        (((point.x * currentHeight) / naturalHeight - imageVerticalCenter) /
          imageVerticalCenter) *
          imageSingleLossVertical -
        mobScreenIconSize / 2;

    fromBigScreen
      ? theme.direction === "ltr"
        ? (style["right"] = // positioned from right instead of left because of drawer on the left that if i positioned from left i should compensate for could be changed
            currentWidth -
            (point.y * currentWidth) / naturalWidth + // origional right + (((origional right - imageHorizontalCenter)/imageHorizontalCenter)*imageSingleSideLoss - half the point width --ie. center--)
            ((currentWidth -
              (point.y * currentWidth) / naturalWidth -
              imageHorizontalCenter) /
              imageHorizontalCenter) *
              imageSingleSideLoss +
            bigScreenIconSize / 2)
        : (style["right"] = // positioned from right instead of left because of drawer on the left that if i positioned from left i should compensate for could be changed
            currentWidth -
            (point.y * currentWidth) / naturalWidth + // origional right + (((origional right - imageHorizontalCenter)/imageHorizontalCenter)*imageSingleSideLoss - half the point width --ie. center--)
            ((currentWidth -
              (point.y * currentWidth) / naturalWidth -
              imageHorizontalCenter) /
              imageHorizontalCenter) *
              imageSingleSideLoss -
            bigScreenIconSize / 2)
      : (style["left"] = isPortrait
          ? (point.y * currentHeight) / naturalWidth - mobScreenIconSize / 2
          : (point.y * currentWidth) / naturalWidth - mobScreenIconSize / 2);

    if (Number.isNaN(style["top"])) {
      style["top"] = 1; // so that if the last point has no point.x then the skeleton would be able to dissapear
    }

    return (
      <div>
        <div style={style} ref={hotSpotDivRef} id="pointParent">
          <HotSpot
            size={
              fromBigScreen
                ? bigScreenIconSize
                : isPortrait
                ? isIOS()
                  ? mobScreenIconSize
                  : mobScreenIconSize
                : mobScreenIconSize
            }
            id={fromBigScreen ? "point" : "mobPoint"}
            score={point.option_score}
            number={DrawerData[count].index + 1}
            center={false}
            onClick={handleClick}
            className={clsx({
              [classes.hotSpot]: 1,
              [classes.hotSpotArabicShift]: theme.direction === "rtl",
            })}
            unlocked={unlocked}
          />
          <Details
            open={open}
            anchorEl={anchorEl}
            option={point}
            onClose={handleClose}
            id={"details-button-" + point.id}
            isPortrait={isPortrait}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            faults={faults}
            selectedPoint={selectedPoint}
            fromBigScreen={fromBigScreen}
            DrawerData={DrawerData}
            unlocked={unlocked}
          ></Details>
        </div>
        {/* <div
          style={{
            width: "5px",
            height: "5px",
            backgroundColor: "green",
            position: "absolute",
            top: style["top"],
            right: style["right"],
            left: style["left"],
            transform: "translate(-5px)",
          }}
        ></div> */}
      </div>
    );
  }
};

const VisualizationActionBar = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const {
    isPortrait,
    handleSetSide,
    side,
    thirdRow,
    secondRow,
    outside,
    points,
  } = props;
  let buttonStyle = isPortrait
    ? isIOS()
      ? {
          color: "white",
          padding: "0 0 2px",
          width: "100%",
          border: "2px solid #FFFFFF",
          borderRadius: "4px",
          margin: "1.5px 3px",
          width: !outside
            ? `calc(${0.15625 * window.innerHeight}px - 6px)`
            : `${0.15625 * window.screen.height - 6}px`,
          backgroundColor: "black",
          minWidth: "0",
        }
      : {
          color: "white",
          padding: "0 0 2px",
          width: "100%",
          border: "2px solid #FFFFFF",
          borderRadius: "4px",
          margin: "1.5px 3px",
          width: !outside
            ? "calc(15.625vh - 6px)"
            : `${0.15625 * window.screen.height - 6}px`,
          backgroundColor: "black",
          minWidth: "0",
        }
    : isIOS()
    ? {
        color: "white",
        padding: "0 0 2px",
        width: "100%",
        border: "2px solid #FFFFFF",
        borderRadius: "4px",
        margin: "1.5px 3px",
        width: !outside
          ? "calc(15.625vw - 6px)"
          : `${0.15625 * window.screen.height - 6}px`, ///
        backgroundColor: "black",
        minWidth: "0",
      }
    : {
        color: "white",
        padding: "0 0 2px",
        width: "100%",
        border: "2px solid #FFFFFF",
        borderRadius: "4px",
        margin: "1.5px 3px",
        width: "calc(15.625vw - 6px)",
        backgroundColor: "black",
        minWidth: "0",
      };
  return (
    <div>
      <div
        id="sidesMob"
        style={
          isPortrait
            ? isIOS()
              ? {
                  position: "absolute",
                  top: 0,
                  overflow: "auto",
                  backgroundColor: "#053361",
                  width: !outside
                    ? 0.15625 * window.innerHeight
                    : 0.15625 * window.screen.height,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }
              : {
                  position: "absolute",
                  top: 0,
                  overflow: "auto",
                  backgroundColor: "#053361",
                  width: !outside
                    ? "15.625vh"
                    : `${0.15625 * window.screen.height}px`,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }
            : isIOS()
            ? {
                position: "absolute",
                top: 0,
                overflow: "auto",
                backgroundColor: "#053361",
                width: !outside
                  ? "15.625vw"
                  : `${0.15625 * window.screen.height}px`,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }
            : {
                position: "absolute",
                top: 0,
                overflow: "auto",
                backgroundColor: "#053361",
                width: !outside
                  ? "15.625vw"
                  : `${0.15625 * window.screen.width}px`,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }
        }
        className={classes.viewsIconsDiv}
      >
        <Hidden mdUp>
          <div
            id="sidesMobHider"
            style={{
              width: isPortrait ? "100vh" : "100vw", //!mobile ? "100%" : "100vw",
              height: isPortrait ? "100vw" : "100vh", //!mobile ? "100%" : "100vh",
              position: "absolute", //mobile ? "absolute" : "absolute", //"fixed"
              pointerEvents: "none",
              left: "0",
              top: "0",
              zIndex: "2100",
              display: "none",
              backgroundColor: "#000000aa",
              backdropFilter: "blur(6px)",
              // filter: "blur(4px)",
            }}
          ></div>
        </Hidden>
        <div
          style={
            !isPortrait
              ? { flexBasis: "19vh", maxHeight: "19vh", minHeight: "12vw" }
              : { flexBasis: "19vw", maxHeight: "19vw", minHeight: "12vh" }
          }
        >
          <Button
            style={buttonStyle}
            onClick={handleSetSide}
            value="dfv"
            position="ext"
            startIcon={
              <DFAIcon
                style={{ margin: "auto" }}
                size={
                  isPortrait
                    ? 0.84 * 0.15 * window.innerHeight
                    : 0.84 * 0.15 * window.innerWidth
                }
              />
            }
            children={
              <Typography
                className={
                  isPortrait
                    ? classes.visButtonTextPort
                    : classes.visButtonTextLand
                }
              >
                {t("Front Angle")}
              </Typography>
            }
            classes={{
              root: !thirdRow
                ? isPortrait
                  ? classes.visButtonRootPort
                  : classes.visButtonRootLand
                : isPortrait
                ? classes.visButtonRootTHDPort
                : classes.visButtonRootTHDLand,
              text: side === "dfv" ? classes.visButtonSelected : null,
              // wrapper: classes.visButtonWrapper,
              startIcon: classes.visStartIconExterior,
              label: classes.visLabel,
            }}
          ></Button>
        </div>
        <div
          style={
            !isPortrait
              ? { flexBasis: "19vh", maxHeight: "19vh", minHeight: "12vw" }
              : { flexBasis: "19vw", maxHeight: "19vw", minHeight: "12vh" }
          }
        >
          <Button
            style={buttonStyle}
            onClick={handleSetSide}
            value="prf"
            position="ext"
            startIcon={
              <PRAIcon
                style={{ margin: "auto" }}
                size={
                  isPortrait
                    ? 0.84 * 0.15 * window.innerHeight
                    : 0.84 * 0.15 * window.innerWidth
                }
              />
            }
            children={
              <Typography
                className={
                  isPortrait
                    ? classes.visButtonTextPort
                    : classes.visButtonTextLand
                }
              >
                {t("Rear Angle")}
              </Typography>
            }
            classes={{
              root: !thirdRow
                ? isPortrait
                  ? classes.visButtonRootPort
                  : classes.visButtonRootLand
                : isPortrait
                ? classes.visButtonRootTHDPort
                : classes.visButtonRootTHDLand,
              text: side === "prf" ? classes.visButtonSelected : null,
              startIcon: classes.visStartIconExterior,
              label: classes.visLabel,
            }}
          ></Button>
        </div>
        <div
          style={
            !isPortrait
              ? { flexBasis: "19vh", maxHeight: "19vh", minHeight: "12vw" }
              : { flexBasis: "19vw", maxHeight: "19vw", minHeight: "12vh" }
          }
        >
          <Button
            style={buttonStyle}
            onClick={handleSetSide}
            value="fv"
            position="int"
            startIcon={
              <DVIcon
                size={
                  isPortrait
                    ? 0.15 * window.innerHeight
                    : 0.15 * window.innerWidth
                }
              />
            }
            children={
              <Typography
                className={
                  isPortrait
                    ? classes.visButtonTextPort
                    : classes.visButtonTextLand
                }
              >
                {t("Driver View")}
              </Typography>
            }
            classes={{
              root: !thirdRow
                ? isPortrait
                  ? classes.visButtonRootPort
                  : classes.visButtonRootLand
                : isPortrait
                ? classes.visButtonRootTHDPort
                : classes.visButtonRootTHDLand,
              text: side === "fv" ? classes.visButtonSelected : null,
              startIcon: classes.visStartIconInterior,
              label: classes.visLabel,
            }}
          ></Button>
        </div>
        {secondRow && (
          <div
            style={
              !isPortrait
                ? { flexBasis: "19vh", maxHeight: "19vh", minHeight: "12vw" }
                : { flexBasis: "19vw", maxHeight: "19vw", minHeight: "12vh" }
            }
          >
            <Button
              style={buttonStyle}
              onClick={handleSetSide}
              value="rf"
              position="int"
              startIcon={
                <PVIcon
                  size={
                    isPortrait
                      ? 0.15 * window.innerHeight
                      : 0.15 * window.innerWidth
                  }
                />
              }
              children={
                <Typography
                  className={
                    isPortrait
                      ? classes.visButtonTextPort
                      : classes.visButtonTextLand
                  }
                >
                  {t("Passenger View")}
                </Typography>
              }
              classes={{
                root: !thirdRow
                  ? isPortrait
                    ? classes.visButtonRootPort
                    : classes.visButtonRootLand
                  : isPortrait
                  ? classes.visButtonRootTHDPort
                  : classes.visButtonRootTHDLand,
                text: side === "rf" ? classes.visButtonSelected : null,
                startIcon: classes.visStartIconInterior,
                label: classes.visLabel,
              }}
            ></Button>
          </div>
        )}
        {thirdRow && (
          <div
            style={
              !isPortrait
                ? { flexBasis: "19vh", maxHeight: "19vh", minHeight: "12vw" }
                : {
                    flexBasis: "12vw",
                    maxHeight: "12vw",
                    minHeight: "12vh",
                  }
            }
          >
            <Button
              style={buttonStyle}
              onClick={handleSetSide}
              value="3rd"
              position="int"
              startIcon={
                <THDIcon
                  size={
                    isPortrait
                      ? 0.15 * window.innerHeight
                      : 0.15 * window.innerWidth
                  }
                />
              }
              children={
                <Typography
                  className={
                    isPortrait
                      ? classes.visButtonTextPort
                      : classes.visButtonTextLand
                  }
                >
                  {t("3rd Row")}
                </Typography>
              }
              classes={{
                root: !thirdRow
                  ? isPortrait
                    ? classes.visButtonRootPort
                    : classes.visButtonRootLand
                  : isPortrait
                  ? classes.visButtonRootTHDPort
                  : classes.visButtonRootTHDLand,
                text: side === "3rd" ? classes.visButtonSelected : null,
                startIcon: classes.visStartIconInterior,
                label: classes.visLabel,
              }}
            ></Button>
          </div>
        )}
        <div
          style={
            !isPortrait
              ? { flexBasis: "19vh", maxHeight: "19vh", minHeight: "12vw" }
              : { flexBasis: "19vw", maxHeight: "19vw", minHeight: "12vh" }
          }
        >
          <Button
            style={buttonStyle}
            onClick={handleSetSide}
            value="tv"
            position="int"
            startIcon={
              <TVIcon
                size={
                  isPortrait
                    ? 0.15 * window.innerHeight
                    : 0.15 * window.innerWidth
                }
              />
            }
            children={
              <Typography
                className={
                  isPortrait
                    ? classes.visButtonTextPort
                    : classes.visButtonTextLand
                }
              >
                {t("Trunk View")}
              </Typography>
            }
            classes={{
              root: !thirdRow
                ? isPortrait
                  ? classes.visButtonRootPort
                  : classes.visButtonRootLand
                : isPortrait
                ? classes.visButtonRootTHDPort
                : classes.visButtonRootTHDLand,
              text: side === "tv" ? classes.visButtonSelected : null,
              startIcon: classes.visStartIconInterior,
              label: classes.visLabel,
            }}
          ></Button>
        </div>
      </div>
    </div>
  );
};

const getcarIdFromLink = (props) => {
  // to get the car id in case of bigscreen or mobile
  const carId = props.carId === undefined ? props.location.carId : props.carId;
  return carId;
};

// the main component
const Visualization = (props) => {
  var DrawerData = [];
  const {
    getCarFaults,
    getCarVisualization,
    points,
    fromBigScreen,
    close,
    showAppBar,
    openVisualization,
    style,
    className,
    outside,
    imgLoaded,
    setImgLoaded,
    history,
    unlocked,
    setTooltipStartButtonVisability,
    tooltipStartButtonVisability,
    setRecallGroupsToggle,
    previouslyUnlocked,
    getCarVisualizationIDs,
    carFaults,
    logVisitorView,
  } = props;
  let carId = getcarIdFromLink(props); // to get the car id either from props or props.location in case of mobile
  const { t, i18n } = useTranslation();
  ////////////////////////////////////
  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);
  ////////////////////////////////////

  const classes = useStyles();
  const imageRef = useRef();
  const [stageHeight, setStageHeight] = useState(80);
  const [faults, setFaults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [first, setFirst] = useState(true);
  const [position, setPosition] = useState(positions[0].id);
  const [side, setSide] = useState(positions[0].sides[0].id);
  const [open, setOpen] = useState(fromBigScreen || false);
  const [iosInnerHeight, setIosInnerHeight] = useState(window.innerHeight); // delete?
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" }); // from react responsive node module
  const [lastPointLoaded, setLastPointLoaded] = useState(false);
  const [faultsLoaded, setFaultsLoaded] = useState(false); // for drawer
  const [visImage, setVisImage] = useState(null);
  const [sharedSkeletonLoad, setSharedSkeletonLoad] = useState(false);
  const [run, setRun] = useState(false);
  const [enteredFullScreen, setEnteredFullScreen] = useState(false);

  // the next state and use effect are used to create a variable (arrangedPointsKeys) that contains the submitted sides only in the right order
  const [arrangedPointsKeys, setArrangedPointsKeys] = useState([]);
  useEffect(() => {
    let pointsKeys = Object.keys(points); // this only contains submitted sides (may not contain 3rd row)
    let arrangedSides = ["dfv", "prf", "fv", "rf", "3rd", "tv"]; // this specifies the right order for sides
    let newArrangedSides = arrangedSides.filter((el) =>
      pointsKeys.includes(el)
    );
    setArrangedPointsKeys(newArrangedSides);
  }, [points]);

  if (props.location.carId) {
    localStorage.setItem("carId", JSON.stringify(props.location.carId)); // saves the carId to local storage so the website doesnot crash on reload i didnot save them to store because they are only needed here i might change that later if we need to
  }

  useEffect(() => {
    // delete?
    setIosInnerHeight(window.innerHeight);
  }, [isPortrait, imageRef, loading]);

  useEffect(() => {
    function handleResize() {
      if (imageRef.current) {
        let boundingRect = imageRef.current.getBoundingClientRect();
        if (window.innerWidth > 960) {
          const { width } = boundingRect;
          const { naturalWidth, naturalHeight } = imageRef.current;
          setStageHeight((width * naturalHeight) / naturalWidth);
        } else {
          const { width } = boundingRect;
          const { naturalWidth, naturalHeight } = imageRef.current;
          setStageHeight((width * naturalHeight) / naturalWidth);
        }
      }
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [imageRef]);

  useEffect(() => {
    if (imageRef?.current?.complete) {
      if (!history.location.pathname.includes("/visualization")) {
        // mobile visualization component dont have setImgLoaded////
        setImgLoaded(true); //  here
      } else {
        if (props.location.setMobImageLoaded) {
          props.location.setMobImageLoaded();
        } else {
          history.goBack();
        }
      }
    }
    // }
  }, [imageRef?.current?.complete, points]); // points was added for first load the previous car image is in points and cpmplete is true

  useEffect(() => {
    if (visImage == null) {
      if (points[side]?.image?.includes(`Car-${carId}`)) {
        setVisImage(points[side]?.image);
      }
    }
  }, [visImage, points?.[side]?.image]); // points[side]?.image added to trigger after the right car image is loaded into points[side]
  useEffect(() => {
    // start of revert scroll functions in ios Chrome landscape
    let last_known_scroll_position = 0;
    let ticking = false;
    let initialScroll = 14; // this is read in the alert(scroll_pos) check if the same for all mobiles???
    function revertScroll(scroll_pos) {
      if (scroll_pos !== initialScroll && !outside) {
        window.scrollTo(0, -scroll_pos);
      }
    }

    function animationFrame(e) {
      last_known_scroll_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          revertScroll(last_known_scroll_position);
          ticking = false;
        });
        ticking = true;
      }
    }

    function handleChromeMobLandscapeURLBar() {
      setTimeout(function () {
        window.scrollTo(0, initialScroll);
      }, 1000);
    }
    // end of revert scroll functions in ios Chrome landscape
    /////////////////////////////////////////////////////////
    // start of show url bar functions in ios safari landscape
    function handleSafariMobLandscapeURLBar() {
      setTimeout(function () {
        window.scrollTo(0, 1);
      }, 1000);
    }
    // end of show url bar functions in ios safari landscape
    /////////////////////////////////////////////////////////
    // revert scroll addEventListener in ios Chrome landscape only
    if (
      isIOS() &&
      navigator.userAgent.indexOf("CriOS") != -1 &&
      openVisualization &&
      !isPortrait
    ) {
      document.addEventListener("scroll", animationFrame);
      window.addEventListener(
        "orientationchange",
        handleChromeMobLandscapeURLBar
      );
    }
    // show url bar on orientationChange addEventListener in ios safari landscape only
    else if (
      isIOS() &&
      navigator.userAgent.indexOf("Safari") != -1 &&
      // !isPortrait &&
      openVisualization
    ) {
      window.addEventListener(
        "orientationchange",
        handleSafariMobLandscapeURLBar
      );
    }

    // cleanup removing event listeners
    return (_) => {
      document.removeEventListener("scroll", animationFrame);
      window.removeEventListener(
        "orientationchange",
        handleChromeMobLandscapeURLBar
      );
      window.removeEventListener(
        "orientationchange",
        handleSafariMobLandscapeURLBar
      );
    };
  }, [isPortrait, openVisualization]);

  useEffect(() => {
    setFaults(carFaults?.[side]);
    setLoading(false);
    setFirst(false);
    setRun(true);
    setFaultsLoaded(true);
  }, []);

  useEffect(() => {
    // gets faults and visualization data including view image faults images point locations etc
    if (side) {
      setFaults(carFaults?.[side]);
      setLoading(false);
      setFirst(false);
      setRun(true);
      setFaultsLoaded(true);
      // !previouslyUnlocked?.current && setRecallGroupsToggle((prev) => !prev);
    }

    if (imageRef.current) {
      let boundingRect = imageRef.current.getBoundingClientRect();

      if (window.innerWidth > 960) {
        const { width } = boundingRect;
        const { naturalWidth, naturalHeight } = imageRef.current;
        setStageHeight((width * naturalHeight) / naturalWidth);
      } else {
        let height = 80;
        setStageHeight(height / 1);
      }
    }
    try {
      handleDrawerData();
      // so that the undeclared handleDrawerData dont throw an error upon exiting visualization in a way other than the back arrow button
    } catch (error) {
      console.log(error);
    }
  }, [carId, side, position, carFaults, imgLoaded]);
  ////////////////////////////////////////////
  // useEffect(() => {
  //   if (unlocked) {
  //     forceUpdate();
  //   }
  // }, [unlocked, points]);
  ////////////////////////////////////////////

  if (!isIOS()) {
    var imgHeight = fromBigScreen
      ? "82.1918%"
      : isPortrait
      ? window.screen.width
      : window.screen.height;

    var imgWidth = fromBigScreen
      ? "72.4138%"
      : isPortrait
      ? window.screen.height
      : window.screen.width;
  } else if (isIOS() && !isPortrait) {
    var imgHeight = fromBigScreen
      ? "72.4138%"
      : isPortrait
      ? window.screen.height
      : window.screen.width;

    var imgWidth = fromBigScreen
      ? "82.1918%"
      : isPortrait
      ? window.screen.width
      : "100vw";
  } else if (isIOS() && isPortrait) {
    var imgHeight = fromBigScreen
      ? "82.1918%"
      : isPortrait
      ? window.screen.width
      : window.screen.height;

    var imgWidth = fromBigScreen
      ? "72.4138%"
      : isPortrait
      ? "90vh"
      : window.screen.width;
  }

  // initial state of classes supplied to the main image containing div and the image itself
  let picclasspick = classes.fullport;
  let divClassPick = classes.portDiv;

  // the function that checks the orientation and chosses the corresponding class
  const pick = () => {
    if (!fromBigScreen) {
      if (isPortrait) {
        picclasspick = classes.fullport;
        divClassPick = classes.portDiv;
      } else {
        picclasspick = classes.fullland;
        divClassPick = classes.landDiv;
      }
    }
  };

  //function to exit full screen if it has already been entered in bigscreen it closes the popup dialog
  const exitFullscreen = (event) => {
    localStorage.removeItem("carId");
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => console.log("Document Exited form Full screen mode"))
        .catch((err) => console.error(err));
      showAppBar(); // displays the app bar hidden upon entering fullscreen
    } else {
      showAppBar && showAppBar();
      return fromBigScreen ? close() : null;
    }
  };

  // keeps track if fullscreen is entered
  useEffect(() => {
    if (document.fullscreen) {
      setEnteredFullScreen(true);
    }
  }, [document.fullscreen]);

  useEffect(() => {
    //to get the size and position of the image, used for positioning the arrow containig div
    function handleResize() {
      if (imageRef.current) {
        let boundingRect = imageRef.current.getBoundingClientRect();
        setImageData({
          naturalHeight: imageRef.current.naturalHeight,
          naturalWidth: imageRef.current.naturalWidth,

          width: boundingRect.width,
          height: boundingRect.height,
        });
      }
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return (_) => {
      //clean up
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [imageRef]);

  useEffect(() => {
    let imageSkeletonLoad =
      (imgLoaded !== undefined ? !imgLoaded : !props.location.mobImageLoaded) ||
      (!lastPointLoaded &&
        points[side]?.points &&
        Object.keys(points[side].points).length > 0); // added .length so that if there is no points then skeleton will not wait for last point to load to dissapear
    if (!imageSkeletonLoad && faultsLoaded) {
      // setSharedSkeletonLoad(false);
      setTooltipStartButtonVisability &&
        setTooltipStartButtonVisability({
          ...tooltipStartButtonVisability,
          loading: false,
        });
    }
  }, [imgLoaded, lastPointLoaded, points?.[side]?.points, faultsLoaded]);

  var tag = 0;
  var minor = 0;
  var major = 0;
  const handleDrawerData = () => {
    DrawerData = [];
    if (faults) {
      faults.map((fault, i) => {
        Object.keys(fault.condition.answer).map((key) => {
          fault.condition.answer[key].map((element, index) => {
            if (element.with_image) {
              DrawerData.push({
                id: element.id,
                index: tag,
                fieldTitle:
                  localStorage.getItem("language") == "en"
                    ? fault.condition?.title_lang?.en
                    : fault.condition?.title_lang?.ar,
                optionTitle:
                  localStorage.getItem("language") == "en"
                    ? element.option_lang?.en
                    : element.option_lang?.ar,
                images: element.images,
                answerUniqueId: element.answer_id,
                option_score: element.option_score,
              });
              element.option_score > 50 ? (minor += 1) : (major += 1);
              tag = tag + 1;
            }
          });
        });
      });
    }
  };
  handleDrawerData();

  //Comment this out to test in computer responsive mode{
  if (enteredFullScreen && !document.fullscreen) {
    try {
      showAppBar();
      // only works if the device entered full screen at the beginning if not it won't automatically redirect on entrance the back button will automatically take him to previous screen no exit fullscreen required
      return <Redirect to={"/car/" + carId} />; //To return to previous page instead of exiting full screen only when the back button is clicked in mobile
    } catch {
      console.log("xxxxxxx");
    }
  }

  // sets the clicked view
  const handleSetSide = (event) => {
    setVisImage(null); // so that the new image is shown when skeleton ends without the prev image showing for some milli seconds
    setLastPointLoaded(false);
    if (!history.location.pathname.includes("/visualization")) {
      // mobile visualization component dont have setImgLoaded
      setImgLoaded(false);
    }
    setFaultsLoaded(false);

    setSide(
      !fromBigScreen ? event.currentTarget.value : event.currentTarget.value
    );
    setPosition(
      !fromBigScreen
        ? combinedPositions[event.currentTarget.value]
        : event.currentTarget.attributes.position.nodeValue
    );
  };

  const handleOpen = () => {
    setOpen(true);
    setTooltipStartButtonVisability({
      ...tooltipStartButtonVisability,
      drawerOpened: true,
    });
  };

  const actual = isPortrait
      ? window.innerWidth / 0.75 + 0.15625 * window.innerHeight
      : window.innerHeight / 0.75 + 0.15625 * window.innerWidth,
    available = isPortrait ? window.innerHeight : window.innerWidth,
    dontFit = actual > available,
    scaleDown = available / actual;

  const imageStyle = fromBigScreen
    ? { transform: "none" }
    : isPortrait
    ? isIOS()
      ? !outside
        ? {
            height: window.innerWidth,
            width: window.innerWidth / 0.75,
            top: 0,
          }
        : {
            height: imgHeight,
            width: 0.84375 * window.screen.height,
          }
      : !outside
      ? {
          height: imgHeight,
          width: window.screen.width / 0.75,
          top: `${
            (window.screen.height -
              (window.screen.width / 0.75 + 0.15625 * window.screen.height)) /
            2
          }px`,
        }
      : {
          height: imgHeight,
          width: `${0.84375 * window.screen.height}px`,
        }
    : isIOS()
    ? !outside
      ? {
          height: window.innerHeight,
          width: window.innerHeight / 0.75,
        }
      : {
          height: "100%",
          width: 0.84375 * window.screen.height,
        }
    : !outside
    ? { height: window.screen.height, width: window.screen.height / 0.75 }
    : {
        height: `${imgHeight - 40}px`,
        width: `calc(84.375% - 40px)`,
      }; //might be a problem check (imgHeight)
  return (
    <div style={style} className={className} id="visu">
      {openVisualization && (
        <div
          className={classes.visualizationDialogDiv}
          style={
            !fromBigScreen
              ? {
                  // display: "block",
                  display: "grid",
                  backgroundColor: "black",
                  // backgroundColor: "red",
                  height: `${window.innerHeight}px`,
                  width: `${window.innerWidth}px`,
                  position: "fixed",
                }
              : {}
          }
        >
          {pick()}
          <div
            style={
              dontFit && !fromBigScreen
                ? {
                    display: "flex",
                    transform: `scale(${scaleDown}) translate(calc(-50% / ${scaleDown}),calc(-50% / ${scaleDown})`,
                    height: `${window.innerHeight / scaleDown}px`,
                    top: `calc(50% * ${scaleDown})`,
                    left: "50%",
                    position: "relative",
                    backgroundColor: "green",
                  }
                : { display: "flex" }
            }
          >
            <div
              className={classes.visImageDiv}
              style={
                fromBigScreen
                  ? {}
                  : {
                      height: window.innerHeight / scaleDown,
                      width: window.innerHeight,
                      top: 0,
                      overflow: "hidden",
                    }
              }
            >
              <img
                src={
                  points?.[side] !== undefined
                    ? visImage
                    : require("../images/img_placeholder.png")
                }
                className={
                  (picclasspick,
                  fromBigScreen ? classes.bigScreenImage : picclasspick)
                }
                // alt={"loading....."}
                alt={" "}
                ref={imageRef}
                onLoad={(event) => {
                  event.persist();
                  const boundingRect = event.target.getBoundingClientRect(); // gets data of the image's height width naturl height etc
                  setImageData({
                    ...boundingRect,
                    naturalHeight: event.target.naturalHeight,
                    naturalWidth: event.target.naturalWidth,

                    width: boundingRect.width,
                    height: boundingRect.height,
                  });
                }}
                style={{ ...imageStyle, backgroundColor: "rgba(0,0,0,0.11)" }} /// check this(web vis & mobile vis) if necessary.
              />
            </div>
            {/* {sharedSkeletonLoad && (
              <Hidden smDown>
                <div
                  className={classes.visImageDiv}
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    transform: "none",
                  }}
                >
                  <Skeleton
                    className={
                      (picclasspick,
                      fromBigScreen ? classes.bigScreenImage : picclasspick)
                    }
                    style={imageStyle}
                  />
                </div>
              </Hidden>
            )} */}
            <DrawerLeft
              isIOS={isIOS}
              minorCounter={minor}
              majorCounter={major}
              images={points}
              isPortrait={isPortrait}
              side={side}
              faults={faults}
              imgHeight={imgHeight}
              fromBigScreen={fromBigScreen} // to be always open in fullscreen
              open={open}
              setOpen={setOpen}
              faultsLoaded={faultsLoaded}
              setFaultsLoaded={setFaultsLoaded}
              sharedSkeletonLoad={sharedSkeletonLoad}
              dontFit={dontFit}
              scaleUp={1 / scaleDown}
              unlocked={unlocked}
              setTooltipStartButtonVisability={setTooltipStartButtonVisability}
              tooltipStartButtonVisability={tooltipStartButtonVisability}
            />
            <div
              id="visualizationImageMobile"
              className={!fromBigScreen ? divClassPick : classes.imgDiv}
              style={
                fromBigScreen
                  ? theme.direction === "rtl"
                    ? {
                        position: outside ? "absolute" : "fixed",
                      }
                    : {
                        position: outside ? "absolute" : "fixed",
                      }
                  : isPortrait
                  ? isIOS()
                    ? !outside
                      ? {
                          height: window.innerWidth,
                          width: window.innerWidth / 0.75,
                          position: outside ? "absolute" : "fixed",
                          top: 0,
                        }
                      : {
                          height: imgHeight,
                          width: 0.84375 * window.screen.height,
                          position: outside ? "absolute" : "fixed",
                        }
                    : !outside
                    ? {
                        height: imgHeight,
                        width: window.screen.width / 0.75,
                        position: outside ? "absolute" : "fixed",
                        top: `${
                          (window.screen.height -
                            (window.screen.width / 0.75 +
                              0.15625 * window.screen.height)) /
                          2
                        }px`,
                      }
                    : {
                        height: imgHeight,
                        width: `${0.84375 * window.screen.height}px`,
                        position: outside ? "absolute" : "fixed",
                      }
                  : isIOS()
                  ? !outside
                    ? {
                        height: window.innerHeight,
                        width: window.innerHeight / 0.75,

                        position: outside ? "absolute" : "fixed",
                      }
                    : {
                        height: "100%",
                        width: 0.84375 * window.screen.height - 40,
                        position: outside ? "absolute" : "fixed",
                      }
                  : !outside
                  ? {
                      height: imgHeight,
                      width: window.screen.height / 0.75,
                      position: outside ? "absolute" : "fixed",
                    } //might be a problem check (imgHeight)
                  : {
                      height: `${imgHeight - 40}px`,
                      width: `calc(84.375% - 40px)`,
                      position: outside ? "absolute" : "fixed",
                    }
              }
            >
              <Hidden mdUp>
                <div
                  id="TourerMobHider"
                  style={{
                    width: isPortrait ? "100vh" : "100vw", //!mobile ? "100%" : "100vw",
                    height: isPortrait ? "100vw" : "100vh", //!mobile ? "100%" : "100vh",
                    position: "absolute", //mobile ? "absolute" : "absolute", //"fixed"
                    pointerEvents: "none",
                    left: "0",
                    top: "0",
                    zIndex: "2100",
                    display: "none",
                    backgroundColor: "#000000aa",
                    backdropFilter: "blur(6px)",
                    // filter: "blur(4px)",
                  }}
                ></div>
              </Hidden>
              {/* action bar only in mobile */}
              {!fromBigScreen && (
                <VisualizationActionBar
                  outside={outside}
                  images={points}
                  thirdRow={points["3rd"] !== undefined}
                  secondRow={points["rf"] !== undefined}
                  height={imgHeight}
                  width={imgWidth}
                  isPortrait={isPortrait}
                  handleSetSide={(ev) => handleSetSide(ev)}
                  setOpen={setOpen}
                  open={open}
                  side={side}
                />
              )}

              {!fromBigScreen && (
                <div
                  className={classes.viewListDiv}
                  onClick={handleOpen}
                  id="issuesMob"
                >
                  <OpenListIcon
                    className={classes.openListIcon}
                    style={
                      localStorage.getItem("language") === "ar"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                    fill="white"
                  />
                </div>
              )}
              {/* views buttons only in big screen */}

              {fromBigScreen && (
                <div className={classes.choicesDiv} id="sides">
                  {points?.["dfv"] !== undefined && (
                    <Button
                      className={clsx({
                        [classes.selectionDiv]: 1,
                        [classes.selectionButton]: side === "dfv",
                      })}
                      classes={{
                        label: classes.selectionLabel,
                      }}
                      onClick={(ev) => handleSetSide(ev)}
                      value="dfv"
                      position="ext"
                    >
                      <img
                        className={
                          side !== "dfv"
                            ? classes.viewSelectionImage
                            : classes.viewSelectionImageWidth
                        }
                        src={
                          side === "dfv"
                            ? require("../images/viewSelectionImages/frontAngleSelection1.png")
                            : require("../images/viewSelectionImages/frontAngleSelection0.png")
                        }
                      ></img>
                      <Typography
                        style={
                          side === "dfv"
                            ? {
                                backgroundColor: "#07303E",
                                color: "white",
                                borderRadius:
                                  localStorage.getItem("language") === "en"
                                    ? "0 0 8px 0"
                                    : "0 0 0 8px",
                              }
                            : null
                        }
                        className={classes.viewTitle}
                      >
                        {t("Front Angle")}
                      </Typography>
                    </Button>
                  )}
                  {points["prf"] !== undefined && (
                    <Button
                      className={clsx({
                        [classes.selectionDiv]: 1,
                        [classes.selectionButton]: side === "prf",
                      })}
                      classes={{
                        label: classes.selectionLabel,
                      }}
                      onClick={(ev) => handleSetSide(ev)}
                      value="prf"
                      position="ext"
                    >
                      <img
                        className={
                          side !== "prf"
                            ? classes.viewSelectionImage
                            : classes.viewSelectionImageWidth
                        }
                        src={
                          side === "prf"
                            ? require("../images/viewSelectionImages/rearAngleSelection1.png")
                            : require("../images/viewSelectionImages/rearAngleSelection0.png")
                        }
                      ></img>
                      <Typography
                        style={
                          side === "prf"
                            ? {
                                backgroundColor: "#07303E",
                                color: "white",
                                borderRadius:
                                  localStorage.getItem("language") === "en"
                                    ? "0 0 8px 0"
                                    : "0 0 0 8px",
                              }
                            : null
                        }
                        className={classes.viewTitle}
                      >
                        {t("Rear Angle")}
                      </Typography>
                    </Button>
                  )}
                  {points["fv"] !== undefined && (
                    <Button
                      className={clsx({
                        [classes.selectionDiv]: 1,
                        [classes.selectionButton]: side === "fv",
                      })}
                      classes={{
                        label: classes.selectionLabel,
                      }}
                      onClick={(ev) => handleSetSide(ev)}
                      value="fv"
                      position="int"
                    >
                      <img
                        className={
                          side !== "fv"
                            ? classes.viewSelectionImage
                            : classes.viewSelectionImageWidth
                        }
                        src={
                          side === "fv"
                            ? require("../images/viewSelectionImages/driverViewSelection1.png")
                            : require("../images/viewSelectionImages/driverViewSelection0.png")
                        }
                      ></img>
                      <Typography
                        style={
                          side === "fv"
                            ? {
                                backgroundColor: "#07303E",
                                color: "white",
                                borderRadius:
                                  localStorage.getItem("language") === "en"
                                    ? "0 0 8px 0"
                                    : "0 0 0 8px",
                              }
                            : null
                        }
                        className={classes.viewTitle}
                      >
                        {t("Driver View")}
                      </Typography>
                    </Button>
                  )}
                  {points["rf"] !== undefined && (
                    <Button
                      className={clsx({
                        [classes.selectionDiv]: 1,
                        [classes.selectionButton]: side === "rf",
                      })}
                      classes={{
                        label: classes.selectionLabel,
                      }}
                      onClick={(ev) => handleSetSide(ev)}
                      value="rf"
                      position="int"
                    >
                      <img
                        className={
                          side !== "rf"
                            ? classes.viewSelectionImage
                            : classes.viewSelectionImageWidth
                        }
                        src={
                          side === "rf"
                            ? require("../images/viewSelectionImages/passengerViewSelection1.png")
                            : require("../images/viewSelectionImages/passengerViewSelection0.png")
                        }
                      ></img>
                      <Typography
                        style={
                          side === "rf"
                            ? {
                                backgroundColor: "#07303E",
                                color: "white",
                                borderRadius:
                                  localStorage.getItem("language") === "en"
                                    ? "0 0 8px 0"
                                    : "0 0 0 8px",
                              }
                            : null
                        }
                        className={classes.viewTitle}
                      >
                        {t("Passenger View")}
                      </Typography>
                    </Button>
                  )}
                  {points["3rd"] !== undefined && (
                    <Button
                      className={clsx({
                        [classes.selectionDiv]: 1,
                        [classes.selectionButton]: side === "3rd",
                      })}
                      classes={{
                        label: classes.selectionLabel,
                      }}
                      onClick={(ev) => handleSetSide(ev)}
                      value="3rd"
                      position="int"
                    >
                      <img
                        className={
                          side !== "3rd"
                            ? classes.viewSelectionImage
                            : classes.viewSelectionImageWidth
                        }
                        src={
                          side === "3rd"
                            ? require("../images/viewSelectionImages/3rdRowSelection1.png")
                            : require("../images/viewSelectionImages/3rdRowSelection0.png")
                        }
                      ></img>
                      <Typography
                        style={
                          side === "3rd"
                            ? {
                                backgroundColor: "#07303E",
                                color: "white",
                                borderRadius:
                                  localStorage.getItem("language") === "en"
                                    ? "0 0 8px 0"
                                    : "0 0 0 8px",
                              }
                            : null
                        }
                        className={classes.viewTitle}
                      >
                        {t("3rd row")}
                      </Typography>
                    </Button>
                  )}
                  {points["tv"] !== undefined && (
                    <Button
                      style={
                        side == "tv"
                          ? {
                              width: "126px",
                              borderLeft:
                                localStorage.getItem("language") === "en"
                                  ? "none"
                                  : null,
                              borderRadius: "0 8px 8px 0",
                              marginBottom: "0",
                            }
                          : { marginBottom: "0" }
                      }
                      className={clsx({
                        [classes.selectionDiv]: 1,
                        [classes.selectionButton]: side === "tv",
                      })}
                      classes={{
                        label: classes.selectionLabel,
                      }}
                      onClick={(ev) => handleSetSide(ev)}
                      value="tv"
                      position="int"
                    >
                      <img
                        className={
                          side !== "tv"
                            ? classes.viewSelectionImage
                            : classes.viewSelectionImageWidth
                        }
                        src={
                          side === "tv"
                            ? require("../images/viewSelectionImages/trunkSelection1.png")
                            : require("../images/viewSelectionImages/trunkSelection0.png")
                        }
                      ></img>
                      <Typography
                        style={
                          side === "tv"
                            ? {
                                backgroundColor: "#07303E",
                                color: "white",
                                borderRadius:
                                  localStorage.getItem("language") === "en"
                                    ? "0 0 8px 0"
                                    : "0 0 0 8px",
                              }
                            : null
                        }
                        className={classes.viewTitle}
                      >
                        {t("Trunk")}
                      </Typography>
                    </Button>
                  )}
                </div>
              )}

              {/* exit button */}
              {/* probably should change to goBack instead of link to */}
              <Hidden mdUp>
                {!outside && (
                  <Link
                    to={"/car/" + carId}
                    onClick={exitFullscreen}
                    className={
                      fromBigScreen ? classes.webExit : classes.MobExit
                    }
                  >
                    <Grid container className={classes.closeIconGrid}>
                      <CloseIcon
                        id="closeVisMobBut"
                        className={classes.closeIcon}
                      />
                    </Grid>
                  </Link>
                )}
              </Hidden>
              {/* rendering of points */}
              {/* {points[side] && imgLoaded */}
              {points?.[side]?.points
                ? Object.keys(points[side].points).map((key, i) => {
                    DrawerData.map((element) => {
                      if (element?.answerUniqueId != undefined) {
                        if (element?.answerUniqueId.toString() === key) {
                          element.x = points[side].points[key].x;
                          element.y = points[side].points[key].y;
                          element.option = points[side].points[key].option;
                          element.point = points[side].points[key];
                        }
                      }
                    });
                    return (
                      <>
                        <Point
                          side={side}
                          logVisitorView={logVisitorView}
                          style={{ transform: "rotate(-90deg)" }}
                          point={DrawerData[i]}
                          testPoint={points[side].points[key]}
                          k={key}
                          i={i}
                          imageData={imageData}
                          key={`point_${i}_${key}`}
                          isPortrait={isPortrait}
                          imgHeight={imgHeight}
                          imgWidth={imgWidth}
                          count={i}
                          faults={faults}
                          fromBigScreen={fromBigScreen}
                          DrawerData={DrawerData}
                          setLastPointLoaded={setLastPointLoaded}
                          lastPoint={
                            Object.keys(points[side].points).length - 1 == i
                          }
                          unlocked={unlocked}
                        />
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      )}
      <Hidden smDown>
        <Typography className={classes.visualizationDisclaimer}>
          {t(
            "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity"
          )}
        </Typography>
      </Hidden>
    </div>
  );
};

Visualization.defaultProps = {
  openVisualization: true, // since in mobile there is no prop supplied this is the default for the dialog
};

const mapStateToProps = (state) => ({
  points: state.car.unlocked_part?.points ?? {},
  unlocked: state.car.car.unlocked,
  carFaults: state.car.unlocked_part?.faults ?? {
    dfv: [],
    prf: [],
    fv: [],
    rf: [],
    tv: [],
  },
});

const mapDispatchToProps = (dispatch) => ({
  getCarFaults(payload) {
    dispatch(getCarFaults(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
  getCarVisualization(payload) {
    dispatch(getCarVisualization(payload));
  },
  getCarVisualizationIDs(payload) {
    dispatch(getCarVisualizationIDs(payload));
  },
  setPoints(payload) {
    dispatch(updatePoints(payload));
  },
  logVisitorView(payload) {
    dispatch(logVisitorView(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Visualization)
);
