import React, { useEffect, useState, useRef } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";
import PreInspected from "../icons/PreInspected.svg";
import Transparent from "../icons/Transparent.svg";
import Free from "../icons/Free.svg";
import theme from "../theme";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CreditActionBarComponent from "./Subscriptions/CreditActionBarComponent";

const useStyles = makeStyles((theme) => ({
  actionBarRelative: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      transformOrigin: "bottom left",
      zIndex: 10,
      width: "100%",
      left: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
      height: "100%",
      position: "relative",
    },
  },
  actionBarPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
      borderRadius: 0,
      padding: "10px 24px",
    },
  },
  stickyDiv: {
    position: "sticky",
    top: "45px",
    minWidth: "290px",
    height: "max-content",
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      width: "290px",
      margin: "119px 21px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "119px auto 0 0",
    },
    [theme.breakpoints.up("xl")]: {
      width: "374px",
    },
  },
  bookAppointmentPaper: {
    boxShadow: "0px 3.28852px 5.26164px rgba(10, 47, 60, 0.2)",
    backgroundColor: "#F9FAFD",
  },
  loudSpeakerDiv: {
    display: "flex",
    justifySelf: "center",
    marginBottom: "4.66px",
    [theme.breakpoints.up("xl")]: {
      marginBottom: "6px",
    },
  },
  loudSpeakerIcon: {
    marginRight: "7.78px",
    [theme.breakpoints.up("xl")]: {
      marginRight: "10px",
    },
  },
  actionBarHeader: {
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "15.55px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
  },
  actionBarSubHeader: {
    fontSize: "10.8847px",
    fontWeight: "500",
    color: "#07303E",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "10.8847px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  hr: {
    width: "100%",
    margin: "7.77px 0 16px",
    height: "1px",
    border: "none",
    backgroundColor: "rgb(177, 177, 177)",
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 24px",
    },
  },
  featuredImageDiv: {
    //div added around image to hide image and image broken borders
    overflow: "hidden",
    width: "calc(56.76px - 2px)",
    height: "calc(43.54px - 2px)",
    borderRadius: "3.8874px",
    flexBasis: "calc(22% - 2px)",
    minWidth: "calc(22% - 2px)",
    [theme.breakpoints.up("md")]: {
      width: "calc(56.76px - 2px)",
      height: "calc(43.54px - 2px)",
      borderRadius: "3.8874px",
      flexBasis: "calc(22% - 2px)",
      minWidth: "calc(22% - 2px)",
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(73px - 2px)",
      height: "calc(56px - 2px)",
      borderRadius: "3.8874px",
      flexBasis: "calc(22% - 2px)",
      minWidth: "calc(22% - 2px)",
    },
  },
  featuredImage: {
    margin: "-1px",
    width: "56.76px",
    // height: "43.54px",
    borderRadius: "3.8874px",
    flexBasis: "22%",
    minWidth: "22%",
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      width: "56.76px",
      // height: "43.54px",
      borderRadius: "3.8874px",
      flexBasis: "22%",
      minWidth: "22%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "73px",
      // height: "56px",
      borderRadius: "3.8874px",
      flexBasis: "22%",
      minWidth: "22%",
    },
  },
  detailDiv: {
    display: "flex",
    flexBasis: "74.17%",
    minWidth: "74.17%",
    flexWrap: "wrap",
    marginBottom: "11.67px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("xl")]: {
      marginBottom: "15.68px",
    },
  },
  carNameTypography: {
    fontSize: "12.4397px",
    fontWeight: "500",
    color: "#07303E",
    flexBasis: "100%",
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      fontSize: "12.4397px",
      marginBottom: "3.11px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
      marginBottom: "4px",
    },
  },
  priceTypography: {
    fontSize: "14.1601px",
    fontWeight: "500",
    color: "#F15A29",
    [theme.breakpoints.up("md")]: {
      fontSize: "14.1601px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18.21px",
    },
  },
  odometerDiv: {
    display: "flex",
    alignItems: "center",
  },
  odometerTypography: {
    fontSize: "12.3901px",
    fontWeight: "400",
    color: "#909090",
    [theme.breakpoints.up("md")]: {
      marginLeft: "3.54px",

      fontSize: "12.3901px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "15.94px",
      marginLeft: "4.55px",
    },
  },
  valuePropesitionPaper: {
    boxShadow: "0px 3.28852px 5.26164px rgba(10, 47, 60, 0.2)",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "118.95px",
    marginTop: "16px",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "0",
    [theme.breakpoints.up("xl")]: {
      height: "136px",
      // marginTop: "24px",
    },
  },
  vPInnerPaper: {
    flexBasis: "29.769%",
    minWidth: "29.769%",
    borderRadius: "0",
    border: "1px solid #e1e1e1",
    boxShadow: "none",
    height: "95.63px",
    alignSelf: "center",
    [theme.breakpoints.up("xl")]: {
      height: "109px",
    },
  },
  vpImages: {
    display: "block",
    margin: "8px auto 0",
    // width: "66.94px",
    [theme.breakpoints.up("xl")]: {
      // width: "86.1px",
      margin: "9px auto 4px",
    },
  },
  vpTypography: {
    display: "block",
    fontSize: "10px",
    fontWeight: "500",
    textAlign: "center",
    color: "#535353",
    [theme.breakpoints.up("xl")]: {
      margin: "0 auto 11px",
      fontSize: "12px",
      width: "103px",
    },
  },
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    padding: "10px 48px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: "8.94px 34.21px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
      padding: "11.5px 43px",
    },
  },

  formBlock: {
    margin: "10px",
    width: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  firstName: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "6px",
    },
  },
  [theme.breakpoints.up("md")]: {
    lastName: {
      paddingLeft: "6px",
    },
  },
  formControlRoot: {
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
    },
  },
  inputSpacing: {
    margin: "14px 0", /////
    height: "46px", /////
    borderRadius: "8.67px",
    boxShadow: "0px 2.20309px 2.67182px rgba(130, 148, 154, 0.4)",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      margin: "10px 0",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "46px", /////
    [theme.breakpoints.down("sm")]: {
      // height: window.screen.height * 0.0707,
      // maxHeight: "40px",
      backgroundColor: "white",
      height: "37px",
    },
  },
  inputLabel: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(14px,11px) scale(1)",
    },
    transform: "translate(14px,15px) scale(1)",
  },
  correctBorder: {
    borderColor: "#37C534!important",
  },
  correctColor: {
    color: "#37C534!important",
  },
  optionalColor: {
    color: "#bdbdbd",
  },
  innerCardButton: {
    margin: "40px 10px 100px",
    padding: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
  checkedIcon: {
    backgroundColor: "#0c6885",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
      [theme.breakpoints.down("sm")]: {
        width: 16,
        height: 16,
      },
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  changeNumber: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 2% -10px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 -10px 0",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  newButton: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    padding: "10px 48px",
    width: "230px",
    height: "40px",
    margin: "auto",
  },
  newLoudSpeakerIcon: {
    marginRight: "11.61px",
  },
}));

const BookAppointmentActionBar = (props) => {
  const { t } = useTranslation();
  const {
    numberWithCommas,
    carCard,
    carImages,
    user,
    car,
    sellerReport,
    imrsz,
    // handleClickOpen /*,isVisible*/,
    // setChargeOptionsOpen,
  } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const featuredImageRef = useRef();
  const [featuredImageLoaded, setFeaturedImageLoaded] = useState(false);
  const [miniImageLoaded, setMiniImageLoaded] = useState(true);
  const [form, setForm] = useState({
    firstName: user ? user.first_name : "",
    lastName: user ? user.last_name : "",
    phone: user ? user.phone : "",
    time: "",
  });
  ////////////////////////////////////////////////////
  const actionButtonRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  ////////////////////////////////////////////////////
  const [featuredImage, setFeaturedImage] = useState();
  const xLargeUp = useMediaQuery(theme.breakpoints.up("xl"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const VPObj = [
    {
      icon: (
        <img src={PreInspected} loading="lazy" className={classes.vpImages} />
      ),
      text: t("Pre-Inspected Cars"),
    },
    {
      icon: (
        <img src={Transparent} loading="lazy" className={classes.vpImages} />
      ),
      text: t("Limited Human Intervention"),
    },
    {
      icon: <img src={Free} loading="lazy" className={classes.vpImages} />,
      text: t("Pay Less, Get More"),
    },
  ];

  useEffect(() => {
    document.getElementById("root").style.filter = "none";
    setForm({
      firstName: user ? user?.first_name : "",
      lastName: user ? user?.last_name : "",
      phone: user ? user?.phone : "",
    });
    return () => {};
  }, [user]);

  useEffect(() => {
    // setFeaturedImage(carImages.filter((image) => image.includes("Featured"))); // causes problems when images are intered in arabic
    setFeaturedImage(imrsz ? imrsz(carImages[0]) : carImages[0]); // if car was entered correctly first image is featured else it would have first exterior image
  }, [carImages]);

  useEffect(() => {
    setFeaturedImageLoaded(true);
  }, [miniImageLoaded]);

  //////////////////////////////////////////////////
  useEffect(() => {
    const options = {};
    const actionButtonObserver = new IntersectionObserver(
      (entries, actionButtonObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(entry.isIntersecting);
          } else if (
            !entry.isIntersecting &&
            entry?.boundingClientRect?.y < 0
          ) {
            setIsVisible(entry.isIntersecting);
          } else {
            // not intersecting but came from below (we dont want it to re appear (keeps true from first if))
          }
        });
      },
      options
    );
    if (actionButtonRef.current) {
      actionButtonObserver.observe(actionButtonRef.current);
    }
    return () => {
      if (actionButtonRef.current) {
        actionButtonObserver.unobserve(actionButtonRef.current);
      }
    };
  });
  return (
    <>
      <div className={classes.stickyDiv}>
        <Hidden smDown>
          <div square>
            <div>
              <Paper square className={classes.bookAppointmentPaper}>
                <CreditActionBarComponent
                  carCard={carCard}
                  featuredImage={featuredImage}
                  sellerReport={sellerReport}
                />
              </Paper>
            </div>
            <Hidden smDown>
              <Paper square className={classes.valuePropesitionPaper}>
                {VPObj.map((vpItem) => (
                  <Paper className={classes.vPInnerPaper}>
                    {vpItem.icon}
                    <Typography className={classes.vpTypography}>
                      {vpItem.text}
                    </Typography>
                  </Paper>
                ))}
              </Paper>
            </Hidden>
          </div>
        </Hidden>
      </div>
      {/* ////////////////////////////////////////////////////////// */}
      <Hidden mdUp>
        <div ref={actionButtonRef}>
          <CreditActionBarComponent
            carCard={carCard}
            isVisible={isVisible}
            sellerReport={sellerReport}
          />
        </div>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  car: state.car.car,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookAppointmentActionBar)
);
