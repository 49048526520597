import React from "react";

const CheckSign = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={0.91 * size}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1499 5.11789L3.91913 8.229L10.1499 1.229"
        stroke={fill}
        stroke-width="1.81943"
      />
    </svg>
  );
};

export default CheckSign;
