import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  smPapers: {
    backgroundColor: "unset",
    borderRadius: "0",
    boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.4)",
    margin: "15px 0",
    padding: "15px",
    [theme.breakpoints.up("md")]: {
      all: "initial",
      width: "100%",
    },
  },
  header: {
    transform: "none",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "0 0 15px",
      width: "230px",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#07303E",
      padding: "0",
      width: "300px",
    },
  },
  reportIcons: {
    transform: "none",
    height: "15px",
    width: "15px",
    margin: "0 5px 0 0",
    [theme.breakpoints.up("md")]: {
      height: "clamp(15px,2vw,24px)",
      width: "clamp(15px,2vw,24px)",
    },
  },
  inspDetText: {
    // transform: "none",
    width: "85px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#9A9A9A",
    fontSize: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
  },
  inspDetSubText: {
    // transform: "none",
    width: "100px",
    color: "#07303E",
    fontSize: "10px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
  },
  // noOfPoints: {
  //   transform: "none",
  //   width: "35%",
  //   fontSize: "12px",
  //   fontWeight: "500",
  //   color: "#07303E",
  //   // marginRight: "3px",
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "clamp(10px,1.1vw,18px)",
  //     alignSelf: "center",
  //   },
  // },
  conditionReportHr: {
    height: "1px",
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, #0c6885 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.up("md")]: {
      margin: "32px 0 30px",
      backgroundImage:
        "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    },
  },
  titleDiv: {
    borderBottom: "2px solid #0c6885",
    margin: "0 -15px",
    padding: "0 15px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
    },
  },
  borderedDiv: {
    [theme.breakpoints.up("md")]: {
      border: "0.5px solid #186985",
      paddingBottom: "15px",
    },
  },
  reportSummaryDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 10px 18px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "0.5px solid  #186985",
      padding: "15px 20px 18px",
      marginBottom: "26px",
    },
  },
  reportSummaryText: {
    transform: "none",
    width: "40%",
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(12px,1.1vw,20px)",
      alignSelf: "center",
      width: "40%",
    },
  },
  // pointsText: {
  //   transform: "none",

  //   fontSize: "12px",
  //   fontWeight: "400",
  //   color: "#9A9A9A",
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "clamp(10px,1.1vw,18px)",
  //     alignSelf: "center",
  //     marginRight: "14px",
  //   },
  // },
  visualScoreGrid: {
    padding: "0 10px",
    [theme.breakpoints.up("md")]: {
      padding: "0 20px",
    },
  },
  rep: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#00BDFF",
    display: "flex",
    justifyContent: "center",
  },
  // arrow: {
  //   height: "15px",
  // },
  groupScore: {
    transform: "none",
    width: "15%",

    color: "#07303E",
    fontSize: "12px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  groupTitle: {
    transform: "none",
    width: "40%",
    color: "#07303E",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  scoreLine: {
    width: "100%",
    height: "6px",
    marginBottom: "10px",
    marginTop: "3px",
    transform: "none",
    [theme.breakpoints.up("md")]: {
      height: "12px",
      marginBottom: "20px",
    },
  },
  conditionReportDiv: {},
}));

const ConditionReportSkeleton = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.smPapers}>
      <div className={classes.titleDiv}>
        <Skeleton className={classes.header}></Skeleton>
      </div>
      <div className={classes.borderedDiv}>
        <div className={classes.reportSummaryDiv}>
          <div>
            <Skeleton
              style={{
                width: "100px",
                marginBottom: "3px",
              }}
              className={classes.reportSummaryText}
            ></Skeleton>
            <Skeleton
              style={{
                width: "124px",
              }}
              className={classes.reportSummaryText}
            ></Skeleton>
          </div>
        </div>
        <hr className={classes.conditionReportHr} />
        <div style={{ display: "flex", marginBottom: "25px" }}>
          <div style={{ marginLeft: "10px" }}>
            <Skeleton className={classes.reportIcons} />
            <Skeleton className={classes.inspDetText} />
            <Skeleton className={classes.inspDetSubText} />
          </div>
          <div style={{ right: "15px", position: "absolute" }}>
            <Skeleton className={classes.reportIcons} />
            <Skeleton className={classes.inspDetText} />
            <Skeleton className={classes.inspDetSubText} />
          </div>
        </div>
        <hr className={classes.conditionReportHr} />
        {/* <div className={classes.conditionReportDiv}> */}
        <Grid container>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((group, i) => (
            <Grid item xs={12} md={6}>
              <Grid container className={classes.visualScoreGrid}>
                <Grid item xs={6}>
                  <Skeleton className={classes.groupTitle}></Skeleton>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Skeleton className={classes.groupScore}></Skeleton>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton className={classes.scoreLine} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* </div> */}
      </div>
      <Hidden smDown>
        <Grid
          container
          className={classes.borderedDiv}
          style={{ borderTop: " none" /*marginBottom: "72px"*/ }}
        >
          <Grid item sm={12} className={classes.rep}>
            <div>
              <Grid container>
                <Skeleton
                  style={{
                    padding: "26px 0 11px",
                    textTransform: "none",
                  }}
                >
                  <Grid item>
                    <Skeleton
                      style={{
                        transform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "#00BDFF",
                        width: "165px",
                      }}
                    ></Skeleton>
                  </Grid>
                  <Grid item></Grid>
                </Skeleton>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Hidden>
    </Paper>
  );
};

export default ConditionReportSkeleton;
