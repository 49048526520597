import React from "react";
import {
  Breadcrumbs,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import BackArrow from "../icons/BackArrow";
import MetaDecorator from "../MetaDecorator";
import MaterialLink from "@material-ui/core/Link";
import generalClasses from "./generalStyles";
export const NavState = (props) => {
  // this compoenent containes the home / all result /car name
  const { carCard, t, carFace, history, car_info, carData } = props;
  const classes = generalClasses();
  return (
    <>
      <div className={classes.container}>
        {/* <MetaDecorator
          title={"Search for Pre-inspected cars for free on Markabte"}
          description={`Check ${carCard?.year} ${carCard?.make} ${carCard?.model}: JD ${carCard?.price} - ${carCard?.milage},| Markabte`}
          image={carFace}
        /> */}
        <Grid container justify="center" style={{ padding: "0" }}>
          <Grid item xs={12}>
            <Paper className={classes.navigationPaper} square>
              <Hidden smDown>
                <Breadcrumbs
                  className={classes.breadcrumbsComponent}
                  separator={"/"}
                  aria-label="breadcrumb"
                >
                  <MaterialLink className={classes.Link} color="#626465">
                    <Link
                      style={{
                        color: "#626465",
                        textDecoration: "none",
                        fontSize: "14px",
                      }}
                      className={classes.Link}
                      to="/"
                    >
                      {t("Home")}
                    </Link>
                  </MaterialLink>
                  <MaterialLink className={classes.Link} color="#626465">
                    <Link
                      style={{
                        color: "#626465",
                        textDecoration: "none",
                        fontSize: "14px",
                      }}
                      className={classes.Link}
                      onClick={() => {
                        history.length <= 2
                          ? history.replace("/search-results/other=all")
                          : history.goBack();
                      }}
                    >
                      {t("All Results")}
                    </Link>
                  </MaterialLink>

                  {carData?.card?.year ? (
                    <div style={{ display: "flex" }}>
                      <Typography
                        color="#07303E"
                        style={{
                          fontSize: "14px",
                          color: "#07303E",
                        }}
                        children={`${carData?.card?.year}`}
                      />
                      {"\u00A0"}
                      <Typography
                        color="#07303E"
                        style={{
                          fontSize: "14px",
                          color: "#07303E",
                        }}
                        children={`${carData?.card?.make}`}
                      />
                      {"\u00A0"}
                      <Typography
                        color="#07303E"
                        style={{
                          fontSize: "14px",
                          color: "#07303E",
                        }}
                        children={`${carData?.card?.model}`}
                      />
                      {"\u00A0"}
                      <Typography
                        color="#07303E"
                        style={{
                          fontSize: "14px",
                          color: "#07303E",
                        }}
                        children={`${carData?.card?.trim}`}
                      />
                      {"\u00A0"}
                      <Typography
                        color="#07303E"
                        style={{
                          fontSize: "14px",
                          color: "#07303E",
                        }}
                        children={`${t("Details")}`}
                      />
                      {"\u00A0"}
                    </div>
                  ) : (
                    <Typography
                      color="#07303E"
                      style={{
                        fontSize: "14px",
                        color: "#07303E",
                      }}
                      children={t(`Car Loading ...`)}
                    />
                  )}
                </Breadcrumbs>
              </Hidden>
              <Hidden mdUp>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <IconButton
                    onClick={() => {
                      history.replace("/search-results/other=all");
                    }}
                  >
                    <BackArrow
                      size="15"
                      fill="white"
                      style={
                        localStorage.getItem("language") == "en"
                          ? {}
                          : { transform: "rotate(180deg)" }
                      }
                    ></BackArrow>
                  </IconButton>
                  <Typography
                    style={
                      localStorage.getItem("language") === "ar"
                        ? {
                            fontSize: "12px",
                            fontWeight: "700",
                            color: "white",
                            margin: "auto",
                          }
                        : {
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "white",
                            margin: "auto",
                          }
                    }
                  >
                    {t("All Results")}
                  </Typography>
                </div>
              </Hidden>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
