import React from "react";
const WhatsappIcon = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      width={size}
      height={size * 0.974}
      viewBox="0 0 19 18"
      style={style}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3569 10.7304L13.3502 10.7866C11.7009 9.96463 11.5284 9.85513 11.3154 10.1746C11.1677 10.3959 10.7372 10.8976 10.6074 11.0461C10.4762 11.1924 10.3457 11.2036 10.1229 11.1024C9.89792 10.9899 9.17567 10.7536 8.32067 9.98863C7.65467 9.39238 7.20767 8.66113 7.07567 8.43613C6.85592 8.05663 7.31567 8.00263 7.73417 7.21063C7.80917 7.05313 7.77092 6.92938 7.71542 6.81763C7.65917 6.70513 7.21142 5.60263 7.02392 5.16313C6.84392 4.72513 6.65867 4.78063 6.51992 4.78063C6.08792 4.74313 5.77217 4.74913 5.49392 5.03863C4.28342 6.36913 4.58867 7.74163 5.62442 9.20113C7.65992 11.8651 8.74442 12.3556 10.7274 13.0366C11.2629 13.2069 11.7512 13.1829 12.1374 13.1274C12.5679 13.0591 13.4627 12.5866 13.6494 12.0579C13.8407 11.5291 13.8407 11.0904 13.7844 10.9891C13.7289 10.8879 13.5819 10.8316 13.3569 10.7304V10.7304Z"
        fill={fill}
      />
      <path
        d="M15.6166 2.58683C9.84981 -2.98792 0.306063 1.05533 0.302313 8.91983C0.302313 10.4918 0.714062 12.0248 1.49856 13.3786L0.226562 18.0001L4.97781 16.7611C10.9066 19.9636 18.2236 15.7111 18.2266 8.92433C18.2266 6.54233 17.2966 4.30058 15.6053 2.61608L15.6166 2.58683ZM16.7281 8.89958C16.7236 14.6243 10.4393 18.1996 5.46906 15.2776L5.19906 15.1171L2.38656 15.8483L3.14031 13.1146L2.96106 12.8333C-0.131938 7.90958 3.42156 1.47458 9.28056 1.47458C11.2711 1.47458 13.1393 2.25083 14.5463 3.65708C15.9526 5.05133 16.7281 6.91958 16.7281 8.89958V8.89958Z"
        fill={fill}
      />
    </svg>
  );
};

export default WhatsappIcon;
