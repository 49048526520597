import React, { useEffect, useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { getCarGroups, getCar, getUnlockedPart } from "../store/actions/car";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Visualisation from "./CosmeticConditionProto";
import BackArrowTail from "../components/icons/BackArrowTail";
import BackArrowIcon from "../components/icons/BackArrow.js";
import BackArrow from "../components/icons/BackArrow";
import Exterior from "../icons/exterior.svg";
import Interior from "../icons/interior.svg";
import Tires from "../icons/tires.svg";
import Frame from "../icons/Frame.svg"; //will be added when i know the name for it in admin panel
import Dashboard from "../icons/OBD.svg"; //will be added when i know the name for it in admin panel
import Switches from "../icons/Controls.svg";
import BodyPaint from "../icons/BodyPaint.svg";
import Equipment from "../icons/equipment.svg";
import AcHeater from "../icons/AcHeater.svg";
import FaultyAnswer from "../icons/faultyPoint.svg";
import UnFaultyAnswer from "../icons/unFaultyPoint.svg";
import InformativeAnswer from "../icons/infoPoint.svg";
import Information from "../icons/Information.svg";
import NotIncluded from "../icons/notIncluded.svg";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import Hidden from "@material-ui/core/Hidden";
import ConditionReport from "../components/ConditionReport";
import HeartCircleIcon from "../components/icons/HeartCircleIcon";
import ShareEllipseIcon from "../components/icons/ShareEllipseIcon";
// import MobVisClickEn from "../components/icons/MobVisClickEn";
import MobVisClickEn from "../components/icons/visGifIcon.gif";
import MobVisClickAr from "../components/icons/MobVisClickAr";
import ClickableAnswerDialog from "../components/ClickableAnswerDialog";
import FaultyDetails from "./computerscan/FaultDetails";
import Ev from "../icons/Ev.svg";
import HybridBattery from "../icons/HybridBattery.svg";
import { getCarFaults, getCarVisualization } from "../store/actions/car";

import { useTranslation } from "react-i18next";
// import ConditionReportSkeleton from "../../../../CRS";
import InspectionSkeleton from "../components/skeletons/InspectionSkeleton";
import ConditionReportSkeleton from "../components/skeletons/ConditionReportSkeleton";
import VisualisationSkeleton from "../components/skeletons/VisualisationSkeleton";
import { numberWithCommas } from "../helpers/functions";
import ReactGa from "react-ga";
import FaultCard from "./computerscan/FaultCard";
import CardIterator from "./computerscan/CardIterator";
import MarkabtePopup from "../components/MarkabtePopup";
import FrameFaultsLis from "../components/inspection/FrameFaultsLis";
import FaultsStepper from "../components/FaultsStepper";
import Locked from "../components/icons/locked";
import useNonInitialEffect from "../helpers/useNonInitialEffect";
import BodyPaintResult from "../components/BodyPaintResult";
import Tour from "./Tour";
import VisLock from "../icons/VisLock.svg";
import CloseIcon from "@material-ui/icons/Close";
import Moment from "moment";
import { logVisitorView } from "../store/actions/accounts";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  navigationPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "39px",
      backgroundColor: "#186985",
    },
  },
  fancyNavigationPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "56px",
      backgroundColor: "#186985",
      justifyContent: "space-between",
    },
  },
  reportIcons: {
    height: "15px",
    width: "15px",
    margin: "0 5px 0 0",
  },
  noOfPoints: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#07303E",
    marginRight: "3px",
  },
  table: {
    width: "100%",
    fontSize: "1rem",
    backgroundColor: "#BFD6DE",
    borderCollapse: "collapse",
    display: "table",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.up("sm")]: { margin: "0 auto 20px" },
  },
  tablehead: {
    padding: "5px 10px",
    fontWeight: "700",
    fontSize: "1rem",
    backgroundColor: "#BFD6DE", // experimental
  },
  firstitem: {
    padding: "5px 0 5px 8px",
    fontWeight: "700",
    backgroundColor: "white",
    width: "55%",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: { padding: "0 0 0 8px" },
  },
  seconditem: {
    padding: "5px 13px",
    borderLeft: "1.33333px solid #C4C4C4",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: { padding: "2.5px 13px" },
  },
  thirditem: { marginLeft: "auto" },
  row: {
    borderBottom:
      "1px solid radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "0 0 15px",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "clamp(20px,2vw,24px)",
      margin: "34px 0 26px 24px",
    },
  },
  headerVisualization: {
    whiteSpace: "nowrap",
    color: "#186985",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      margin: "0 25px 13px 0",
    },
  },
  headerVisualizationPaper: {
    borderRadius: 0,
    background: "#FFFFFF",
    boxSizing: "border-box",
    boxShadow: "none",

    [theme.breakpoints.down("sm")]: {
      // padding: "0px 0px 16px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "34px 0px",
    },
  },
  visualizationImageStyle: {
    width: "100%",
    objectFit: "cover",
    height: "auto",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    position: "relative",
    filter: "blur(5px) brightness(50%)",
    borderRadius: "2px",
  },
  visMobIconText: {
    position: "absolute",
    bottom: "17px",
    left: "50%",
    marginLeft: "-100px",
    width: "200px",
    textAlign: "center",
    fontFamily: "Poppins",
    color: "#fff",
  },
  visMobIcon: {
    position: "absolute",
    bottom: "27px",
    left: "50%",
    marginLeft: "-36.5px",
    width: "73px",
  },
  visMobShap: {
    position: "absolute",
    top: "0",
    left: localStorage.getItem("language") === "en" && 0,
    right: localStorage.getItem("language") === "ar" && 0,
    width: "101px",
    height: "28px",
    clipPath: "polygon(0 0, 100% 0%, 74% 100%, 0% 100%)",
    background: "#07303E",
    color: "#fff",
    width: "120px",
    height: "30px",
    textAlign: "center",
    fontFamily: "Poppins",
    lineHeight: "3",
    fontSize: "10px",
  },
  visualizationImageDivStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(#00000000 13%, #000000cc)",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    borderRadius: "8.27px",
  },
  visualizationIconStyle: {
    width: "clamp(40px,4vw,50px)",
    transform: "translate(-50%,0)",
    position: "relative",
    left: "50%",
  },
  noteTypographyMedium: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#07303E",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      margin: "13px 0 24px 0",
    },
  },
  noteTypographyBold: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  inspDetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#9A9A9A",
    fontSize: "10px",
  },
  inspDetSubText: {
    color: "#07303E",
    fontSize: "10px",
    fontWeight: "500",
  },
  Link: {
    fontSize: "18px",
    color: "#707678",
    cursor: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "line-below",
    },
  },
  groupScore: {
    marginLeft: "auto",
    fontSize: "clamp(1rem,1.5vw,1.125rem)",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
  },
  groupIcon: {
    marginRight: "16px",
    height: "30px",
    width: "30px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
      height: "20px",
      width: "20px",
    },
  },
  exteriorGroupIcon: {
    marginRight: "16px",
    height: "32px",
    width: "32px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
      height: "20px",
      width: "20px",
    },
  },
  subGroupText: {
    fontSize: "1rem",
    fontWeight: "500",
    padding: "10px 16px",
    textAlign: "left",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "8px 0 10px 12px",
    },
  },
  subGroupScoreText: {
    fontSize: "1rem",
    fontWeight: "500",
    padding: "5px 10px",
    textAlign: "right",
  },
  navigationDiv: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
  },
  reportGrid: {
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
    },
  },
  reportPaper: {
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
      // margin: "50px 17px 0 4px", // page margin mismatch but matches design
    },
  },
  smPapers: {
    borderRadius: "0",
    boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.4)",
    margin: "15px 0",
    padding: "15px",
    [theme.breakpoints.up("md")]: {
      all: "initial",
    },
  },
  firstGridItem: {},
  expansionPanelSummaryContent: {
    margin: "10px 0",
  },
  LockedInspectionMainDiv: {
    marginTop: "24px",
    maxWidth: "375px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  LockedInspectionTitleDiv: {
    display: "flex",
    borderBottom: "1px solid #C4C4C4",
    paddingBottom: "8px",
  },
  LockedInspectionTitleTypography: {
    color: "#07303E",
    fontWeight: 600,
    fontSize: "14px",
    marginLeft: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  LockedInspectionInfoDiv: { paddingTop: "8px" },
  LockedInspectionInfoTypography: {
    color: "#07303E",
    fontWeight: 500,
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  LockedInspectionSampleLink: {
    display: "contents",
    cursor: "pointer",
    color: "#186985",
    fontWeight: 600,
    fontSize: "12px",
    textTransform: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  expansionPanelSummaryRoot: {
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: { padding: "0 10px" },
  },
  expansionPanelDetails: {
    height: "min-content",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      // display: "inline-block",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 12px",
    },
  },

  column: {
    columnCount: "2",
    columnWidth: "349px", // decrease to get columns to break faster but long text will wrap more
    columnGap: "0px",
    [theme.breakpoints.up("sm")]: {},
  },
  visualizationDiv: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      // height: "28vw",
      // height: "calc(56.5vw - 342.67px)", // calculated to keep the image at the same height to width ratio,
      // changing width of drawer ,or action bar grid item ,or first grid item's margin or padding will cause sizing problems
      height: "min(28vw,calc(56.5vw - 342.67px))",
      maxWidth: "734px", // for very big screens
      maxHeight: "419px", // for very big screens
      margin: "auto",
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  visualizationView: {
    position: "absolute",
    transformOrigin: "top left",
  },
  visualizationHr: {
    height: "1px",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 -20px 20px",
    },
  },
  fullReportHr: {
    margin: "0 -20px 9px",
    height: "1px",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  rowHr: {
    height: "2px",
    border: "none",
    margin: "6px 0",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      height: "1px",
    },
  },
  reportFilter: {
    color: "#07303E",
    fontSize: "18px",
    fontWeight: "500",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "56px 0px 28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      color: "#07303E",
      margin: "4px 0px 16px",
      marginRight: "12px",
    },
  },
  groupTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
  },
  groupDisclaimer: {
    textAlign: "center",
    width: "764px",
    margin: "auto",
    fontSize: "16px",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      // padding: "0 10px 10px 10px",
      width: "auto",
    },
  },
  disclaimerDiv: {
    margin: "20px 0px 38px 0px",
    background: "#F9FAFF",
    padding: "14px 11px 32px 11px",
  },
  actionBarRelative: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 90px)",
      position: "fixed",
      top: 0,
      transformOrigin: "top left",
      left: "45px",
      zIndex: 10,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
      height: "100%",
      position: "relative",
    },
  },
  actionBarPaper: {
    [theme.breakpoints.down("sm")]: {
      // padding: "8px 16px",
      margin: "0 -45px",
      filter: "drop-shadow(0px -2px 4px rgba(15, 71, 90, 0.4))",
      borderRadius: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: "43px 30px 0",
      position: "sticky",
      // top: "50px",// this is for not full paper looks better image problem
      top: "0",
      height: "81vh", //check on other screen sizes and try calc(87.35vh - 50px)
    },
  },
  discriptionGridContainer: {
    margin: "auto",
    width: "80%",
    maxWidth: "max-content",
    [theme.breakpoints.down("xs")]: {
      // width: "90%",
      width: "100%",
    },
  },
  discriptionText: {
    fontSize: "14px",
    margin: "0 9px 0 4px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  discriptionIcon: {
    width: "21px",
    height: "21px",
    margin: "0 0 0 9px",
    [theme.breakpoints.down("sm")]: {
      width: "17.5px",
      height: "17.5px",
      margin: "0 0 0 6px",
    },
  },
  tooltip: {
    backgroundColor: "white",
    color: "rgb(99,99,99)",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    padding: "15px",
    maxWidth: "327px",
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      padding: "8px",
      maxWidth: "218px",
    },
  },
  tooltipArrow: {
    "&::before": {
      backgroundColor: "white",
      border: "1px solid #000000",
      width: "95%",
    },
  },
  infoIcon: {
    width: "18px",
    height: "18px",
    margin: "auto 4px",
    [theme.breakpoints.down("sm")]: {
      width: "15px",
      height: "15px",
    },
  },
  firstItemTypography: {
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  answerText: {
    fontSize: "14px",
    fontWeight: "400",
    fontColor: "#636363",
  },
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    padding: "10px 48px",
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: "8.94px 34.21px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
      padding: "11.5px 43px",
    },
  },
  featuredImageDiv: {
    //div added around image to hide image and image broken borders
    overflow: "hidden",
    width: "calc(56.76px - 2px)",
    height: "calc(43.54px - 2px)",
    borderRadius: "3.8874px",
    flexBasis: "calc(22% - 2px)",
    minWidth: "calc(22% - 2px)",
    padding: "0 10px 0 0",
    objectFit: "cover",
  },
  featuredImage: {
    borderRadius: "3.8874px",
    transform: "translate(-50%, -50%)",
    position: "relative",
    top: "50%",
    left: "50%",
    width: "auto",
    height: "100%",
  },
  navBarRelative: {
    position: "fixed",
    top: 0,
    // transformOrigin: "bottom left",
    zIndex: 10000,
    width: "100%",
    left: 0,
    display: "block",
    // alignContent: "center",
    // opacity: 1,
    // transition: "opacity 10ms ease-in",
    // transition: "transform 0s",
    transition: "opacity 1s,visibility .6s",
    // transform: "translateY(100%)",
  },
  navBarRelativeHidden: {
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 0s,visibility .5s",
  },
  navbartitle: {
    fontSize: "13px",
    fontWeight: "600",
    color: "white",
    margin: "auto",
  },
  cardPriceTypo: {
    fontSize: "12px",
    fontWeight: "500",
    color: "white",
    margin: "auto",
  },
  visualizationHr: {
    border: "none",
    height: "1px",
    transform: "translate(-16px, 25px)",
    width: "calc(100% + 32px)",
    background:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  clickableAnswer: {
    textDecoration: "underline",
    color: "#00BDFF",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
  },
  tableInfoDiv: {
    backgroundColor: "#EAECF0",
    marginInline: "12px",
    borderRadius: "5px",
    marginBottom: "36px",
    [theme.breakpoints.down("sm")]: {
      marginInline: "12px",
      borderRadius: "5px",
      marginBottom: "26px",
      padding: "0 15px",
    },
  },
  groupNote: {
    margin: "0 0 34px 12px",
    color: "#636363",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "0",
    },
  },
  secondNote: {
    marginTop: "-24px",
  },
  dialogPaperWidthSm: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0",
    },
  },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  inspectionNotesDialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inspectionNotesDialogContentText: {
    textAlign: "center",
    fontSize: "14px",
    padding: "0 0 22px",
    margin: 0,
    color: "black",
  },
  firstinspectionTypo: {
    textAlign: "left",
    color: "#07303E",
    fontSize: "18px",
    fontWeight: "500",
  },
  inspectionNotesSpan: {
    color: "#07303E",
    fontSize: "20px",
    fontWeight: "600",
  },
  tableDiv: {
    display: "inline-block",
    width: "100%",
  },
  tableDivPaintWeb: {
    [theme.breakpoints.up("md")]: {
      minHeight: "550px",
    },
  },
}));

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

// switch component for inspection points with faults only this is the markup and styles only(logic is in main component)
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: "56px 16px 28px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0c6885",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },

  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#DBDBDB",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "auto",
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const nth = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};

const InspectionNotesDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, handelPublishCar, inspectionNotes } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
        paperWidthSm: classes.dialogPaperWidthSm,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={true}
        className={classes.inspectionNotesDialogTitle}
      >
        <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
          {t("Notes")}
        </Typography>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClose();
          }}
        />
      </DialogTitle>
      {inspectionNotes?.map((item, index) => {
        return (
          <DialogContent classes={{ root: classes.dialogContentText }}>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.inspectionNotesDialogContentText}
            >
              <Typography className={classes.firstinspectionTypo}>
                <span className={classes.inspectionNotesSpan}>
                  {t("Note")} {index + 1}:{" "}
                </span>{" "}
                {t(item)}
              </Typography>
            </DialogContentText>
          </DialogContent>
        );
      })}
    </Dialog>
  );
};

const InspectionReport = (props) => {
  const { t, i18n } = useTranslation();
  let mmRegex = new RegExp(/[0-9]+.*[0-9]*\smm+/);
  const classes = useStyles();
  const carId = props.match.params.id;
  const {
    history,
    date,
    carList,
    points,
    hideAppBar,
    showAppBar,
    handleCallback,
    carInfo,
    unlocked,
    setRecallGroupsToggle,
    previouslyUnlocked,
    groups,
    logVisitorView,
    // groupTemplate,
  } = props;
  const ref = useRef(null);
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" }); // from react responsive node module
  const visualizationImage = points?.dfv?.image;
  const [openVisualization, setOpenVisualization] = useState(false); // a state to open and close the bigger image view on Big screens
  const [isSmallDevice, setIsSmallDevice] = useState(true); //a stste describing the size of the screen true:mobile or tablet, false:big tablet or pc
  const [visualisationPath, setVisualizationPath] = useState(""); // a state containing the path that the link on the visualization icon directs to small screen: to cosmetic condition page, big screen: do not redirect
  const [openTooltip, setOpenTooltip] = useState(false);
  const [tooltipButtonId, setTooltipButtonId] = useState(0);
  const backgroundRef = useRef(); // reference to the div node that contains the bigger image and stepper in the Big screen, used for exiting the "dialog" bach to the normal vehicle detail page
  const [imgLoaded, setImgLoaded] = useState(false);
  const [hideNav, setHideNav] = useState(true);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [imageDialogProps, setImageDialogProps] = useState({});
  const [showfaults, setshowfaults] = useState(false);
  const [faults, setfaults] = useState({});
  const [frameFault, setframeFault] = useState({});
  const [frameFaultopen, setframeFaultopen] = useState(false);
  const [faultOpen, setfaultOpen] = useState(false);
  const [openInspection, setOpenInspection] = useState(false);
  const [inspectionNotes, setInspectionNotes] = useState({});
  const [groupTemplate, setgroupTemplate] = useState([]);
  const [interiorFaults, setinteriorFaults] = useState({
    images: [],
    score: 0,
  });
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentId, setCurrentId] = useState(
    string_between_strings("_ga=", ";", document.cookie)
  ); // two years
  const [currentGid, setCurrentGid] = useState(
    string_between_strings("_gid=", ";", document.cookie)
  ); /// 24 hours
  const [startTime, setStartTime] = useState(
    Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
  );
  const visualInspectionReport = useRef(null);
  const fullInspectionReport = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isVisibleFull, setIsVisibleFull] = useState(true);
  const [noted, setNoted] = useState(true);
  const [visNoted, setVisNoted] = useState(true);

  function string_between_strings(startStr, endStr, str) {
    let pos = str.indexOf(startStr) + startStr.length;
    return str.substring(pos, str.indexOf(endStr, pos));
  }

  useEffect(() => {
    const options = {};
    const actionButtonObserver = new IntersectionObserver(
      (entries, actionButtonObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && visNoted) {
            setIsVisible(entry.isIntersecting);
            actionButtonObserver.unobserve(visualInspectionReport.current);
            logVisitorView({
              page_title: currentPath,
              start_time: startTime,
              session_one: currentId,
              session_two: currentGid,
              tag: "Visual Inspection Report Title",
            });
            setVisNoted(false);
          } else if (
            !entry.isIntersecting &&
            entry?.boundingClientRect?.y < 0
          ) {
            setIsVisible(entry.isIntersecting);
          } else {
            // not intersecting but came from below (we dont want it to re appear (keeps true from first if))
          }
        });
      },
      options
    );
    if (visualInspectionReport.current) {
      actionButtonObserver.observe(visualInspectionReport.current);
    }
    return () => {
      if (visualInspectionReport.current) {
        actionButtonObserver.unobserve(visualInspectionReport.current);
      }
    };
  });

  useEffect(() => {
    const options = {};
    const actionButtonObserver = new IntersectionObserver(
      (entries, actionButtonObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && noted) {
            setIsVisibleFull(entry.isIntersecting);
            actionButtonObserver.unobserve(fullInspectionReport.current);
            logVisitorView({
              page_title: currentPath,
              start_time: startTime,
              session_one: currentId,
              session_two: currentGid,
              tag: "Full Inspection Report",
            });
            setNoted(false);
          } else if (
            !entry.isIntersecting &&
            entry?.boundingClientRect?.y < 0
          ) {
            setIsVisibleFull(entry.isIntersecting);
          } else {
            // not intersecting but came from below (we dont want it to re appear (keeps true from first if))
          }
        });
      },
      options
    );
    if (fullInspectionReport.current) {
      actionButtonObserver.observe(fullInspectionReport.current);
    }
    return () => {
      if (fullInspectionReport.current) {
        actionButtonObserver.unobserve(fullInspectionReport.current);
      }
    };
  });

  const getScoreColor = (x) => {
    if (isNaN(x)) {
      return "#07303E";
    }
    if (x <= 20) {
      return "#D40000";
    } else if (x <= 30) {
      return "#EA3800";
    } else if (x <= 40) {
      return "#EA5400";
    } else if (x <= 50) {
      return "#FF7A00";
    } else if (x <= 60) {
      return "#FFA800";
    } else if (x <= 70) {
      return "#FFD600";
    } else if (x <= 80) {
      return "#C5E500";
    } else if (x <= 90) {
      return "#5BCE00";
    } else if (x <= 100) {
      return "#00A900";
    }
  };
  const navRef = useRef();
  const getHistoryFaults = (arr) => {
    try {
      return arr.find((o) => o.id === 953).extended_answers.length;
    } catch (e) {
      console.log("error! can not find history states.");
      return 0;
    }
  };
  const faultscnt = (flts) => {
    var fields = Array.isArray(flts) ? flts : flts.fields ? flts.fields : flts;
    var fltscnt = 0;
    for (var i = 0; i < fields.length; i++) {
      fltscnt += fields[i].extended_answers.length;
    }
    return fltscnt;
  };

  useEffect(() => {
    setStartTime(
      Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
    );
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (navRef.current) {
        if (window.scrollY <= 357) setHideNav(true);
        else if (window.scrollY > 357 || this.window.scrollY < 0)
          setHideNav(false);
      }
    });
  }, [navRef]);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const showInteriorFaults = (data) => {
    setfaultOpen(true);
    let newimages = [];
    let images_counts = [];
    let ximages = [];
    let newDetaildFWIMGS = [];
    for (var i = 0; i < data.images.length; i++) {
      Object.values(data.images[i]).map((el) => {
        images_counts.push(el[0].length);
        el[0].map((img) => {
          newimages.push({ [Object.keys(data.images[i])[0]]: [img, el[1]] });
          ximages.push(img);
        });
      });
    }
    var all_images_count = 0;
    for (let i = 0; i < data.detailedFaultsWithImages.length; i++) {
      for (let j = 0; j < images_counts[i]; j++) {
        let blockedOBJ = data.detailedFaultsWithImages[i];
        let blockedOBJfield = Object.keys(data.detailedFaultsWithImages[i])[0];
        blockedOBJ[blockedOBJfield][0] = [ximages[all_images_count]];
        newDetaildFWIMGS.push(blockedOBJ);
        all_images_count = all_images_count + 1;
      }
    }

    setinteriorFaults({
      images: ximages, //array of all faults images for this part
      option_score: data.score,
      issue: data.issue,
      detailedFaultsWithImages: newDetaildFWIMGS, //object of fault data and images
    });
  };

  const handleTooltipOpen = (event) => {
    setOpenTooltip(true);
    setTooltipButtonId(event.currentTarget.id);
  };

  const infoSpecialCase = (fieldId) => {
    let specialCaseIds = [
      "59",
      "61",
      "264",
      "66",
      "68",
      "265",
      "73",
      "75",
      "266",
      "80",
      "82",
      "267",
      "906",
    ]; // 3 fields for each tire change to backend later
    return specialCaseIds.includes(fieldId);
  };

  const fieldTypes = {
    for_details_report: 1,
  };

  const cars = {};
  for (var i = 0; i <= carList.length - 1; i++) {
    cars[carList[i].id] = carList[i];
  }
  const [loadingCar, setLoadingCar] = useState(true);
  const score = ["100", "70", "65", "25", "45", "33", "51"];
  // const carInfo = carData;
  const [switchState, setSwitchState] = useState(false); //switchState will later be used to render faulty points only
  var pointCount = 0;
  var noComments = 1; //to render No comments! text incase of no comments
  const [noOfPoints, setNoOfPoints] = useState(0); // a state tha hold the counted number of points to cause a rerender
  // const [doneCounting, setDoneCounting] = useState(false);
  const [infoStyle, setInfoStyle] = useState("flex");
  const dateObject = new Date(date); //to convert the date text into an object to extract day month and year
  // let counterIsDone = false;
  let allFields = [];
  let allOptions = [];
  let optionsCount = 1;
  const tireAgeIds = ["59", "66", "73", "80"]; //Tire age ids
  // use this instead of [3, 3, 3] in skelStructure

  const skelStructure = [
    // defines how many tables and fields are in the skeleton
    // Iterator(5),
    [5, 5, 5, 5, 5],
    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
    [3, 3, 3],
    [4, 4, 4, 4],
    [6, 6, 6, 6, 6, 6, 7],
  ];
  const DetInspRepIcons = {
    //an object holding the group name as a key and the icon as the value
    6: Switches,
    7: Exterior,
    8: Equipment,
    9: Tires,
    10: Interior,
    15: Frame,
    19: Ev,
    16: Dashboard,
    17: BodyPaint,
    18: AcHeater,
    20: HybridBattery,
    21: HybridBattery,
  };
  const DetInspRepDiscription = {
    6: {
      text: t(
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Working") },
        { icon: FaultyAnswer, represents: t("Not Working") },
        { icon: Information, represents: t("Additional Information") },
      ],
    }, // Switches,
    7: {
      text: t(
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Excellent") },
        { icon: InformativeAnswer, represents: t("Minor Issue") },
        { icon: FaultyAnswer, represents: t("Major Issue") },
      ],
      note: t(
        "* We do not record any issue in the body that is less than 0.2 (cm)."
      ),
      secondNote: t(
        "* We do not take images of light scratches on the car's Exterior"
      ),
    }, // Exterior,
    19: {
      text: t(
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Good") },
        { icon: InformativeAnswer, represents: t("Moderate") },
        { icon: FaultyAnswer, represents: t("Poor") },
      ],
      note: t(
        "* We do not inspect whether the battery was replaced or repaired."
      ),
    }, //ev
    20: {
      text: t(
        "We test the battery under load by evaluating cell resistance & voltage values."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Excellent") },
        { icon: InformativeAnswer, represents: t("Moderate") },
        { icon: FaultyAnswer, represents: t("Replace") },
      ],
      note: t("* We do not check if the battery was replaced or repaired."),
    }, //hybrid battery nickle
    21: {
      text: t(
        "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("No Faults") },
        { icon: FaultyAnswer, represents: t("Faults") },
      ],
      note: t("* We do not check if the battery was replaced or repaired."),
    }, //hybrid battery lithium
    8: {
      text: t("We check the availability of standard equipment in the vehicle"),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Available") },
        { icon: FaultyAnswer, represents: t("Not Available") },
      ],
      note: t("* We do not confirm the genuineness of the equipment."),
    }, // Equipment,
    9: {
      text: t(
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Good") },
        { icon: InformativeAnswer, represents: t("Monitor") },
        { icon: FaultyAnswer, represents: t("Replace") },
        { icon: Information, represents: t("Additional Information") },
      ],
      note: t(
        "* We do not inspect the inside of the tire or the internal sidewall of the tire."
      ),
    }, // Tires,
    10: {
      text: t(
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Excellent") },
        { icon: InformativeAnswer, represents: t("Minor Issue") },
        { icon: FaultyAnswer, represents: t("Major Issue") },
      ],
      note: t("* We do not record any scratch that cannot be felt by hand."),
    }, // Interior,
    15: {
      text: t(
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Good/ Good+Issues") },
        { icon: FaultyAnswer, represents: t("Issues") },
      ],
      note: t(
        "* We do not validate the results of an inspection report from any third party."
      ),
    }, // Frame,
    16: {
      text: t(
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("No Faults") },
        { icon: FaultyAnswer, represents: t("Faults") },
      ],
      note: t(
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired)."
      ),
    }, // computer scan,
    17: {
      text: t(
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Origional Paint") },
        { icon: FaultyAnswer, represents: t("Repainted") },
      ],
      note: t("* In some cases, Re-painted panels cannot be detected."),
    }, // BodyPaint,
    18: {
      text: t(
        "We measure temperature of cooling/heating systems & check air throw for all AC vents."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Working") },
        { icon: FaultyAnswer, represents: t("Not Working") },
        { icon: NotIncluded, represents: t("Not Included") },
        { icon: Information, represents: t("Additional Information") },
      ],
    },
  };
  let query = ""; // along with fieldTypes querys the api for report data instead of details data
  for (let key in fieldTypes) {
    if (fieldTypes.hasOwnProperty(key)) {
      query = query + key + "=" + fieldTypes[key] + "&";
    }
  }

  const tireAgeAnswer = (answer) => {
    let splitAnswer = answer.split(".");
    return splitAnswer[0] + t(" yr. ") + splitAnswer[1] + t(" mos");
  };

  useEffect(() => {
    setNoOfPoints(pointCount); // to cause a rerender after counting points
    handleCallback(pointCount);
    // setDoneCounting(counterIsDone);
  });

  const changeFaults = (destructuredFields, key) => {
    setfaults(destructuredFields[key]);
  };

  useEffect(() => {
    if (
      openInspection ||
      openAnswer ||
      faultOpen ||
      frameFaultopen ||
      showfaults
    ) {
      document.getElementById("root").style.filter = "blur(16px)";
    }
  }, [openInspection, openAnswer, faultOpen, frameFaultopen, showfaults]);

  const handleSwitchChange = (event) => {
    // swich change function the value of switchState will later be used to render faulty points only
    setSwitchState(event.target.checked);
    ReactGa.event({
      category: "View Faults Only",
      action: "Clicked View Faults Only",
      value: event.target.checked ? 1 : 0,
      label: `${event.target.checked ? "faults only" : "all"}`,
    });
  };

  const checkGroupFilter = (List) => {
    if (switchState) {
      return List.filter((element) => {
        if (groupTemplate?.[element?.group_id] == "0 Faults") return false;
        return (
          element?.group_score?.fields?.group_score < 100 ||
          element?.group_score?.fields?.group_score === undefined
        );
      });
    } else {
      return List;
    }
  };

  const checkSubgroupFilter = (list, group_id) => {
    if (switchState) {
      return list.filter((element) => {
        if (group_id == 16 || group_id == 21) {
          var accomulatedExtendedAnswers = [];
          element.fields.map((el) => {
            accomulatedExtendedAnswers = [
              ...accomulatedExtendedAnswers,
              ...el.extended_answers,
            ];
          });
          if (accomulatedExtendedAnswers.length == 0) {
            return false;
          }
        }
        return (
          element?.score?.fields?.subgroup_score < 100 ||
          element?.score?.fields?.subgroup_score === undefined
        );
      });
    } else {
      return list;
    }
  };

  const checkFieldFilter = (list) => {
    if (switchState) {
      return list.filter((element) => {
        return (
          element?.field_score < 100 ||
          element?.field_score === undefined ||
          !!element?.extended_answers.length
        );
      });
    } else {
      return list;
    }
  };

  useEffect(() => {
    var tableIndecators = {};
    groups.groups.map((el) => {
      if (el.group_id == 15 || el.group_id == 16 || el.group_id == 21) {
        if (el.group_id == 15) {
          tableIndecators[el.group_id] =
            typeof el?.answers == "number"
              ? el.answers + " " + t("Good")
              : t("Not Available");
        }
        if (el.group_id == 16) {
          tableIndecators[el.group_id] =
            typeof el?.answers == "number"
              ? el.answers + " " + t("Faults")
              : t("Not Available");
        }
        if (el.group_id == 21) {
          tableIndecators[el.group_id] =
            typeof el?.answers == "number"
              ? el.answers + " " + t("Faults")
              : t("Not Available");
        }
        return;
      }
      if (el.group_id !== 15 && el.group_id !== 16 && el.group_id !== 21) {
        if (typeof Number(el?.group_score.fields?.group_score) == "number") {
          tableIndecators[el.group_id] =
            Math.floor(el?.group_score.fields?.group_score) + "%";
        } else {
          tableIndecators[el.group_id] = t("Not Available");
        }
        return;
        // tableIndecators[el.group_id] = t("N/A");
      }

      // if (
      //   !(
      //     group?.sub_groups
      //       ?.map((subgroup) => {
      //         return subgroup.fields.length;
      //       })
      //       ?.reduce((partialSum, a) => partialSum + a, 0) <= 1
      //   )
      // ) {
      //   tableIndecators[el.group_id] = t("Not Available");
      // }
      // ? t(
      //   "Not Available"
      // )

      //   group?.sub_groups
      //     ?.map((subgroup) => {
      //       return subgroup.fields.length;
      //     })
      //     ?.reduce((partialSum, a) => partialSum + a, 0) <= 1
      //     ? null
      //     : t("Faults");
    });
    setgroupTemplate(tableIndecators);
    // setCarDataTemplate()
  }, [groups]);

  useEffect(() => {
    // here the category of the size of the screen is set (isSmallDevice) and the path (imagePath) is set accordingly to the category
    const isTabletOrMobileWidth = window.matchMedia(
      "(max-device-width: 959px)"
    );
    const isTabletOrMobileHeight = window.matchMedia(
      "(max-device-width: 959px)"
    );
    if (isTabletOrMobileHeight.matches && isTabletOrMobileWidth.matches) {
      setIsSmallDevice(true);
      setVisualizationPath("/visualization");
    } else {
      setIsSmallDevice(false);
      setVisualizationPath("");
      if (history.location.pathname.includes("inspection-report")) {
        history.goBack();
      }
    }
  }, []);

  const handleImgClick = (event) => {
    // image's onClick function requests full screen and orientation lock if available in small screen and sets openDialog if big screen
    //not sure if necessary still doesnot fullscreen on safari and the rest did without extra vendor prefix
    //but condition is neccissary
    if (isSmallDevice) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen().catch((e) => console.log(e));
      } else if (document.body.mozRequestFullscreen) {
        document.body.mozRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen().catch((e) => console.log(e));
      }
      if (window.screen.orientation) {
        window.screen.orientation
          .lock("landscape-primary")
          .catch((e) => console.log(e));
      }
      hideAppBar(); // hides app bar in small screen visualization so that the speed dial button's transparent black div get positioned right
    } else {
      setOpenVisualization(true);
      document.getElementById("root").style.filter = "blur(16px)"; // adds blur to root element
    }
  };

  useEffect(() => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => console.log("Document Exited form Full screen mode"))
        .catch((err) => console.error(err));
      showAppBar(); // displays the app bar hidden upon entering fullscreen
    } else {
      showAppBar();
    }
  }, []);

  useEffect(() => {
    // to close big screen's dialog if anything but the image or the stepper was clicked (containing div) or if Esc was pressed
    const handleModalClose = (event) => {
      if (backgroundRef.current === event.target) {
        setOpenVisualization(false);
        document.getElementById("root").style.filter = "none"; // removes blur from root element
      }
    };
    document.addEventListener("mousedown", handleModalClose);
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setOpenVisualization(false);
        document.getElementById("root").style.filter = "none"; // removes blur from root element
      }
    });

    return (_) => {
      // clean up
      document.removeEventListener("mousedown", handleModalClose);
      window.removeEventListener("keyup", (e) => {
        if (e.key === "Escape") {
          setOpenVisualization(false);
          document.getElementById("root").style.filter = "none"; // removes blur from root element
        }
      });
    };
  }, [backgroundRef]);

  const renderDialog = () => {
    const handleCloseBigScreenVisualization = () => {
      setOpenVisualization(false);
      // document.body.style.overflow = "auto"
      document.getElementById("root").style.filter = "none"; // removes blur from root element
    };
    return (
      <>
        <Hidden smDown>
          <Tour
            imgLoaded={ref.current}
            setImgLoaded={setImgLoaded}
            fromBigScreen={true}
            close={handleCloseBigScreenVisualization}
            carId={carId}
            // openVisualization={openVisualization}
            openVisualization={isSmallDevice ? openVisualization : true}
            setRecallGroupsToggle={setRecallGroupsToggle}
            previouslyUnlocked={previouslyUnlocked}
          >
            <Visualisation
              setRecallGroupsToggle={setRecallGroupsToggle}
              previouslyUnlocked={previouslyUnlocked}
            />
          </Tour>
        </Hidden>
        {/* <Hidden mdUp>
          <Visualisation
            imgLoaded={imgLoaded}
            setImgLoaded={setImgLoaded}
            fromBigScreen={true}
            close={handleCloseBigScreenVisualization}
            carId={carId}
            // openVisualization={openVisualization}
            openVisualization={isSmallDevice ? openVisualization : true}
            setRecallGroupsToggle={setRecallGroupsToggle}
            previouslyUnlocked={previouslyUnlocked}
          />
        </Hidden> */}
      </>
    );
  };

  const handleAnswerClick = (image, title) => {
    setImageDialogProps({ image: image, title: title });
    setOpenAnswer(true);
  };

  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel, groupTitle) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    isExpanded &&
      logVisitorView({
        page_title: currentPath,
        start_time: startTime,
        session_one: currentId,
        session_two: currentGid,
        tag: groupTitle,
      });
  };
  const handleInspectionNotesDialog = (inspectionNotes) => {
    setInspectionNotes({ inspectionNotes });
    setOpenInspection(true);
  };
  const handleClose = () => {
    setOpenInspection(false);
    document.getElementById("root").style.filter = "none"; // removes blur from root element
  };

  const getComputerScanFaults = (obj) => {
    if (obj != undefined) {
      let count = 0;
      for (let i = 0; i < obj.length; i++) {
        let temp = obj[i].fields;
        for (let j = 0; j < Object.keys(temp).length; j++) {
          count += temp[j].extended_answers.length;
        }
      }
      return count;
    }
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center" style={{ paddingTop: 0 }}>
        <Grid item xs={12} className={classes.navigationDiv}>
          <Hidden mdUp>
            <>
              {!showfaults ||
                (frameFaultopen && (
                  <div
                    ref={navRef}
                    className={clsx({
                      [classes.navBarRelative]: 1,
                      [classes.navBarRelativeHidden]: hideNav,
                    })}
                  >
                    <Paper className={classes.fancyNavigationPaper} square>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            history.length <= 2
                              ? history.replace("/search-results/other=all")
                              : history.goBack();
                          }}
                        >
                          <BackArrowIcon
                            size="16.43"
                            fill="white"
                            style={
                              localStorage.getItem("language") === "ar"
                                ? { transform: "rotate(180deg)" }
                                : {}
                            }
                          />
                        </IconButton>
                        <div className={classes.featuredImageDiv}>
                          <img
                            // ref={featuredImageRef}
                            src={visualizationImage}
                            slt="featured_image"
                            className={classes.featuredImage}
                          />
                        </div>
                        <div>
                          <div style={{ display: "flex" }}>
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.year}`}{" "}
                            </Typography>
                            {"\u00A0"}
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.make}`}{" "}
                            </Typography>
                            {"\u00A0"}
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.model}`}{" "}
                            </Typography>
                            {"\u00A0"}
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.trim}`}{" "}
                            </Typography>
                          </div>
                          <Typography className={classes.cardPriceTypo}>
                            {t("english JD")}
                            {`${numberWithCommas(carInfo?.card?.price)}`}
                            {t("arabic JD")}
                          </Typography>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <HeartCircleIcon
                          size="43"
                          mobile={true}
                          selected={false}
                        />
                        <ShareEllipseIcon size="43" mobile={true} />
                      </div>
                    </Paper>
                  </div>
                ))}
            </>
          </Hidden>
          <Grid container className={classes.reportGrid}>
            <Grid item sm={12} className={classes.firstGridItem}>
              <div
                style={
                  unlocked
                    ? {}
                    : {
                        // pointerEvents: "none",
                        position: "relative",
                        marginTop: "24px",
                      }
                }
              >
                {!unlocked && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    style={{
                      position: "absolute",
                      top: 0,
                      textAlign: "center",
                      zIndex: "1",
                      width: "100vw",
                      lineHeight: 4,
                      left: "50%",
                      marginTop: "10px",
                      marginLeft: "-50vw",
                      background: "#ffffff77",
                      height: "100%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <img src={VisLock} />
                    </div>
                    <div>
                      <Typography style={{ fontWeight: 500 }}>
                        {t("Inspection Details are Locked.")}
                      </Typography>
                    </div>
                    <div>
                      <Typography>
                        {t(
                          "Check out a sample of our inspection report for different cars."
                        )}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ backgroundColor: "#fff" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push("/sample-reports");
                        }}
                      >
                        {t("View Report Samples")}
                      </Button>
                    </div>
                  </div>
                )}
                <div
                  style={
                    unlocked
                      ? {}
                      : {
                          pointerEvents: "none",
                          filter: "blur(8px)",
                          background:
                            "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 47.57%)",
                        }
                  }
                >
                  <Paper className={classes.reportPaper}>
                    <Paper className={classes.headerVisualizationPaper}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          ref={visualInspectionReport}
                          style={
                            localStorage.getItem("language") === "ar"
                              ? { fontWeight: "700" }
                              : { fontWeight: "500" }
                          }
                          className={classes.headerVisualization}
                          id="Showfullreport"
                        >
                          {t("Visual Inspection Report")}
                        </Typography>
                        <Hidden smDown>
                          <hr
                            style={{
                              height: "1px",
                              width: "100%",
                              border: "none",
                              background: "#D2D2D2",
                              margin: "auto",
                            }}
                          />
                        </Hidden>
                      </div>
                      <Hidden smDown>
                        <Typography className={classes.noteTypographyMedium}>
                          <Typography className={classes.noteTypographyBold}>
                            {t("Note")}:
                          </Typography>
                          {t(
                            "Click on the colored markers to see issue details."
                          )}
                        </Typography>
                      </Hidden>
                      <Hidden mdUp>
                        <Typography className={classes.noteTypographyMedium}>
                          <Typography className={classes.noteTypographyBold}>
                            {t("Note")}:
                          </Typography>
                          {t("Tap to view vehicle cosmetic issues.")}
                        </Typography>
                      </Hidden>
                      <Hidden mdUp>
                        <Link
                          to={{
                            pathname: visualisationPath,
                            carId: carId,
                            openVisualization: true,
                            setMobImageLoaded: () => setImgLoaded(true),
                            mobImageLoaded: imgLoaded,
                          }}
                        >
                          <div
                            className={classes.visualizationDiv}
                            onClick={handleImgClick}
                          >
                            <div
                              style={{
                                width: "100%", // 100% is 100vw -40px padding (360=>360-40=320)
                                height: isPortrait
                                  ? `calc(${
                                      // to match visualisation width + 20px (width in vis is height in div -20)
                                      ((window.screen.width - 40) /
                                        window.screen.height) *
                                      window.screen.width
                                    }px + 20px)`
                                  : isIOS()
                                  ? `${window.screen.width}px`
                                  : `${window.screen.height}px`, /////here
                                // "100vh",
                                borderRadius: "8.27px",
                                backgroundColor: "#07303E",
                              }}
                            >
                              <Visualisation
                                className={classes.visualizationView}
                                imgLoaded={imgLoaded}
                                setImgLoaded={setImgLoaded}
                                outside={true}
                                style={{
                                  // filter: "blur(2px)",
                                  height: isPortrait
                                    ? `${
                                        // actual width is height * scale due to scale and rotate
                                        (window.screen.height /
                                          window.screen.width) *
                                        100
                                      }vw`
                                    : isIOS()
                                    ? `${window.screen.width - 40}px`
                                    : `${window.screen.height - 40}px`,
                                  width: isPortrait
                                    ? "100vw"
                                    : `${
                                        100 /
                                        ((window.screen.width - 40) /
                                          window.screen.width)
                                      }%`, // actual height is width * scale due to scale and rotate
                                  transform: isPortrait
                                    ? localStorage.getItem("language") === "en"
                                      ? `translate(0%,
                                      ${
                                        ((window.screen.width - 35) /
                                          window.screen.height) *
                                          window.screen.width +
                                        10
                                      }px) rotate(-90deg) scale(${
                                          (window.screen.width - 30) /
                                          window.screen.height
                                        })`
                                      : `translate(0%,${
                                          ((window.screen.width - 35) /
                                            window.screen.height) *
                                            window.screen.width +
                                          10
                                        }px) rotate(90deg) scale(${
                                          (window.screen.width - 30) /
                                          window.screen.height
                                        })`
                                    : `translate(0,${20}px)`,
                                }}
                                fromBigScreen={false}
                                carId={carId}
                                openVisualization={true}
                                setRecallGroupsToggle={setRecallGroupsToggle}
                                previouslyUnlocked={previouslyUnlocked}
                              />
                              {!ref.current && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: isPortrait
                                      ? `calc(${
                                          ((window.screen.width - 40) /
                                            window.screen.height) *
                                          window.screen.width
                                        }px + 20px)`
                                      : isIOS()
                                      ? `${window.screen.width}px`
                                      : `${window.screen.height}px`,
                                    position: "absolute",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <Skeleton
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      transform: "scale(1,0.9)",
                                      backgroundColor: "rgba(7, 48, 62, 0.5)",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className={classes.visualizationImageDivStyle}>
                              <div className={classes.visMobIcon}>
                                <img
                                  src={MobVisClickEn}
                                  style={{ width: "73px" }}
                                />
                              </div>
                              <div className={classes.visMobIconText}>
                                <div>{t("Click to view issues")}</div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Hidden>
                      {renderDialog()}
                      <div>
                        <div style={{ display: "flex" }}>
                          <Typography
                            style={
                              localStorage.getItem("language") === "ar"
                                ? { fontWeight: "700" }
                                : { fontWeight: "500" }
                            }
                            className={classes.headerVisualization}
                          >
                            {t("Full Inspection Report")}
                          </Typography>
                          <Hidden smDown>
                            <hr
                              style={{
                                height: "1px",
                                width: "100%",
                                border: "none",
                                background: "#D2D2D2",
                                margin: "auto",
                              }}
                            />
                          </Hidden>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography className={classes.reportFilter}>
                            {t("View Faults Only")}
                          </Typography>
                          <IOSSwitch
                            checked={switchState}
                            onChange={handleSwitchChange}
                          />
                        </div>
                        {groups?.groups?.length != 0 ? (
                          // to not  crash before data is fetched from api
                          <div>
                            {checkGroupFilter(groups?.groups).map(
                              (group, i) => {
                                const subGroups = group?.sub_groups;
                                return group?.title !== "comments" ? ( // removes comment from groups expansion panel
                                  <ExpansionPanel
                                    style={{
                                      background: "#F4F5F7",
                                      margin: "-1px",
                                      border: "1px solid #B5B5B5",
                                    }}
                                    expanded={expanded === `group_${i}`}
                                    onChange={handleChange(
                                      `group_${i}`,
                                      group?.title_lang?.en
                                    )}
                                    key={`group_${i}`}
                                  >
                                    <ExpansionPanelSummary
                                      classes={{
                                        content:
                                          classes.expansionPanelSummaryContent,
                                        root: classes.expansionPanelSummaryRoot,
                                      }}
                                      expandIcon={
                                        <ExpandMoreIcon
                                          style={{ fill: "#000000" }}
                                        />
                                      }
                                    >
                                      <img
                                        src={
                                          group != undefined
                                            ? DetInspRepIcons[group["group_id"]]
                                            : null
                                        }
                                        className={
                                          group != undefined
                                            ? group["group_id"] === 7
                                              ? classes.exteriorGroupIcon
                                              : classes.groupIcon
                                            : null
                                        }
                                        alt="DetInspRepIcons"
                                      />
                                      <Typography
                                        ref={fullInspectionReport}
                                        style={
                                          localStorage.getItem("language") ===
                                          "ar"
                                            ? { fontWeight: "600" }
                                            : { fontWeight: "400" }
                                        }
                                        className={classes.groupTitle}
                                      >
                                        {localStorage.getItem("language") ===
                                        "ar"
                                          ? group?.title_lang?.ar
                                          : group?.title_lang?.en}
                                      </Typography>
                                      <Typography
                                        className={classes.groupScore}
                                        style={
                                          group?.group_id != 15 &&
                                          group?.group_id != 16 &&
                                          group?.group_id != 21 &&
                                          !group?.group_score?.fields // remove hard coded car 1216
                                            .not_available
                                            ? {
                                                color:
                                                  !group?.group_score || // or ev battery not available
                                                  (group?.group_id == 19 &&
                                                    group?.sub_groups?.[0]
                                                      ?.fields?.[0]?.field_id ==
                                                      902) ||
                                                  (group?.group_id == 20 &&
                                                    (group?.sub_groups?.[0]
                                                      ?.fields?.[0]
                                                      ?.selected_option ==
                                                      4261 ||
                                                      group?.sub_groups?.[0]?.fields?.filter(
                                                        (field) => {
                                                          return (
                                                            field.selected_option ==
                                                            4268
                                                          );
                                                        }
                                                      ).length))
                                                    ? "#929693"
                                                    : getScoreColor(
                                                        group?.group_score
                                                          ?.fields?.group_score
                                                      ),
                                              }
                                            : { color: "#8A8A8A" }
                                        }
                                      >
                                        {group?.group_score?.fields // remove hard coded car 1216
                                          .not_available
                                          ? t("Not Available")
                                          : group?.group_id != 15
                                          ? group?.group_id != 16 &&
                                            group?.group_id != 21
                                            ? !group?.group_score
                                              ? "N/A"
                                              : (group?.group_id == 19 &&
                                                  group?.sub_groups?.[0]
                                                    ?.fields?.[0]?.field_id ==
                                                    902) ||
                                                (group?.group_id == 20 &&
                                                  (group?.sub_groups?.[0]
                                                    ?.fields?.[0]
                                                    ?.selected_option == 4261 ||
                                                    group?.sub_groups?.[0]?.fields?.filter(
                                                      (field) => {
                                                        return (
                                                          field.selected_option ==
                                                          4268
                                                        );
                                                      }
                                                    ).length)) // hybrid battery not available
                                              ? t("Not Available")
                                              : Math.floor(
                                                  group?.group_score?.fields
                                                    ?.group_score
                                                ) + "%"
                                            : group?.sub_groups
                                                ?.map((subgroup) => {
                                                  return subgroup.fields.length;
                                                })
                                                ?.reduce(
                                                  (partialSum, a) =>
                                                    partialSum + a,
                                                  0
                                                ) <= 1
                                            ? t("Not Available")
                                            : group?.sub_groups
                                            ? getComputerScanFaults(
                                                group?.sub_groups
                                              ) +
                                              " " +
                                              t("Faults")
                                            : ""
                                          : `${
                                              group?.sub_groups
                                                ? group?.sub_groups[0]?.fields?.filter(
                                                    (el) =>
                                                      el.selected_option == 1285
                                                  ).length
                                                  ? t("Not Available")
                                                  : `${
                                                      group?.sub_groups[0]?.fields?.filter(
                                                        (el) =>
                                                          el.id !== 849 && // fix later probably bring with BE for detail report
                                                          el.field_score == 100
                                                      ).length
                                                    } ${t("Good")}`
                                                : ""
                                            }`}
                                      </Typography>
                                      {/* {groupTemplate?.[group?.group_id]} */}
                                      {/* ffffffffffffffffffffffffffff */}
                                      {/* </Typography> */}
                                    </ExpansionPanelSummary>
                                    <div className={classes.tableInfoDiv}>
                                      <Typography
                                        className={classes.groupDisclaimer}
                                      >
                                        {group != undefined &&
                                        DetInspRepDiscription[group["group_id"]]
                                          ? DetInspRepDiscription[
                                              group["group_id"]
                                            ].text
                                          : null}
                                      </Typography>
                                      <Grid
                                        container
                                        justify="space-between"
                                        className={
                                          classes.discriptionGridContainer
                                        }
                                      >
                                        {groups?.groups?.length != 0 &&
                                        DetInspRepDiscription[group["group_id"]]
                                          ? DetInspRepDiscription[
                                              group["group_id"]
                                            ]?.iconDisc.map((item) => (
                                              <Grid
                                                item
                                                style={{
                                                  display: "flex",
                                                  marginBlock: "14px",
                                                }}
                                              >
                                                <img
                                                  src={item.icon}
                                                  alt=""
                                                  className={
                                                    classes.discriptionIcon
                                                  }
                                                />
                                                <Typography
                                                  className={
                                                    classes.discriptionText
                                                  }
                                                >
                                                  {item.represents}
                                                </Typography>
                                              </Grid>
                                            ))
                                          : null}
                                      </Grid>
                                    </div>

                                    <div className={classes.column}>
                                      <ExpansionPanelDetails
                                        className={
                                          classes.expansionPanelDetails
                                        }
                                      >
                                        {subGroups !== undefined ? ( // to not crash before end of slow fields fetch
                                          checkSubgroupFilter(
                                            subGroups,
                                            group.group_id
                                          ).map((subGroup, index) => {
                                            const fields = subGroup.fields;
                                            let destructuredFields = {};
                                            {
                                              var i = 0;
                                              checkFieldFilter(fields).map(
                                                (option) => {
                                                  let x = fields?.filter(
                                                    (el) => {
                                                      return (
                                                        el.selected_option ===
                                                          option.selected_option &&
                                                        option.selected_option !==
                                                          null &&
                                                        option.selected_option !==
                                                          3263 && // original paint
                                                        option.selected_option !==
                                                          3264 && // repainted paint
                                                        option.selected_option !==
                                                          3265 // in applicable paint
                                                      );
                                                    }
                                                  );
                                                  destructuredFields =
                                                    Object.assign(
                                                      destructuredFields,
                                                      {
                                                        [option.field_id]: {
                                                          field_tag:
                                                            option?.field_tag
                                                              ?.title,
                                                          field_id:
                                                            option?.field_tag
                                                              ?.id,
                                                          field:
                                                            localStorage.getItem(
                                                              "language"
                                                            ) == "ar"
                                                              ? option
                                                                  ?.field_title_lang
                                                                  ?.ar
                                                              : option
                                                                  ?.field_title_lang
                                                                  ?.en,
                                                          field_tree_id:
                                                            option?.field_tree_id,
                                                          score:
                                                            option?.field_score,
                                                          img: {
                                                            [option?.field_title]:
                                                              [
                                                                ...(destructuredFields[
                                                                  option
                                                                    ?.field_id
                                                                ]?.img[
                                                                  option
                                                                    ?.field_title
                                                                ] ?? []),
                                                                ...(option?.images ??
                                                                  []),
                                                              ],
                                                          },
                                                          fieldType:
                                                            option.field_type,
                                                          detailedFaultsWithImages:
                                                            [
                                                              ...(destructuredFields[
                                                                option?.field_id
                                                              ]
                                                                ?.detailedFaultsWithImages ??
                                                                []),
                                                              {
                                                                [option?.field_tree_id]:
                                                                  option?.with_image ==
                                                                  false
                                                                    ? localStorage.getItem(
                                                                        "language"
                                                                      ) == "en"
                                                                      ? [
                                                                          [
                                                                            "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                                                          ],
                                                                          option?.field_score,
                                                                          option
                                                                            ?.option_title_lang
                                                                            ?.en,
                                                                        ]
                                                                      : [
                                                                          [
                                                                            "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                                                          ],
                                                                          option?.field_score,
                                                                          option
                                                                            ?.option_title_lang
                                                                            ?.ar,
                                                                        ]
                                                                    : [
                                                                        option?.images,
                                                                        option?.field_score,
                                                                        localStorage.getItem(
                                                                          "language"
                                                                        ) ==
                                                                        "en"
                                                                          ? option
                                                                              ?.option_title_lang
                                                                              ?.en
                                                                          : option
                                                                              ?.option_title_lang
                                                                              ?.ar,
                                                                      ],
                                                              },
                                                            ],
                                                          images: [
                                                            ...(destructuredFields[
                                                              option?.field_id
                                                            ]?.images ?? []),
                                                            {
                                                              [option?.field_title]:
                                                                option?.with_image ==
                                                                false
                                                                  ? localStorage.getItem(
                                                                      "language"
                                                                    ) == "en"
                                                                    ? [
                                                                        [
                                                                          "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                                                        ],
                                                                        option?.field_score,
                                                                      ]
                                                                    : [
                                                                        [
                                                                          "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                                                        ],
                                                                        option?.field_score,
                                                                      ]
                                                                  : [
                                                                      option?.images,
                                                                      option?.field_score,
                                                                    ],
                                                            },
                                                          ],
                                                          answers:
                                                            Object.assign(
                                                              destructuredFields[
                                                                option.field_id
                                                              ]
                                                                ? destructuredFields[
                                                                    option
                                                                      ?.field_id
                                                                  ].answers
                                                                : {},
                                                              {
                                                                [localStorage.getItem(
                                                                  "language"
                                                                ) == "en"
                                                                  ? option
                                                                      ?.option_title_lang
                                                                      ?.en ||
                                                                    option.text
                                                                  : option
                                                                      ?.option_title_lang
                                                                      ?.ar ||
                                                                    option.text]:
                                                                  x.length,
                                                              }
                                                            ),
                                                          clickableAnswers: {
                                                            title:
                                                              option?.field_tag
                                                                ?.id === 24
                                                                ? option.field_id ===
                                                                  335
                                                                  ? t(
                                                                      "Report Image"
                                                                    )
                                                                  : option.text
                                                                : null,
                                                            image:
                                                              option?.images,
                                                          },
                                                          extended_answers:
                                                            option?.extended_answers,
                                                        },
                                                      }
                                                    );
                                                }
                                              );
                                            }
                                            pointCount =
                                              pointCount +
                                              Object.keys(destructuredFields)
                                                .length;
                                            return (
                                              <div>
                                                <Hidden mdUp>
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      marginBottom: "50px",
                                                      width:
                                                        "calc(100vw - 32px)",
                                                    }}
                                                  >
                                                    {group.group_id == 17 ? (
                                                      <BodyPaintResult
                                                        fields={
                                                          group?.sub_groups[0]
                                                            .fields
                                                        }
                                                      ></BodyPaintResult>
                                                    ) : null}
                                                  </div>
                                                </Hidden>

                                                <div
                                                  className={clsx({
                                                    [classes.tableDiv]: 1,
                                                    [classes.tableDivPaintWeb]:
                                                      group?.group_id == 17,
                                                  })}
                                                  ref={
                                                    group?.group_id == 17
                                                      ? ref
                                                      : null
                                                  }
                                                >
                                                  <Paper
                                                    className={classes.table}
                                                  >
                                                    <header
                                                      style={{
                                                        display: "table-row",
                                                      }}
                                                      className={
                                                        classes.tablehead
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "table-cell",
                                                        }}
                                                      >
                                                        <Typography
                                                          className={
                                                            classes.subGroupText
                                                          }
                                                        >
                                                          {/* here */}
                                                          {localStorage.getItem(
                                                            "language"
                                                          ) == "ar"
                                                            ? subGroup.title_ar
                                                            : subGroup.title_en}
                                                        </Typography>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "table-cell",
                                                        }}
                                                      >
                                                        <Typography
                                                          className={
                                                            classes.subGroupScoreText
                                                          }
                                                          style={
                                                            group?.sub_groups[ // remove hard coded car 1216
                                                              index
                                                            ]?.score?.fields
                                                              ?.not_available
                                                              ? {
                                                                  color:
                                                                    "#8A8A8A",
                                                                }
                                                              : group?.group_id ==
                                                                  20 &&
                                                                (group
                                                                  ?.sub_groups?.[0]
                                                                  ?.fields?.[0]
                                                                  ?.selected_option ==
                                                                  4261 ||
                                                                  group?.sub_groups?.[0]?.fields?.filter(
                                                                    (field) => {
                                                                      return (
                                                                        field.selected_option ==
                                                                        4268
                                                                      );
                                                                    }
                                                                  ).length)
                                                              ? {
                                                                  color:
                                                                    "#929693",
                                                                }
                                                              : group?.group_id !=
                                                                  15 &&
                                                                group?.group_id !=
                                                                  16 &&
                                                                group?.group_id !=
                                                                  21 &&
                                                                !(
                                                                  group?.group_id ==
                                                                    19 &&
                                                                  group
                                                                    ?.sub_groups?.[0]
                                                                    ?.fields?.[0]
                                                                    ?.field_id ==
                                                                    902
                                                                )
                                                              ? //here
                                                                {
                                                                  color:
                                                                    !subGroup.score
                                                                      ? "#929693"
                                                                      : getScoreColor(
                                                                          subGroup
                                                                            ?.score
                                                                            ?.fields
                                                                            ?.subgroup_score
                                                                        ),
                                                                }
                                                              : {
                                                                  color:
                                                                    "#8A8A8A",
                                                                }
                                                          }
                                                        >
                                                          {group?.sub_groups[ // remove hard coded car 1216
                                                            index
                                                          ]?.score?.fields
                                                            ?.not_available
                                                            ? t("Not Available")
                                                            : group?.group_id ==
                                                                20 &&
                                                              (group
                                                                ?.sub_groups?.[0]
                                                                ?.fields?.[0]
                                                                ?.selected_option ==
                                                                4261 ||
                                                                group?.sub_groups?.[0]?.fields?.filter(
                                                                  (field) => {
                                                                    return (
                                                                      field?.selected_option ==
                                                                      4268
                                                                    );
                                                                  }
                                                                ).length)
                                                            ? t("Not Available")
                                                            : group?.group_id !=
                                                              15
                                                            ? group?.group_id !=
                                                                16 &&
                                                              group?.group_id !=
                                                                21
                                                              ? !subGroup.score
                                                                ? "N/A"
                                                                : group?.group_id ==
                                                                    19 &&
                                                                  group
                                                                    ?.sub_groups?.[0]
                                                                    ?.fields?.[0]
                                                                    ?.field_id ==
                                                                    902
                                                                ? t(
                                                                    "Not Available"
                                                                  )
                                                                : Math.floor(
                                                                    subGroup
                                                                      .score
                                                                      ?.fields
                                                                      ?.subgroup_score
                                                                  )
                                                              : group?.sub_groups
                                                                  ?.map(
                                                                    (
                                                                      subgroup
                                                                    ) => {
                                                                      return subgroup
                                                                        .fields
                                                                        .length;
                                                                    }
                                                                  )
                                                                  ?.reduce(
                                                                    (
                                                                      partialSum,
                                                                      a
                                                                    ) =>
                                                                      partialSum +
                                                                      a,
                                                                    0
                                                                  ) <= 1
                                                              ? t(
                                                                  "Not Available"
                                                                )
                                                              : faultscnt(
                                                                  subGroup
                                                                ) -
                                                                getHistoryFaults(
                                                                  subGroup?.fields
                                                                )
                                                            : ""}{" "}
                                                          {group?.sub_groups[ // remove hard coded car 1216
                                                            index
                                                          ]?.score?.fields
                                                            ?.not_available
                                                            ? ""
                                                            : group?.group_id ==
                                                                20 &&
                                                              (group
                                                                ?.sub_groups?.[0]
                                                                ?.fields?.[0]
                                                                ?.selected_option ==
                                                                4261 ||
                                                                group?.sub_groups?.[0]?.fields?.filter(
                                                                  (field) => {
                                                                    return (
                                                                      field.selected_option ==
                                                                      4268
                                                                    );
                                                                  }
                                                                ).length)
                                                            ? null
                                                            : group?.group_id ==
                                                              20
                                                            ? "%"
                                                            : group?.group_id !=
                                                              15
                                                            ? group?.group_id !=
                                                                16 &&
                                                              group?.group_id !=
                                                                21
                                                              ? group?.group_id ==
                                                                  19 &&
                                                                group
                                                                  ?.sub_groups?.[0]
                                                                  ?.fields?.[0]
                                                                  ?.field_id ==
                                                                  902
                                                                ? null
                                                                : "%"
                                                              : group?.sub_groups
                                                                  ?.map(
                                                                    (
                                                                      subgroup
                                                                    ) => {
                                                                      return subgroup
                                                                        .fields
                                                                        .length;
                                                                    }
                                                                  )
                                                                  ?.reduce(
                                                                    (
                                                                      partialSum,
                                                                      a
                                                                    ) =>
                                                                      partialSum +
                                                                      a,
                                                                    0
                                                                  ) <= 1
                                                              ? null
                                                              : t("Faults")
                                                            : ""}
                                                        </Typography>
                                                      </div>
                                                    </header>
                                                    {Object.keys(
                                                      destructuredFields
                                                    )
                                                      .sort((a, b) => {
                                                        return (
                                                          destructuredFields[a]
                                                            .field_tree_id -
                                                          destructuredFields[b]
                                                            .field_tree_id
                                                        );
                                                      })
                                                      .map(
                                                        (key, fieldIndex) => {
                                                          return key == 950 &&
                                                            Object.keys(
                                                              destructuredFields[
                                                                key
                                                              ].answers
                                                            )[0] ==
                                                              "null" ? null : (
                                                            <div
                                                              style={{
                                                                display:
                                                                  "table-row-group",
                                                              }}
                                                            >
                                                              {fieldIndex ===
                                                              0 ? (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "table-row",
                                                                  }}
                                                                >
                                                                  <td
                                                                    colSpan="2"
                                                                    style={{
                                                                      padding: 0,
                                                                      backgroundColor:
                                                                        "#fff",
                                                                    }}
                                                                  >
                                                                    <hr
                                                                      style={{
                                                                        height:
                                                                          "2px",
                                                                        border:
                                                                          "none",
                                                                        margin:
                                                                          "6px 0",
                                                                      }}
                                                                    />
                                                                  </td>
                                                                </div>
                                                              ) : (
                                                                <Hidden smDown>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "table-row",
                                                                      height:
                                                                        "8px",
                                                                    }}
                                                                  >
                                                                    <td
                                                                      colSpan="2"
                                                                      style={{
                                                                        padding: 0,
                                                                        backgroundColor:
                                                                          "#fff",
                                                                      }}
                                                                    ></td>
                                                                  </div>
                                                                </Hidden>
                                                              )}
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "table-row",
                                                                  backgroundColor:
                                                                    "#fff",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "table-cell",
                                                                  }}
                                                                  className={
                                                                    classes.firstitem
                                                                  }
                                                                >
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                    }}
                                                                  >
                                                                    <Typography
                                                                      style={
                                                                        localStorage.getItem(
                                                                          "language"
                                                                        ) ===
                                                                        "ar"
                                                                          ? {
                                                                              fontWeight:
                                                                                "600",
                                                                            }
                                                                          : {
                                                                              fontWeight:
                                                                                "500",
                                                                            }
                                                                      }
                                                                      className={
                                                                        classes.firstItemTypography
                                                                      }
                                                                    >
                                                                      {
                                                                        destructuredFields[
                                                                          key
                                                                        ].field
                                                                      }
                                                                    </Typography>
                                                                    <Hidden
                                                                      smDown
                                                                    >
                                                                      {infoSpecialCase(
                                                                        key
                                                                      ) && (
                                                                        <Tooltip
                                                                          enterDelay={
                                                                            500
                                                                          }
                                                                          leaveDelay={
                                                                            3000
                                                                          }
                                                                          title={
                                                                            group?.group_id !==
                                                                            19 ? (
                                                                              t(
                                                                                "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                                              )
                                                                            ) : (
                                                                              <div>
                                                                                <Typography>
                                                                                  {t(
                                                                                    "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery."
                                                                                  )}
                                                                                </Typography>
                                                                                <div
                                                                                  style={{
                                                                                    display:
                                                                                      "block",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        UnFaultyAnswer
                                                                                      }
                                                                                      alt="infoIcon"
                                                                                      className={
                                                                                        classes.infoIcon
                                                                                      }
                                                                                    />
                                                                                    <Typography>
                                                                                      {t(
                                                                                        "Good"
                                                                                      )}
                                                                                      {
                                                                                        " -(SOH >= 78%)"
                                                                                      }
                                                                                    </Typography>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        InformativeAnswer
                                                                                      }
                                                                                      alt="infoIcon"
                                                                                      className={
                                                                                        classes.infoIcon
                                                                                      }
                                                                                    />
                                                                                    <Typography>
                                                                                      {t(
                                                                                        "Moderate"
                                                                                      )}
                                                                                      {
                                                                                        "-(50% <= SOH < 78%)"
                                                                                      }
                                                                                    </Typography>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        FaultyAnswer
                                                                                      }
                                                                                      alt="infoIcon"
                                                                                      className={
                                                                                        classes.infoIcon
                                                                                      }
                                                                                    />
                                                                                    <Typography>
                                                                                      {t(
                                                                                        "Poor"
                                                                                      )}
                                                                                      {
                                                                                        "-(SOH < 50%)"
                                                                                      }
                                                                                    </Typography>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            )
                                                                          }
                                                                          arrow
                                                                          placement="top-start"
                                                                          classes={{
                                                                            tooltip:
                                                                              classes.tooltip,
                                                                            arrow:
                                                                              classes.tooltipArrow,
                                                                          }}
                                                                        >
                                                                          <img
                                                                            src={
                                                                              Information
                                                                            }
                                                                            alt="infoIcon"
                                                                            className={
                                                                              classes.infoIcon
                                                                            }
                                                                          />
                                                                        </Tooltip>
                                                                      )}
                                                                    </Hidden>
                                                                    <Hidden
                                                                      mdUp
                                                                    >
                                                                      {infoSpecialCase(
                                                                        key
                                                                      ) && (
                                                                        <Tooltip
                                                                          enterDelay={
                                                                            500
                                                                          }
                                                                          leaveDelay={
                                                                            3000
                                                                          }
                                                                          title={
                                                                            group?.group_id !==
                                                                            19 ? (
                                                                              t(
                                                                                "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                                              )
                                                                            ) : (
                                                                              <div>
                                                                                <Typography>
                                                                                  {t(
                                                                                    "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery."
                                                                                  )}
                                                                                </Typography>
                                                                                <div
                                                                                  style={{
                                                                                    display:
                                                                                      "block",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        UnFaultyAnswer
                                                                                      }
                                                                                      alt="infoIcon"
                                                                                      className={
                                                                                        classes.infoIcon
                                                                                      }
                                                                                    />
                                                                                    <Typography>
                                                                                      {t(
                                                                                        "Good"
                                                                                      )}
                                                                                      {
                                                                                        " -(SOH >= 78%)"
                                                                                      }
                                                                                    </Typography>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        InformativeAnswer
                                                                                      }
                                                                                      alt="infoIcon"
                                                                                      className={
                                                                                        classes.infoIcon
                                                                                      }
                                                                                    />
                                                                                    <Typography>
                                                                                      {t(
                                                                                        "Moderate"
                                                                                      )}
                                                                                      {
                                                                                        "-(50% <= SOH < 78%)"
                                                                                      }
                                                                                    </Typography>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        FaultyAnswer
                                                                                      }
                                                                                      alt="infoIcon"
                                                                                      className={
                                                                                        classes.infoIcon
                                                                                      }
                                                                                    />
                                                                                    <Typography>
                                                                                      {t(
                                                                                        "Poor"
                                                                                      )}
                                                                                      {
                                                                                        "-(SOH < 50%)"
                                                                                      }
                                                                                    </Typography>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            )
                                                                          }
                                                                          arrow
                                                                          placement="top-start"
                                                                          open={
                                                                            openTooltip &&
                                                                            tooltipButtonId ===
                                                                              key
                                                                          }
                                                                          onClose={
                                                                            handleTooltipClose
                                                                          }
                                                                          classes={{
                                                                            tooltip:
                                                                              classes.tooltip,
                                                                            arrow:
                                                                              classes.tooltipArrow,
                                                                          }}
                                                                          enterTouchDelay={
                                                                            0
                                                                          }
                                                                          leaveTouchDelay={
                                                                            6000
                                                                          }
                                                                        >
                                                                          <Button
                                                                            id={
                                                                              key
                                                                            }
                                                                            onClick={
                                                                              handleTooltipOpen
                                                                            }
                                                                            style={{
                                                                              padding:
                                                                                "0",
                                                                              minWidth:
                                                                                "0px",
                                                                            }}
                                                                          >
                                                                            <img
                                                                              src={
                                                                                Information
                                                                              }
                                                                              alt="infoIcon"
                                                                              className={
                                                                                classes.infoIcon
                                                                              }
                                                                            />
                                                                          </Button>
                                                                        </Tooltip>
                                                                      )}
                                                                    </Hidden>
                                                                  </div>
                                                                </div>
                                                                {Object.keys(
                                                                  destructuredFields[
                                                                    key
                                                                  ].answers
                                                                ).map(
                                                                  (
                                                                    answer,
                                                                    index
                                                                  ) => {
                                                                    if (
                                                                      index == 0
                                                                    ) {
                                                                      // just a workaround to prevent the display of multiple answers fix later (interior, exterior tables new design)
                                                                      return (
                                                                        <div>
                                                                          <div
                                                                            className={
                                                                              classes.seconditem
                                                                            }
                                                                          >
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                              }}
                                                                            >
                                                                              {/* // from here // */}
                                                                              {destructuredFields[
                                                                                key
                                                                              ]
                                                                                .clickableAnswers
                                                                                .title ===
                                                                                null &&
                                                                                (group?.group_id ==
                                                                                  15 &&
                                                                                destructuredFields[
                                                                                  key
                                                                                ]
                                                                                  .field_id ==
                                                                                  25 ? (
                                                                                  //
                                                                                  <Typography
                                                                                    className={
                                                                                      classes.answerText
                                                                                    }
                                                                                    onClick={() => {
                                                                                      setframeFaultopen(
                                                                                        true
                                                                                      );
                                                                                      setframeFault(
                                                                                        {
                                                                                          title:
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .field,
                                                                                          data: {
                                                                                            issues:
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ].extended_answers
                                                                                                .filter(
                                                                                                  (
                                                                                                    el
                                                                                                  ) =>
                                                                                                    el.score !==
                                                                                                    100
                                                                                                )
                                                                                                .map(
                                                                                                  (
                                                                                                    el
                                                                                                  ) =>
                                                                                                    el.text
                                                                                                ),
                                                                                          },
                                                                                        }
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    {destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .score !==
                                                                                      0 &&
                                                                                      `${t(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ].extended_answers.filter(
                                                                                          (
                                                                                            el
                                                                                          ) =>
                                                                                            el.score ==
                                                                                            100
                                                                                        )[0]
                                                                                          ?.english_text
                                                                                      )}`}
                                                                                    {destructuredFields[
                                                                                      key
                                                                                    ].extended_answers.filter(
                                                                                      (
                                                                                        el
                                                                                      ) =>
                                                                                        el.score !=
                                                                                        100.0
                                                                                    )
                                                                                      .length !==
                                                                                      0 &&
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        .score !=
                                                                                        0 && (
                                                                                        <span>
                                                                                          {
                                                                                            " + "
                                                                                          }
                                                                                        </span>
                                                                                      )}
                                                                                    {destructuredFields[
                                                                                      key
                                                                                    ].extended_answers.filter(
                                                                                      (
                                                                                        el
                                                                                      ) =>
                                                                                        el.score !=
                                                                                        100.0
                                                                                    )
                                                                                      .length !=
                                                                                      0 && (
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "#00BDFF",
                                                                                          textDecoration:
                                                                                            "underline",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      >
                                                                                        {t(
                                                                                          "Issues"
                                                                                        )}{" "}
                                                                                        |{" "}
                                                                                        {
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ].extended_answers.filter(
                                                                                            (
                                                                                              el
                                                                                            ) =>
                                                                                              el.score !=
                                                                                              100.0
                                                                                          )
                                                                                            .length
                                                                                        }
                                                                                      </span>
                                                                                    )}
                                                                                  </Typography>
                                                                                ) : (
                                                                                  <Typography
                                                                                    className={
                                                                                      classes.answerText
                                                                                    }
                                                                                    onClick={() => {
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.field_tag ==
                                                                                        "Equipment" &&
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      )[0] !=
                                                                                        "Not Present" &&
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      )[0] !=
                                                                                        "No Keys" &&
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      )[0] !=
                                                                                        "لا مفاتيح" &&
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      )[0] !=
                                                                                        "لا يوجد"
                                                                                        ? handleAnswerClick(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .clickableAnswers
                                                                                              .image,
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .field
                                                                                          )
                                                                                        : key ==
                                                                                            945 ||
                                                                                          key ==
                                                                                            990
                                                                                        ? handleInspectionNotesDialog(
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            )
                                                                                          )
                                                                                        : key ==
                                                                                            951 &&
                                                                                          Object.keys(
                                                                                            destructuredFields[951]
                                                                                              .answers
                                                                                          ) !=
                                                                                            "No Notes" &&
                                                                                          Object.keys(
                                                                                            destructuredFields[951]
                                                                                              .answers
                                                                                          ) !=
                                                                                            "لا ملاحظات"
                                                                                        ? handleInspectionNotesDialog(
                                                                                            Object.keys(
                                                                                              destructuredFields[951]
                                                                                                .answers
                                                                                            )
                                                                                          )
                                                                                        : key ==
                                                                                            971 &&
                                                                                          Object.keys(
                                                                                            destructuredFields[971]
                                                                                              .answers
                                                                                          ) !=
                                                                                            "No Notes" &&
                                                                                          Object.keys(
                                                                                            destructuredFields[971]
                                                                                              .answers
                                                                                          ) !=
                                                                                            "لا ملاحظات"
                                                                                        ? handleInspectionNotesDialog(
                                                                                            Object.keys(
                                                                                              destructuredFields[971]
                                                                                                .answers
                                                                                            )
                                                                                          )
                                                                                        : // (group?.group_id ==
                                                                                          //     7 ||
                                                                                          //     group?.group_id ==
                                                                                          //       10)
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .fieldType ==
                                                                                            "ddma" &&
                                                                                          group?.group_id <
                                                                                            16 &&
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .score !=
                                                                                            100 &&
                                                                                          showInteriorFaults(
                                                                                            {
                                                                                              images:
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .images,
                                                                                              score:
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .score,
                                                                                              issue:
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .option_title,
                                                                                              //////////////////
                                                                                              detailedFaultsWithImages:
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .detailedFaultsWithImages,
                                                                                              //////////////////
                                                                                            }
                                                                                          );
                                                                                      (group?.group_id ==
                                                                                        16 ||
                                                                                        group?.group_id ==
                                                                                          21) &&
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          ?.extended_answers
                                                                                          ?.length >
                                                                                          0 &&
                                                                                        setshowfaults(
                                                                                          !showfaults
                                                                                        );
                                                                                      changeFaults(
                                                                                        destructuredFields,
                                                                                        key
                                                                                      );
                                                                                    }}
                                                                                    style={
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      ) ==
                                                                                        "No Notes" ||
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      ) ==
                                                                                        "لا ملاحظات"
                                                                                        ? {
                                                                                            color:
                                                                                              "#636363",
                                                                                          }
                                                                                        : destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            ?.field_tag ==
                                                                                            "Equipment" &&
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .answers
                                                                                          )[0] !=
                                                                                            "Not Present" &&
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .answers
                                                                                          )[0] !=
                                                                                            "No Keys" &&
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .answers
                                                                                          )[0] !=
                                                                                            "لا مفاتيح" &&
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .answers
                                                                                          )[0] !=
                                                                                            "لا يوجد"
                                                                                        ? {
                                                                                            color:
                                                                                              "#00bdff",
                                                                                            textDecoration:
                                                                                              "underline",
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          }
                                                                                        : key ==
                                                                                            945 ||
                                                                                          (key ==
                                                                                            951 &&
                                                                                            Object.keys(
                                                                                              destructuredFields[951]
                                                                                                .answers
                                                                                            ) !=
                                                                                              "No Notes" &&
                                                                                            Object.keys(
                                                                                              destructuredFields[951]
                                                                                                .answers
                                                                                            ) !=
                                                                                              "لا ملاحظات") ||
                                                                                          (key ==
                                                                                            971 &&
                                                                                            Object.keys(
                                                                                              destructuredFields[971]
                                                                                                ?.answers
                                                                                            ) !=
                                                                                              "No Notes" &&
                                                                                            Object.keys(
                                                                                              destructuredFields[971]
                                                                                                .answers
                                                                                            ) !=
                                                                                              "لا ملاحظات")
                                                                                        ? {
                                                                                            color:
                                                                                              "#00BDFF",
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          }
                                                                                        : destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .fieldType ==
                                                                                          "ddma"
                                                                                        ? // group?.group_id ==
                                                                                          // 7 ||
                                                                                          // group?.group_id ==
                                                                                          // 10
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .score !=
                                                                                          100
                                                                                          ? {
                                                                                              color:
                                                                                                "#00BDFF",
                                                                                              textDecorationLine:
                                                                                                "underline",
                                                                                              cursor:
                                                                                                "pointer",
                                                                                            }
                                                                                          : {
                                                                                              color:
                                                                                                "#636363",
                                                                                            }
                                                                                        : destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            ?.extended_answers
                                                                                            ?.length >
                                                                                          0
                                                                                        ? {
                                                                                            color:
                                                                                              "#00BDFF",
                                                                                            textDecoration:
                                                                                              "underline",
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          }
                                                                                        : {
                                                                                            paddingRight:
                                                                                              localStorage.getItem(
                                                                                                "language"
                                                                                              ) ===
                                                                                              "en"
                                                                                                ? "8px"
                                                                                                : "0",
                                                                                            paddingLeft:
                                                                                              localStorage.getItem(
                                                                                                "language"
                                                                                              ) ===
                                                                                              "en"
                                                                                                ? "0"
                                                                                                : "8px",
                                                                                            fontSize:
                                                                                              window
                                                                                                .screen
                                                                                                .width >=
                                                                                              960
                                                                                                ? "14px"
                                                                                                : "12px",
                                                                                            fontWeight:
                                                                                              "400",
                                                                                            color:
                                                                                              "#636363",
                                                                                          }
                                                                                    }
                                                                                  >
                                                                                    {/* {here} */}
                                                                                    {key ==
                                                                                      945 ||
                                                                                    key ==
                                                                                      990 ? (
                                                                                      t(
                                                                                        "Notes"
                                                                                      ) +
                                                                                      " | " +
                                                                                      Object.keys(
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers
                                                                                      )
                                                                                        .length
                                                                                    ) : key ==
                                                                                      951 ? (
                                                                                      Object.keys(
                                                                                        destructuredFields[951]
                                                                                          .answers
                                                                                      ) ==
                                                                                        "No Notes" ||
                                                                                      Object.keys(
                                                                                        destructuredFields[951]
                                                                                          .answers
                                                                                      ) ==
                                                                                        "لا ملاحظات" ? (
                                                                                        t(
                                                                                          "No Notes"
                                                                                        )
                                                                                      ) : (
                                                                                        t(
                                                                                          "Notes"
                                                                                        ) +
                                                                                        " | " +
                                                                                        Object.keys(
                                                                                          destructuredFields[951]
                                                                                            .answers
                                                                                        )
                                                                                          .length
                                                                                      )
                                                                                    ) : key ==
                                                                                      971 ? (
                                                                                      Object.keys(
                                                                                        destructuredFields[971]
                                                                                          .answers
                                                                                      ) ==
                                                                                        "No Notes" ||
                                                                                      Object.keys(
                                                                                        destructuredFields[971]
                                                                                          .answers
                                                                                      ) ==
                                                                                        "لا ملاحظات" ? (
                                                                                        t(
                                                                                          "No Notes"
                                                                                        )
                                                                                      ) : (
                                                                                        t(
                                                                                          "Notes"
                                                                                        ) +
                                                                                        " | " +
                                                                                        Object.keys(
                                                                                          destructuredFields[971]
                                                                                            .answers
                                                                                        )
                                                                                          .length
                                                                                      )
                                                                                    ) : //  group?.group_id ==
                                                                                    // 7 ||
                                                                                    // group?.group_id ==
                                                                                    // 10
                                                                                    destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        .fieldType ==
                                                                                      "ddma" ? (
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        .score ==
                                                                                      100 ? (
                                                                                        t(
                                                                                          "Excellent"
                                                                                        )
                                                                                      ) : (
                                                                                        t(
                                                                                          "Issues"
                                                                                        ) +
                                                                                        " | " +
                                                                                        Object.values(
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .answers
                                                                                        ).reduce(
                                                                                          (
                                                                                            a,
                                                                                            b
                                                                                          ) =>
                                                                                            a +
                                                                                            b
                                                                                        )
                                                                                      )
                                                                                    ) : group?.group_id ==
                                                                                        9 &&
                                                                                      mmRegex.test(
                                                                                        answer
                                                                                      ) &&
                                                                                      localStorage.getItem(
                                                                                        "language"
                                                                                      ) ==
                                                                                        "ar" ? (
                                                                                      <span>
                                                                                        {answer.match(
                                                                                          /\d+/
                                                                                        )}{" "}
                                                                                        ملم
                                                                                      </span>
                                                                                    ) : group?.group_id !=
                                                                                        16 &&
                                                                                      group?.group_id !=
                                                                                        21 ? (
                                                                                      <span>
                                                                                        {destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .answers[
                                                                                          answer
                                                                                        ] >
                                                                                        1
                                                                                          ? `${destructuredFields[key].answers[answer]}X `
                                                                                          : ""}
                                                                                        {tireAgeIds.includes(
                                                                                          // to change tireage text format
                                                                                          key
                                                                                        )
                                                                                          ? tireAgeAnswer(
                                                                                              // turns yr.mo to "yr yrs. mo mos"
                                                                                              answer
                                                                                            )
                                                                                          : answer}
                                                                                      </span>
                                                                                    ) : (
                                                                                      <>
                                                                                        {destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .extended_answers
                                                                                          .length ==
                                                                                        0
                                                                                          ? key ==
                                                                                              950 ||
                                                                                            key ==
                                                                                              985
                                                                                            ? Object.keys(
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .answers
                                                                                              )[0]
                                                                                            : t(
                                                                                                "No Faults"
                                                                                              )
                                                                                          : t(
                                                                                              "Faults | "
                                                                                            ) +
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .extended_answers
                                                                                              .length}
                                                                                      </>
                                                                                    )}
                                                                                  </Typography>
                                                                                ))}
                                                                              {/* // to here // */}
                                                                              <Typography
                                                                                className={
                                                                                  classes.clickableAnswer
                                                                                }
                                                                                onClick={() => {
                                                                                  handleAnswerClick(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .clickableAnswers
                                                                                      .image,
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .field
                                                                                  );
                                                                                }}
                                                                              >
                                                                                {destructuredFields[
                                                                                  key
                                                                                ]
                                                                                  .clickableAnswers
                                                                                  .title &&
                                                                                  t(
                                                                                    "View Image"
                                                                                  )}
                                                                                {/* removed  */}
                                                                              </Typography>
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "table-cell",
                                                                                  display:
                                                                                    "flex",
                                                                                  alignSelf:
                                                                                    "center",
                                                                                }}
                                                                                className={
                                                                                  classes.thirditem
                                                                                }
                                                                              >
                                                                                {destructuredFields[
                                                                                  key
                                                                                ]
                                                                                  ?.field_tag ==
                                                                                "Equipment" ? (
                                                                                  Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      ?.answers
                                                                                  )[0] ==
                                                                                    "Not Present" ||
                                                                                  Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      ?.answers
                                                                                  )[0] ==
                                                                                    "لا يوجد" ||
                                                                                  Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      ?.answers
                                                                                  )[0] ==
                                                                                    "No Keys" ||
                                                                                  Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      ?.answers
                                                                                  )[0] ==
                                                                                    "لا مفاتيح" ? (
                                                                                    <img
                                                                                      src={
                                                                                        FaultyAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  ) : Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "Present" ||
                                                                                    Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "يوجد" ||
                                                                                    Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "مفتاحين" ||
                                                                                    Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "Two Keys" ? (
                                                                                    <img
                                                                                      src={
                                                                                        UnFaultyAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  ) : Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "One Key" ||
                                                                                    Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "مفتاح واحد" ? (
                                                                                    <img
                                                                                      src={
                                                                                        InformativeAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  ) : (
                                                                                    ""
                                                                                  )
                                                                                ) : key ==
                                                                                  987 ? (
                                                                                  <img
                                                                                    src={
                                                                                      NotIncluded
                                                                                    }
                                                                                    alt="not included"
                                                                                    width="22px"
                                                                                  />
                                                                                ) : key !=
                                                                                    950 && // distance since data reset dtc
                                                                                  key !=
                                                                                    985 && // battery type hybrid lithium
                                                                                  key !=
                                                                                    945 && // inspection notes hybrid nickle
                                                                                  key !=
                                                                                    990 && // inspection notes hybrid lithium
                                                                                  key !=
                                                                                    951 && // inspection notes dtc
                                                                                  key !=
                                                                                    953 && // recorded codes dtc & hybrid lithium
                                                                                  (group?.group_id ==
                                                                                    16 ||
                                                                                    group?.group_id ==
                                                                                      21) ? (
                                                                                  destructuredFields[
                                                                                    key
                                                                                  ]
                                                                                    ?.extended_answers
                                                                                    ?.length ==
                                                                                  0 ? (
                                                                                    <img
                                                                                      src={
                                                                                        UnFaultyAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  ) : (
                                                                                    <img
                                                                                      src={
                                                                                        FaultyAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  )
                                                                                ) : group?.group_id ==
                                                                                  20 ? (
                                                                                  Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      ?.answers
                                                                                  )[0] ==
                                                                                    "Abnormal" ||
                                                                                  Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      ?.answers
                                                                                  )[0] ==
                                                                                    "غير طبيعي" ? (
                                                                                    <img
                                                                                      src={
                                                                                        FaultyAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  ) : Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "Normal" ||
                                                                                    Object.keys(
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.answers
                                                                                    )[0] ==
                                                                                      "طبيعي" ? (
                                                                                    <img
                                                                                      src={
                                                                                        UnFaultyAnswer
                                                                                      }
                                                                                      alt="condition"
                                                                                    />
                                                                                  ) : key ==
                                                                                    922 ? (
                                                                                    group
                                                                                      ?.group_score
                                                                                      ?.fields
                                                                                      ?.group_score >
                                                                                    75 ? (
                                                                                      <img
                                                                                        src={
                                                                                          UnFaultyAnswer
                                                                                        }
                                                                                        alt="condition"
                                                                                      />
                                                                                    ) : group
                                                                                        ?.group_score
                                                                                        ?.fields
                                                                                        ?.group_score >=
                                                                                        50 &&
                                                                                      group
                                                                                        ?.group_score
                                                                                        ?.fields
                                                                                        ?.group_score <=
                                                                                        75 ? (
                                                                                      <img
                                                                                        src={
                                                                                          InformativeAnswer
                                                                                        }
                                                                                        alt="condition"
                                                                                      />
                                                                                    ) : (
                                                                                      <img
                                                                                        src={
                                                                                          FaultyAnswer
                                                                                        }
                                                                                        alt="condition"
                                                                                      />
                                                                                    )
                                                                                  ) : null
                                                                                ) : destructuredFields[
                                                                                    key
                                                                                  ]
                                                                                    .score ===
                                                                                  "N/A" ? null : destructuredFields[
                                                                                    key
                                                                                  ]
                                                                                    .score <=
                                                                                  50 ? (
                                                                                  <img
                                                                                    src={
                                                                                      FaultyAnswer
                                                                                    }
                                                                                    alt="condition"
                                                                                  />
                                                                                ) : (destructuredFields[
                                                                                    key
                                                                                  ]
                                                                                    .field_tag ===
                                                                                    "Cosmetic Test" ||
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .field_tag ===
                                                                                      "Tire Test" ||
                                                                                    key ==
                                                                                      194 ||
                                                                                    key ==
                                                                                      983) &&
                                                                                  destructuredFields[
                                                                                    key
                                                                                  ]
                                                                                    .score <=
                                                                                    99 ? (
                                                                                  <img
                                                                                    style={{
                                                                                      width:
                                                                                        "22px",
                                                                                      height:
                                                                                        "22px",
                                                                                    }}
                                                                                    src={
                                                                                      InformativeAnswer ///////
                                                                                    }
                                                                                    alt="condition"
                                                                                  />
                                                                                ) : (
                                                                                  <img
                                                                                    src={
                                                                                      UnFaultyAnswer
                                                                                    }
                                                                                    alt="condition"
                                                                                  />
                                                                                )}
                                                                              </div>
                                                                            </div>

                                                                            {/* here */}
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    }
                                                                  }
                                                                )}
                                                              </div>
                                                              {Object.keys(
                                                                destructuredFields
                                                              ).length -
                                                                1 !==
                                                              fieldIndex ? (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "table-row",
                                                                  }}
                                                                >
                                                                  <td
                                                                    colSpan="2"
                                                                    style={{
                                                                      padding: 0,
                                                                      backgroundColor:
                                                                        "#fff",
                                                                    }}
                                                                  >
                                                                    <hr
                                                                      className={
                                                                        classes.rowHr
                                                                      }
                                                                    />
                                                                  </td>
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "table-row",
                                                                    height:
                                                                      "8px",
                                                                  }}
                                                                >
                                                                  <td
                                                                    colSpan="2"
                                                                    style={{
                                                                      padding: 0,
                                                                      backgroundColor:
                                                                        "#fff",
                                                                    }}
                                                                  ></td>
                                                                </div>
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </Paper>
                                                </div>
                                                <Hidden smDown>
                                                  <div
                                                    style={
                                                      group?.group_id == 17
                                                        ? {
                                                            height: `${
                                                              ref?.current
                                                                ?.clientHeight -
                                                              20
                                                            }px`,
                                                          }
                                                        : {}
                                                    }
                                                  >
                                                    {}
                                                    {group?.group_id == 17 ? (
                                                      <BodyPaintResult
                                                        fields={
                                                          group?.sub_groups[0]
                                                            .fields
                                                        }
                                                      ></BodyPaintResult>
                                                    ) : null}
                                                  </div>
                                                </Hidden>
                                              </div>
                                            );
                                          })
                                        ) : (
                                          // <div>
                                          //   {skelStructure.map((table) => (
                                          <div>
                                            {skelStructure.map((table) => (
                                              <div>
                                                <Skeleton
                                                  variant="rect"
                                                  width="100%"
                                                  height="34px"
                                                  style={{
                                                    margin: "20px 0 5px",
                                                  }}
                                                ></Skeleton>

                                                {Object.keys(table).map(
                                                  (row) => (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <Skeleton
                                                        variant="rect"
                                                        width="50%"
                                                        height="32px"
                                                        style={{
                                                          margin: "0 2px 5px",
                                                        }}
                                                      ></Skeleton>
                                                      <Skeleton
                                                        variant="rect"
                                                        width="50%"
                                                        height="32px"
                                                        style={{
                                                          margin: "0 2px 5px",
                                                        }}
                                                      ></Skeleton>
                                                      <div
                                                        style={{
                                                          height: "12px",
                                                        }}
                                                      >
                                                        <Skeleton
                                                          variant="rect"
                                                          width="100%"
                                                          height="2px"
                                                          style={{
                                                            margin: "0 0 14px",
                                                          }}
                                                          className={
                                                            classes.rowHr
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </ExpansionPanelDetails>
                                    </div>
                                    <Typography className={classes.groupNote}>
                                      {group != undefined &&
                                      DetInspRepDiscription[group["group_id"]]
                                        ? DetInspRepDiscription[
                                            group["group_id"]
                                          ].note
                                        : null}
                                    </Typography>
                                    <Typography
                                      className={clsx({
                                        [classes.groupNote]: 1,
                                        [classes.secondNote]: 1,
                                      })}
                                    >
                                      {group?.sub_groups?.find(
                                        (o) => o.id == 66
                                      ) && (
                                        <Typography
                                          style={{
                                            color: "#636363",
                                            fontSize: "12px",
                                            marginTop: "16px",
                                          }}
                                        >
                                          {t(
                                            "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report."
                                          )}
                                        </Typography>
                                      )}
                                      {group != undefined &&
                                      DetInspRepDiscription[group["group_id"]]
                                        ? DetInspRepDiscription[
                                            group["group_id"]
                                          ].secondNote
                                        : null}
                                    </Typography>
                                  </ExpansionPanel>
                                ) : null;
                              }
                            )}
                          </div>
                        ) : (
                          [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                            <Skeleton
                              style={{
                                height: "52px",
                                transform: "scale(1,0.95)",
                              }}
                              className={classes.expansionPanelSummaryRoot}
                            />
                          ))
                        )}
                      </div>
                    </Paper>
                    <div className={classes.disclaimerDiv}>
                      <Typography
                        style={
                          localStorage.getItem("language") === "ar"
                            ? {
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "#535353",
                                marginBottom: "5px",
                              }
                            : {
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#535353",
                                marginBottom: "5px",
                              }
                        }
                      >
                        {t("Disclaimer")}:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          // padding: "5px 11px 32px 11px",
                          color: "#535353",
                        }}
                      >
                        {localStorage.getItem("language") === "en" ? (
                          <>
                            <p>
                              * * Markabte condition report is based on the
                              condition of the vehicle during the time of
                              inspection only and for the points included in the
                              report only. Markabte LLC can not be held
                              responsible or liable in any way due to your
                              reliance on this report.
                            </p>
                            <p>
                              * Markabte has relied on the odometer reading as
                              seen at the time of inspection and is not
                              responsible for verifying its genuineness.
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              * يعتمد تقرير مركبتي على حالة المركبة خلال وقت
                              الفحص فقط وللنقاط المشمولة بالتقرير فقط ولا يمكن
                              تحميل شركة مركبتي المسؤولية بأي شكل من الأشكال
                              بسبب اسخدام هذا التقرير.
                            </p>
                            <p>
                              * يعتمد تقرير مركبتي على قراءة عداد المسافات خلال
                              وقت الفحص وليست مسؤولة عن التحقق من صحتها.
                            </p>
                          </>
                        )}
                      </Typography>
                    </div>
                  </Paper>
                  {/* </Grid> */}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* // ) } */}
        </Grid>
      </Grid>{" "}
      {showfaults && (
        <FaultyDetails
          open={showfaults}
          setOpen={setshowfaults}
          system={faults.field}
          faultcnt={`${faults?.extended_answers?.length} ${t("faults")}`}
        >
          <CardIterator fields={faults} carInfo={carInfo} />
        </FaultyDetails>
      )}
      <MarkabtePopup
        dir={"rtl"}
        open={frameFaultopen}
        setOpen={setframeFaultopen}
        title={frameFault.title}
        action={`${frameFault?.data?.issues?.length} ${t("Frame Issues")}`}
        headColor={"#FEFEFE"}
        cardColor={"#F9FAFF"}
        // headShadow={'0px 7.75168px 12.9195px rgba(130, 148, 154, 0.4)'}
        withdivider={true}
      >
        <FrameFaultsLis data={frameFault?.data?.issues} />
      </MarkabtePopup>
      <FaultsStepper
        open={faultOpen}
        setOpen={setfaultOpen}
        {...interiorFaults}
      />
      <ClickableAnswerDialog
        open={openAnswer}
        setOpen={setOpenAnswer}
        {...imageDialogProps}
      />
      <InspectionNotesDialog
        open={openInspection}
        {...inspectionNotes}
        handleClose={handleClose}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  carList: state.car.cars,
  groups: state.car.unlocked_part?.groups ?? { groups: [] },
  // groupTemplate: state.car.unlocked_part?.groupTemplate,
  carId: state.car.car.id, //remove?
  date: state.car.car.created_at,
  enteredBy: state.car.car.entered_by_fullname,
  points: state.forms.points,
  unlocked: state.car.car.unlocked,
});

const mapDispatchToProps = (dispatch) => ({
  getCarGroups(payload) {
    // gets the groups including the comments
    dispatch(getCarGroups(payload));
  },
  getCarFaults(payload) {
    dispatch(getCarFaults(payload));
  },
  getCarVisualization(payload) {
    dispatch(getCarVisualization(payload));
  },
  logVisitorView(payload) {
    dispatch(logVisitorView(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InspectionReport)
);
