import React from "react";

const FuelType = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 143 143"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#FuelTypeClip0)">
        <path
          d="M79.8292 17.6722H29.8007V51.0245H79.8292V17.6722ZM71.4911 42.6864H38.1388V26.0103H71.4911V42.6864Z"
          fill={fill}
        />
        <path
          d="M127.026 13.5026L108.289 28.2243C106.099 29.9447 104.843 32.529 104.843 35.3139V71.8691C104.843 77.3042 101.358 81.9382 96.5054 83.6592V0.995483H13.1246V106.486C6.21648 106.556 0.617462 112.194 0.617462 119.118V142.743H109.012V119.118C109.012 112.194 103.413 106.556 96.5054 106.486V92.2949C106.009 90.3583 113.182 81.9357 113.182 71.8691V49.6343H122.532C126.572 49.6343 129.858 46.3482 129.858 42.309V21.8818L129.91 21.8407H142.365V13.5026H127.026ZM21.4627 9.33356H88.1673V83.6592C83.3148 81.9382 79.8292 77.3042 79.8292 71.8691V59.362H71.4911V71.8691C71.4911 81.9357 78.6641 90.358 88.1673 92.2949V106.483H21.4627V9.33356ZM100.674 119.118V134.405H8.95554V119.118C8.95554 116.749 10.8833 114.821 13.2524 114.821H96.3775C98.7466 114.821 100.674 116.749 100.674 119.118ZM121.52 41.2962H113.182V35.3142C113.182 35.1046 113.276 34.9103 113.441 34.7811L121.52 28.433V41.2962Z"
          fill={fill}
        />
        <path
          d="M50.6466 59.3622H58.9847V92.7145H50.6466V59.3622Z"
          fill={fill}
        />
        <path
          d="M29.8007 59.3622H38.1388V92.7145H29.8007V59.3622Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="FuelTypeClip0">
          <rect
            width="141.747"
            height="141.747"
            fill="white"
            transform="translate(0.617462 0.995483)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FuelType;
