import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PhoneRunner from "../../components/icons/PhoneRunner";
import WhatsappIcon from "../icons/WhatsappRunner";

import Unlocked from "../icons/unlocked";
import markabteUser from "../../icons/markabteUser.svg";
import AskIcon from "../icons/askIcon.svg";
import LoudSpeakerIcon from "../icons/LoudSpeaker";
import theme from "../../theme";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import { getCreditInfo, unlockCar } from "../../store/actions/auth";
import {
  callDealershipAnonymously,
  getDealerHours,
  requestRunnerCall,
  requestWhatsAppMessage,
} from "../../store/actions/dealership";
import { logConferenceCall } from "../../store/actions/metrics";
import { listPlans } from "../../store/actions/subscriptionPlans";
import WebSubscriptionsPage from "./WebSubscriptionsPage";
import Moment from "moment";
import { logVisitorView } from "../../store/actions/accounts";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    boxSizing: "border-box",
    border: "0.929185px solid #ADB5BA",
    borderRadius: "3px", // removed to not messup the outline's border
    padding: "16px 18px 32px 18px",
    display: "flex",
    flexDirection: "column",
    margin: "23px 0",
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      border: "none",
      margin: 0,
    },
  },
  ///////////////////////////////////////////
  button: {
    color: "white",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    paddingBlock: "12px",
  },
  outlinedButton: {
    color: "#186985",
    whiteSpace: "nowrap",
  },
  greenButton: {
    backgroundColor: "#3CB656",
    "&:hover": {
      backgroundColor: "#2C6E4D",
    },
  },
  ////////////////////////////////////////////
  actionBarRelative: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      transformOrigin: "bottom left",
      zIndex: 10,
      width: "100%",
      left: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
      height: "100%",
      position: "relative",
    },
  },
  actionBarPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      boxShadow: "0px -3.28852px 5.26164px rgba(10, 47, 60, 0.2);",
      borderRadius: 0,
      padding: "10px 24px",
    },
  },
  /////////////////////////////////////////
  loggedHeaderDiv: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #B1B1B1",
    paddingBottom: "19px",
    marginBottom: "15px",
  },
  subscriptionInfoDiv: { display: "flex", alignSelf: "center" },
  imageDiv: { marginRight: "11px" },
  namePlanDiv: {},
  userName: { fontSize: "17px", fontWeight: 600, color: "#07303E" },
  userNameWidth: { width: "100px" },
  planName: { fontSize: "13px", fontWeight: 400, color: "#636363" },
  planNameWidth: { width: "50px" },
  creditsDiv: { display: "flex" },
  credits: {
    fontSize: "36px",
    fontWeight: 500,
    color: "#07303E",
    marginRight: "2.5px",
  },
  creditsWidth: { width: "30px" },
  creditsDiscriptionDiv: {
    display: "flex",
    flexDirection:
      localStorage.getItem("language") == "en" ? "column" : "column-reverse",
    justifyContent: "center",
  },
  creditsDiscriptionFirst: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#6B8495",
    lineHeight: 1,
  },
  creditsDiscriptionSecond: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#6B8495",
    lineHeight: 1,
  },
  /////////////////////////
  loggedContentDiv: {
    paddingBottom: "16px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "1px solid #B1B1B1",
      marginBottom: "20px",
    },
  },
  loggedOutContentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loggedIconTitleDiv: {
    display: "flex",
  },
  loudSpeakerIcon: {
    marginRight: "11.61px",
  },
  loggedTitle: {
    marginBottom: "4px",
    fontSize: "18px",
    fontWeight: 600,
    color: "#07303E",
    whiteSpace: "nowrap",
  },
  loggedContent: { fontSize: "14px", fontWeight: 500, color: "#6B8495" },
  /////////////////////////////////////////
  bookAppointmentPaper: {
    top: 0,
    left: 0,
    boxShadow: "0px 3.28852px 5.26164px rgba(10, 47, 60, 0.2);",
    backgroundColor: "#F9FAFD",
    width: "100%",
    zIndex: 1,
  },
  contentMainDiv: {
    padding: "16px",
    paddingBottom: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100% - 40px)",
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #C4C4C4",
    paddingBottom: "8px",
    marginBottom: "16px",
    alignItems: "start",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  headerTitleSubtitle: {
    [theme.breakpoints.up("md")]: {
      marginInline: "auto",
    },
  },
  headerTitle: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#07303E",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  headerSubtitle: {
    fontSize: "15px",
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  closeIconButton: {
    transform: "translate(12px,-12px)",
  },
  ///////////////////////////
  callAnonymouslySubHeader: {
    fontSize: "14px",
    color: "#07303E",
    marginBottom: "32px",
    marginTop: "20px",
  },
  unlockInspectionButton: {
    marginBlock: "16px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  balanceInnerDiv: {
    display: "flex",
    alignItems: "center",
  },
  balanceTypography: {
    fontWeight: 700,
  },
  scale: {
    transform: "scale(0.85)",
  },
}));

let d = new Date();
let n = d.toLocaleTimeString("en-GB");

const DrawerSliderContent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    handleClose,
    drawerSliderState,
    creditsInfo,
    carCard,
    car,
    user,
    history,
    unlockCar,
    callDealershipAnonymously,
    requestRunnerCall,
    requestWhatsAppMessage,
    logConferenceCall,
    getCreditInfo,
    disabledTimes,
    dealershipPhoneNumber,
    choosePlanOpen,
    setChoosePlanOpen,
  } = props;

  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [maqsamCalled, setMaqsamCalled] = useState(false);

  // Inner Buttons onClick Functions
  const handleCallback = (e) => {
    e.stopPropagation();
    requestRunnerCall({
      carId: car.id,
      dealerId: car.dealership_id,
      userId: user.user_id,
      date: new Date(),
      message: t("markabte agent will call you shortly."),
    });
    handleClose();
  };

  const handleWhatsapp = (e) => {
    e.stopPropagation();
    requestWhatsAppMessage({
      carId: car.id,
      dealerId: car.dealership_id,
      userId: user.user_id,
      date: new Date(),
    });
    if (drawerSliderState.userCarState == "callIndependent") {
      window.open(
        `https://wa.me/${car?.runner_data?.whatsappPhoneNumber}/?text= ${t(
          "I am interested in"
        )} ${carCard?.make}  ${carCard?.model} ${carCard?.year} ${t(
          "with listing id"
        )} ${car?.id}`
      );
    } else {
      //callSeller
      window.open(
        `https://wa.me/${car?.runner_data?.whatsappPhoneNumber}/?text= ${t(
          "لدي استفسار بخصوص مركبتي"
        )} ${carCard?.make}  ${carCard?.model} ${carCard?.year} ${t(
          "اعلان رقم"
        )} ${car?.id}`
      );
    }
    handleClose();
  };

  const handleCallAnonymous = () => {
    setMaqsamCalled(true);
    setTimeout(() => setMaqsamCalled(false), 10000);
    callDealershipAnonymously({
      userPhoneNumber: user.phone,
      dealershipPhoneNumber,
      callback: () => {
        logConferenceCall({
          dealer: car.dealership_id,
          car: car.id,
        });
      },
    });
    // handleClose();
  };

  const handleUnlock = () => {
    setLoading(true);
    unlockCar({
      carid: car.id,
      user_id: user.user_id,
      callback: (response) => {
        // credits are decreased by websocket not reducer on the frontend in case of ws disconnect credits check is on backend and an error is sent back then getCreditInfo refreshes credits amount
        getCreditInfo({ user_id: user.user_id });
        handleClose();
        setLoading(false);
        window.location.reload();
      },
    });
  };

  const handleRecharge = () => {
    if (small) {
      setChoosePlanOpen(true);
      handleClose();
    } else {
      //redirect
      history.push("/subscription-plans");
    }
  };

  //   const handleCallAgent = () => {};

  const loadSkeleton = {
    title: "",
    subtitle: "",
    content: <Skeleton />,
  };

  const callIndependent = {
    title: "Contact Markabte Agent",
    subtitle: "Choose method of contact below",
    content: (
      <>
        <Button
          className={clsx({ [classes.button]: 1, [classes.greenButton]: 1 })}
          style={{ marginBottom: "16px" }}
          fullWidth
          variant="contained"
          startIcon={
            <PhoneRunner
              size={16}
              fill={
                car?.runner_data?.call_requested?.id
                  ? "rgba(0,0,0,0.26)"
                  : "white"
              }
            />
          }
          onClick={handleCallback}
          disabled={car?.runner_data?.call_requested?.id}
        >
          {t("Call me")}
        </Button>
        <Button
          className={clsx({ [classes.button]: 1 })}
          fullWidth
          color="primary"
          variant="contained"
          startIcon={
            <WhatsappIcon
              size={16}
              fill={
                !car?.runner_data?.whatsappPhoneNumber
                  ? "rgba(0,0,0,0.26)"
                  : "white"
              }
            />
          }
          onClick={handleWhatsapp}
          disabled={!car?.runner_data?.whatsappPhoneNumber}
        >
          {t("Send Whatsapp Text")}
        </Button>
      </>
    ),
  };
  const callDealer = {
    title: "Call Seller Anonymously",
    subtitle: "",
    content: (
      <>
        <Typography className={classes.callAnonymouslySubHeader}>
          {maqsamCalled ? (
            t("Please wait, we will call you now.")
          ) : n < disabledTimes?.time_from || n > disabledTimes?.time_to ? (
            <>
              {t(
                "Sorry, you can't call the seller past working hours. Please call during working hours from"
              )}
              {"\u00A0"}
              {(n < disabledTimes?.time_from || n > disabledTimes?.time_to) &&
                `${disabledTimes?.time_from?.substring(
                  0,
                  5
                )} - ${disabledTimes?.time_to?.substring(0, 5)}.`}
            </>
          ) : disabledTimes.length == 0 ? (
            t(
              "Sorry, the seller is not accepting calls for today. Please try again on another day."
            )
          ) : (
            t(
              "You will be connected with the seller anonymously via Markabte Call Portal."
            )
          )}
        </Typography>
        <Button
          className={clsx({ [classes.button]: 1, [classes.greenButton]: 1 })}
          style={{ marginBottom: "16px" }}
          fullWidth
          variant="contained"
          startIcon={
            <PhoneRunner
              size={16}
              fill={
                maqsamCalled ||
                n < disabledTimes?.time_from ||
                n > disabledTimes?.time_to ||
                disabledTimes.length == 0
                  ? "rgba(0,0,0,0.26)"
                  : "white"
              }
            />
          }
          onClick={handleCallAnonymous}
          disabled={
            // loading ||
            maqsamCalled ||
            n < disabledTimes?.time_from ||
            n > disabledTimes?.time_to ||
            disabledTimes.length == 0
          }
        >
          {t("Call Me Now")}
        </Button>
      </>
    ),
  };
  const unlock = {
    title: "Unlock Inspection",
    subtitle: "",
    content: (
      <>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Typography
            style={{
              color: "#07303E",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "4px",
            }}
          >
            {`${carCard?.year}`}
          </Typography>
          {"\u00A0"}
          <Typography
            style={{
              color: "#07303E",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "4px",
            }}
          >
            {`${carCard?.make}`}
          </Typography>
          {"\u00A0"}
          <Typography
            style={{
              color: "#07303E",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "4px",
            }}
          >
            {`${carCard?.model}`}
          </Typography>
          {"\u00A0"}
          <Typography
            style={{
              color: "#07303E",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "4px",
            }}
          >
            {`${carCard?.trim}`}
          </Typography>
        </div>
        <Typography
          style={{ fontSize: "14px", color: "#07303E", marginBottom: "8px" }}
        >
          {t(
            "Are you sure you want to unlock inspection details and seller’s information for this car?"
          )}
        </Typography>
        <div
          style={{
            display: "grid",
            position: "relative",
            marginBottom: "16.44px",
          }}
        >
          <Button
            className={clsx({
              [classes.button]: 1,
              [classes.unlockInspectionButton]: 1,
            })}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleUnlock}
            disabled={loading}
          >
            {t("Unlock Inspection")}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Typography
            style={{
              color: "#6B8495",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {t("Remaining balance after use")}:&nbsp;
          </Typography>
          <Typography
            style={{
              color: "#6B8495",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {creditsInfo?.credit_amount - 1}&nbsp;
            {t("Car Credits")}
          </Typography>
        </div>
      </>
    ),
  };
  const recharge = {
    title: "Charge Credits",
    subtitle: "",
    content: (
      <>
        <div className={classes.balanceInnerDiv}>
          <Typography className={classes.balanceTypography}>
            {t("Current Balance") + " :"}
          </Typography>
          <div
            className={clsx({ [classes.creditsDiv]: 1, [classes.scale]: 1 })}
          >
            {typeof creditsInfo?.credit_amount == "number" ? (
              <Typography className={classes.credits}>
                {creditsInfo?.credit_amount}
              </Typography>
            ) : (
              <Skeleton
                className={clsx({
                  [classes.credits]: 1,
                  [classes.creditsWidth]: 1,
                })}
              />
            )}
            <div className={classes.creditsDiscriptionDiv}>
              <Typography className={classes.creditsDiscriptionFirst}>
                {t("Car")}
              </Typography>
              <Typography className={classes.creditsDiscriptionSecond}>
                {t("Credits")}
              </Typography>
            </div>
          </div>
        </div>
        <Typography style={{ margin: "10px 0 32px", fontSize: "14px" }}>
          {t("Please charge your balance to unlock inspection reports.")}
        </Typography>
        <Button
          className={clsx({
            [classes.button]: 1,
          })}
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleRecharge}
        >
          {t("Charge Credits")}
        </Button>
      </>
    ),
  };
  const callSeller = {
    title: "Contact Sales Agent",
    subtitle: "Choose method of contact below",
    content: (
      <div>
        <a href={`tel:${dealershipPhoneNumber}`} style={{ all: "initial" }}>
          <Button
            className={clsx({ [classes.button]: 1, [classes.greenButton]: 1 })}
            style={{ marginBottom: "16px" }}
            fullWidth
            variant="contained"
            startIcon={<PhoneRunner size={16} fill="white" />}
          >
            {t("Call")}
          </Button>
        </a>
        <Button
          className={clsx({ [classes.button]: 1 })}
          fullWidth
          color="primary"
          variant="contained"
          startIcon={
            <WhatsappIcon
              size={16}
              fill={
                !car?.runner_data?.whatsappPhoneNumber
                  ? "rgba(0,0,0,0.26)"
                  : "white"
              }
            />
          }
          onClick={handleWhatsapp}
          disabled={!car?.runner_data?.whatsappPhoneNumber}
        >
          {t("Send Whatsapp Text")}
        </Button>
      </div>
    ),
  };

  const InnerData = {
    skeleton: [loadSkeleton],
    independentSeller: [callIndependent],
    dealerUnLocked: [callDealer],
    dealerLocked: [callDealer, unlock, recharge],
    sellerReport: [callSeller],
    loggedOutSellerReport: [],
  };

  const selectedGroup =
    InnerData[drawerSliderState.userCarState][drawerSliderState.index];

  return (
    <div className={classes.contentMainDiv}>
      <div className={classes.headerDiv}>
        <Hidden smDown>
          {theme.direction === "ltr" ? (
            <KeyboardArrowLeft
              onClick={handleClose}
              style={{
                fill: "#07303E",
                fontSize: "33px",
                cursor: "pointer",
                transform: "translate(-8px,-4px)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              onClick={handleClose}
              style={{
                fill: "#07303E",
                fontSize: "33px",
                cursor: "pointer",
                transform: "translate(8px,-4px)",
              }}
            />
          )}
        </Hidden>
        <div className={classes.headerTitleSubtitle}>
          <Typography className={classes.headerTitle}>
            {t(selectedGroup?.title)}
          </Typography>
          <Typography className={classes.headerSubtitle}>
            {t(selectedGroup?.subtitle)}
          </Typography>
        </div>
        <Hidden mdUp>
          <IconButton onClick={handleClose} className={classes.closeIconButton}>
            <Close style={{ color: "#07303E" }} />
          </IconButton>
        </Hidden>
      </div>
      <div style={{ marginBlock: "auto" }}>{selectedGroup?.content}</div>
    </div>
  );
};

const DrawerSlider = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    drawerSliderState,
    handleClose,
    creditsInfo,
    carCard,
    car,
    user,
    history,
    unlockCar,
    callDealershipAnonymously,
    requestRunnerCall,
    requestWhatsAppMessage,
    logConferenceCall,
    getCreditInfo,
    disabledTimes,
    dealershipPhoneNumber,
    listPlans,
  } = props;

  const [choosePlanOpen, setChoosePlanOpen] = useState(false);

  const drawerSliderProps = {
    handleClose: handleClose,
    drawerSliderState: drawerSliderState,
    creditsInfo: creditsInfo,
    carCard: carCard,
    car,
    user,
    unlockCar: unlockCar,
    callDealershipAnonymously: callDealershipAnonymously,
    requestRunnerCall: requestRunnerCall,
    requestWhatsAppMessage: requestWhatsAppMessage,
    logConferenceCall: logConferenceCall,
    getCreditInfo: getCreditInfo,
    disabledTimes: disabledTimes,
    dealershipPhoneNumber: dealershipPhoneNumber,
    history: history,
    listPlans: listPlans,
    choosePlanOpen: choosePlanOpen,
    setChoosePlanOpen: setChoosePlanOpen,
  };

  return (
    <>
      <Hidden smDown>
        <Slide
          direction={theme.direction === "ltr" ? "left" : "right"}
          in={drawerSliderState.open}
          timeout={200}
          mountOnEnter
          unmountOnExit
          style={!drawerSliderState.open ? { display: "none" } : {}}
        >
          <Paper square className={classes.bookAppointmentPaper}>
            <DrawerSliderContent {...drawerSliderProps} />
          </Paper>
        </Slide>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          open={drawerSliderState.open}
          anchor="bottom"
          onClose={handleClose}
        >
          <DrawerSliderContent {...drawerSliderProps} />
        </Drawer>
        <Hidden mdUp>
          <WebSubscriptionsPage
            mobOpenPopup={choosePlanOpen}
            setMobOpenPopup={setChoosePlanOpen}
          />
        </Hidden>
      </Hidden>
    </>
  );
};

const MobBottomAction = (props) => {
  const classes = useStyles();

  const { children, isVisible } = props;
  return (
    <Hidden mdUp>
      <Slide
        direction="up"
        in={!isVisible}
        timeout={100}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.actionBarRelative}>
          <Paper className={classes.actionBarPaper}>{children}</Paper>
        </div>
      </Slide>
    </Hidden>
  );
};

const ButtonSellector = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { buttonGroup, handleOpen } = props;

  const buttonsProps = {
    skeleton: [{}],
    independentSeller: [
      {
        color: "",
        variant: "contained",
        onClick: () => handleOpen("indConnect"),
        className: clsx({ [classes.greenButton]: 1, [classes.button]: 1 }),
        children: t("Connect"),
        startIcon: <PhoneRunner size={16} fill="white" />,
      },
    ],
    dealerUnLocked: [
      {
        color: "",
        variant: "contained",
        onClick: () => handleOpen("dealerAnonymous"),
        className: clsx({ [classes.greenButton]: 1, [classes.button]: 1 }),
        children: t("Connect"),
        startIcon: <PhoneRunner size={16} fill="white" />,
      },
    ],
    dealerLocked: [
      {
        color: "primary",
        variant: "contained",
        onClick: () => handleOpen("unlock"),
        className: clsx({ [classes.button]: 1 }),
        children: t("Unlock"),
        startIcon: <Unlocked size={16} fill="white" />,
      },
      {
        color: "",
        variant: "contained",
        onClick: () => handleOpen("dealerAnonymous"),
        className: clsx({ [classes.greenButton]: 1, [classes.button]: 1 }),
        children: t("Connect"),
        startIcon: <PhoneRunner size={16} fill="white" />,
      },
    ],
    sellerReport: [
      {
        color: "",
        variant: "contained",
        onClick: () => handleOpen("indConnect"),
        className: clsx({ [classes.greenButton]: 1, [classes.button]: 1 }),
        children: t("Contact Sales Agent"),
        startIcon: <PhoneRunner size={16} fill="white" />,
      },
    ],
    loggedOutSellerReport: [
      {
        color: "primary",
        variant: "contained",
        onClick: () => handleOpen("indConnect"),
        className: clsx({ [classes.button]: 1 }),
        children: t("Sign In"),
      },
    ],
    sampleReport: [
      {
        color: "primary",
        variant: "outlined",
        onClick: () => handleOpen("sampleReport"),
        className: clsx({ [classes.button]: 1, [classes.outlinedButton]: 1 }),
        children: t("Frequently Asked Questions"),
      },
    ],
  };

  const selectedGroup = buttonsProps[buttonGroup];

  return (
    <Grid container spacing={2}>
      {selectedGroup.map((buttonProps) => {
        return (
          <Grid item xs={12 / selectedGroup.length}>
            <Button {...buttonProps} fullWidth />
          </Grid>
        );
      })}
    </Grid>
  );
};

const LoggedComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, creditsInfo, userCarState } = props;

  const descriptionDiv = {
    skeleton: { subtitle: "" },
    independentSeller: { subtitle: "Contact Markabte Agent directly." },
    dealerUnLocked: { subtitle: "Contact the seller directly." },
    dealerLocked: {
      subtitle: "Unlock inspection report and contact the seller.",
    },
    sellerReport: { subtitle: "Contact your dedicated sales agent" },
    loggedOutSellerReport: {
      subtitle: "You must be signed in to view the Seller inspection report",
    },
    sampleReport: {
      subtitle:
        "View our FAQ about Markabte’s inspection below or contact us for any question.",
    },
  };

  const selectedGroup = descriptionDiv[userCarState];

  return (
    <>
      {user && userCarState !== "sampleReport" && (
        <div className={classes.loggedHeaderDiv}>
          <div className={classes.subscriptionInfoDiv}>
            <div className={classes.imageDiv}>
              <img
                style={{
                  width: "44px",
                  height: "44px",
                }}
                src={markabteUser}
                alt="user"
              />
            </div>
            <div className={classes.namePlanDiv}>
              {user?.first_name && user?.last_name ? (
                <Typography className={classes.userName}>
                  {`${user.first_name} ${user.last_name}`}
                </Typography>
              ) : (
                <Skeleton
                  className={clsx({
                    [classes.userName]: 1,
                    [classes.userNameWidth]: 1,
                  })}
                />
              )}
              <Typography className={classes.planName}>
                {t("Markabte User")}
              </Typography>
            </div>
          </div>
          <div className={classes.creditsDiv}>
            {typeof creditsInfo?.credit_amount == "number" ? (
              <Typography className={classes.credits}>
                {creditsInfo?.credit_amount}
              </Typography>
            ) : (
              <Skeleton
                className={clsx({
                  [classes.credits]: 1,
                  [classes.creditsWidth]: 1,
                })}
              />
            )}
            <div className={classes.creditsDiscriptionDiv}>
              <Typography className={classes.creditsDiscriptionFirst}>
                {t("Car")}
              </Typography>
              <Typography className={classes.creditsDiscriptionSecond}>
                {t("Credits")}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {userCarState === "sampleReport" && (
        <div className={classes.loggedHeaderDiv}>
          <div style={{ display: "flex" }}>
            <img src={AskIcon} width="25px" />
            <Typography
              style={{
                margin: "0 10px",
                color: "#07303E",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              {t("Markabte’s Inspection FAQ")}
            </Typography>
          </div>
        </div>
      )}
      <div
        className={clsx({
          [classes.loggedContentDiv]: 1,
          [classes.loggedOutContentDiv]: !user,
        })}
      >
        <div className={classes.loggedIconTitleDiv}>
          {!user &&
            userCarState !== "loggedOutSellerReport" &&
            userCarState !== "sampleReport" && (
              <LoudSpeakerIcon
                size={30}
                fill={"#07303E"}
                className={classes.loudSpeakerIcon}
                style={
                  localStorage.getItem("language") === "en"
                    ? {}
                    : { transform: "rotate(180deg) scaleY(-1)" }
                }
              />
            )}
          {userCarState !== "sampleReport" && (
            <Typography className={classes.loggedTitle}>
              {userCarState == "sellerReport"
                ? t("Do you have any questions?")
                : userCarState == "loggedOutSellerReport"
                ? t("You are not signed in")
                : t("Interested in This Car?")}
            </Typography>
          )}
        </div>
        <Typography className={classes.loggedContent}>
          {t(selectedGroup.subtitle)}
        </Typography>
      </div>
    </>
  );
};

const ActionBarTest = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isVisible,
    user,
    creditsInfo,
    car,
    unlocked,
    carCard,
    history,
    sellerReport,
    isSampleReport,
    unlockCar,
    callDealershipAnonymously,
    requestRunnerCall,
    requestWhatsAppMessage,
    logConferenceCall,
    getCreditInfo,
    disabledTimes,
    dealershipPhoneNumber,
    listPlans,
    logVisitorView,
  } = props;

  const [userCarState, setUserCarState] = useState("skeleton");
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentId, setCurrentId] = useState(
    string_between_strings("_ga=", ";", document.cookie)
  ); // two years
  const [currentGid, setCurrentGid] = useState(
    string_between_strings("_gid=", ";", document.cookie)
  ); /// 24 hours
  const [startTime, setStartTime] = useState(
    Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
  );

  function string_between_strings(startStr, endStr, str) {
    let pos = str.indexOf(startStr) + startStr.length;
    return str.substring(pos, str.indexOf(endStr, pos));
  }

  useEffect(() => {
    setStartTime(
      Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
    );
  }, []);

  useEffect(() => {
    setUserCarState(
      !isSampleReport
        ? !sellerReport
          ? unlocked
            ? car.runner_car
              ? "independentSeller"
              : "dealerUnLocked"
            : "dealerLocked"
          : user
          ? "sellerReport"
          : "loggedOutSellerReport"
        : "sampleReport"
    );
  }, [isSampleReport, sellerReport, unlocked, car, creditsInfo]);

  const [drawerSliderState, setDrawerSliderState] = useState({
    open: false,
    userCarState: userCarState,
    index: 0,
  });

  // Outer Buttons onClick Functions
  const handleFAQ = () => {
    history.push("/Frequent-Asked-Questions");
  };

  const handleSignIn = () => {
    history.push(
      sellerReport
        ? {
            pathname: "/signin",
            state: {
              prevUrl: window.location.href,
              sellerVDP: window.location.pathname,
            },
          }
        : {
            pathname: "/signin",
            state: { prevUrl: window.location.href, fromVDP: true },
          }
    );
  };

  const handleOpen = (button) => {
    const selectTab = () => {
      if (userCarState == "dealerLocked") {
        if (button == "unlock") {
          if (!creditsInfo?.credit_amount) {
            // credit_amount must not be undefined or null add condition
            return 2;
          }
          return 1;
        }
        return 0;
      }
      return 0;
    };
    setDrawerSliderState({
      open: true,
      userCarState: userCarState,
      index: selectTab(),
    });
  };

  const handleButtonClick = (buttonName) => {
    logVisitorView({
      page_title: currentPath,
      start_time: startTime,
      session_one: currentId,
      session_two: currentGid,
      tag: "Connect Button",
    });
    if (isSampleReport) {
      handleFAQ();
      return;
    }
    if (user) {
      handleOpen(buttonName);
      return;
    }
    handleSignIn();
    return;
  };
  //////////////////////////////////

  const handleClose = () => {
    setDrawerSliderState({ open: false, userCarState: userCarState, index: 0 });
  };

  return (
    <div style={{ display: "flex" }}>
      <Slide
        direction={theme.direction === "ltr" ? "left" : "right"}
        in={!drawerSliderState.open || smDown}
        timeout={200}
        mountOnEnter
        unmountOnExit
        className={classes.mainDiv}
        style={drawerSliderState.open && !smDown ? { display: "none" } : {}}
      >
        <div>
          <LoggedComponent
            user={user}
            creditsInfo={creditsInfo}
            userCarState={userCarState}
          />
          <ButtonSellector
            buttonGroup={userCarState}
            handleOpen={handleButtonClick}
          />
        </div>
      </Slide>
      <MobBottomAction isVisible={isVisible}>
        <ButtonSellector
          buttonGroup={userCarState}
          handleOpen={handleButtonClick}
        />
      </MobBottomAction>
      <DrawerSlider
        drawerSliderState={drawerSliderState}
        handleClose={handleClose}
        creditsInfo={creditsInfo}
        carCard={carCard}
        car={car}
        user={user}
        history={history}
        unlockCar={unlockCar}
        callDealershipAnonymously={callDealershipAnonymously}
        requestRunnerCall={requestRunnerCall}
        requestWhatsAppMessage={requestWhatsAppMessage}
        logConferenceCall={logConferenceCall}
        getCreditInfo={getCreditInfo}
        disabledTimes={disabledTimes}
        dealershipPhoneNumber={dealershipPhoneNumber}
        listPlans={listPlans}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  creditsInfo: state.auth.credits_info,
  car: state.car.car,
  dealership_phone_number: state?.car?.car?.phone,
  dealershipId: state.car.car.dealership_id,
});

const mapDispatchToProps = (dispatch) => ({
  unlockCar(payload) {
    dispatch(unlockCar(payload));
  },
  getCreditInfo(payload) {
    dispatch(getCreditInfo(payload));
  },
  callDealershipAnonymously(payload) {
    dispatch(callDealershipAnonymously(payload));
  },
  getDealerHours(payload) {
    dispatch(getDealerHours(payload));
  },
  requestRunnerCall(payload) {
    dispatch(requestRunnerCall(payload));
  },
  requestWhatsAppMessage(payload) {
    dispatch(requestWhatsAppMessage(payload));
  },
  logConferenceCall(payload) {
    dispatch(logConferenceCall(payload));
  },
  listPlans(payload) {
    dispatch(listPlans(payload));
  },
  logVisitorView(payload) {
    dispatch(logVisitorView(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionBarTest)
);
