import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  reportGrid: {
    backgroundColor: "#f3f3f3",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 50px",
    },
  },
  reportPaper: {
    backgroundColor: "unset",
    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 15px",
      margin: "50px 17px 0 4px",
    },
  },
  smPapers: {
    borderRadius: "0",
    boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.4)",
    margin: "15px 0",
    padding: "15px",
    backgroundColor: "unset",

    [theme.breakpoints.up("md")]: {
      all: "initial",
    },
  },
  firstGridItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "56px",
    },
  },
  expansionPanel: {
    marginBottom: "5px",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.4)",
    height: "52px",
    transform: "none",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },
}));

const InspectionSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.reportGrid}>
      <Grid item sm={12} md={9} className={classes.firstGridItem}>
        <Paper className={classes.reportPaper}>
          <Paper className={classes.smPapers}>
            <div>
              <div style={{ marginBottom: "25%" }}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((group, i) => (
                  <Skeleton
                    className={classes.expansionPanel}
                    key={`group_${i}`}
                  />
                ))}
              </div>
            </div>
          </Paper>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default InspectionSkeleton;
