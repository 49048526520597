import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import plus from "../../../icons/plus.svg";
import minus from "../../../icons/minus.svg";
import MarkabtePopup from "../../MarkabtePopup";
import ChoosePlan from "../Free/ChoosePlan";
import PopupDrawer from "../PopupDrawer";
import { connect } from "react-redux";
import { listPlans } from "../../../store/actions/subscriptionPlans";
import useNonInitialEffect from "../../../helpers/useNonInitialEffect";
import { useHistory, withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    margin: 0,
    width: "calc(100vw - 44px)",
    position: "absolute",
    bottom: 0,
    left: 0,
    boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
    borderRadius: 0,
    padding: "16px 22px",
  },
  headerMainDiv: {
    borderBottom: "1px solid #C4C4C4",
    padding: "0 0 10px 0",
  },
  headerSubDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "4px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#07303E",
  },
  subTitle: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#07303E",
  },

  dialogContentText: {
    padding: "0",
    display: "flex",
    alignSelf: "center",
    padding: "23px 0",
    borderBottom: "1px solid #C4C4C4",
  },
  dialogButtons: {
    height: "57px",
    width: "57px",
    minWidth: "57px",
    borderRadius: "50%",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  dialogButtonsMarginRight: {
    marginRight: "15px",
  },
  dialogButtonsMarginLeft: {
    marginLeft: "15px",
  },
  field: {
    width: "100%",
    height: "73.88px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      height: "57px",
    },
  },
  input: {
    padding: "27.44px 14px",
    [theme.breakpoints.down("md")]: {
      padding: "19px 14px",
    },
  },
  inputRoot: {
    color: "#0C6885",
    userSelect: "none",
    textAlign: "center",
    fontSize: "32px",
    padding: 0,
    height: "57px",
    fontWeight: "600",
  },
  dialogActions: { flexDirection: "column", padding: "16px 0" },
  continueButton: {
    padding: "12px",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
    maginBottom: "12px",
    width: (props) => (props.upgradable ? "40%" : "87.35%"),
    lineHeight: 1.5,
  },
  subscribedUpgradeDiv: { display: "flex", paddingTop: "10.5px" },
  upgradeDiscription: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#07303E",
    marginRight: "4.5px",
    whiteSpace: "nowrap",
  },
  upgradetextButton: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#186985",
    textDecoration: "underline",
    cursor: "pointer",
  },
  countControll: {
    padding: 0,
    margin: "12px 0",
    display: "inline-flex",
    width: "96%",
    justifyContent: "center",
    position: "relative",
    left: "50%",
    marginLeft: "-47%",
    marginBottom: "10px",
    [theme.breakpoints.up("md")]: {
      padding: "10px 0",
    },
  },
  hr: {
    width: "92%",
    margin: "auto",
    border: "0px",
    height: "1px",
    background: "#c4c4c4",
    flexShrink: 0,
  },
}));

const SubscribedChargeCredits = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const history = useHistory();

  const {
    open,
    setOpen,
    freePremium,
    credits_info,
    listPlans,
    upgradable,
    setCustomProps,
    customProps,
    upgradeFromSubscribed,
    setUpgradeFromSubscribed,
  } = props;
  const [choosePlanOpen, setChoosePlanOpen] = useState(false);
  const [credits, setCredits] = useState(1);
  const [payment, setPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [chargeOptionsOpen, setChargeOptionsOpen] = useState({});
  const [plans, setPlans] = useState([]);
  const [isupgradable, setIsupgradable] = useState(false);
  const [refill, setRefill] = useState(false);
  const [plan, setPlan] = useState({
    title: "",
    plan_car_count: "",
    price_per_car: "",
    expiration_period: "",
    id: 0,
  });

  const handleChange = (event) => {
    setCredits(event.target.value);
  };

  useEffect(() => {
    if (upgradeFromSubscribed) {
      setChoosePlanOpen(true);
      setRefill(false);
      setIsupgradable(true);
      setPlan(selectedPlan);
    }
  }, [upgradeFromSubscribed]);

  useEffect(() => {
    listPlans({
      callback: (results) => {
        setPlans(results);
        // props.setPlans(results);
      },
    });
    // setPlan({
    //   plan_car_count: credits,
    //   price_per_car: credits_info.plan.price_per_car,
    //   expiration_period: credits_info.plan.expiration_period,
    //   title: credits_info.plan.title,
    //   id: credits_info.plan.id,
    //   markabte_advisor: false,
    //   free_history_report: false,
    // });
  }, []);

  useEffect(() => {
    // setPlan({
    //   plan_car_count: credits,
    //   price_per_car: credits_info.plan.price_per_car,
    //   expiration_period: credits_info.plan.expiration_period,
    //   title: credits_info.plan.title,
    //   id: credits_info.plan.id,
    //   markabte_advisor: false,
    //   free_history_report: false,
    // });
  }, [credits]);

  useEffect(() => {
    setPlan(selectedPlan);
  }, [selectedPlan]);
  return (
    <div>
      <MarkabtePopup
        title={t("Re-fill Credits")}
        action={t("Choose the number of cars below")}
        open={open}
        setOpen={setOpen}
        shrinkCard={true}
        sharePage={true}
        actionColor={"#000000"}
      >
        <div>
          <Divider className={classes.hr} />
          <div
            className={classes.countControll}
            style={{ display: "inline-flex" }}
          >
            <Button
              color="primary"
              variant="contained"
              className={clsx({
                [classes.dialogButtons]: 1,
                [classes.dialogButtonsMarginRight]: 1,
              })}
              disabled={credits == 1}
              onClick={() => setCredits(credits - 1)}
            >
              <img src={minus} />
            </Button>
            <TextField
              id="name"
              variant={"outlined"}
              onChange={handleChange}
              className={classes.field}
              inputProps={{
                classes: { input: classes.input },
                className: classes.inputRoot,
              }}
              value={credits}
              disabled
            />
            <Button
              color="primary"
              variant="contained"
              className={clsx({
                [classes.dialogButtons]: 1,
                [classes.dialogButtonsMarginLeft]: 1,
              })}
              onClick={() => setCredits(credits + 1)}
            >
              <img src={plus} />
            </Button>
          </div>
          <Divider className={classes.hr} />
        </div>
        <div
          style={{
            margin: "8px",
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          {upgradable && (
            <>
              <Hidden mdUp>
                <Button
                  variant="outlined"
                  color="primary"
                  // fullWidth
                  className={classes.continueButton}
                  onClick={() => {
                    setChoosePlanOpen(true);
                    setRefill(false);
                    setIsupgradable(true);
                    setPlan(selectedPlan);
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              </Hidden>
              <Hidden smDown>
                <Button
                  variant="outlined"
                  color="primary"
                  // fullWidth
                  className={classes.continueButton}
                  onClick={() => {
                    history.push({
                      pathname: "/subscription-plans",
                      state: {
                        plans: plans.filter((plan) => !plan.free_plan),
                        upgrade: true,
                        currentPlanId: credits_info.subscription_plan,
                      },
                    });
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              </Hidden>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            {...{ fullWidth: !freePremium }}
            className={classes.continueButton}
            onClick={() => {
              setPayment(true);
              setRefill(true);
              setIsupgradable(false);
              setPlan({
                plan_car_count: credits,
                price_per_car: credits_info.plan.price_per_car,
                expiration_period: credits_info.plan.expiration_period,
                title: credits_info.plan.title,
                id: credits_info.plan.id,
                markabte_advisor: false,
                free_history_report: false,
              });
            }}
          >
            {t("Continue")}
          </Button>
        </div>
      </MarkabtePopup>
      <PopupDrawer
        open={payment}
        // plan={plans.filter((plan) => plan.free_plan)}
        setOpen={setPayment}
        plan={plan}
        setChargeOptionsOpen={setOpen}
        refill={refill}
        selectedPlan={selectedPlan}
        setCredits={setCredits}
        isUpgrade={isupgradable}
      />
      <ChoosePlan // this is a copy of choose plan so make sure that you are using the correct choose plan
        open={choosePlanOpen}
        refillcloser={() => {
          setOpen(false);
          setUpgradeFromSubscribed && setUpgradeFromSubscribed(false);
        }}
        setChoosePlanOpen={setChoosePlanOpen}
        plans={plans.filter((plan) => !plan.free_plan)}
        setPlan={setPlan}
        selectedPlan={selectedPlan}
        setSelectedPlanOut={setSelectedPlan}
        setPopupDrawerOpen={setPayment} //setPopupDrawerOpen
        setChargeOptionsOpen={setChargeOptionsOpen}
        userPlan={credits_info.subscription_plan}
        isUpgrade={isupgradable}
      />
    </div>
  );
};

SubscribedChargeCredits.defaultProps = {
  open: true,
  freePremium: false,
  setOpen: () => {},
};

SubscribedChargeCredits.propTypes = {
  open: PropTypes.bool,
  freePremium: PropTypes.bool,
  setOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  credits_info: state.auth.credits_info,
});

const mapDispatchToProps = (dispatch) => ({
  listPlans(payload) {
    dispatch(listPlans(payload));
  },
});
// export default withRouter(PaymentSummary);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscribedChargeCredits)
);
