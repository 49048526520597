import React, { useEffect, useState } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
// import HeartEllipseIcon from "./icons/HeartEllipseIcon";
import HeartCircleIcon from "./icons/HeartCircleIcon";
import ShareEllipseIcon from "./icons/ShareEllipseIcon";
import MarkabtePopup from "./MarkabtePopup";

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import FacebookIcon from "./icons/FacebookIcon";
import WhatsappIcon from "./icons/WhatsappShareIcon";
import { Button, Hidden, TextField, Typography } from "@material-ui/core";
import EmailShare from "./icons/EmailShare";
import { distanceWithCommas } from "../helpers/functions";
const useStyles = makeStyles((theme) => ({
  mainDiv: {
    position: "absolute",
    display: "flex",
    top: "calc(100% - 10px);",
    left: "calc(100% - 19px);",
    // transform: " translate(-100%,-115%)",
    transform: " translate(-100%,-129%)", // check
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      // left: "100%",
      top: "calc(100% - -10px);",
      left: "calc(100% + 9px)", ////new
      // right: "+1.5%",
      transform: " translate(-100%,-75%)",
    },
  },
  shareIconButton: {
    padding: 0,
    marginRight: "19px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
    },
  },
  likeIconButton: {
    padding: 0,
  },
  socialButtons: {
    marginLeft: "30px",
  },
  rounded: {
    borderRadius: "5px",
    fontSize: "14px",
    border: "0",
  },
  clipboard: {
    width: "63%",
    background: "#F0F0F0",
    margin: "0 21px",
    [theme.breakpoints.down("sm")]: {
      width: "57%",
    },
  },
  shareContainer: {
    width: "100%",
    display: "inline-flex",
    margin: "25px 0 16px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
}));

const ImageActions = (props) => {
  const classes = useStyles();
  const { style, car_id, carCard } = props;
  const [open, setOpen] = useState(false);
  const [copied, setcopied] = useState(false);
  function copyToClipboard(textToCopy) {
    /* Get the text field */
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      setcopied(true);
      setTimeout(() => {
        setcopied(false);
      }, 5000);
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
        setcopied(true);
        setTimeout(() => {
          setcopied(false);
        }, 5000);
      });
    }
    /* Alert the copied text */
  }

  const { t, i18n } = useTranslation();

  return (
    <div
      className={classes.mainDiv}
      // style={{
      //   position: "absolute",
      //   top: "80.5%",
      //   left: "81%",
      //   transform: "translate(-100%,0)",
      // }}
    >
      <Hidden smDown>
        <IconButton
          onClick={() => {
            setOpen(true);
            document.getElementById("root").style.filter = "blur(16px)";
          }}
          className={clsx({
            [classes.shareIconButton]: true,
          })}
          style={{ boxShadow: "0px 4px 8px rgba(7, 48, 62, 0.15)" }}
          // onClick={/* share function */}
        >
          <ShareEllipseIcon size="43" fill={"#07303E"} />
        </IconButton>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
          className={clsx({
            [classes.shareIconButton]: true,
          })}
          style={{ boxShadow: "0px 4px 8px rgba(7, 48, 62, 0.15)" }}
          // onClick={/* share function */}
        >
          <ShareEllipseIcon size="43" fill={"#07303E"} />
        </IconButton>
      </Hidden>
      <IconButton
        className={clsx({
          [classes.likeIconButton]: true,
        })}
        // onClick={() => setSelected(!selected)}
        style={{ boxShadow: "0px 4px 8px rgba(7, 48, 62, 0.15)" }}
      >
        <HeartCircleIcon
          size="43"
          fill={"#07303E"}
          /*selected={selected}*/ selected={false}
        />
      </IconButton>
      <MarkabtePopup
        setOpen={setOpen}
        open={open}
        title={t("Share Car Listing")}
        sharePage={true}
        maxHeight="211px"
      >
        <div className={classes.shareContainer}>
          <TextField
            value={window.location.origin + "/car/" + car_id}
            // style={{ }}
            id="carlink"
            name="carlink"
            className={`${classes.rounded} ${classes.clipboard}`}
            fullWidth
            autoComplete="given-name"
            variant="outlined"
          />
          <Button
            variant={"contained"}
            // color="primary"
            className={classes.rounded}
            style={{
              width: "27%",
              background: "#186985",
              color: "#fff",
            }}
            onClick={() => {
              copyToClipboard(window.location.origin + "/car/" + car_id)
                .then(() => console.log("text copied !"))
                .catch(() => console.log("error"));
            }}
          >
            {t(copied ? "copied" : "Copy Link")}
            {copied && "!"}
          </Button>
        </div>
        <div style={{ margin: "16px 0 24px" }}>
          <span className={classes.socialButtons}>
            <FacebookShareButton
              url={window.location.origin + "/car/" + car_id}
              quote={`${t("Check")} ${carCard?.card?.year} ${
                carCard?.card?.make
              } ${carCard?.card?.model} : JO${
                carCard?.card?.price
              } - ${distanceWithCommas(carCard?.card?.milage)} , | ${t(
                "Markabte Search for Pre-inspected cars for free  on Markabte markabte.com"
              )}`}
              hashtage="#React"
            >
              {/* <FacebookIcon logoFillColor="white" round={true}></FacebookIcon> */}
              <FacebookIcon size={"36px"} fill="#186985" />
            </FacebookShareButton>
          </span>
          <span className={classes.socialButtons}>
            <WhatsappShareButton
              url={window.location.origin + "/car/" + car_id}
              title={`${t("Check")} ${carCard?.card?.year} ${
                carCard?.card?.make
              } ${carCard?.card?.model} : JO${
                carCard?.card?.price
              } - ${distanceWithCommas(carCard?.card?.milage)} , | ${t(
                "Markabte Search for Pre-inspected cars for free  on Markabte"
              )} markabte.com ${t("Checkout this car on markabte")} -`}
              // title={t("Checkout this car on markabte -")}
            >
              <WhatsappIcon size="36px" fill="#186985" />
            </WhatsappShareButton>
          </span>
          <span className={classes.socialButtons}>
            <EmailShareButton
              url={window.location.origin + "/car/" + car_id}
              title="markabte whatsapp"
            >
              <EmailShare size="36px" fill="#186985" />
            </EmailShareButton>
          </span>
        </div>
      </MarkabtePopup>
    </div>
  );
};

export default ImageActions;
