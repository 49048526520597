import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useParams } from "react-router-dom";
import useGeneralStyles from "./generalStyles";
function VHinfo(props) {
  const { t, mediumDown, CarInfo } = props;
  const classes = useGeneralStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        justify="space-between"
        classes={{ root: classes.headerwrap }}
      >
        <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
          <Typography
            style={
              localStorage.getItem("language") === "ar"
                ? { fontWeight: "700" }
                : { fontWeight: "500" }
            }
            className={classes.header}
            noWrap
          >
            {t("Vehicle Information")}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs>
            <Divider component="div" classes={{ root: classes.Divider }} />
          </Grid>
        </Hidden>
      </Grid>
      <ExpansionPanel
        defaultExpanded={true}
        disabled={true}
        style={{
          all: "unset",
          marginBottom: "32px",
        }}
      >
        <ExpansionPanelDetails
          className={classes.vehicleDetailsExpansionPanelDetails}
          classes={{ root: classes.vehicleDetailsExpansionPanelDetails }}
        >
          {/* {[destructuredGroups].length !== 0 */}
          {CarInfo.length !== 0
            ? CarInfo.sort((a, b) => {
                return a.field_tree_id - b.field_tree_id;
              }).map((field, i) => {
                let rowColor = !mediumDown
                  ? i % 2 === 0
                    ? "#BFD6DE"
                    : "white"
                  : i % 2 === 0
                  ? "#BFD6DE"
                  : "white";
                let gridColumn = 1; /* gridColumn used to be equal to (i % 2 === 0 ? 1 : 2) to seperate information into 2 columns*/
                return (
                  <List
                    style={{
                      padding: "0",
                      display: "grid",
                      gridColumn: gridColumn,
                    }}
                    defaultExpanded={i === 0}
                    key={`group_${i}`}
                  >
                    <div>
                      <ListItem
                        key={`group_field_${i}`}
                        className={classes.listItem}
                      >
                        <ListItemText
                          classes={{ root: classes.detailItem }}
                          style={{
                            backgroundColor: rowColor,
                            height: "100%",
                          }}
                        >
                          <Grid
                            container
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              item
                              xs={6}
                              className={classes.detailNameGrid}
                            >
                              <b
                                style={
                                  localStorage.getItem("language") === "ar"
                                    ? { fontWeight: "600" }
                                    : { fontWeight: "500" }
                                }
                                className={classes.detailName}
                              >
                                {field.field_title}
                              </b>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                padding: "4px 0",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <text
                                style={
                                  localStorage.getItem("language") === "ar"
                                    ? { fontWeight: "600" }
                                    : { fontWeight: "500" }
                                }
                                className={classes.detail}
                              >
                                <>
                                  <p>{field.answer_title}</p>
                                </>
                              </text>
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    </div>
                  </List>
                );
              })
            : [1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                return (
                  <Skeleton
                    variant="rect"
                    className={classes.vehicleInformationSkeleton}
                  />
                );
              })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Hidden mdUp>
        <Divider
          light
          style={{
            height: "9px",
            width: "calc(100% + 32px)",
            margin: "0 -16px",
          }}
        />
      </Hidden>
    </>
  );
}

export default VHinfo;
