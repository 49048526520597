import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  totalPriceDiv: {
    backgroundColor: "#E2F0F5",
    borderRadius: "3px",
    padding: "14px",
    justifyContent: "center",
    display: "grid",
    marginBottom: "16.4px",
    [theme.breakpoints.up("md")]: {
      padding: "16px",
      marginBottom: "21px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  paymentHeader: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#07303E",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  totalPrice: {
    fontSize: "27px",
    fontWeight: 700,
    color: "#07303E",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  expiration: {
    fontSize: "10px",
    fontWeight: 500,
    color: "#999999",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "11.25px",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
}));

const PaymentSummary = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { title, totalPrice, expiration } = props;

  return (
    <div className={classes.totalPriceDiv}>
      <Typography className={classes.paymentHeader}>
        {t("Total Package Fees")}
      </Typography>
      {console.log("rrrrrrree", title, totalPrice)}
      <Typography className={classes.totalPrice}>{`${t(totalPrice)} ${t(
        "JD"
      )}`}</Typography>
      {/* <Typography className={classes.expiration}>{`${t(
        "*Car Credit valid for"
      )} ${t(expiration)} ${t("months")}`}</Typography> */}
      <Typography className={classes.expiration}>
        {t("*Car Credits valid for 3 months")}
      </Typography>
      {/* change static month make it a function i gues 180 => 6 months (180 days) */}
    </div>
  );
};

PaymentSummary.defaultProps = {
  title: "One Time Purchase",
  totalPrice: 50,
  expiration: 3,
};

PaymentSummary.propTypes = {
  title: PropTypes.string,
  totalPrice: PropTypes.number,
  expiration: PropTypes.number,
};

export default PaymentSummary;
