import {
  GET_CITIES_REQUEST,
  GET_AREAS_REQUEST,
  GET_MAKE_REQUEST,
  GET_MODELS_REQUEST,
  GET_AVAILABLE_APPOINTMENTS,
  GET_YEARS,
  ADD_APPOINTMENT,
} from "../constants";

export function getCities(payload) {
  console.log("QQQ1", payload);
  return {
    type: GET_CITIES_REQUEST,
    payload: payload,
  };
}

export function getAreas(payload) {
  console.log("QQQ1", payload);
  return {
    type: GET_AREAS_REQUEST,
    payload: payload,
  };
}

export function getMake(payload) {
  console.log("QQQ1", payload);
  return {
    type: GET_MAKE_REQUEST,
    payload: payload,
  };
}

export function getModels(payload) {
  console.log("QQQ1", payload);
  return {
    type: GET_MODELS_REQUEST,
    payload: payload,
  };
}

export function getAvailableAppointments(payload) {
  console.log("QQQ1", payload);
  return {
    type: GET_AVAILABLE_APPOINTMENTS,
    payload: payload,
  };
}

export function getYears(payload) {
  return {
    type: GET_YEARS,
    payload: payload,
  };
}

export function addAppointment(payload) {
  return {
    type: ADD_APPOINTMENT,
    payload: payload,
  };
}
