import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import BackArrowTail from "../components/icons/BackArrowTail";
import HotSpot from "./HotSpot";
import Skeleton from "@material-ui/lab/Skeleton";

const drawerWidth = 346;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootOpen: {
    width: "100%",
    height: "100%",
    zIndex: "2100",
    position: "fixed",
    top: 0,
    left: 0,
  },
  landscapeIOSRootOpen: {
    width: "100%",
    height: "100%",
    zIndex: "2100",
    position: "fixed",
    top: "50%",
    transform: "translate(0,-50%)",
    left: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    borderRadius: "0 20px 20px 0",
    borderRight: "none",
  },
  landscapeIOSDrawerPaper: {
    width: drawerWidth,
    height: "100%",
    borderRadius: "0 20px 20px 0",
    borderRight: "none",
  },
  drawerPaperBig: {
    // width: "26.5517%",
    height: "100%",
    borderRight: "none",
    backgroundColor: "#F3F4FA",
    [theme.breakpoints.up("md")]: {
      width: "239.53px",
      height: "419.95px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "269.4px",
      height: "472.33px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "308px",
      height: "540px",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "12px 18px",
    backgroundColor: "#07303E",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      minHeight: "max-content",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
      minHeight: "26px",
    },
  },
  drawerHeaderTypography: {
    color: "white",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "17.18px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
  },
  drawerSubheaderTypography: {
    fontWeight: "600",
    color: "white",
    [theme.breakpoints.up("md")]: {
      fontSize: "10.93px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12.3px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  closedButton: {
    left: "3px",
    top: "50%",
  },
  majorMinorTypography: {
    color: "#07303E",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14.06px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15.82px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },
  majorMinorTypographySkeleton: {
    transform: "scale(1)",
    [theme.breakpoints.down("sm")]: {
      height: "18px",
      width: "103px",
    },
    [theme.breakpoints.up("md")]: {
      height: "21.09px",
      width: "121px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "23.73px",
      width: "136px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "27px",
      width: "155px",
    },
  },
  openButton: {
    left: "255px",
    top: "50%",
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedPortraitButton: {
    top: "3px",
  },
  openPortraitButton: {
    top: "255px",
    transition: theme.transitions.create(["top"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerViewName: {
    padding: "0 0 0 12px",
  },
  severityCountIcon: {
    borderRadius: "50%",
    margin: "auto 5px auto 0",
    width: "1rem",
    height: "1rem",
  },
  noIssuesTypography: {
    color: "#07303E",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "9.37px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "10.54px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "12px",
    },
  },
  drawerCloseIcon: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "9px",
    cursor: "pointer",
  },

  faultTitleTypography: {
    fontWeight: 500,
    borderRight: "1px solid #C4C4C4",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "9.37px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "10.54px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "12px",
    },
  },
  faultTypography: {
    whiteSpace: "normal",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "9.37px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "10.54px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "12px",
    },
  },
  majorMinorDiv: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "11.71px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "13.18px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "15px",
    },
  },
}));

const RenderFaults = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    sevirityFaults,
    sevirity,
    faultsLoaded,
    sharedSkeletonLoad,
    unlocked,
  } = props;
  return !sharedSkeletonLoad
    ? sevirityFaults.map((fault, faultIndex) => (
        <div style={faultIndex % 2 === 0 ? { backgroundColor: "#BFD6DE" } : {}}>
          <ListItem
            key={`fault_no_${fault.index}`}
            style={{ padding: "8px 5px" }}
          >
            <Grid
              container
              style={{
                justifyContent: "space-between",
                height: "inherit",
              }}
            >
              <Grid
                item
                xs={1}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ListItemIcon style={{ minWidth: "0" }}>
                  {/* div margin auto 0 and center true are only used in drawer and not on points */}
                  <div style={{ margin: "auto 0" }}>
                    <HotSpot
                      size={16.57}
                      score={sevirity === "minor" ? 100 : 0}
                      number={fault.index}
                      center={true}
                      unlocked={unlocked}
                    />
                  </div>
                </ListItemIcon>
              </Grid>

              <Grid item xs={5} style={{ margin: "auto" }}>
                <Typography className={classes.faultTitleTypography}>
                  {fault.title}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ margin: "auto" }}>
                <Typography
                  className={classes.faultTypography}
                  style={{
                    color: "#535353",
                  }}
                >
                  {lang == "en"
                    ? fault?.option_lang?.en
                    : fault?.option_lang?.ar}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </div>
      ))
    : [1, 2, 3].map(() => (
        <Skeleton
          variant="rect"
          style={{
            width: "100%",
            height: "30px",
            transform: "none",
            marginBottom: "30px",
          }}
        />
      ));
};
var lang = localStorage.getItem("language");
const DrawerLeft = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    images,
    isPortrait,
    side,
    faults,
    imgHeight,
    fromBigScreen,
    majorCounter,
    minorCounter,
    open,
    setOpen,
    isIOS,
    setFaultsLoaded,
    faultsLoaded,
    sharedSkeletonLoad,
    dontFit,
    scaleUp,
    unlocked,
    setTooltipStartButtonVisability,
    tooltipStartButtonVisability,
  } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTooltipStartButtonVisability &&
      setTooltipStartButtonVisability({
        ...tooltipStartButtonVisability,
        drawerOpened: false,
      });
  };
  let buttonTop = "";
  let buttonLeft = "";
  let buttontransform = "";
  let anchor = "";
  let buttonTransformOrigin = "";

  var tag = 0;
  isPortrait ? (buttonTop = "3px") : (buttonTop = "50%");
  isPortrait ? (buttonLeft = "50%") : (buttonLeft = "");
  isPortrait
    ? (buttontransform = "translate(-50%,-50%)")
    : theme.direction === "ltr"
    ? (buttontransform = "translate(-50%,-50%) rotate(-90deg)")
    : (buttontransform = "translate(50%,50%) rotate(90deg)");

  if (!isPortrait && theme.direction === "rtl") {
    buttonTransformOrigin = "top right";
  }

  !fromBigScreen && isPortrait ? (anchor = "top") : (anchor = "left");

  const [minorFaults, setMinorFaults] = useState(null);
  const [majorFaults, setMajorFaults] = useState(null);
  const firstLoad = useRef();
  useEffect(() => {
    let minorFaultsTemp = [];
    let majorFaultsTemp = [];
    let counter = 0;
    if (faults) {
      faults.map((fault, index) =>
        Object.keys(fault.condition.answer).map((key, idx) =>
          fault.condition.answer[key].map((element, i) => {
            if (element.with_image) {
              counter += 1;
              Object.assign(element, {
                title:
                  lang == "en"
                    ? fault?.condition?.title_lang?.en
                    : fault?.condition?.title_lang?.ar,
                index: counter,
              });
              element.option_score > 50
                ? minorFaultsTemp.push(element)
                : majorFaultsTemp.push(element);
            }
          })
        )
      );
    }

    if (firstLoad.current === true || true) {
      // so that at the first load where points is an empty array, minor and major faults are not set and the skeleton can load properly instead of "there where no maj/min faults in this view"
      setMajorFaults(majorFaultsTemp);
      setMinorFaults(minorFaultsTemp);
    }
    firstLoad.current = true;
  }, [faults]);

  useEffect(() => {
    if (minorFaults?.length !== undefined) {
      setFaultsLoaded(true);
    }
  }, [minorFaults]);

  return (
    <div
      id="issues"
      className={
        (classes.root,
        open &&
          (isIOS()
            ? isPortrait
              ? classes.rootOpen
              : classes.landscapeIOSRootOpen
            : classes.rootOpen))
      } // clsx could be used instead
      style={
        open && !fromBigScreen
          ? dontFit
            ? {
                backgroundColor: "rgba(0,0,0,0.8)",
                transform: `scale(${scaleUp})`,
              }
            : { backgroundColor: "rgba(0,0,0,0.8)" }
          : { width: "26.5517%" }
      }
    >
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: fromBigScreen
            ? classes.drawerPaperBig
            : isIOS()
            ? isPortrait
              ? classes.drawerPaper
              : classes.landscapeIOSDrawerPaper
            : classes.drawerPaper,
        }}
        style={
          !fromBigScreen && isPortrait
            ? theme.direction === "ltr"
              ? {
                  transform: "rotate(90deg)",
                  height: imgHeight,
                  transformOrigin: "top left",
                  position: "fixed",
                  left: "100%",
                  top: `${
                    dontFit
                      ? -(
                          window.innerHeight -
                          (window.innerWidth / 0.75 +
                            0.15625 * window.innerHeight)
                        ) / 2
                      : 0
                  }px`,
                  zIndex: open ? 2 : null,
                }
              : {
                  transform: "rotate(-90deg)",
                  height: imgHeight,
                  transformOrigin: "top right",
                  position: "fixed",
                  right: "100%",
                  top: `${
                    dontFit
                      ? -(
                          window.innerHeight -
                          (window.innerWidth / 0.75 +
                            0.15625 * window.innerHeight)
                        ) / 2
                      : 0
                  }px`,
                  zIndex: open ? 2 : null,
                }
            : {}
        }
      >
        <div className={classes.drawerHeader}>
          <Grid container>
            <Grid item xs={9} md={12} style={{ margin: "auto 0" }}>
              <Typography className={classes.drawerHeaderTypography}>
                {["dfv", "prf"].includes(side)
                  ? t("Exterior Issues")
                  : t("Interior Issues")}
              </Typography>
              <Typography className={classes.drawerSubheaderTypography}>
                {side === "dfv"
                  ? t("Driver Angle")
                  : side === "prf"
                  ? t("Passenger Angle")
                  : side === "fv"
                  ? t("Front Angle")
                  : side === "rf"
                  ? t("Rear Angle")
                  : side === "3rd"
                  ? t("3rd Row View")
                  : side === "tv"
                  ? t("Trunk")
                  : ""}
              </Typography>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={3} className={classes.drawerCloseIcon}>
                <CloseIcon
                  color="white"
                  style={{ color: "white" }}
                  onClick={handleDrawerClose}
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
        <div className={classes.majorMinorDiv}>
          {!sharedSkeletonLoad ? (
            <Typography className={classes.majorMinorTypography}>
              {/* {t("Major Issue")}: {majorCounter} */}
              {t("Major Issue")}: {majorFaults?.length}
            </Typography>
          ) : (
            <Skeleton className={classes.majorMinorTypographySkeleton} />
          )}
        </div>
        <List style={{ padding: 0 }}>
          {majorFaults && (
            <RenderFaults
              sevirityFaults={majorFaults}
              sevirity={"major"}
              faultsLoaded={faultsLoaded}
              sharedSkeletonLoad={sharedSkeletonLoad}
              unlocked={unlocked}
            />
          )}
          {!sharedSkeletonLoad && majorFaults?.length === 0 ? (
            <ListItem style={{ backgroundColor: "#BFD6DE" }}>
              <Typography
                className={classes.faultTypography}
                style={{ color: "#07303E" }}
              >
                {t("This Angle has no Major issue")}
              </Typography>
            </ListItem>
          ) : null}
        </List>
        <div className={classes.majorMinorDiv}>
          {!sharedSkeletonLoad ? (
            <Typography className={classes.majorMinorTypography}>
              {/* {t("Minor Issue")}: {minorCounter} */}
              {t("Minor Issue")}: {minorFaults?.length}
            </Typography>
          ) : (
            <Skeleton className={classes.majorMinorTypographySkeleton} />
          )}
        </div>
        <List style={{ padding: 0 }}>
          {minorFaults && (
            <RenderFaults
              sevirityFaults={minorFaults}
              sevirity={"minor"}
              faultsLoaded={faultsLoaded}
              sharedSkeletonLoad={sharedSkeletonLoad}
              unlocked={unlocked}
            />
          )}

          {!sharedSkeletonLoad && minorFaults?.length === 0 ? (
            <ListItem style={{ backgroundColor: "#BFD6DE" }}>
              <Typography
                className={classes.faultTypography}
                style={{ color: "#07303E" }}
              >
                {t("This Angle has no Minor Issue")}
              </Typography>
            </ListItem>
          ) : null}
        </List>
      </Drawer>
    </div>
  );
};

export default DrawerLeft;
