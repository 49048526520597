import React from "react";

const ShareEllipseIcon = (props) =>{
const {size, fill, style, className, selected, mobile} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 43 43" fill={fill} xmlns="http://www.w3.org/2000/svg">
<circle cx="21.5" cy="21.5" r="21.5" fill="white" fill-opacity={mobile?0:null}/>
<path 
    d="M27.7305 16.875C25.8358 16.875 24.293 15.3332 24.293 13.4375C24.293 11.5418 25.8358 10 27.7305 10C29.6253 10 31.168 11.5418 31.168 13.4375C31.168 15.3332 29.6253 16.875 27.7305 16.875ZM27.7305 11.375C26.5938 11.375 25.668 12.2998 25.668 13.4375C25.668 14.5752 26.5938 15.5 27.7305 15.5C28.8671 15.5 29.793 14.5752 29.793 13.4375C29.793 12.2998 28.8671 11.375 27.7305 11.375Z" 
    fill={mobile?"white":"#07303E"}
/>
<path 
    d="M27.7305 32C25.8358 32 24.293 30.4582 24.293 28.5625C24.293 26.6668 25.8358 25.125 27.7305 25.125C29.6253 25.125 31.168 26.6668 31.168 28.5625C31.168 30.4582 29.6253 32 27.7305 32ZM27.7305 26.5C26.5938 26.5 25.668 27.4248 25.668 28.5625C25.668 29.7002 26.5938 30.625 27.7305 30.625C28.8671 30.625 29.793 29.7002 29.793 28.5625C29.793 27.4248 28.8671 26.5 27.7305 26.5Z" 
    fill={mobile?"white":"#07303E"}
/>
<path 
    d="M14.4375 24.4375C12.5427 24.4375 11 22.8957 11 21C11 19.1043 12.5427 17.5625 14.4375 17.5625C16.3323 17.5625 17.875 19.1043 17.875 21C17.875 22.8957 16.3323 24.4375 14.4375 24.4375ZM14.4375 18.9375C13.3008 18.9375 12.375 19.8623 12.375 21C12.375 22.1377 13.3008 23.0625 14.4375 23.0625C15.5742 23.0625 16.5 22.1377 16.5 21C16.5 19.8623 15.5742 18.9375 14.4375 18.9375Z" 
    fill={mobile?"white":"#07303E"}
/>
<path 
    d="M16.8326 20.3302C16.5933 20.3302 16.3605 20.2055 16.2349 19.9838C16.0469 19.6538 16.1616 19.2339 16.4916 19.046L24.9974 14.1968C25.3263 14.0071 25.7471 14.1226 25.9341 14.4536C26.1221 14.7836 26.0075 15.2034 25.6775 15.3913L17.1717 20.2404C17.0644 20.3009 16.9481 20.3302 16.8326 20.3302Z" 
    fill={mobile?"white":"#07303E"}
/>
<path 
    d="M25.3365 27.8932C25.221 27.8932 25.1045 27.8638 24.9973 27.8026L16.4915 22.9533C16.1615 22.7655 16.047 22.3455 16.2348 22.0155C16.4218 21.6847 16.8418 21.5701 17.1718 21.7589L25.6774 26.608C26.0074 26.796 26.122 27.2158 25.9342 27.5458C25.8076 27.7685 25.5748 27.8932 25.3365 27.8932Z" 
    fill={mobile?"white":"#07303E"}
/>
</svg>
);
};

export default ShareEllipseIcon;
