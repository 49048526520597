import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import {
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getCities,
  getAreas,
  getMake,
  getModels,
  getAvailableAppointments,
  getYears,
  addAppointment,
} from "../store/actions/timeSlots";
import backEndDateFormat from "../helpers/backEndDateFormat";

const initialFormState = {
  city: "",
  area: {},
  carMake: "",
  carModel: "",
  year: "",
  date: "",
  timeSlot: null,
  sellerName: "",
  sellerPhone: "",
  buyerName: "",
  buyerPhone: "",
};

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    // marginTop: -12,
    // marginLeft: -12,
  },
}));
const AppiontmentForm = (props) => {
  const {
    getCities,
    cities,
    areas,
    getAreas,
    getMake,
    getModels,
    make,
    models,
    getAvailableAppointments,
    getYears,
    years,
    available_appointments,
    addAppointment,
  } = props;
  const { t } = useTranslation();
  const [formState, setFormState] = useState(initialFormState);
  const [tempAreaGroup, setTempAreaGroup] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const afterTomorrow = new Date(today);
  afterTomorrow.setDate(today.getDate() + 2);
  const {
    city,
    area,
    carMake,
    carModel,
    year,
    canBeInspected,
    date,
    time,
    buyerName,
    buyerPhone,
    sellerName,
    sellerPhone,
  } = formState;

  useEffect(() => {
    setTempAreaGroup(area.area_group_name);
  }, [formState.area]);

  useEffect(() => {
    console.log("freqwfd", formState);
    setIsFormValid(
      city !== "" &&
        Object.keys(area).length !== 0 &&
        carMake !== "" &&
        carModel !== "" &&
        year !== "" &&
        date !== "" &&
        time !== "" &&
        buyerName !== "" &&
        buyerPhone !== "" &&
        sellerName !== "" &&
        sellerPhone !== ""
    );
  }, [formState]);

  useEffect(() => {
    getCities();
    getAreas();
    getMake();
    getModels();
    getAvailableAppointments();
    getYears();
  }, []);

  const handleChangeCity = (event) => {
    const { name, value } = event.target;
    console.log("freewwq", formState.timeSlot);
    // if (formState.timeSlot == null && name == "date") {
    //   setError(true);
    // } else if (name == "date") {
    //   setError(false);
    // }
    console.log("vfvfdddd", event.target);
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(formState);
    // addAppointment(formState);
    // setLoading(false);
    addAppointment({
      ...formState,
      callback: () => {
        setLoading(false);
      },
    });
  };

  return (
    <div
      style={{
        display: "inline-grid",
        width: "50vw",
        marginInline: "25%",
        marginBlock: "25px",
        border: "solid gray",
        padding: "15px",
      }}
    >
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{t("City")}</InputLabel>
        <Select name="city" value={formState.city} onChange={handleChangeCity}>
          {cities?.map((city) => (
            <MenuItem key={city.city_id} value={city.city_name.toLowerCase()}>
              {city.city_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        disabled={formState.city == "" ? true : false}
        className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-label">{t("Area")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="area"
          onChange={handleChangeCity}
        >
          {areas
            ?.filter(
              (area) => area.city.toLowerCase() == formState.city.toLowerCase()
            )
            ?.map((area) => (
              <MenuItem key={area.id} value={area}>
                {area.region_name}
              </MenuItem>
            ))}
        </Select>
        {/* {error && (
          <FormHelperText>
            This area is not available for the next three days.
          </FormHelperText>
        )} */}
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{t("Make")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          // onChange={handleChange}
          name="carMake"
          value={formState.carMake}
          onChange={handleChangeCity}
        >
          {make?.map((make) => (
            <MenuItem value={make.id}>{make.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={classes.formControl}
        disabled={formState.carMake == "" ? true : false}
      >
        <InputLabel id="demo-simple-select-label">{t("Model")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          onChange={handleChangeCity}
          value={formState.carModel}
          name="carModel"
        >
          {models
            ?.filter((model) => model.make == formState.carMake)
            ?.map((model) => (
              <MenuItem value={model.id}>{model.title_en}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{t("Year")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formState.year}
          name="year"
          onChange={handleChangeCity}
        >
          {years?.map((year) => (
            <MenuItem value={year.title}>{year.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{t("Date")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formState.date}
          name="date"
          onChange={handleChangeCity}
        >
          <MenuItem value={backEndDateFormat(today)}>
            {today.toLocaleDateString("en-GB")}
          </MenuItem>
          <MenuItem value={backEndDateFormat(tomorrow)}>
            {tomorrow.toLocaleDateString("en-GB")}
          </MenuItem>
          <MenuItem value={backEndDateFormat(afterTomorrow)}>
            {afterTomorrow.toLocaleDateString("en-GB")}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{t("Time")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formState.timeSlot}
          name="timeSlot"
          onChange={handleChangeCity}
          // disabled={formState.timeSlot == null}
        >
          {console.log(
            "fffree",
            available_appointments?.filter(
              (el) => el["city"] == formState.city
            )["slots"]
          )}
          {available_appointments
            ?.filter(
              (city) => city.city.toLowerCase() === formState.city.toLowerCase()
            )
            ?.flatMap((city) =>
              city.slots
                ?.filter((area) => area.area_group === tempAreaGroup)
                ?.flatMap((area) =>
                  area.slots
                    ?.filter(
                      (slot) =>
                        slot.count !== 0 && slot.booked_on == formState.date
                    ) /// here!!
                    .map((slot) => (
                      <MenuItem value={slot}>{slot.time_slot}</MenuItem>
                    ))
                )
            )}
        </Select>
      </FormControl>
      <TextField
        value={formState.buyerName}
        name="buyerName"
        onChange={handleChangeCity}
        label={t("Buyer Name")}
      />
      <TextField
        value={formState.buyerPhone}
        name="buyerPhone"
        onChange={handleChangeCity}
        label={t("Buyer Phone Number")}
      />
      <TextField
        value={formState.sellerName}
        name="sellerName"
        onChange={handleChangeCity}
        label={t("Seller Name")}
      />
      <TextField
        value={formState.sellerPhone}
        name="sellerPhone"
        onChange={handleChangeCity}
        label={t("Seller Phone Number")}
      />
      <div
        style={{
          position: "relative",
        }}
      >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          style={{
            marginTop: "20px",
          }}
          // className={classes.applyButton}
          disabled={!isFormValid || loading}
          onClick={handleSubmit}
        >
          <Typography className={classes.applyTypography}>
            {t("Book now!")}
          </Typography>
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cities: state.timeSlots.cities,
  areas: state.timeSlots.areas,
  make: state.timeSlots.make,
  models: state.timeSlots.models,
  years: state.timeSlots.years,
  available_appointments: state.timeSlots.available_appointments,
});

const mapDispatchToProps = (dispatch) => ({
  getCities(payload) {
    dispatch(getCities(payload));
  },
  getAreas(payload) {
    dispatch(getAreas(payload));
  },
  getMake(payload) {
    dispatch(getMake(payload));
  },
  getModels(payload) {
    dispatch(getModels(payload));
  },
  getAvailableAppointments(payload) {
    dispatch(getAvailableAppointments(payload));
  },
  getYears(payload) {
    dispatch(getYears(payload));
  },
  addAppointment(payload) {
    dispatch(addAppointment(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppiontmentForm)
);
