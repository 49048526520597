import React from "react";

const FacebookIcon = (props) => {
  const { size, fill, style } = props;
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M31.7838 0.5H4.54054C2.03568 0.5 0 2.53568 0 5.04054V32.2838C0 34.7871 2.03568 36.8243 4.54054 36.8243H31.7838C34.2871 36.8243 36.3243 34.7871 36.3243 32.2838V5.04054C36.3243 2.53568 34.2871 0.5 31.7838 0.5Z"
          fill="#3B5999"
        />
        <path
          d="M24.9729 18.6622V14.1216C24.9729 12.8685 25.99 12.9865 27.2432 12.9865H29.5135V7.31083H24.9729C21.2103 7.31083 18.1621 10.359 18.1621 14.1216V18.6622H13.6216V24.3379H18.1621V36.8243H24.9729V24.3379H28.3783L30.6486 18.6622H24.9729Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="36.3243"
            height="36.3243"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
