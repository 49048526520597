import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavState } from "../../components/CarInfo/SideryComponents";
import { withRouter, useParams } from "react-router";
import useGeneralStyles from "../../components/CarInfo/generalStyles";
import { useEffect } from "react";
import { simpleCarData, VHCarInfo } from "../../store/actions/car";
import GeneralCarInfoContainer from "../../components/CarInfo/GeneralCarInfoContainer";
import LoadingIcon from "../../components/icons/LoadingSceenWithDots.gif";
// const GeneralCarInfoContainer = React.lazy(() =>
// import("../../components/CarInfo/GeneralCarInfoContainer")
// );

function CarInformation(props) {
  const {
    history,
    car_info,
    mainRef,
    hideAppBar,
    simpleCarData,
    VHCarInfo,
    sellerReport,
    showAppBar,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const [carData, setCarData] = useState({});

  useEffect(() => {
    simpleCarData({
      carId: params.id,
      callback: (response) => {
        setCarData(response);
      },
    });
  }, []);

  return (
    <div>
      <NavState
        t={t}
        carFace=""
        history={history}
        car_info={car_info}
        carData={carData}
      />
      {/* <Suspense
        fallback={
          <div
            style={{
              width: "100vw",
              height: "90vh",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LoadingIcon} />
          </div>
        }
      > */}
      <GeneralCarInfoContainer
        carId={params.id}
        hideAppBar={hideAppBar}
        t={t}
        mainRef={mainRef}
        carData={carData}
        showAppBar={showAppBar}
        sellerReport={sellerReport}
      />
      {/* </Suspense> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  car_info: state.car.car,
});

const mapDispatchToProps = (dispatch) => ({
  simpleCarData(payload) {
    dispatch(simpleCarData(payload));
  },
  VHCarInfo(payload) {
    dispatch(VHCarInfo(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarInformation)
);
