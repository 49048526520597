import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Drawer,
  Paper,
  Hidden,
  Typography,
  Button,
  Slide,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import PaymentBill from "./PaymentBill";
import { connect } from "react-redux";
import PaymentMethod from "./PaymentMethod";
import ChoosePlan from "./Free/ChoosePlan";
import SubscriptionPlans from "./SubscriptionPlans";
import moment from "moment";
import SubscribedChargeCredits from "./Subscribed/SubscribedChargeCredits";
import { subscribePlan } from "../../store/actions/auth";
import { withRouter } from "react-router-dom";
import CardInfo from "./CardInfo";
import CheckoutSessionComponent from "../CheckoutSessionComponent";
// import ChargeOptions from "./Free/ChargeOptions";

const useStyles = makeStyles((theme) => ({
  mainDiv: {},
  dialogPaper: {
    padding: "24px 16px",
    height: "calc(100% - 48px)",
  },
  dialogActions: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "calc(100% - 32px)",
    backgroundColor: "white",
    boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
    padding: "3.5px 16px",
  },
  drawerRoot: {
    overflow: "scroll",
  },
  drawerPaper: {
    padding: "32px",
    width: "336px",
    // position: "absolute", // check if causes problems (it was removed because of drawer over shooting it's final position but it was origionaly added for either no scroll in origional position fixed or not being properly positioned from right and bottom maybe was solved in later css changes)
    height: "calc(100% - 64px)",
  },
  buttons: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1,
    textTransform: "none",
    padding: "13px",
    margin: "6.5px 0",
    borderRadius: "5.625px",
  },
  buttonsDiv: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0",
    },
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const PopupDrawer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    open,
    setOpen,
    plan,
    subscribePlan,
    user,
    setChargeOptionsOpen,
    history,
    refill,
    isUpgrade,
    setCredits,
    selectedPlan,
    credits_info,
    // chargeOptionsProps,
    setInterestedState,
    setFirstPopup,
  } = props;

  const [drawerTab, setDrawerTab] = useState(0);
  const [pay, setPay] = useState(false);

  useEffect(() => {
    open == true
      ? (document.getElementById("root").style.filter = "blur(12px)")
      : (document.getElementById("root").style.filter = "none");
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setDrawerTab(0);
  };

  const handleCompletion = () => {
    setLoading(false);
    setValidation({
      nameOnCard: null,
      number: null,
      expiryMonth: null,
      expiryYear: null,
      securityCode: null,
    });
  };

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [renderPay, setRenderPay] = useState(false);
  const [validation, setValidation] = useState({
    nameOnCard: null,
    number: null,
    expiryMonth: null,
    expiryYear: null,
    securityCode: null,
  });

  useEffect(() => {
    console.log("aaaaaaaaaaaaaaaa", validation);
  }, [validation]);

  const subscribePlanFunction = () => {
    subscribePlan({
      plan: plan,
      user_id: user.user_id,
      refill: refill || false,
      message:
        localStorage.getItem("language") == "en"
          ? plan?.plan_car_count > 1
            ? plan?.plan_car_count +
              " " +
              "Credits have been added to your balance."
            : "1 Credit has been added to your balance."
          : `تم اضافة ${plan?.plan_car_count} رصيد مركبات الى رصيدك`,
      callback: (response) => {
        if (response.success) {
          if (window.location.href.includes("/subscription-plans")) {
            window.history.back();
            setCredits && setCredits(0);
            setOpen(false);
            setChargeOptionsOpen && setChargeOptionsOpen(false);
          }
        }
        setOpen(false);
        setChargeOptionsOpen && setChargeOptionsOpen(false);
      },
    });
  };

  return (
    <div className={classes.mainDiv}>
      <Hidden mdUp>
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen
          classes={{ paperFullScreen: classes.dialogPaper }}
        >
          {drawerTab == 0 ? (
            <>
              {console.log("151522s", selectedPlan, plan)}
              <PaymentBill
                withSummary
                handleClose={handleClose}
                planType={plan?.title}
                noOfCars={plan?.plan_car_count}
                pricePerCar={plan?.price_per_car}
                summaryProps={{
                  title:
                    refill && !isUpgrade
                      ? t("Re-fill Fees")
                      : `${plan?.title} ${t("Plan")}`,
                  totalPrice: plan?.plan_car_count * plan?.price_per_car,
                  expiration: plan?.expiration_period?.split(" ")[0] / 30, // fix later create a function
                }}
              />
              {/* <PaymentMethod setSelectedMethodGetter={setSelectedMethod} /> */}
              <DialogActions className={classes.dialogActions}>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  fullWidth
                  // disabled={selectedMethod == null}
                  onClick={() => {
                    // subscribePlan({
                    //   plan: plan,
                    //   user_id: user.user_id,
                    //   refill: refill || false,
                    //   message: isUpgrade
                    //     ? t("Plan upgraded successfully.")
                    //     : refill || plan.id == 1
                    //     ? t("1 car credit has been added to your balance.")
                    //     : `${t("Subscribed to")} ${
                    //         localStorage.getItem("language") == "en"
                    //           ? `${plan.title}`
                    //           : `${plan.title_ar}`
                    //       } ${t("plan successfully!")}`,
                    //   callback: (response) => {
                    //     refill && setCredits && setCredits(1);
                    //     setOpen(false);
                    //     setChargeOptionsOpen && setChargeOptionsOpen(false);
                    //   },
                    // });
                    // setDrawerTab(1);
                    setOpen(false);
                    setFirstPopup(false);
                    setRenderPay(true);
                  }}
                >
                  {isUpgrade
                    ? t("Upgrade Plan")
                    : refill
                    ? t("Re-fill Credits")
                    : t("Continue to Payment")}
                </Button>
              </DialogActions>
            </>
          ) : (
            <div>
              <CardInfo
                handleCompletion={handleCompletion}
                validation={validation}
                setValidation={setValidation}
                setLoading={setLoading}
                // handleClose={() => setDrawerTab(2)}
                handleClose={() => {
                  setDrawerTab(1);
                  setFirstPopup(false);
                  setInterestedState(false);
                  setOpen(false);
                }}
                validityPeriod={plan?.expiration_period?.split(" ")[0] / 30}
                amount={plan?.plan_car_count * plan?.price_per_car}
                pay={pay}
                plan={plan}
                refill={refill}
                setCredits={setCredits}
                setOpen={setOpen}
                setChargeOptionsOpen={setChargeOptionsOpen}
                setDrawerTab={setDrawerTab}
                setInterestedState={setInterestedState}
                setFirstPopup={setFirstPopup}
              />

              <div className={classes.buttonsDiv}>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    marginBottom: "16.44px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.buttons}
                    disabled={
                      loading ||
                      !Object.values(validation).every((value) => value)
                    }
                    onClick={() => {
                      setLoading(true);

                      setPay((prev) => {
                        return !prev;
                      });
                    }}
                    // onClick={() => {
                    //   subscribePlan({
                    //     plan: plan,
                    //     user_id: user.user_id,
                    //     refill: refill || false,
                    //     message:
                    //       localStorage.getItem("language") == "en"
                    //         ? plan?.plan_car_count > 1
                    //           ? plan?.plan_car_count +
                    //             " " +
                    //             "Credits have been added to your balance."
                    //           : "1 Credit has been added to your balance."
                    //         : `تم اضافة ${plan?.plan_car_count} رصيد مركبات الى رصيدك`,
                    //     callback: (response) => {
                    //       if (response.success) {
                    //         if (
                    //           window.location.href.includes("/subscription-plans")
                    //         ) {
                    //           window.history.back();
                    //           setCredits && setCredits(0);
                    //           setOpen(false);
                    //           setChargeOptionsOpen && setChargeOptionsOpen(false);
                    //         }
                    //       }
                    //       setOpen(false);
                    //       setChargeOptionsOpen && setChargeOptionsOpen(false);
                    //     },
                    //   });
                    //   setDrawerTab(0);
                    //   setInterestedState(false);
                    //   setFirstPopup(false);
                    // }}
                  >
                    {t("Pay")}
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <Hidden smDown>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleClose}
                    className={classes.buttons}
                  >
                    {t("Back")}
                  </Button>
                </Hidden>
              </div>
            </div>
          )}
        </Dialog>
      </Hidden>
      <Hidden smDown>
        <Drawer
          open={open}
          anchor="right"
          variant="temporary"
          className={classes.drawerRoot}
          classes={{ paper: classes.drawerPaper }}
        >
          {/* {drawerTab == 0 && (
            <Slide
              //  direction={value == 0 ? "right" : "left"}
              direction={"left"}
              in={drawerTab == 0}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <ChargeOptions {...chargeOptionsProps} />
              </div>
            </Slide>
          )} */}
          {/* {drawerTab == 1 && ( */}
          {drawerTab == 0 && (
            <Slide
              //  direction={value == 0 ? "right" : "left"}
              direction={"right"}
              // in={drawerTab == 1}
              in={drawerTab == 0}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <PaymentBill
                  withSummary
                  handleClose={handleClose}
                  planType={plan?.title}
                  noOfCars={plan?.plan_car_count}
                  pricePerCar={plan?.price_per_car}
                  summaryProps={{
                    title: `${plan?.title} ${t("Plan")}`,
                    totalPrice: plan?.plan_car_count * plan?.price_per_car,
                    expiration: plan?.expiration_period?.split(" ")[0] / 30, // fix later create a function
                  }}
                />
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    // setDrawerTab(1);
                    setOpen(false);
                    setRenderPay(true);
                  }}
                >
                  {t("Continue to Payment")}
                </Button>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleClose}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Slide>
          )}
          {/* {drawerTab == 2 && ( */}
          {drawerTab == 1 && (
            <Slide
              //  direction={value == 0 ? "right" : "left"}
              direction={"left"}
              in={drawerTab == 1}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <PaymentMethod
                  withSummary
                  handleClose={handleClose}
                  setSelectedMethodGetter={setSelectedMethod}
                  summaryProps={{
                    title: `${plan?.title} ${t("Plan")}`,
                    totalPrice: plan?.plan_car_count * plan?.price_per_car,
                    expiration: plan?.expiration_period?.split(" ")[0] / 30, // fix later create a function
                  }}
                />
                {/* //////////// */}
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={selectedMethod == null}
                  onClick={() => {
                    // setDrawerTab(3);
                    setDrawerTab(2);
                  }}
                  // onClick={() => {
                  //   subscribePlan({
                  //     plan: plan,
                  //     user_id: user.user_id,
                  //     refill: refill || false,
                  //     message: isUpgrade
                  //       ? t("Plan upgraded successfully.")
                  //       : refill
                  //       ? t("1 car credit has been added to your balance.")
                  //       : `${t("Subscribed to")} ${
                  //           localStorage.getItem("language") == "en"
                  //             ? `${plan.title}`
                  //             : `${plan.title_ar}`
                  //         } ${t("plan successfully!")}`,
                  //     callback: (response) => {
                  //       if (response.success) {
                  //         if (
                  //           window.location.href.includes("/subscription-plans")
                  //         ) {
                  //           window.history.back();
                  //           setCredits && setCredits(0);
                  //           setOpen(false);
                  //           setChargeOptionsOpen && setChargeOptionsOpen(false);
                  //         }
                  //       }
                  //       setOpen(false);
                  //       setChargeOptionsOpen && setChargeOptionsOpen(false);
                  //     },
                  //   });
                  //   setDrawerTab(1);
                  // }}
                >
                  {isUpgrade
                    ? t("Upgrade Plan")
                    : refill
                    ? t("Re-fill Credits")
                    : t("Continue")}
                </Button>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  // onClick={handleClose}
                  // onClick={() => setDrawerTab(1)}
                  onClick={() => setDrawerTab(0)}
                >
                  {t("Back")}
                </Button>
              </div>
            </Slide>
          )}
          {/* {drawerTab == 3 && ( */}
          {drawerTab == 2 && (
            <Slide
              //  direction={value == 0 ? "right" : "left"}
              direction={"left"}
              // in={drawerTab == 3}
              in={drawerTab == 2}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <CardInfo
                  handleCompletion={handleCompletion}
                  validation={validation}
                  setValidation={setValidation}
                  setLoading={setLoading}
                  // handleClose={() => setDrawerTab(2)}
                  handleClose={() => {
                    setDrawerTab(1);
                    setFirstPopup(false);
                    setInterestedState(false);
                    setOpen(false);
                  }}
                  validityPeriod={plan?.expiration_period?.split(" ")[0] / 30}
                  amount={plan?.plan_car_count * plan?.price_per_car}
                  pay={pay}
                  plan={plan}
                  refill={refill}
                  setCredits={setCredits}
                  setOpen={setOpen}
                  setChargeOptionsOpen={setChargeOptionsOpen}
                  setDrawerTab={setDrawerTab}
                  setInterestedState={setInterestedState}
                  setFirstPopup={setFirstPopup}
                />
                <div className={classes.buttonsDiv}>
                  <div
                    style={{
                      display: "grid",
                      position: "relative",
                      marginBottom: "16.44px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.buttons}
                      disabled={
                        loading ||
                        !Object.values(validation).every((value) => value)
                      }
                      onClick={() => {
                        setLoading(true);
                        setPay((prev) => {
                          return !prev;
                        });
                      }}
                      //plan,refill,setCredits,setOpen,setChargeOptionsOpen,setDrawerTab,setInterestedState,setFirstPopup
                      // onClick={() => {
                      //   subscribePlan({
                      //     plan: plan,
                      //     user_id: user.user_id,
                      //     refill: refill || false,
                      //     message:
                      //       localStorage.getItem("language") == "en"
                      //         ? plan?.plan_car_count > 1
                      //           ? plan?.plan_car_count +
                      //             " " +
                      //             "Credits have been added to your balance."
                      //           : "1 Credit has been added to your balance."
                      //         : `تم اضافة ${plan?.plan_car_count} رصيد مركبات الى رصيدك`,
                      //     callback: (response) => {
                      //       if (response.success) {
                      //         if (
                      //           window.location.href.includes(
                      //             "/subscription-plans"
                      //           )
                      //         ) {
                      //           window.history.back();
                      //           setCredits && setCredits(0);
                      //           setOpen(false);
                      //           setChargeOptionsOpen &&
                      //             setChargeOptionsOpen(false);
                      //         }
                      //       }
                      //       setOpen(false);
                      //       setChargeOptionsOpen && setChargeOptionsOpen(false);
                      //     },
                      //   });
                      //   setDrawerTab(0);
                      //   setInterestedState(false);
                      //   setFirstPopup(false);
                      // }}
                    >
                      {t("Pay")}
                    </Button>

                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Hidden smDown>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleClose}
                      className={classes.buttons}
                    >
                      {t("Back")}
                    </Button>
                  </Hidden>
                </div>
              </div>
            </Slide>
          )}
        </Drawer>
      </Hidden>
      {renderPay && (
        <CheckoutSessionComponent
          description={plan?.title_en}
          amount={plan?.plan_car_count * plan?.price_per_car}
          setRenderPay={setRenderPay}
          subscribePlanFunction={subscribePlanFunction}
        />
      )}
    </div>
  );
};

PopupDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
  plan: {},
  setFirstPopup: () => {},
  setInterestedState: () => {},
};

PopupDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  plan: PropTypes.object,
  setFirstPopup: PropTypes.func,
  setInterestedState: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  unlocked: state.car.car.unlocked,
  credits_info: state.auth.credits_info,
  sold: state.car.car.is_sold,
  carid: state.car.car.id,
  car: state.car.car,
});

const mapDispatchToProps = (dispatch) => ({
  subscribePlan(payload) {
    dispatch(subscribePlan(payload));
  },
});
// export default withRouter(PaymentSummary);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PopupDrawer)
);
