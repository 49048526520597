import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Score from "./ScorePrototype";

import DTC from "../icons/DTC.svg";
import Frame from "../icons/Frame.svg";
import HybridBattery from "../icons/HybridBattery.svg";

import theme from "../theme";
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ReportSummaryCalendar from "../icons/ReportSummaryCalendar.svg";
import Locked from "./icons/locked";
import ClickableAnswerDialog from "./ClickableAnswerDialog";
import Moment from "moment";
import { logVisitorView } from "../store/actions/accounts";
import { connect } from "react-redux";
import ShowFull from "../icons/ShowFull.svg";

const useStyles = makeStyles((theme) => ({
  smPapers: {
    borderRadius: "0",
    boxShadow: "unset",
    margin: "24px 012",
    [theme.breakpoints.up("md")]: {
      all: "initial",
      width: "100%",
    },
  },
  header: {
    whiteSpace: "nowrap", /// check this
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
      color: "#186985",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#186985",
      padding: "0",
      margin: "34px 0",
    },
  },
  reportIcons: {
    height: "15px",
    width: "15px",
    margin: "0 5px 0 0",
    [theme.breakpoints.up("md")]: {
      height: "clamp(15px,2vw,24px)",
      width: "clamp(15px,2vw,24px)",
    },
  },
  inspDetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#636363",
    fontSize: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
  },
  noOfPoints: {
    fontSize: "12px",
    fontWeight: "500",
    color: "white",
    marginRight: "3px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  },
  conditionReportHr: {
    height: "1px",
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, #0c6885 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.up("md")]: {
      margin: "32px 0 30px",
      backgroundImage:
        "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    },
  },
  titleDiv: {
    margin: "0 -15px",
    padding: "0 15px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
    },
  },
  reportSummaryDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  reportTitleGrid: {
    alignItems: "center",
    height: "50px",
    [theme.breakpoints.up("md")]: {
      height: "39px",
    },
  },
  reportSummaryText: {
    color: "white",
    fontSize: "12px",
    fontWeight: "500",
    paddingLeft: "18px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      alignSelf: "center",
      paddingLeft: "20px",
    },
  },
  visualScoreGrid: {
    backgroundColor: "white",
    padding: "0 10px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "15px 0px 20px",
      borderBottom: "1px solid #CDCDCD",
    },
  },
  groupScore: {
    color: "#07303E",
    fontSize: "12px", // 11
    fontWeight: "600",
    [theme.breakpoints.up("md")]: {
      fontSize: "12px", // 12
    },
  },
  groupPoints: {
    color: "#626465",
    fontSize: "12px", // 11
    fontWeight: "400",
    // paddingRight: "4px",
    width: "70px",
    borderRight: "1px solid #C4C4C4",
    marginRight: "4px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px", // 12
    },
  },
  groupTitle: {
    color: "#07303E",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  Divider: {
    height: 1,
    backgroundColor: "#D2D2D2",
    marginLeft: "24px",
  },
  headerWrap: {
    margin: "13px 0",
    [theme.breakpoints.up("md")]: {
      margin: "34px 0",
    },
  },
  reportPaper: {
    // paddingBottom: "23.5px",
    background: "#07303E",
    borderRadius: "8.3px",
    margin: "0",
    marginBottom: "15px",
    [theme.breakpoints.up("md")]: {
      background: "#07303E",
      borderRadius: "16px",
      // paddingBottom: "25px",
    },
  },
  reportSummarySubInfo: {
    // marginBottom: "-3px",
    borderBottom: "2px solid #CDCDCD",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    background: "#F9FAFD",
    [theme.breakpoints.up("md")]: {
      marginBottom: "-1px",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      background: "#F9FAFD",
    },
  },

  subInfoMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "22px 0",
      // width: "auto",
      margin: "0px 12.5px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "16px 20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "19px 24px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "23px 28.5px",
    },
  },
  dateMainDiv: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      // borderBottom: "1px solid #C4C4C4",
      // paddingBottom: "9px",
      // marginBottom: "16px",
      justifyContent: "center",
    },
  },
  calendarIcon: {
    marginRight: "6px",
    alignSelf: "baseline",
    [theme.breakpoints.down("sm")]: {
      // marginRight: "9px",
      // width: "18px",
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      width: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "18px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "21.34px",
    },
  },
  dateTypographyDiv: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "flex",
    // },
  },
  firstDateTypography: {
    color: "#626465",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      color: "#626465",
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  secondDateTypography: {
    color: "#07303E",
    fontSize: "11px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  pointScoreMainDiv: { display: "flex", justifyContent: "center" },
  pointDiv: {
    display: "flex",
    // borderRight: "1px solid #C4C4C4",
    // [theme.breakpoints.down("sm")]: {
    //   paddingRight: "11.5px",
    //   marginRight: "11.5px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   paddingRight: "12px",
    //   marginRight: "12px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   paddingRight: "13.4px",
    //   marginRight: "13.4px",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   paddingRight: "15.9px",
    //   marginRight: "15.9px",
    // },
  },
  pointFirstTypography: {
    color: "#07303E",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "4px",
      fontSize: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "4.7px",
      fontSize: "27px",
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: "5.6px",
      fontSize: "32px",
    },
  },
  pointSecondTypography: {
    color: "#626465",
    fontSize: "10px",
    fontWeight: 400,
    maxWidth: "48px",
    whiteSpace: "normal",
    alignSelf: "center",
    minWidth: "min-content",
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "13px",
    },
  },
  scoreDiv: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "11.5px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "15.35px",
    },
  },
  scoreFirstTypography: {
    fontWeight: 600,
    fontSize: "24px",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      marginRight: "4px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "27px",
      marginRight: "4.7px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "32px",
      marginRight: "5.6px",
    },
  },
  scoreSecondTypography: {
    color: "#626465",
    fontSize: "10px",
    fontWeight: 400,
    maxWidth: "48px",
    whiteSpace: "normal",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "13px",
    },
  },
  blur: {
    filter: "blur(10px)",
  },
  scoresLockedDiv: {
    display: "flex",
    backgroundColor: "white",
    padding: "20px 20px 0px 20px",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px 0px 0px",
    },
  },
  scoresLockedTypography: {
    color: "#07303E",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginLeft: "6px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
      marginLeft: "4px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "11px",
      marginLeft: "4.5px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "13px",
      marginLeft: "5.33px",
    },
  },

  scoreGridItem: {
    padding: "5.5px",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "5.5px 20px",
      justifyContent: "space-between",
    },
  },
  firstTableCell: {
    display: "flex",
  },
  secondTableCell: {
    display: "flex",
  },
  newGroupTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "500",
    },
    whiteSpace: "nowrap",
  },
  dtcGray: {
    color: "#626465",
  },
  lockedCarFrameImageClickableTypography: {
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: "500",
    color: "#186985",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "500",
    },
    whiteSpace: "nowrap",
  },
  unavailableReportImage: {
    cursor: "zoom-in",
    color: "#626465",
    cursor: "default",
    textDecoration: "none",
  },
  newSubGroupTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    fontSize: "12px",
    fontWeight: "400",
    color: "#07303E",
    whiteSpace: "nowrap",
    margin: "auto",
  },

  groupIcon: {
    width: "20px",
    height: "28px",
    marginRight: "7px",
  },
  computerAndFrame: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tableCellDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      display: "list-item",
      marginLeft: "15px",
    },
  },
  groupsDiv: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15.5px",
    },
  },
  outerGroupDiv: {
    width: "50%",
    borderBottom: "1px solid #c4c4c4",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  groupDiv: {
    display: "flex",
    borderRight: "1px solid #c4c4c4",
    margin: "16px 0",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      borderRight: "none",
      justifyContent: "space-between",
      margin: "6px 0",
    },
  },
  dtcGroupDiv: {},
  hybridGroupDiv: {
    display: "none",
  },
  frameGroupOuterDiv: {
    width: "100%",
  },
  frameGroupDiv: {
    width: "100%",
    borderBottom: "none",
  },
  withotBorderRight: {
    borderRight: "none",
  },
  showFullTypo: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#45A5C7",
    marginRight: "5px",
  },
  showFullDiv: {
    display: "flex",
    justifyContent: "center",
    paddingBlock: "15px",
    cursor: "pointer",
  },
}));

const nth = (day) => {
  if (day > 3 && day < 21) return day + "th";
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};

const ConditionReport = (props) => {
  const { t, i18n } = useTranslation();
  const mediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();
  const {
    noOfPoints,
    enteredBy,
    dateObject,
    groups,
    carId,
    history,
    score,
    unlocked,
    logVisitorView,
  } = props;
  let computerScanGroup = groups?.groups?.filter((el) => el?.group_id == 16)[0];
  let frameGroup = groups?.groups?.filter((el) => el?.group_id == 15)[0];
  let hybridLithiumGroup = groups?.groups?.filter(
    (el) => el?.group_id == 21
  )[0];
  let is_inspection = window.location.href.includes("inspection-report");

  const [totalPointsCount, setTotalPointsCount] = useState(0);
  const [openFrameImage, setOpenFrameImage] = useState(false);
  const inspectionReportSummary = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentId, setCurrentId] = useState(
    string_between_strings("_ga=", ";", document.cookie)
  ); // two years
  const [currentGid, setCurrentGid] = useState(
    string_between_strings("_gid=", ";", document.cookie)
  ); /// 24 hours
  const [startTime, setStartTime] = useState(
    Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
  );
  const vehicleFeatures = useRef(null);
  const [isVisibleReport, setIsVisibleReport] = useState(true);

  function string_between_strings(startStr, endStr, str) {
    let pos = str.indexOf(startStr) + startStr.length;
    return str.substring(pos, str.indexOf(endStr, pos));
  }

  function scrollToVisualization() {
    let element = document.getElementById("Showfullreport");
    element.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    const options = {};
    const actionButtonObserver = new IntersectionObserver(
      (entries, actionButtonObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisibleReport(entry.isIntersecting);
            actionButtonObserver.unobserve(inspectionReportSummary.current);
            logVisitorView({
              page_title: currentPath,
              start_time: startTime,
              session_one: currentId,
              session_two: currentGid,
              tag: "Inspection Report Summary Title",
            });
          } else if (
            !entry.isIntersecting &&
            entry?.boundingClientRect?.y < 0
          ) {
            setIsVisibleReport(entry.isIntersecting);
          } else {
            // not intersecting but came from below (we dont want it to re appear (keeps true from first if))
          }
        });
      },
      options
    );
    if (inspectionReportSummary.current) {
      actionButtonObserver.observe(inspectionReportSummary.current);
    }
    return () => {
      if (inspectionReportSummary.current) {
        actionButtonObserver.unobserve(inspectionReportSummary.current);
      }
    };
  });

  useEffect(() => {
    if (groups != undefined) {
      setTotalPointsCount(0);
      groups.groups.map((group, i) => {
        if (group.group_id !== 15) {
          setTotalPointsCount((prev) => prev + group.group_points);
        }
      });
    }
  }, [groups]);

  const getScoreColor = (x) => {
    if (isNaN(x)) {
      return "#07303E";
    }
    if (x <= 20) {
      return "#D40000";
    } else if (x <= 30) {
      return "#EA3800";
    } else if (x <= 40) {
      return "#EA5400";
    } else if (x <= 50) {
      return "#FF7A00";
    } else if (x <= 60) {
      return "#FFA800";
    } else if (x <= 70) {
      return "#FFD600";
    } else if (x <= 80) {
      return "#C5E500";
    } else if (x <= 90) {
      return "#5BCE00";
    } else if (x <= 100) {
      return "#00A900";
    }
  };

  return (
    <Paper
      style={
        is_inspection
          ? { padding: "0 16px 32px" }
          : { padding: "0", width: "100%" }
      }
      className={classes.smPapers}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        justify="space-between"
        classes={{ root: classes.headerwrap }}
      >
        <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
          <Typography
            ref={inspectionReportSummary}
            style={
              localStorage.getItem("language") === "ar"
                ? { fontWeight: "700" }
                : { fontWeight: "500" }
            }
            className={classes.header}
            // noWrap
          >
            {t("Inspection Report Summary")}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs>
            <Divider component="div" classes={{ root: classes.Divider }} />
          </Grid>
        </Hidden>
      </Grid>
      <Paper classes={{ root: classes.reportPaper }}>
        <div className={classes.reportSummaryDiv}>
          <Grid container className={classes.reportTitleGrid}>
            <div>
              <Typography
                style={
                  localStorage.getItem("language") === "ar"
                    ? {
                        fontWeight: "600",
                      }
                    : {}
                }
                className={classes.reportSummaryText}
              >
                {t("Report Summary")}
              </Typography>
            </div>
          </Grid>
        </div>
        <Grid container classes={{ root: classes.reportSummarySubInfo }}>
          <div className={classes.subInfoMainDiv}>
            <div className={classes.dateMainDiv}>
              <img
                src={ReportSummaryCalendar}
                className={classes.calendarIcon}
              />
              <div className={classes.dateTypographyDiv}>
                <Typography className={classes.firstDateTypography}>
                  {t("Inspected On")}:
                </Typography>
                <Typography className={classes.secondDateTypography}>
                  {localStorage.getItem("language") === "en"
                    ? nth(dateObject.getDate())
                    : dateObject.getDate()}{" "}
                  {t(
                    dateObject.toLocaleString("default", {
                      month: "long",
                    })
                  )}{" "}
                  {dateObject.getFullYear()}
                </Typography>
              </div>
            </div>
            <div className={classes.pointScoreMainDiv}>
              <div className={classes.pointDiv}>
                <Typography className={classes.pointFirstTypography}>
                  {totalPointsCount}
                  {"\u00A0"}
                </Typography>
                <Typography className={classes.pointSecondTypography}>
                  {t("Inspected Point")}
                </Typography>
              </div>
              {/* <div className={classes.scoreDiv}>
                <Typography
                  className={clsx({
                    [classes.scoreFirstTypography]: 1,
                    [classes.blur]: !unlocked || !score,
                  })}
                  style={{ color: getScoreColor(score) }}
                >
                  {`${score}%`}
                  {"\u00A0"}
                </Typography>
                <Typography className={classes.scoreSecondTypography}>
                  {t("Markabte Score")}
                </Typography>
              </div> */}
            </div>
          </div>
        </Grid>
        <div>
          {/* {!unlocked && (
            <div className={classes.scoresLockedDiv}>
              <Locked size={10} fill={"#07303E"} />
              <Typography className={classes.scoresLockedTypography}>
                {t("Inspection scores are locked.")}
              </Typography>
            </div>
          )} */}
          <Grid container className={classes.visualScoreGrid}>
            {!unlocked && (
              <div className={classes.scoresLockedDiv}>
                <Locked size={10} fill={"#07303E"} />
                <Typography className={classes.scoresLockedTypography}>
                  {t("Inspection scores are locked.")}
                </Typography>
              </div>
            )}
            {groups !== undefined
              ? groups.groups.map(
                  (group, i) =>
                    group.group_id !== 15 &&
                    group.group_id !== 16 &&
                    group.group_id !== 21 && (
                      <Grid
                        container
                        xs={12}
                        lg={6}
                        classes={{ root: classes.scoreGridItem }}
                      >
                        <Grid item>
                          <Typography
                            style={
                              localStorage.getItem("language") === "ar"
                                ? { fontWeight: "600" }
                                : { fontWeight: "400" }
                            }
                            className={classes.groupTitle}
                          >
                            {group.title}
                            {/*  */}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={
                              (localStorage.getItem("language") === "ar"
                                ? { fontWeight: "600" }
                                : { fontWeight: "500" },
                              group.group_score?.pk === 10442 ||
                              group.group_score?.pk === 10582 || // remove hard coded cars 1216,1223
                              (group.group_score?.fields?.group_score ==
                                "Not Available" &&
                                group.group_id !== 15) ||
                              !group.group_score ||
                              group.group_points == 0
                                ? { color: "#626465" }
                                : { color: "#07303E" })
                            }
                            className={clsx({
                              [classes.groupScore]: 1,
                              [classes.blur]: !unlocked,
                            })}
                          >
                            {!group.group_score ||
                            group.group_points == 0 ||
                            group.group_score?.pk === 10442 ||
                            group.group_score?.pk === 10582 // remove hard coded cars 1216,1223
                              ? t("Not Available")
                              : !Number(
                                  group.group_score?.fields?.group_score
                                ) &&
                                group.group_score?.fields?.group_score !==
                                  "0.00"
                              ? t(group.group_score?.fields?.group_score)
                              : Math.floor(
                                  group.group_score?.fields?.group_score
                                ) + "%"}
                          </Typography>
                          {!(
                            (group.group_id == 19 || group.group_id == 20) &&
                            !group.group_points
                          ) &&
                          group.group_score?.pk !== 10442 &&
                          group.group_score?.pk !== 10582 ? ( // remove hard coded cars 1216,1223
                            <Typography
                              style={
                                localStorage.getItem("language") === "ar"
                                  ? { fontWeight: "600" }
                                  : { fontWeight: "500" }
                              }
                              className={classes.groupPoints}
                            >
                              {(group.group_id == 20 || group.group_id == 20) &&
                              !group.group_points
                                ? ""
                                : !group.group_points
                                ? "0" + " " + t("Points")
                                : group.group_points + " " + t("Points")}
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <Score
                            value={
                              !group.group_score ||
                              !Number(group.group_score?.fields?.group_score)
                                ? 0
                                : Math.floor(
                                    group.group_score?.fields?.group_score
                                  )
                            }
                            unAvailable={
                              !!!Number(group.group_score?.fields?.group_score)
                            }
                            unlocked={unlocked}
                          />
                        </Grid>
                      </Grid>
                    )
                )
              : [1, 2, 3, 4, 5, 6, 7].map(() => {
                  return (
                    <Grid
                      container
                      xs={12}
                      lg={6}
                      classes={{ root: classes.scoreGridItem }}
                    >
                      <Grid item>
                        <Skeleton
                          style={{ width: "140px", height: "21px" }}
                          className={classes.groupTitle}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Skeleton
                          style={{ width: "34px", height: "21px" }}
                          className={classes.groupScore}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          style={{
                            width: "100%",
                            height: "6px",
                            marginTop: "7px",
                            transform: "none",
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
          </Grid>
        </div>
        <Table style={{ backgroundColor: "white" }}>
          <div className={classes.groupsDiv}>
            <div
              className={clsx({
                [classes.outerGroupDiv]: 1,
                [classes.hybridGroupDiv]: !hybridLithiumGroup?.title,
              })}
            >
              <div
                className={clsx({
                  [classes.groupDiv]: 1,
                  [classes.hybridGroupDiv]: !hybridLithiumGroup?.title,
                })}
              >
                {groups !== undefined && hybridLithiumGroup?.title ? (
                  // <div className={classes.firstTableCell}>
                  <>
                    {" "}
                    {/**/}
                    <div className={classes.tableCellDiv}>
                      <Hidden smDown>
                        <img
                          className={classes.groupIcon}
                          src={HybridBattery}
                        />
                      </Hidden>
                      <div style={{ display: "flex" }}>
                        <Typography className={classes.newGroupTitle}>
                          {/* {`${t(hybridLithiumGroup?.title)}:`} */}
                          {t("Hybrid Battery")}
                        </Typography>
                        <Typography className={classes.newSubGroupTitle}>
                          {"\u00A0"}
                          {t("(Lithium)")}:
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        style={
                          typeof hybridLithiumGroup?.answers !== "string"
                            ? { fontSize: "23px" }
                            : { fontSize: "14px", lineHeight: "34.5px" }
                        }
                        className={classes.newGroupTitle}
                      >
                        {"\u00A0"}
                        {t(hybridLithiumGroup?.answers)}
                      </Typography>
                      {typeof hybridLithiumGroup?.answers !== "string" && (
                        <Typography
                          style={{ fontSize: "12px", color: "#9A9A9A" }}
                        >
                          {"\u00A0"}
                          {t("Faults")}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className={classes.outerGroupDiv}>
              <div
                className={clsx({
                  [classes.groupDiv]: 1,
                  [classes.dtcGroupDiv]: !hybridLithiumGroup?.title,
                  [classes.withotBorderRight]: hybridLithiumGroup?.title,
                })}
              >
                {groups !== undefined ? (
                  // <div className={classes.firstTableCell}>
                  <>
                    <div className={classes.tableCellDiv}>
                      <Hidden smDown>
                        <img className={classes.groupIcon} src={DTC} />
                      </Hidden>
                      <div style={{ display: "flex" }}>
                        <Typography className={classes.newGroupTitle}>
                          {t(computerScanGroup?.title)}
                        </Typography>
                        <Typography className={classes.newSubGroupTitle}>
                          {"\u00A0"}
                          {t("(Markabte)")} :
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        style={
                          typeof computerScanGroup?.answers !== "string"
                            ? { fontSize: "23px" }
                            : { fontSize: "14px", lineHeight: "34.5px" }
                        }
                        className={classes.newGroupTitle}
                      >
                        {"\u00A0"}
                        {t(computerScanGroup?.answers)}
                      </Typography>
                      {typeof computerScanGroup?.answers !== "string" && (
                        <Typography
                          style={{ fontSize: "12px", color: "#9A9A9A" }}
                        >
                          {"\u00A0"}
                          {!unlocked ? t("System") : t("Faults")}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : (
                  <Skeleton
                    style={{
                      width: "50%",
                      height: "50px",
                      marginInline: "20px",
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={clsx({
                [classes.outerGroupDiv]: 1,
                [classes.frameGroupOuterDiv]: hybridLithiumGroup?.title,
              })}
            >
              <div
                className={clsx({
                  [classes.groupDiv]: 1,
                  [classes.frameGroupDiv]: hybridLithiumGroup?.title,
                  [classes.withotBorderRight]: 1,
                })}
              >
                {groups !== undefined ? (
                  // <div className={classes.secondTableCell}>
                  <>
                    <div className={classes.tableCellDiv}>
                      <Hidden smDown>
                        <img className={classes.groupIcon} src={Frame} />
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{ display: "flex" }}>
                          <Typography className={classes.newGroupTitle}>
                            {t(frameGroup?.title)}
                          </Typography>
                          <Typography
                            style={
                              localStorage.getItem("language") === "ar"
                                ? {
                                    marginLeft: "38px",
                                  }
                                : {}
                            }
                            className={classes.newSubGroupTitle}
                          >
                            {"\u00A0"}
                            {t("(3rd Party)")} :
                          </Typography>
                        </div>
                      </Hidden>
                      <Hidden smDown>
                        <Typography className={classes.newGroupTitle}>
                          {t(frameGroup?.title)}
                        </Typography>
                        <Typography className={classes.newSubGroupTitle}>
                          {"\u00A0"}
                          {t("(3rd Party)")} :
                        </Typography>
                      </Hidden>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        style={
                          typeof frameGroup?.answers !== "string"
                            ? { fontSize: "23px" }
                            : { fontSize: "14px", lineHeight: "34.5px" }
                        }
                        className={classes.newGroupTitle}
                      >
                        {"\u00A0"}
                        {t(frameGroup?.answers)}
                      </Typography>
                      {typeof frameGroup?.answers !== "string" && (
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#9A9A9A",
                          }}
                        >
                          {"\u00A0"}
                          {t("Good")}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : (
                  <Skeleton
                    style={{
                      width: "50%",
                      height: "50px",
                      marginInline: "20px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Table>
        <div onClick={scrollToVisualization} className={classes.showFullDiv}>
          <Typography className={classes.showFullTypo}>
            {t("Show full report")}
          </Typography>
          <img src={ShowFull} />
        </div>
      </Paper>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logVisitorView(payload) {
    dispatch(logVisitorView(payload));
  },
});

export default connect(null, mapDispatchToProps)(ConditionReport);
