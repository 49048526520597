import {
  CREATE_CHECKOUT_SESSION,
  CREATE_SESSION,
  REQUEST_PAY,
  UPDATE_PAYMENT_RESULTS,
} from "../constants";

export function createSession(payload) {
  return {
    type: CREATE_SESSION,
    payload: payload,
  };
}

export function createCheckoutSession(payload) {
  return {
    type: CREATE_CHECKOUT_SESSION,
    payload: payload,
  };
}

export function requestPay(payload) {
  return {
    type: REQUEST_PAY,
    payload: payload,
  };
}

export function updatePaymentResults(payload) {
  return {
    type: UPDATE_PAYMENT_RESULTS,
    payload: payload,
  };
}
