import React from "react";

function HandleActions(props) {
  const {
    images,
    setActiveImage,
    setBackClicked,
    setNextClicked,
    setOpenDialog,
    setSwitchState,
    setPositionActiveImage,
    positionImages,
    hideAppBar,
    activeImage,
    backClicked,
    switchState,
    isSmallDevice,
    mobDialog,
    setMobDialog,
  } = props;

  const handlePreviousImage = () => {
    activeImage >= 1
      ? setActiveImage((prevActiveStep) => prevActiveStep - 1)
      : setActiveImage(() => images.length - 1);
    setBackClicked(!backClicked);
  };

  const handleNextImage = () => {
    activeImage < images.length - 1
      ? setActiveImage((prevActiveStep) => prevActiveStep + 1)
      : setActiveImage(() => 0);
    setNextClicked((nextClicked) => !nextClicked);
  };
  const handleImgClick = (event) => {
    // image's onClick function requests full screen and orientation lock if available in small screen and sets openDialog if big screen
    //not sure if necessary still doesnot fullscreen on safari and the rest did without extra vendor prefix
    //but condition is neccissary
    if (isSmallDevice) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen().catch((e) => console.log(e));
      } else if (document.body.mozRequestFullscreen) {
        document.body.mozRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen().catch((e) => console.log(e));
      }
      if (window.screen.orientation) {
        window.screen.orientation
          .lock("landscape-primary")
          .catch((e) => console.log(e));
      }
      setMobDialog && setMobDialog(true);
      hideAppBar(); // hides app bar in small screen visualization so that the speed dial button 2021 Markabte. All Rights Reserved.'s transparent black div get positioned right
    } else {
      setOpenDialog(true);
    }
  };

  const handleSwitchChange = (event) => {
    // swich change function the value of switchState will later be used to render interior or exterior images
    setSwitchState(event.target.checked);
    setActiveImage(
      event.target.checked
        ? 0
        : !switchState
        ? positionImages.length - 1
        : positionImages.length
    );
    setPositionActiveImage(0);
  };
  const imageLocalization = (img) => {
    if (!img) return null;
    // var imge =
    // "https://markabte-static.s3.eu-central-1.amazonaws.com/CPDTest/Car-938-Image-Center+Console-22-6-2022_11_31_49.jpg";
    //markabte-static.s3.eu-central-1.amazonaws.com/        Car-938-Image-Center%20Console-22-6-2022_11%3A31%3A49.png
    var imgfited = img.split("/")[img.split("/").length - 1].split(".")[0];

    imgfited = decodeURIComponent(imgfited);
    var imageTemplate = `https://markabte-static.s3.eu-central-1.amazonaws.com/CPDTest/${imgfited}.png`;
    return imageTemplate;
  };
  return {
    handleSwitchChange: handleSwitchChange,
    handleImgClick: handleImgClick,
    handleNextImage: handleNextImage,
    handlePreviousImage: handlePreviousImage,
    imageLocalization: imageLocalization,
  };
}

export default HandleActions;
