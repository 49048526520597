import React from "react";

const Unlocked = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={1.2307 * size}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3236 5.57711H4.03341V3.13772C4.03341 2.06187 4.93835 1.18662 6.05066 1.18662C7.16297 1.18662 8.06791 2.06187 8.06791 3.13772V4.27183H9.25453V3.13772C9.25453 1.40757 7.81727 0 6.05066 0C4.28405 0 2.84679 1.40757 2.84679 3.13772V5.57711H1.77993C0.798476 5.57711 0 6.37558 0 7.35704V13.4088C0 14.3902 0.798476 15.1887 1.77993 15.1887H10.3236C11.305 15.1887 12.1035 14.3902 12.1035 13.4088V7.35704C12.1035 6.37558 11.305 5.57711 10.3236 5.57711ZM10.9169 13.4088C10.9169 13.7359 10.6507 14.0021 10.3236 14.0021H1.77993C1.45278 14.0021 1.18662 13.7359 1.18662 13.4088V7.35704C1.18662 7.02989 1.45278 6.76373 1.77993 6.76373H10.3236C10.6507 6.76373 10.9169 7.02989 10.9169 7.35704V13.4088Z"
        fill={fill}
      />
      <path
        d="M6.05179 8.48421C5.44557 8.48421 4.95416 8.97562 4.95416 9.58183C4.95416 9.9689 5.1547 10.3089 5.45738 10.5044V11.8067C5.45738 12.1344 5.723 12.4 6.05069 12.4C6.37834 12.4 6.644 12.1344 6.644 11.8067V10.5058C6.94789 10.3106 7.14941 9.96985 7.14941 9.58183C7.14941 8.97562 6.658 8.48421 6.05179 8.48421Z"
        fill={fill}
      />
    </svg>
  );
};

export default Unlocked;
