import React, { useEffect, useRef, useState, Suspense } from "react";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import {
  getPageTimeStamp,
  getUnseenNotifications,
  socketConnect,
} from "../store/actions/websockets";
import { getCreditInfo, getUnlockedInfo, logout } from "../store/actions/auth";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ReactGa from "react-ga";
import Button from "@material-ui/core/Button";
import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import promoBackgroundEn from "../images/promoBackgroundEn.png";
import promoBackgroundAr from "../images/promoBackgroundAr.png";
import Promo from "../icons/Promo";
import GATrakingId from "../helpers/GATrakingId";
import CarInformation from "./CarListings/CarInformation";
import LoadingScreen from "../components/LoadingScreen";
import {
  togglePromotionDisabled,
  logVisitorView,
} from "../store/actions/accounts";
import Moment from "moment";
import AppiontmentForm from "../components/AppiontmentForm";

////////////////////////////////////////////////////////////////////////
const Handle404 = React.lazy(() => import("../components/Handle404"));
const NavBar = React.lazy(() => import("../components/NavBar"));
const SignUpPage = React.lazy(() => import("./SignUpPage"));
const Signin = React.lazy(() => import("./Signin"));
const ForgotPasswordPage = React.lazy(() => import("./ForgotPasswordPage"));
////////////////////////////////////////////////////////////////////////

const Footer = React.lazy(() => import("../components/Footer"));
const CarListings = React.lazy(() => import("./CarListings/CarListings"));
const ReportSample = React.lazy(() => import("./CarListings/ReportSample"));
const InspectionReport = React.lazy(() =>
  import("../wrappers/InspectionReportProto")
);
const MyGarageOne = React.lazy(() => import("./MyGarage/MyGarage"));
const MyGarage = React.lazy(() => import("./MyGarage/MyGaragePage"));
const ApptCardTry = React.lazy(() =>
  import("../components/AppointmentComponents/ApptCardTry")
);
const TermsAndConditions = React.lazy(() => import("./TermsAndConditions"));
const CarDetails = React.lazy(() => import("./CarListings/CarDetails"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"));
const IndependentSeller = React.lazy(() => import("./IndependentSeller"));
const ExpReport = React.lazy(() => import("./CarListings/ExpReport"));
const WebSubscriptionsPage = React.lazy(() =>
  import("../components/Subscriptions/WebSubscriptionsPage")
);
const Tour = React.lazy(() => import("../wrappers/Tour"));
const ComingSoon = React.lazy(() => import("../components/ComingSoon"));
const BecomeAnOfficer = React.lazy(() => import("./BecomeAnOfficer"));
const JoinUS = React.lazy(() => import("./MyGarage/JoinUS"));
const InspectionProcess = React.lazy(() => import("./InspectionProcess"));
const Home = React.lazy(() => import("./HomeNew"));
const FAQs = React.lazy(() => import("./FrequentQuestionsComponent.js"));

// import CheckoutSession from "../components/CheckoutSession";
// import CardInfo from "../components/Subscriptions/CardInfo";

const drawerWidthDesktop = 254;
const drawerWidthMobile = 231;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1,
    },
    background: "white",
    maxHeight: "86px",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    background: "white",
    boxShadow: "5px 0px 14px rgba(7, 48, 62, 0.3)",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("md")]: {
      // marginLeft: drawerWidthDesktop,
      // minHeight: "90px",
    },
  },
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "white",
  },
  selected: {
    background: "rgba(196,196,196,0.5)",
    color: "#fff",
  },
  logoListItemRoot: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuuButtonRoot: {
    padding: 0,
  },
  listItemGutters: {
    paddingLeft: "25px",
  },
  listItemIconRoot: {
    width: "28px",
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    paddingRight: "11.5px",
  },
  userListItemGutters: {
    padding: "25px 14px 0",
  },
  userListItemGuttersMdUp: {
    padding: "20px 0 0",
  },
  backdropRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  toolbarGutters: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "68px",
      paddingRight: "68px",
    },
  },
  ListItemRoot: {
    padding: "14px 16px 14px",
  },
  bigScreenFullName: {
    color: "#07303E",
    fontSize: "20px",
    lineHeight: "21px",
    marginLeft: "14px",
    padding: "0 0 20px ",
  },
  mobFullName: {
    color: "#07303E",
    fontSize: "14px",
    lineHeight: "21px",
    marginLeft: "14px",
  },
  dialogPaper: {
    backgroundImage:
      localStorage.getItem("language") == "en"
        ? `url(${promoBackgroundEn})`
        : `url(${promoBackgroundAr})`,
    backgroundSize: "100% 100%",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      margin: 0,
      backgroundSize: "contain",
    },
  },
  dialogContainer: {
    // [theme.breakpoints.down("sm")]: {
    height: "auto",
    // },
  },
  dialogclass: {
    width: "470px",
    // height: "475px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "auto",
      height: "auto",
    },
  },
  imageSize: {
    width: "145px",
    alignSelf: "center",
    marginTop: "-12px",
  },
  typoDiv: {
    textAlign: "center",
    marginTop: "190px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "230px",
    },
  },
  titleTypo: {
    color: "#07303E",
    fontSize: "21px",
    fontWeight: "600",
    marginBottom: "9px",
  },
  subTitleTypo: {
    color: "#6B8495",
    fontSize: "16px",
    fontWeight: "500",
    marginInline: "45px",
  },
  dialogPaperWidthSm: {
    [theme.breakpoints.up("md")]: {
      maxHeight: "90vh",
    },
  },
  mobileContainer: {
    textAlign: "center",
    padding: "10px 20px",
  },
  mobileDivMargining: {
    // margin: "10px auto",
    margin: "25px auto",
    // display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  mobileDivMarginingDivider: {
    margin: "10px auto",
  },
  flexedContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    padding: "0px 0px 20px",
  },
  exploreBeta: {
    boxShadow: "0px 1.75032px 1.75032px rgba(15, 71, 90, 0.25)",
  },
}));

function FirstSignUpPopup(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { openFirstSignUpPopup, setOpenFirstSignUpPopup, user } = props;

  return (
    <div>
      <Dialog
        id="freeCreditPopup"
        open={openFirstSignUpPopup}
        onClose={() => {
          document.getElementById("root").style.filter = "none";
          setOpenFirstSignUpPopup(false);
        }}
        classes={{
          paper: classes.dialogPaper,
          root: classes.dialogRoot,
          container: classes.dialogContainer,
          paperWidthSm: classes.dialogPaperWidthSm,
        }}
        className={classes.dialogclass}
      >
        <div
          style={{
            textAlign: "right",
            padding: "20px 20px 0 0",
          }}
        >
          <CloseIcon
            onClick={() => {
              document.getElementById("root").style.filter = "none";
              setOpenFirstSignUpPopup(false);
            }}
          />
        </div>
        <div className={classes.typoDiv}>
          <Promo />
          <Typography className={classes.titleTypo}>
            {t("You are Now a Markabte User!")}
          </Typography>
          <Typography className={classes.subTitleTypo}>
            {t(
              "Use your free credit to unlock any car inspection report of your choice."
            )}
          </Typography>
        </div>
        <Button
          onClick={() => {
            document.getElementById("root").style.filter = "none";
            setOpenFirstSignUpPopup(false);
          }}
          style={{
            width: "338px",
            height: "50px",
            alignSelf: "center",
            marginTop: "36px",
            marginBottom: "25px",
          }}
          variant="contained"
          color="primary"
        >
          {t("Continue")}
        </Button>
      </Dialog>
    </div>
  );
}

const Dashboard = (props) => {
  if (props.user !== null) {
    var {
      user: { user_id, first_name, last_name },
    } = props;
  }
  const classes = useStyles();
  const [isVisualization, setIsVisualization] = useState(false);
  const [openFirstSignUpPopup, setOpenFirstSignUpPopup] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentId, setCurrentId] = useState(
    string_between_strings("_ga=", ";", document.cookie)
  ); // two years
  const [currentGid, setCurrentGid] = useState(
    string_between_strings("_gid=", ";", document.cookie)
  ); /// 24 hours
  const [startTime, setStartTime] = useState(
    Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
  );

  const {
    history,
    socketConnect,
    getPageTimeStamp,
    getUnseenNotifications,
    isAuthenticated,
    logout,
    user,
    getCreditInfo,
    getUnlockedInfo,
    togglePromotionDisabled,
    logVisitorView,
  } = props;

  function string_between_strings(startStr, endStr, str) {
    let pos = str.indexOf(startStr) + startStr.length;
    return str.substring(pos, str.indexOf(endStr, pos));
  }

  useEffect(() => {
    setStartTime(
      Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
    );
    setCurrentPath(window.location.pathname);
    setCurrentId(string_between_strings("_ga=", ";", document.cookie));
    setCurrentGid(string_between_strings("_gid=", ";", document.cookie));
  }, []);

  useEffect(() => {
    setStartTime(
      Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
    );
    setCurrentPath(window.location.pathname);
    logVisitorView({
      page_title: currentPath,
      start_time: startTime,
      session_one: currentId,
      session_two: currentGid,
      tag: "enter",
    });
  }, [window.location.pathname]);
  useEffect(() => {
    const handleTabClose = (event) => {
      logVisitorView({
        page_title: currentPath,
        start_time: startTime,
        session_one: currentId,
        session_two: currentGid,
        tag: "exit",
      });
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [window.location.pathname]);

  const mainRef = useRef();

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
    if (
      // pages that open in a new tab
      !window.location.href.includes("/car") &&
      !window.location.href.includes("/sample-reports/") &&
      !window.location.href.includes("/subscription-plans")
    ) {
      // reopen promotion bar
      togglePromotionDisabled(false);
    }
  }, []);

  useEffect(() => {
    ReactGa.initialize(GATrakingId());
    !`${window.location.pathname}${window.location.search}`.includes(
      "/search-results/"
    ) &&
      ReactGa.pageview(
        window.location.pathname + window.location.search !== "/"
          ? window.location.pathname + window.location.search
          : "/home"
      );
  }, [window.location.pathname, window.location.search]);

  useEffect(() => {
    socketConnect({
      role: "regular", // role changes
      userId: user_id,
    });
    if (user_id) {
      getPageTimeStamp({ user_id: user_id });
      getUnseenNotifications({ user_id: user_id });
      getCreditInfo({ user_id: user_id });
      getUnlockedInfo({ user_id: user_id });
      ReactGa.set({ userId: user_id });
    }
  }, [user_id]);

  useEffect(() => {
    // if (!!!user_id) {
    socketConnect({
      role: "regular", // role changes
      userId: user_id,
    });
    // }
  }, [props.user]);

  const hideAppBar = () => {
    setIsVisualization(true);
  };
  const showAppBar = () => {
    setIsVisualization(false);
  };

  props.history.listen((location, action) => {
    document.getElementById("root").style.filter = "none";
  });

  return (
    <div ref={mainRef}>
      {!isVisualization ? (
        <Suspense fallback={<></>}>
          <NavBar
            isAuthenticated={isAuthenticated}
            first_name={first_name}
            last_name={last_name}
            logout={logout}
          />
        </Suspense>
      ) : null}
      <main className={classes.content}>
        <Switch>
          <Route path="/privacy-policy">
            <Suspense fallback={<LoadingScreen />}>
              <PrivacyPolicy />
            </Suspense>
          </Route>
          <Route path="/terms-and-conditions">
            <Suspense fallback={<LoadingScreen />}>
              <TermsAndConditions />
            </Suspense>
          </Route>
          <Route path="/subscription-plans">
            <Suspense fallback={<LoadingScreen />}>
              <WebSubscriptionsPage />
            </Suspense>
          </Route>
          <Route path="/become-an-officer">
            <Suspense fallback={<LoadingScreen />}>
              <BecomeAnOfficer />
            </Suspense>
          </Route>
          <Route path="/inspection-process">
            <Suspense fallback={<LoadingScreen />}>
              <InspectionProcess />
            </Suspense>
          </Route>
          <Route path="/appt-card/">
            <Suspense fallback={<LoadingScreen />}>
              <ApptCardTry />
            </Suspense>
          </Route>
          <Route path="/garage/">
            <Suspense fallback={<LoadingScreen />}>
              <MyGarage />
            </Suspense>
          </Route>
          <Route path="/garageTwo/">
            <Suspense fallback={<LoadingScreen />}>
              <MyGarageOne />
            </Suspense>
          </Route>

          <Route path={"/car/:id/inspection-report"}>
            <Suspense fallback={<LoadingScreen />}>
              <InspectionReport
                hideAppBar={hideAppBar}
                showAppBar={showAppBar}
              />
            </Suspense>
          </Route>
          <Route path="/inspection-seller-report/car/:id">
            <Suspense fallback={<LoadingScreen />}>
              <ExpReport
                hideAppBar={hideAppBar}
                showAppBar={showAppBar}
                mainRef={mainRef}
              />
            </Suspense>
          </Route>
          {/* <Route path={"/Scar/:id"}> */}
          <Route path={"/car/:id"}>
            <CarInformation
              hideAppBar={hideAppBar}
              showAppBar={showAppBar}
              mainRef={mainRef}
            />
          </Route>

          <Route path={"/Scar/:id"}>
            <Suspense fallback={<LoadingScreen />}>
              <CarDetails
                hideAppBar={hideAppBar}
                showAppBar={showAppBar}
                mainRef={mainRef}
              />
            </Suspense>
          </Route>

          <Route path="/visualization">
            <Suspense fallback={<LoadingScreen />}>
              <Tour showAppBar={showAppBar} />
            </Suspense>
          </Route>
          <Route path="/book-appointment">
            <AppiontmentForm />
          </Route>
          <Route path="/search-results/:query/filter/:filter">
            <Suspense fallback={<LoadingScreen />}>
              <CarListings />
            </Suspense>
          </Route>

          <Route path="/search-results/:query">
            <Suspense fallback={<LoadingScreen />}>
              <CarListings />
            </Suspense>
          </Route>
          <Route path="/sample-reports">
            <Suspense fallback={<LoadingScreen />}>
              <ReportSample />
            </Suspense>
          </Route>

          {/* forget pass */}
          <Route exact path="/forgetpassword">
            <Suspense fallback={<LoadingScreen />}>
              <ForgotPasswordPage />
            </Suspense>
          </Route>
          <Route path={"/signin"}>
            <Suspense fallback={<LoadingScreen />}>
              <Signin />
            </Suspense>
          </Route>
          <Route path="/signup">
            <Suspense fallback={<LoadingScreen />}>
              <SignUpPage setOpenFirstSignUpPopup={setOpenFirstSignUpPopup} />
            </Suspense>
          </Route>
          <Route path={"/JoinUS"}>
            <Suspense fallback={<LoadingScreen />}>
              <JoinUS />
            </Suspense>
          </Route>
          <Route path={"/IndependentSeller"}>
            <Suspense fallback={<LoadingScreen />}>
              <IndependentSeller />
            </Suspense>
          </Route>
          <Route path={"/something-went-wrong"}>
            <Suspense fallback={<LoadingScreen />}>
              <Handle404 />
            </Suspense>
          </Route>
          <Route path={"/404"}>
            <Suspense fallback={<LoadingScreen />}>
              <Handle404 />
            </Suspense>
          </Route>
          <Route path={"/comingsoon"}>
            <Suspense fallback={<LoadingScreen />}>
              <ComingSoon />
            </Suspense>
          </Route>
          <Route path={"/Frequent-Asked-Questions"}>
            <Suspense fallback={<LoadingScreen />}>
              <FAQs />
            </Suspense>
          </Route>
          <Route path={"/"}>
            <Suspense fallback={<LoadingScreen />}>
              <Home />
            </Suspense>
          </Route>
        </Switch>
        <FirstSignUpPopup
          user={user}
          setOpenFirstSignUpPopup={setOpenFirstSignUpPopup}
          openFirstSignUpPopup={openFirstSignUpPopup}
        ></FirstSignUpPopup>
      </main>
      {!isVisualization ? (
        <Suspense fallback={<></>}>
          <Footer />
        </Suspense>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  unseenCarListings: state.websockets.unseenCarListings,
});

const mapDispatchToProps = (dispatch) => ({
  socketConnect(payload) {
    dispatch(socketConnect(payload));
  },
  getPageTimeStamp(payload) {
    dispatch(getPageTimeStamp(payload));
  },
  getUnseenNotifications(payload) {
    dispatch(getUnseenNotifications(payload));
  },
  logout() {
    dispatch(logout());
  },
  // socketConnect(payload) {
  //   dispatch(socketConnect(payload));
  // },
  getCreditInfo(payload) {
    dispatch(getCreditInfo(payload));
  },
  getUnlockedInfo(payload) {
    dispatch(getUnlockedInfo(payload));
  },
  togglePromotionDisabled(payload) {
    dispatch(togglePromotionDisabled(payload));
  },
  logVisitorView(payload) {
    dispatch(logVisitorView(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
