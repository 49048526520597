import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  createCheckoutSession,
  updatePaymentResults,
} from "../store/actions/payment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toggleNotification } from "../store/actions/notification";

// const {REACT_APP_MPGS_MERCHANT_ID, REACT_APP_MPGS_BASE_URL, REACT_APP_SERVER_URL} = process.env;

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: "100vw",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.75)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 9998,
  },
  typographyDiv: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,0%)",
    width: "calc(100% - 32px)",
    textAlign: "center",
  },
  typography: {
    color: "white",
    fontSize: "20px",
    fontWeight: 600,
  },
}));

const MASTER_CARD_CHECKOUT_JS_SRC =
  "https://mepspay.gateway.mastercard.com/checkout/version/60/checkout.js";
const MPGS_TIMEOUT = 5000;

const onScriptLoad = ({
  initialized,
  sessionId,
  orderId,
  amount,
  description,
}) => {
  const { Checkout } = window;

  if (!Checkout) {
    return;
  }

  Checkout.configure({
    session: {
      id: sessionId,
    },
    merchant: "9800086200",
    order: {
      amount: amount,
      currency: "JOD",
      description: description,
      id: orderId,
    },
    interaction: {
      operation: "PURCHASE",
      merchant: {
        name: localStorage.getItem("language") == "en" ? "Markabte" : "مركبتي",
        address: {
          line1: "Amman",
          line2: "Jordan",
        },
        phone: "+962795087430",
        logo: "https://markabte-static.s3.eu-central-1.amazonaws.com/MepsLogo.png",
      },
      locale: localStorage.getItem("language") == "en" ? "en_US" : "ar_JO",
      theme: "default",
      displayControl: {
        billingAddress: "HIDE",
        customerEmail: "HIDE",
        orderSummary: "HIDE",
        shipping: "HIDE",
      },
    },
  });

  initialized();
};

const loadCallbacks = () => {
  const script = document.createElement("script");
  const code = `
            function cancelCallback() {}
            function timeoutCallback() {}
            function errorCallback(error) {}
            function completeCallback(resultIndicator, sessionVersion) {}
        `;
  script.type = "text/javascript";
  script.async = true;
  script.id = "CheckoutSessionCallbacks";
  try {
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
  } catch (e) {
    script.text = code;
    document.body.appendChild(script);
  }
};

const loadScript = async (description, amount, createCheckoutSession) => {
  if (!document) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject();
    }, MPGS_TIMEOUT);

    const prevScript = document.querySelector(
      `script[src="${MASTER_CARD_CHECKOUT_JS_SRC}"]`
    );

    if (prevScript) {
      prevScript.remove();
    }

    loadCallbacks();

    const script = document.createElement("script");
    script.src = MASTER_CARD_CHECKOUT_JS_SRC;

    script.async = true;
    script.onerror = reject;

    script["data-error"] = "errorCallback";
    script["data-cancel"] = "cancelCallback";
    script["data-complete"] = "completeCallback";
    script["data-timeout"] = "timeoutCallback";

    script.onload = async () => {
      createCheckoutSession({
        amount: amount,
        item: description,
        callback: async (response) => {
          await onScriptLoad({
            initialized: resolve,
            sessionId: response?.session_id,
            orderId: response?.order_id,
            amount: amount,
            description: description,
          });
        },
      });
    };
    document.body.appendChild(script);
  });
};

const CheckoutSessionComponent = (props) => {
  const {
    description,
    amount,
    createCheckoutSession,
    setRenderPay,
    subscribePlanFunction,
    updatePaymentResults,
    toggleNotification,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [initializing, setInitializing] = useState(false);
  const [error, setError] = useState();
  const firstLoad = useRef(false);

  const errorNotification = () => {
    toggleNotification({
      message: t("Something went wrong, please try again."),
      open: true,
      variant: "error",
    });
  };

  useEffect(() => {
    setInitializing(true);
    loadScript(description, amount, createCheckoutSession)
      .then(() => {
        setInitializing(false);
        pay();
      })
      .catch(() => {
        errorNotification();
        console.warn("CANT NOT LOAD MASTERCARD PAYMENT");
      });
  }, []);

  const cleanUp = () => {
    setRenderPay(false);
    const callbacksScript = document.getElementById("CheckoutSessionCallbacks");
    if (callbacksScript) {
      callbacksScript.remove();
    }
    const hostedCheckoutIframe = document.querySelector(
      `iframe[title="Hosted Checkout"]`
    );
    if (hostedCheckoutIframe) {
      hostedCheckoutIframe.remove();
    }
    const oldScript = document.querySelector(
      `script[src="${MASTER_CARD_CHECKOUT_JS_SRC}"]`
    );
    if (oldScript) {
      oldScript.remove();
    }
  };

  (() => {
    window.errorCallback = (err) => {
      console.log(JSON.stringify(err));
      if (err) setError(err);
      errorNotification();
      console.log("Payment Error", err);
      try {
        updatePaymentResults();
      } catch {}
      cleanUp();
    };
    window.cancelCallback = () => {
      console.log("Payment cancelled");
      cleanUp();
    };
    window.timeoutCallback = () => {
      console.log("Payment timeout");
      cleanUp();
    };
    window.completeCallback = (resultIndicator, sessionVersion) => {
      console.log(resultIndicator, sessionVersion);
      try {
        subscribePlanFunction();
        updatePaymentResults();
      } catch (error) {
        console.log("EEEEEEEEEEEEEEE", error);
      }
      cleanUp();
    };
  })();

  const pay = () => {
    const { Checkout } = window;
    if (!Checkout || firstLoad.current) {
      return;
    }

    firstLoad.current = true;
    Checkout.showLightbox("#embed-target");
  };

  return (
    <div className={classes.mainDiv}>
      <div className={classes.typographyDiv}>
        <Typography className={classes.typography}>
          {t("Loading, Please wait.")}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createCheckoutSession(payload) {
    dispatch(createCheckoutSession(payload));
  },
  updatePaymentResults(payload) {
    dispatch(updatePaymentResults(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutSessionComponent);
