import React from "react";

const CarDealership = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={0.6402 * size} viewBox="0 0 17 11" fill={fill} xmlns="http://www.w3.org/2000/svg">
<rect x="1.00342" y="4.20782" width="15.4275" height="2.10375" rx="1.05187" fill={fill}/>
<rect y="5.28708" width="7.92364" height="2.10375" rx="1.05187" transform="rotate(-41.8556 0 5.28708)" fill={fill}/>
<rect width="7.80508" height="2.10375" rx="1.05187" transform="matrix(0.744829 0.667255 0.667255 -0.744829 0.0881958 5.58292)" fill={fill}/>
</svg>

);
};

export default CarDealership;