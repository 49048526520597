import { Divider, Grid, Hidden } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  clearUnlockedPart,
  getCarGroups,
  getUnlockedPart,
} from "../../store/actions/car";
import ConditionReport from "../ConditionReport";

function UnlockedSection(props) {
  const {
    carId,
    classes,
    unlocked,
    getCarGroups,
    carScore,
    date,
    enteredBy,
    getUnlockedPart,
    clearUnlockedPart,
    unlocked_part,
    previouslyUnlocked,
  } = props;
  const [reportGroups, setReportGroups] = useState({});
  useEffect(() => {
    {
      getCarGroups({
        carId: carId,
        query: "for_details_report=1&",
        callback: (groups) => {
          let details = {};
          details["groups"] = groups;
          setReportGroups(details);
          if (carId != unlocked_part?.carId) {
            clearUnlockedPart();
          }
          getUnlockedPart({
            carId: carId,
            callback: (car) => {
              previouslyUnlocked.current = car.unlocked;
            },
          });
        },
      });
    }
  }, []);
  const dateObject = new Date(date);
  return (
    <>
      <Grid container className={classes.scorediv}>
        <Hidden mdUp>
          <ConditionReport
            carId={carId}
            noOfPoints={0}
            groups={reportGroups.groups}
            enteredBy={enteredBy}
            dateObject={dateObject}
            score={Math.floor(carScore)}
            unlocked={unlocked}
          />
          <Divider light />
        </Hidden>
      </Grid>
      {/* ///////////////////////////////////////////////////////// */}
      <Grid container className={classes.scorediv}>
        <Hidden smDown>
          <ConditionReport
            carId={carId}
            noOfPoints={0}
            groups={reportGroups.groups}
            enteredBy={enteredBy}
            dateObject={dateObject}
            score={Math.floor(carScore)}
            unlocked={unlocked}
          />
        </Hidden>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  carList: state.car.cars,
  carScore: state.car.car.score,
  date: state.car.car.created_at,
  enteredBy: state.car.car.entered_by_fullname,
  unlocked: state.car.car.unlocked,
  unlocked_part: state.car.unlocked_part,
});

const mapDispatchToProps = (dispatch) => ({
  getCarGroups(payload) {
    dispatch(getCarGroups(payload));
  },
  clearUnlockedPart(payload) {
    dispatch(clearUnlockedPart(payload));
  },
  getUnlockedPart(payload) {
    dispatch(getUnlockedPart(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlockedSection);
