import React, { Component } from "react";
const omit = (obj, omitKey) =>
  Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key];
    }
    return result;
  }, {});

const overlayStyles = {
  position: "absolute",
  filter: "blur(1px)",
  transition: "opacity ease-in 1000ms",
  clipPath: "inset(0)",
  top: 0,
  width: "100%",
  left: 0,

  height: "auto",
};

export default class ProgressiveImage extends Component {
  constructor(props) {
    super(props);
    this.state = { highResImageLoaded: false };
  }
  render() {
    const {
      overlaySrc,
      onClick,
      ref,
      noRelativeParent,
      smallImageRef,
      setSmallImageLoaded,
    } = this.props;
    const { highResImageLoaded } = this.state;
    let filteredProps = omit(this.props, "overlaySrc");
    return (
      <>
        <img
          {...filteredProps}
          onLoad={() => {
            this.setState({ highResImageLoaded: true });
          }}
          ref={(img) => {
            this.highResImage = ref ?? img;
          }}
          src={this.props.src}
          style={{
            height: "auto",
            // position: "absolute",
            top: 0,
            width: "100%",
          }}
          onClick={() => onClick()}
        />
        <img
          {...filteredProps}
          style={{ ...overlayStyles }}
          className={`${overlayStyles}`}
          {...(highResImageLoaded && {
            style: {
              opacity: "0",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              ...this.props.smallStyle,
            },
          })}
          ref={smallImageRef}
          src={overlaySrc}
          onClick={() => onClick()}
          onLoad={() => {
            setSmallImageLoaded && setSmallImageLoaded(true);
          }}
        />
      </>
    );
  }
}
