import React from "react";

const LoudSpeakerIcon = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 28 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.24713 20.623L8.0001 25.3916C8.59417 26.4217 9.93051 26.8031 10.9873 26.1922C12.033 25.5878 12.3924 24.2517 11.7875 23.2051L10.1475 20.3642L11.5677 19.5442C11.8291 19.393 11.9188 19.0588 11.7678 18.7974L11.0569 17.5662C11.1417 17.5497 11.6991 17.4404 21.5277 15.5123C22.7428 15.4463 23.4707 14.1175 22.857 13.0551L21.0402 9.90835L22.2012 8.14984C22.3153 7.97687 22.3221 7.75458 22.2185 7.5752L21.1252 5.68152C21.0216 5.50214 20.825 5.39708 20.6189 5.40925L18.5157 5.53546L16.4988 2.04192C16.2067 1.53583 15.6833 1.2294 15.0991 1.22214C15.092 1.22192 15.0852 1.22192 15.0781 1.22192C14.5135 1.22192 14.001 1.50294 13.6974 1.97807L6.55613 11.0754L1.91353 13.7557C0.0875432 14.8096 -0.544966 17.1521 0.512917 18.983C1.47941 20.6572 3.51702 21.3157 5.24713 20.623V20.623ZM10.8408 23.752C11.1428 24.2743 10.9641 24.9427 10.4402 25.2453C9.91471 25.5492 9.24547 25.3625 8.94694 24.8451L6.21361 20.1103L8.1073 19.017C11.2707 24.4969 10.79 23.6643 10.8408 23.752V23.752ZM9.60081 19.4171L9.05414 18.4703L10.001 17.9236L10.5476 18.8705L9.60081 19.4171ZM20.3473 6.52074L21.1028 7.82932L20.4291 8.84962L19.1269 6.59398L20.3473 6.52074ZM14.6118 2.57748C14.7585 2.33746 14.9912 2.31269 15.0856 2.31525C15.1789 2.31632 15.4115 2.34536 15.5518 2.58837L21.9102 13.6018C22.1173 13.9603 21.8655 14.4111 21.4502 14.4213C21.3757 14.423 21.3413 14.4365 21.1504 14.4722L14.4245 2.82242C14.5656 2.64091 14.5838 2.62339 14.6118 2.57748V2.57748ZM13.697 3.74918L20.0163 14.6947L10.4791 16.5653L7.57921 11.5426L13.697 3.74918ZM1.45976 18.4363C1.21974 18.021 1.0929 17.5495 1.0929 17.0724C1.0929 16.0974 1.61693 15.1894 2.4602 14.7026L6.72099 12.2426L9.45432 16.9768L5.19353 19.437C3.88815 20.1904 2.21335 19.7415 1.45976 18.4363V18.4363Z"
        fill={fill}
      />
      <path
        d="M27.1758 2.39143L24.1296 4.08652C23.8659 4.23343 23.7708 4.56634 23.9177 4.83007C24.0642 5.09358 24.3969 5.18903 24.6613 5.0419L27.7073 3.34681C27.9712 3.19989 28.066 2.86698 27.9191 2.60326C27.7724 2.33932 27.4395 2.24451 27.1758 2.39143V2.39143Z"
        fill={fill}
      />
      <path
        d="M26.8592 8.05317L24.747 7.48728C24.4553 7.40913 24.1555 7.58209 24.0774 7.87379C23.9992 8.16549 24.1724 8.46509 24.4641 8.54324L26.5764 9.10913C26.8698 9.18793 27.1682 9.01218 27.2459 8.72262C27.3241 8.43092 27.1509 8.13111 26.8592 8.05317Z"
        fill={fill}
      />
      <path
        d="M21.5501 0.405285L20.9836 2.51763C20.9055 2.80933 21.0784 3.10893 21.3701 3.1873C21.6616 3.26524 21.9614 3.0927 22.0398 2.80079L22.6061 0.68844C22.6842 0.396743 22.5113 0.0969315 22.2196 0.0187754C21.9281 -0.0593806 21.6283 0.113588 21.5501 0.405285V0.405285Z"
        fill={fill}
      />
    </svg>
  );
};
export default LoudSpeakerIcon;
