// const API_ROOT = process.env.REACT_APP_HOST_URL || "https://localhost:8000/api";
const API_ROOT = "https://api.markabte.com/api";
// const API_ROOT = "http://localhost:8000/api";
// const API_ROOT = "http://192.168.1.120:8000/api";
// const API_ROOT = "http://192.168.1.121:8000/api";
// const API_ROOT = "http://192.168.100.118:8000/api";
// const API_ROOT = "http://192.168.1.93:8000/api";
// const API_ROOT = "http://192.168.0.188:8000/api";
// const API_ROOT = "http://192.168.0.125:8000/api";
// const API_ROOT = "http://192.168.8.105:8000/api";
// const API_ROOT = "http://192.168.8.108:8000/api";
// const API_ROOT = "http://192.168.1.99:8000/api";
// const API_ROOT = "http://192.168.100.107:8000/api";

const API_URLS = (extraData) => ({
  ROOT: API_ROOT,
  AUTH: {
    OBTAIN_TOKEN: API_ROOT + "/token-auth/",
    CHECK_PHONE: API_ROOT + "/unique-phone/",
    SEND_OTP: API_ROOT + "/send-otp/",
    VALIDATE_OTP: API_ROOT + "/validate-otp/",
    SIGNUP_USER: API_ROOT + "/register-user/",

    UPDATE_INFO: API_ROOT + "/update-account/", //check here
    CHECK_PASS: API_ROOT + "/check-password/", //check here

    OBTAIN_REFRESH_TOKEN: API_ROOT + "/token-refresh/",
    // ACTIVATE_ACCOUNT: API_ROOT + "/account_activation/",
    FORGOT_PASSWORD: API_ROOT + "/user-reset-password/", //
    CONFIRM_PASSWORD: API_ROOT + "/reset_confirm/",
    RESET_PASSWORD: API_ROOT + "/user-reset-password/",
    ERROR_LOG: API_ROOT + "/bugs-log/bugs_log/",
  },
  LISTINGS: {
    REQUEST:
      API_ROOT +
      "/dealership/listing-requests/" +
      extraData +
      "/request_listings/", // action name in BE viewset
  },
  CAR_FORM: {
    FORM_FIELDS:
      API_ROOT + "/car-forms/fields/?form=" + extraData + "&is_feature=false",
    FIELD_OPTIONS: API_ROOT + "/car-forms/fields/?id=" + extraData,
    YEARS: API_ROOT + "/car-forms/fields/?id=" + extraData,
  },
  CAR: {
    UPDATE_FORM:
      API_ROOT + "/car/details/" + extraData + "/update_car_form_info/",
    LIST: API_ROOT + "/car/cards-gtr/" + extraData,
    DETAILS: API_ROOT + "/car/details/" + extraData + "/",
    PAGE: API_ROOT + "/car/page/" + extraData + "/",
    DETAILS_IMAGES: API_ROOT + "/car/details-images/" + extraData + "/",
    CAR_FIELDS: API_ROOT + "/car/fields/" + extraData,
    CAR_FORMS: API_ROOT + "/car/forms/" + extraData,
    CAR_FEATURES: API_ROOT + "/car/fields/?is_feature=true&" + extraData,
    CAR_FAULTS: API_ROOT + "/car/faulty-condition/?" + extraData,
    CAR_VISUALIZATION: API_ROOT + "/car/visualization/?" + extraData,
    CAR_VISUALIZATION_IDs:
      API_ROOT + "/car/visualization-available/?" + extraData,
    CAR_UNLOCKED_PART: API_ROOT + "/car/blocking/?" + extraData,
    CAR_GROUPED_FIELDS: API_ROOT + "/car/grouped-fields/" + extraData,
    REPORT_CAR_PURCHASE: API_ROOT + "/car/license-images/",
    GET_UNLOCKED_CARS: API_ROOT + "/car/get-unlocked-cars/",
    GET_SIMPLE_CAR_DATA:
      API_ROOT + "/car/get-simple-car-data/" + extraData + "/",
    GET_VHinfo: API_ROOT + "/car/VHinfo/",
    GET_CAR_FEATURES: API_ROOT + "/car/car_features/",
  },
  NOTIFICATIONS: {
    UPDATE_STAMP: API_ROOT + "/live/page-stamp/" + extraData + "/page_stamp/",
    GET_STAMPS: API_ROOT + "/live/page-stamp/" + extraData + "/",
    GET_UNSEEN_NOTIFICATIONS:
      API_ROOT + "/live/page-stamp/" + extraData + "/unseen_notifications/",
  },
  DEALERSHIP: {
    //   GET_APPOINTMENT_INTERVAL:
    //     API_ROOT + "/dealership/opening-hours/" + extraData,
    CALL_DEALERSHIP_ANONYMOUSLY: API_ROOT + "/call-dealership/",
    GET_DEALER_HOURS: API_ROOT + "/dealership/available-hours/" + extraData,
    REQUEST_RUNNER_CALL: API_ROOT + "/call-runner/",
    SELL_REQUEST: API_ROOT + "/request-car-to-sell/",
    REQUEST_WHATSAPPMESSAGE: API_ROOT + "/msg-runner/",
  },
  // APPOINTMENTS: {
  //   APPT: API_ROOT + "/appointments/appt/",
  //   BOOK_APPOINTMENT: API_ROOT + "/appointments/appointments/",
  //   EDIT_APPOINTMENT: API_ROOT + "/appointments/appointmentshistory/",
  //   GET_APPOINTMENTS: API_ROOT + "/appointments/appointments/" + extraData,
  //   SET_REMOVED: API_ROOT + "/appointments/appointments/" + extraData + "/",
  //   GET_SOLD_APPOINTMENTS:
  //     API_ROOT + "/appointments/sold-appointments/" + extraData,
  //   RESET_SOLD_APPOINTMENTS:
  //     API_ROOT +
  //     "/appointments/sold-appointments/" +
  //     extraData +
  //     "/reset_notification/",
  //   USER_ATTENDED: API_ROOT + "/appointments/appointments/" + extraData + "/",
  //   SET_APPOINTMENT_REMOVED:
  //     API_ROOT + "/appointments/appointments/" + extraData + "/",
  //   GET_DEACTIVATED_CARS:
  //     API_ROOT +
  //     "/appointments/deactivated-cars/" +
  //     extraData +
  //     "/get_deactivated_cars/",
  // },
  MARKETING: {
    VISITOR: API_ROOT + "/marketing/website-vistors-landing/",
  },
  ACCOUNTS: {
    ACCOUNT: API_ROOT + "/accounts/join-our-network/",
    BECOME_AN_OFFICER: API_ROOT + "/accounts/become-an-officer/",
    GET_CREDIT: API_ROOT + "/accounts/get-credits-info/",
    UNLOKED_CARS_INFO: API_ROOT + "/accounts/get-unlocked-info/",
  },
  SUBSCRIPTION_PLANS: {
    LIST: API_ROOT + "/subscription-plans/list-plans",
    UNLOCKCAR: API_ROOT + "/subscription-plans/unlockCar",
    SUBSCIBE: API_ROOT + "/subscription-plans/subscribePlan",
    ADD_CREDITS: API_ROOT + "/subscription-plans/add-credits",
  },
  PAYMENT: {
    CREATE_SESSION: API_ROOT + "/payment/create-session",
    PAY: API_ROOT + "/payment/pay",
    CREATE_CHECKOUT_SESSION: API_ROOT + "/payment/checkout-session",
    UPDATE_PAYMENT_RESULTS: API_ROOT + "/payment/update-results",
  },
  METRICS: {
    CONFERENCE: API_ROOT + "/metrics/call-dealership-record/",
    VDP: API_ROOT + "/metrics/car-details-record/",
    CARD: API_ROOT + "/metrics/car-card-record/",
  },
  TIME_SLOTS: {
    GET_CITIES: API_ROOT + "/time_slots/cities/",
    GET_AREAS: API_ROOT + "/time_slots/areas/",
    GET_MAKE: API_ROOT + "/time_slots/makes/",
    GET_MODELS: API_ROOT + "/time_slots/models/?limit=5000",
    GET_AVAILABLE_APPOINTMENTS: API_ROOT + "/group-by-date-and-area/",
    // ADD_APPOINTMENT: API_ROOT + "/add-appointment/",
    ADD_APPOINTMENT: API_ROOT + "/time_slots/reserved_time_slots/" + extraData,
  },
});

export default API_URLS;
