import {
  Button,
  Collapse,
  Divider,
  ExpansionPanel,
  Grid,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { CarFeatures } from "../../store/actions/car";
import FeatureIcons from "../../helpers/FeatureIcons";
import useGeneralStyles from "./generalStyles";
import Moment from "moment";
import { logVisitorView } from "../../store/actions/accounts";

function CarFeatureList(props) {
  const { t, small, carId, CarFeatures, logVisitorView } = props;
  const [carData, setCarData] = useState({});
  const [openFeature, setOpenFeature] = useState(false);
  const [features, setfeatures] = useState({});
  const vehicleFeaturesRef = useRef();
  const classes = useGeneralStyles();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentId, setCurrentId] = useState(
    string_between_strings("_ga=", ";", document.cookie)
  ); // two years
  const [currentGid, setCurrentGid] = useState(
    string_between_strings("_gid=", ";", document.cookie)
  ); /// 24 hours
  const [startTime, setStartTime] = useState(
    Moment().locale("en-GB").utc().format("YYYY-MM-DD HH:mm:ss.SSS")
  );
  const vehicleFeatures = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  function string_between_strings(startStr, endStr, str) {
    let pos = str.indexOf(startStr) + startStr.length;
    return str.substring(pos, str.indexOf(endStr, pos));
  }

  useEffect(() => {
    CarFeatures({
      carId: carId,
      callback: (response) => {
        setCarData(response);
      },
    });
  }, []);

  useEffect(() => {
    if (carData?.generic_features_names) {
      var f = {};
      carData.generic_features_names.map((feat) => {
        let key =
          feat.field_tag.id == 16 ? feat.field_title : feat.option_title;
        f[key] = feat.selected_option;
      });
      setfeatures(f);
    }
  }, [carData]);

  const getFeatureId = (feature) => {
    return features[feature.title];
  };

  useEffect(() => {
    const options = {};
    const actionButtonObserver = new IntersectionObserver(
      (entries, actionButtonObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(entry.isIntersecting);
            actionButtonObserver.unobserve(vehicleFeatures.current);
            logVisitorView({
              page_title: currentPath,
              start_time: startTime,
              session_one: currentId,
              session_two: currentGid,
              tag: "Vehicle Features Title",
            });
          } else if (
            !entry.isIntersecting &&
            entry?.boundingClientRect?.y < 0
          ) {
            setIsVisible(entry.isIntersecting);
          } else {
            // not intersecting but came from below (we dont want it to re appear (keeps true from first if))
          }
        });
      },
      options
    );
    if (vehicleFeatures.current) {
      actionButtonObserver.observe(vehicleFeatures.current);
    }
    return () => {
      if (vehicleFeatures.current) {
        actionButtonObserver.unobserve(vehicleFeatures.current);
      }
    };
  });
  return (
    <>
      <ExpansionPanel
        defaultExpanded={true}
        disabled={true}
        style={{
          all: "unset",
          marginBottom: "32px",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          justify="space-between"
          classes={{ root: classes.headerwrap }}
        >
          <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
            <Typography
              ref={vehicleFeatures}
              style={
                localStorage.getItem("language") === "ar"
                  ? { fontWeight: "700" }
                  : { fontWeight: "500" }
              }
              className={classes.header}
              noWrap
            >
              {t("Vehicle Features")}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs>
              <Divider component="div" classes={{ root: classes.Divider }} />
            </Grid>
          </Hidden>
        </Grid>
        <Collapse
          in={openFeature}
          collapsedHeight={small ? 180 : 260}
          ref={vehicleFeaturesRef}
          style={{ position: "relative" }}
          timeout={700}
        >
          <ul style={{ margin: 0, padding: 0, width: "100%" }}>
            <Grid container>
              {carData?.generic_features_names ? (
                carData?.generic_features_names?.length > 0 ? ( //##
                  carData?.generic_features_names?.map((feature, i) => (
                    <Grid item xs={6} md={6} style={{ position: "relative" }}>
                      <ListItem
                        style={{
                          padding: "0 0 0 13px",
                        }}
                        key={`feature_first_set_${i}`}
                      >
                        <ListItemIcon
                          classes={{ root: classes.listItemIconRoot }}
                        >
                          <FeatureIcons
                            iconId={getFeatureId({
                              title:
                                feature.field_tag.id == 16
                                  ? feature.field_title
                                  : feature.option_title,
                              id: feature.selected_option,
                            })}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            style={
                              localStorage.getItem("language") === "ar"
                                ? { fontWeight: "600" }
                                : { fontWeight: "400" }
                            }
                            classes={{
                              root: classes.listItemTextRoot,
                            }}
                          >
                            {feature.field_tag.id == 16
                              ? feature.field_title
                              : feature.option_title}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                  ))
                ) : (
                  <Typography>
                    {t("No Features available for this car!")}
                  </Typography>
                )
              ) : (
                <Grid container>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
                    let inc = small ? 5 : 10;
                    return (
                      <Grid item xs={6}>
                        <Skeleton
                          style={{
                            width: `${80 + i * inc}px`,
                            height: "21px",
                            marginBottom: "18px",
                            transform: "none",
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </ul>
          {!openFeature && carData?.generic_features_names?.length > 12 ? (
            <div
              style={{
                position: "absolute",
                top: "100%",
                transform: "translate(0,-100%)",
                width: "100%",
                height: "105px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 16.98%, #FFFFFF 99.99%, #FFFFFF 100%)",
              }}
            />
          ) : null}
        </Collapse>
        {carData?.generic_features_names?.length > 12 ? (
          <>
            <Divider light style={{ height: "1px", width: "100%" }} />
            <Grid container style={{ width: "100%" }}>
              <Button
                classes={{ root: classes.showMoreAndLess }}
                fullWidth
                onClick={() => {
                  setOpenFeature(!openFeature);
                  window.scrollTo({
                    left: 0,
                    top: vehicleFeaturesRef.current.offsetTop,
                    behavior: "smooth",
                  });
                  !openFeature &&
                    logVisitorView({
                      page_title: currentPath,
                      start_time: startTime,
                      session_one: currentId,
                      session_two: currentGid,
                      tag: "Show More Features Button",
                    });
                }}
              >
                <Grid item>
                  <Typography className={classes.showMoreAndLessText}>
                    {!openFeature ? t("Show more") : t("Show less")}
                  </Typography>
                </Grid>
                <Grid item>
                  <ExpandMoreIcon
                    className={[classes.arrow]}
                    style={
                      openFeature
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            color: "#00BDFF",
                            height: "100%",
                            transform: "rotate(-180deg)",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            color: "#00BDFF",
                            height: "100%",
                            transform: "rotate(0)",
                          }
                    }
                  />
                </Grid>
              </Button>
            </Grid>
          </>
        ) : null}
      </ExpansionPanel>
      <Hidden mdUp>
        <Divider
          light
          style={{
            height: "9px",
            width: "calc(100% + 32px)",
            margin: "0 -16px",
            // margin: "0 -12px 0 -12px",
          }}
        />
      </Hidden>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  CarFeatures(payload) {
    dispatch(CarFeatures(payload));
  },
  logVisitorView(payload) {
    dispatch(logVisitorView(payload));
  },
});

export default connect(null, mapDispatchToProps)(CarFeatureList);
