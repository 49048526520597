import {
  LOG_CONFERENCE_CALL,
  LOG_VDP_VIEW,
  LOG_CAR_CARD_VIEW,
} from "../constants";

export function logConferenceCall(payload) {
  return {
    type: LOG_CONFERENCE_CALL,
    payload: payload,
  };
}

export function logVdpView(payload) {
  return {
    type: LOG_VDP_VIEW,
    payload: payload,
  };
}

export function logCarCardView(payload) {
  return {
    type: LOG_CAR_CARD_VIEW,
    payload: payload,
  };
}
