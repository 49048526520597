import { Button, Grid, Paper } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React from "react";
import Slider from "react-slick";
import theme from "../../../theme";
import PositionSwitch from "./PositionSwitch";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import ProgressiveImage from "../../ProgressiveImage";
import useGeneralStyles from "../generalStyles";

function CarImagesDialog(props) {
  const {
    carImages,
    activeImage,
    setSwitchState,
    setActiveImage,
    setPositionActiveImage,
    positionImages,
    setOpenDialog,
    backgroundRef,
    switchState,
    handleNextImage,
    handlePreviousImage,
    nextClicked,
    backClicked,
    innerSlickRef,
    loadedCompleted,
    setLoadedCompleted,
    actions,
  } = props;

  const fullScreenSettings = {
    //number of images
    // props for the image stepper from react-slick
    arrows: false, // include arrows
    dots: true, // include dots
    //true became carImages.length > 5 to solve duplication in case of images less than slidesToShow setting below,
    infinite: true, // to keep scrolling after last and firs images back to first and last image respectivly
    speed: 250, // scroll speed
    slidesToShow: 5, // No. of images shown in the slider
    slidesToScroll: 1, // number of images to scroll when the arrow is clicked
    // initialSlide: switchState ? 0 : extLength, // initial image (vehicle featured image)
  };

  const handleSwitchChange = (event) => {
    // swich change function the value of switchState will later be used to render interior or exterior images
    setSwitchState(event.target.checked);
    setActiveImage(
      event.target.checked
        ? 0
        : !switchState
        ? positionImages.length - 1
        : positionImages.length
    );
    setPositionActiveImage(0);
  };
  const classes = useGeneralStyles();

  return (
    <div
      ref={backgroundRef}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.95)",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: "2000",
      }}
    >
      <div>
        <div
          style={{
            height: "70%",
            position: "fixed",
            top: "40%",
            left: "50%",
            backgroundColor: "rgba(0,0,0,0.95)",
            transform: "translate(-50%,-50%)",
            height: "68.71vh",
            width: "91.61vh",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: "2000",
              height: "68.71vh",
              width: "91.61vh",
            }}
          >
            <ProgressiveImage
              alt={"car-image-" + activeImage}
              overlaySrc={actions.imageLocalization(carImages[activeImage])}
              src={carImages[activeImage]}
              onClick={() => {
                actions.handleImgClick();
              }}
            />
          </div>
          <PositionSwitch
            setPositionExternally={switchState}
            onChange={(event) => handleSwitchChange(event)}
            style={{
              position: "fixed",
              top: "10%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: "2000",
            }}
          />
          <Grid
            style={{ zIndex: "2222" }}
            item
            className={classes.previosIconInDialog}
          >
            <Button
              className={classes.imagePreviousButton}
              onClick={
                theme.direction === "rtl"
                  ? handleNextImage
                  : handlePreviousImage
              }
            >
              {theme.direction === "ltr" ? (
                <KeyboardArrowLeft
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: nextClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              ) : (
                <KeyboardArrowRight
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: backClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              )}
            </Button>
          </Grid>
          <Grid
            style={{ zIndex: "2222" }}
            item
            className={classes.nextIconInDialog}
          >
            <Button
              className={classes.imageNextButton}
              onClick={
                theme.direction === "rtl"
                  ? handlePreviousImage
                  : handleNextImage
              }
            >
              {theme.direction === "ltr" ? (
                <KeyboardArrowRight
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: backClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              ) : (
                <KeyboardArrowLeft
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: nextClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              )}
            </Button>
          </Grid>
        </div>
        <CloseIcon
          className={classes.imagePopperCloseIcon}
          onClick={() => {
            setOpenDialog(false);
          }}
        ></CloseIcon>
        <Paper
          style={{
            marginBottom: "20px",
            position: "fixed",
            top: "88%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "2000",
            // width: "60%",
            backgroundColor: "rgba(0,0,0,0)",
            width: "91.61vw",
          }}
        >
          <Slider
            {...fullScreenSettings}
            ref={innerSlickRef}
            rtl={localStorage.getItem("language") === "en" ? null : true}
          >
            {carImages.map((image, i) => {
              return (
                <div
                  style={{
                    width: "fit-content",
                  }}
                  key={"car-image-container" + i}
                >
                  <img
                    style={{
                      width: "calc(13.68vw - 5px)",
                      height: "calc(10.26vw - 3.75px)",
                      marginRight: "18px",

                      cursor: "pointer",
                    }}
                    src={actions.imageLocalization(image)}
                    alt={"car-image-" + i}
                    className={
                      i != activeImage ? classes.carImage : classes.carImage2
                    }
                    onClick={() => setActiveImage(i)}
                  />
                </div>
              );
            })}
          </Slider>
        </Paper>
      </div>
    </div>
  );
}

export default CarImagesDialog;
