import { makeStyles } from "@material-ui/core";

const useGeneralStyles = makeStyles((theme) => ({
  firstGridItemHome: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 16.73px",
      minWidth: "269.27px",
      maxWidth: "269.27px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 8.34px",
      minWidth: "263.32px",
      maxWidth: "263.32px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 9.38px",
      minWidth: "296.24px",
      maxWidth: "296.24px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 11.166px",
      minWidth: "351px",
      maxWidth: "351px",
    },
  },
  firstPaper: {
    margin: "7.5px 0",
    padding: "0.5em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px rgba(15, 71, 90, 0.4)",
    borderRadius: "0",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0",
    },
  },
  imagePaper: {
    minHeight: "200px",
    margin: "-8px",
    position: "relative",
    borderRadius: "0",
    boxShadow: "none",
  },
  imagePaperHome: {
    minHeight: "200px",

    margin: "-8px",
    position: "relative",
    borderRadius: "0",
    boxShadow: "none",
  },
  Image: {
    objectFit: "cover",
  },
  ImageHome: {
    objectFit: "cover",
  },
  overlaySecondTypography: {
    marginLeft: "auto",
  },
  locationIcon: {
    marginRight: "4px",
  },
  dialogContentText: {
    padding: "0",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "298px",
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  milageTypography: {
    fontSize: "12px",
    color: "#07303E",
    padding: "0 12px 0 5px",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainTextDiv: {
    padding: "4px 8px 8px",
    zIndex: 2,
    position: "relative",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: "4px 8px 16px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "4px 8px 8px",
    },
  },
  lastRowTypography: {
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  likeIconButton: {
    transform: "translate(-100%,-76.47%)",
    left: "100%",
    position: "absolute",
    top: "100%",
    padding: 0,
  },
  soldIconHover: {
    "&:hover": {
      cursor: "auto",
    },
  },

  unlockedMainDiv: {
    position: "absolute",
    top: "9.5px",
    left: "9.5px",
    backgroundColor: "rgba(218, 218, 218, 0.5)",
    borderRadius: "22.78px",
    display: "flex",
    transformOrigin: "left top",
    [theme.breakpoints.up("md")]: {
      transform: "scale(1.25)",
    },
    [theme.breakpoints.up("lg")]: {
      transform: "none!important",
    },
  },
  unlockedIconDiv: {
    backgroundColor: "#51B583",
    borderRadius: "50%",
    width: "22.78px",
    height: "22.78px",
    display: "flex",
  },
  unlockedIcon: {
    margin: "auto",
  },
  unlockedTypography: {
    color: "#626465",
    fontSize: "10.13px",
    fontWeight: "500",
    padding: "0 9.63px 0 4px",
    margin: "auto",
  },
  blur: {
    filter: "blur(6px)",
  },
  inspectedDiv: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
    WebkitUserSelect: "none",
    backgroundColor: "rgba(24, 105, 133, 0.05)",
    padding: "3px 8px",
    borderRadius: "50em",
  },
  inspectedTypography: {
    color: "#626465",
    fontSize: "11px",
    marginLeft: "4px",
    alignSelf: "center",
  },
  typoChip: {
    whiteSpace: "nowrap",
    fontSize: "14px",
    marginBottom: "5px",
  },
  container: {
    padding: "0",
  },
  navigationPaper: {
    display: "flex",
    backgroundColor: "#f3f3f3",
    borderRadius: 0,
    boxShadow: "none",
    height: "39px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#186985",
    },
  },
  breadcrumbsComponent: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      width: "927px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1009px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(320px + 70.414vw)",
    },
  },
  maindiv: {
    padding: "0 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  mediaGrid: {
    position: "relative",
    height: "auto",
    [theme.breakpoints.up("md")]: {
      maxHeight: "52.4419vw",
      minHeight: "42.4419vw",
      marginBottom: "0.53155vw",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "655px",
      marginBottom: "6.09px",
      minHeight: "505px",
    },
  },
  media: {
    // marginBottom: "14px",
    marginBottom: "0.721392vw",
    height: "auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      // margin: "0 -16px 18px",
      margin: "0 0 18px",
      maxHeight: "70vw",
      // maxHeight: "50vh",/
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "52.4419vw",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "655px",
    },
  },
  scorediv: {
    justifyContent: "spaceBetween",
    width: "100%",
  },
  heading: {
    padding: "10px",
    fontSize: "1.1rem",
    fontWeight: "700",
    color: "#0c6885",
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#07303E",
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "12px 0",
      fontWeight: "500",
      fontSize: "16px",
      color: "#186985",
    },
  },
  scoreTitle: {
    padding: "0",
    fontSize: "22px",
    fontWeight: "500",
    color: "#07303E",
  },
  scoredivv: {
    flex: "right",
    justifyContent: "spaceBetween",
    width: "100%",
  },
  viewicon: {
    position: "absolute",
  },
  iconsize: {
    fontSize: "50px",
    fill: "primary",
  },
  rep: {
    fontSize: "25px",
    display: "flex",
    justifyContent: "center",
  },
  arrow: {
    height: "20px",
    dropdownOpen: {
      transform: "rotate(-180deg)",
    },
    dropdownClosed: {
      transform: "rotate(0)",
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    margin: "50px 36px 50px 23px",
    padding: "12px 4.444vw",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 80px",
      padding: "0 16px",
      borderRadius: 0,
    },
  },
  table: {
    width: "100%",
    fontSize: "1rem",
  },
  firstitem: {
    borderRight: "1px dashed black",
    padding: "5px 0 5px 20px",
    fontWeight: "700",
  },
  seconditem: {
    paddingLeft: "20px",
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
      color: "#186985",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#186985",
      padding: "0",
      margin: "34px 0",
    },
  },
  card: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
  maingrid: {
    justifyContent: "spaceAround",
    width: "100%",
  },
  buttons: {
    fontSize: "50px",
  },
  paper: {
    margin: "0px 0 16px",
    fontSize: "30px",
    padding: "0",
  },
  textOverlay: {
    display: "inline",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
  },
  rotate: {
    transform: "rotate(90deg)",
    width: "100%",
    height: "100%",
  },
  fullland: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "100%",
    width: "auto",
  },
  fullport: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(90deg)",
    height: "auto",
    width: "150%",
    transition: "none;",
  },
  grid: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    height: "100%",
    transition: "none;",
  },
  portexit: {
    position: "fixed",
    top: "95%",
    left: "95%",
    transform: "translate(-50%, -50%) rotate(90deg)",
  },
  landexit: {
    position: "fixed",
    top: "5%",
    left: "95%",
    transform: "translate(-50%, -50%)",
  },
  portdiv: {
    transform: "translate(-50%,-50%) rotate(90deg)",
    transformOrigin: "50% 50%",
  },
  Link: {
    fontSize: "18px",
    cursor: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "line-below",
    },
  },
  detailItem: {
    marginTop: "0",
    marginBottom: "0",
  },
  detailName: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#07303E",
    padding: "16px 16px 16px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px 16px",
    },
  },
  detail: {
    paddingLeft: "15%",
    fontSize: "14px",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "14px",
      fontSize: "12px",
    },
  },
  expandedExpansionPanelSummary: {
    margin: 0,
  },
  featureLI: {
    textAlign: "left",
    display: "inline-block",
    listStyle: "none",
    padding: 0,
    position: "relative",
    margin: "12px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "7px 0",
    },

    "&::before": {
      // before has :: instead of : as hover
      color: "#C4C4C4",
      content: '"\\2022"', // content must be double quoted to work
      /* Unicode of character to precede the list item */
      display: "inline-block",
      fontSize: "2em",
      left: "-13px",
      position: "absolute",
      top: "0em",
      margin: "-10px 0",
    },
  },
  previosIcon: {
    position: "absolute",
    left: 0,
    top: "50%",
    top: "24.221vw",
    transform: "translate(0,-50%)",
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      top: "277.5px",
    },
  },
  nextIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    top: "24.221vw",
    transform: "translate(0,-50%)",
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      top: "277.5px",
    },
  },
  previosIconInDialog: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(0,-50%)",
    zIndex: 1,
  },
  nextIconInDialog: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(0,-50%)",
    zIndex: 1,
  },
  imageStepCount: {
    top: "calc(100% - 12px)",
    zIndex: "1",
    position: "absolute",
    left: "19px",
    borderRadius: "5px",
    transform: "translate(0,calc(-100% - 12px))",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    [theme.breakpoints.up("lg")]: {
      // top: "555px",
    },
  },
  imagePreviousButton: {
    padding: 0,
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    borderRadius: 0,
    minWidth: "0px",
    width: "3.5vw",
    height: "6vw",
  },
  imageNextButton: {
    padding: 0,
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0.7) -52.5%, rgba(0, 0, 0, 0) 138.33%)",
    borderRadius: 0,
    minWidth: "0px",
    width: "3.5vw",
    height: "6vw",
  },
  vehicleDetailsExpansionPanelDetails: {
    display: "block",
    padding: "0 0 0!important",
    display: "grid!important",
    [theme.breakpoints.down("md")]: {
      display: "block!important",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 -16px!important",
      border: "none!important",
    },
  },
  cardContentDiv: {
    display: "flex",
    justifyContent: "space-around",
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0 20px",
      justifyContent: "space-between",
    },
  },
  iconDiv: {
    display: "inline-grid",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  iconTypography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      height: "max-content",
      margin: "auto",
      marginLeft: "5px",
    },
  },
  cardContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  secondIconTypography: {
    fontSize: "14px",
    marginLeft: "auto",
  },
  secondIconDiv: {
    justifySelf: "center",
    marginRight: "13px",
  },
  firstHr: {
    height: "1px",
    margin: "0 -16px 30px",
    width: "calc(100% + 32px)",
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px 16px",
    },
  },
  priceTypography: {
    fontSize: "24px",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    color: "#F15A29",
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  priceinstallmentsTypography: {
    fontSize: "18px",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  priceNegotiableDiv: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginTop: "22px",
      justifyContent: "space-between",
    },
  },
  negotiableFlagMainDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  //////////////////////////////////
  mainDetailAppointmentDiv: {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  mainDetailDiv: {
    width: "100%",
    maxWidth: "725px",
    margin: "45px 25px 0 auto",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      minWidth: "100%",
      flexGrow: 0,
      margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "63.28vw",
      flexGrow: 0,
      margin: "45px 21px 0",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "725px",
      minWidth: "725px",
      flexGrow: 0,
      margin: "45px auto 0 auto",
    },
    [theme.breakpoints.up("xl")]: {},
  },
  mainDetailDiv2: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  mainInspectionDiv: {
    margin: "41.13px auto 45px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 0",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 42px)",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1040px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1165px",
    },
  },
  negotiableFlagSecondDiv: {
    borderRadius: "50%",
    border: "2px solid #07303E",
    height: "36px",
    width: "36px",
    marginRight: "-26px",
    position: "relative",
    backgroundColor: "white",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      height: "28px",
      width: "28px",
    },
  },
  negotiableFlagTextDiv: {
    borderRadius: "100rem",
    backgroundColor: "#07303E",
    height: "28.57px",
    paddingLeft: "26px",
    [theme.breakpoints.down("sm")]: {
      height: "20px",
    },
  },
  negotiableFlagTextTypography: {
    margin: "4px 18.5px 2px 11.5px",
    width: "max-content",
    color: "white",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      margin: "3px 12px 2px 12.2px",
    },
  },
  installmentsFirstTypography: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#07303E",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  installmentsSecondTypography: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#07303E",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  priceDiv: {
    // marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  carid: {
    fontFamily: "poppins",
    background: "#F3F3F3",
    padding: "3px 10px",
    color: "#636363",
    [theme.breakpoints.down("sm")]: {
      padding: "3px 7px",
      height: "fit-content",
      background: "#F3F3F3",
      fontFamily: "poppins",
      fontSize: "10px",
      margin: "auto 0",
      whiteSpace: "nowrap",
    },
  },
  fourthHr: {
    height: "1px",
    // margin: "32px -20px 10px",
    // margin: "32px 0 10px",
    margin: "0 -16px 30px", ////new
    width: "calc(100% + 32px)", ////new
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      // margin: "12px -20px 10px",
      margin: "12px 0 10px", ////////
      margin: "0 -16px 30px", ////new
      width: "calc(100% + 32px)", ////new
    },
  },
  listItem: {
    height: "100%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  fifthHr: {
    height: "1px",
    // margin: "28px -20px 30px",
    // margin: "28px 0 30px",
    margin: "0 -16px 30px", ////new
    width: "calc(100% + 32px)", ////new
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      // margin: "10px -20px 0",
      // margin: "10px 0 0",
      margin: "0 -16px 30px", ////new
      width: "calc(100% + 32px)", ////new
    },
  },
  detailNameGrid: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      // padding: "5px 0 5px 0px",
    },
  },
  iconComponent: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "12px",
    },
  },
  webFeaturesHr: {
    height: "1px",
    border: "none",
    marginTop: "1px",
    position: "absolute",
    right: 0,
    top: "100%",

    width: "300%",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  mobFeaturesHr: {
    height: "1px",
    border: "none",
    marginTop: "1px",
    position: "absolute",
    right: 0,
    top: "100%",
    width: "200%",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  maximizeDiv: {
    position: "absolute",
    transform: "translate(50%,-50%)",
    borderRadius: "50%",
    backgroundColor: "rgba(83, 83, 83, 0.5)",
    padding: "5px",
    right: "calc(5% - 2px)",
    top: "calc(5% + 14px)",
  },
  imagePopperCloseIcon: {
    color: "white",
    float: "right",
    margin: "1% 10%",
    cursor: "pointer",
  },
  arrowAnimation: {
    // animation: `$myEffect 300ms ${theme.transitions.easing.easeOut}`,
  },
  arrowAnimationExit: {
    // animation: `$myEffectExit 300ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes myEffect": {
    "0%": {
      fontSize: "6vw",
    },
    "50%": {
      fontSize: "4vw",
    },
    "100%": {
      fontSize: "6vw",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      fontSize: "6vw",
    },
    "50%": {
      fontSize: "4vw",
    },
    "100%": {
      fontSize: "6vw",
    },
  },
  carImage: {
    height: "100",
    // objectFit: "cover",
    margin: "auto",
    filter: "brightness(0.5) !important",
  },
  carImage2: {
    height: "100",
    // objectFit: "cover",
    margin: "auto",
    filter: "brightness(1) !important",
  },
  carImage3: {
    height: 100,
    // objectFit: "cover",
    width: "95.8%",
    filter: "brightness(0.5) !important",
    "&:hover": {
      cursor: "pointer",
    },
  },

  carImage4: {
    height: 100,
    // objectFit: "cover",
    width: "95.8%",
    filter: "brightness(1) !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  mobileGallaryImage: {
    position: "absolute",
    top: "100%",
    zIndex: "1",
    transform: "translate(0%, -100%)",
    left: "-5%",
  },
  mobilegallarydiv: {
    background: "rgba(0, 0, 0, 0.3)",
    // paddingLeft: "12px",
    padding: "3px 4px", ////new
    borderRadius: "4px",
    display: "flex",
  },
  carImagesLengthTypography: {
    color: "white",
    // padding: "8px 12px",
    padding: "0 0 0 6px",
    zIndex: "10",
    fontSize: "12px",
  },
  swipeSlider: {
    height: "75vw!important",
    width: "calc(100% + 32px)",
    margin: "0 -16px",
    transitionDuration: "1s",
    transitionTimingFunction: "ease-in-out",
  },
  CarDetailsTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#07303E",
    marginTop: "auto", ////new
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      fontWeight: "600",
      color: "#07303E",
      marginTop: "0", ////new
    },
  },
  headerWrap: {
    margin: "13px 0",
    [theme.breakpoints.up("md")]: {
      margin: "34px 0",
    },
  },
  Divider: {
    height: 1,
    backgroundColor: "#D2D2D2",
    marginLeft: "24px",
    "flex-shrink": 0,
  },
  featureHideDiv: {
    height: "180px",
    overflow: "hidden",
    position: "relative",
    transition: "height 4000ms ease",
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      position: "relative",
      transition: theme.transitions.create(["height"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
      }),
      height: "270px",
      transform: "height",
    },
  },
  listItemIconRoot: {
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    padding: "9px",
  },
  listItemTextRoot: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "500",
      color: "#07303E",
    },
  },
  mediaSkeleton: {
    width: "100%!important",
    height: "555px",
    transform: "none!important",
    marginBottom: "18px!important",
    display: "none",
    [theme.breakpoints.up("md")]: {
      // height: "48.4419vw!important",
      height: "100%!important",
    },
    [theme.breakpoints.up("lg")]: {
      height: "555px",
    },
  },
  sliderSkeleton: {
    width: "100%!important",
    height: "100px!important",
    transform: "none!important",
  },
  sliderImageSkeleton: {
    width: "94.3%!important",
    height: "100px!important",
    transform: "none!important",
  },
  iconTypographySkeleton: {
    [theme.breakpoints.down("sm")]: {
      width: "45px!important",
      margin: "auto 0 auto 5px!important",
    },
    width: "90px!important",
    height: "24px!important",
    marginLeft: "0px!important",
  },
  priceTypographySkeleton: {
    [theme.breakpoints.down("sm")]: {
      height: "27px!important",
      width: "85px!important",
    },
    width: "115px!important",
    height: "36px!important",
  },
  installmentsTypographySkeleton: {
    [theme.breakpoints.down("sm")]: {
      width: "240px!important",
      height: "21px!important",
    },
    width: "365px!important",
    height: "30px!important",
  },
  vehicleInformationSkeleton: {
    width: "100%!important",
    transform: "none!important",
    marginBottom: "29px!important",
    [theme.breakpoints.down("sm")]: {
      height: "28px!important",
    },
    height: "29px!important",
  },
  mobileNameSkeleton: {
    width: "55%!important",
    height: "27px!important",
    marginTop: "28px!important",
  },
  webNameSkeleton: {
    width: "35%!important",
    height: "36px!important",
  },
  webLocationSkeleton: {
    width: "25%!important",
    height: "24px!important",
    marginBottom: "14px!important",
  },
  mobSecondIconSkeleton: {
    width: "80px!important",
    height: "21px!important",
    marginLeft: "auto!important",
  },
  showMoreAndLess: {
    padding: "10px 32px",
    textTransform: "none",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "10px 32px",
      textTransform: "none",
      justifyContent: "flex-end",
      alignContent: "center",
    },
  },
  showMoreAndLessText: {
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    color: "#00BDFF",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },

  imagesContainer: {
    height: "100%",
    width: "100%",
    position: "fixed",
    zIndex: 10000,
    background: "black",
    top: 0,
    left: 0,
    overflow: "hidden",
  },
  imageSliderWrapper: {
    width: "83vh",
    margin: "auto",
    marginTop: "5vh",
  },
  test: {
    width: "100%",
    height: "100%",
  },
}));

export default useGeneralStyles;
