import { Button, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import Slider from "react-slick";
import theme from "../../../theme";
import ImageActions from "../../ImageActions";
import clsx from "clsx";
import { getCarDetailImages, VHCarInfo } from "../../../store/actions/car";
import { useEffect } from "react";
import { connect } from "react-redux";
import PositionSwitch from "./PositionSwitch";
import CarImagesDialog from "./CarImagesDialog";
import { logVdpView } from "../../../store/actions/metrics";
import HandleActions from "./HandleActions";
// import ViewImg from "../../components/imageviewProto";
import ViewImg from "../imageviewProtoCarImages";
import ProgressiveImage from "../../ProgressiveImage";
import useGeneralStyles from "../generalStyles";
import { Live } from "../../../helpers/functions";

function CarImages(props) {
  const {
    hideAppBar,
    t,
    getCarDetailImages,
    logVdpView,
    images,
    exteriorImages,
    interiorImages,
    carId,
    showAppBar,
    setImages,
    setExteriorImages,
    setInteriorImages,
    VHCarInfo,
    setCarInfo,
    carData,
  } = props;
  const classes = useGeneralStyles();
  const [activeImage, setActiveImage] = useState(0);
  const [backClicked, setBackClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [nextClicked, setNextClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [switchState, setSwitchState] = useState(true); //switchState will later be used to render interior or exterior images
  const [positionActiveImage, setPositionActiveImage] = useState(0);
  const [swipeSliderLoaded, setSwipeSliderLoaded] = useState(false);
  const [imagePath, setImagePath] = useState(""); // a state containing the path that the link on the image directs to small screen: to image view page, big screen: do not redirect
  const [openDialog, setOpenDialog] = useState(false); // a state to open and close the bigger image view on Big screens
  const [cardMediaLoaded, setCardMediaLoaded] = useState(false);
  const [positionImages, setPositionImages] = useState([]);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [mobDialog, setMobDialog] = useState(false);
  const [loadedimagesmob, setloadedimagesmob] = useState(false);
  const params = useParams();

  const [loadedCompleted, setLoadedCompleted] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(true);
  const [visualisationPath, setVisualizationPath] = useState("");
  const [smallImageLoaded, setSmallImageLoaded] = useState(false);

  // refs
  const slickRef = useRef();
  const cardMediaRef = useRef();
  const swipeSliderRef = useRef();
  const backgroundRef = useRef(); // reference to the div node that contains the bigger image and stepper in the Big screen, used for exiting the "dialog" bach to the normal vehicle detail page
  const innerSlickRef = useRef();
  // sliders sittings
  const fullScreenSettings2 = {
    /// outside slider (5)
    // props for the image stepper from react-slick
    lazyLoad: true,
    arrows: true, // include arrows
    infinite: true, // to keep scrolling after last and firs images back to first and last image respectivly
    speed: 250, // scroll speed
    slidesToShow: 5, // No. of images shown in the slider
    slidesToScroll: 1, // number of images to scroll when the arrow is clicked
    adaptiveHeight: true, //
    focusOnSelect: true,
  };
  const fullScreenSettings3 = {
    // props for the image stepper from react-slick
    lazyLoad: true,
    arrows: false, // include arrows
    infinite: switchState
      ? exteriorImages.length > 1
      : interiorImages.length > 1, // to keep scrolling after last and firs images back to first and last image respectivly
    speed: 250, // scroll speed
    slidesToShow: 1, // No. of images shown in the slider
    slidesToScroll: 1, // number of images to scroll when the arrow is clicked
    initialSlide: 0, // initial image (vehicle featured image)
  };

  // effects

  useEffect(() => {
    if (
      // cardMediaRef?.current?.complete && // problem was that carImages.length !== 0 ? carImages?.[activeImage]
      // cardMediaRef?.current?.src?.includes("http")
      smallImageLoaded
    ) {
      setCardMediaLoaded(true);
    }
    // }, [cardMediaRef.current?.complete, cardMediaRef?.current?.src]);
  }, [smallImageLoaded]);

  useEffect(() => {
    // useEffect(() => {
    getCarDetailImages({
      carId: carId,
      callback: (images) => {
        let imageCompact = [...images.images[0], ...images.images[1]];
        let allImages = [];
        let extimgs = [];
        let intimgs = [];
        let cnt = 0;
        for (let i of imageCompact) {
          allImages = [...allImages, ...i.answer.images];
          if (cnt > images.images[0].length) {
            intimgs = [...intimgs, ...i.answer.images];
          } else {
            extimgs = [...extimgs, ...i.answer.images];
          }
          cnt += 1;
        }
        setImages(allImages);
        setExteriorImages(extimgs);
        setInteriorImages(intimgs);

        VHCarInfo({
          carId: params.id,
          callback: (response) => {
            setCarInfo(response.results);
          },
        });
      },
    });
    // }, []);

    // get images

    setSliderLoaded(true);
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
    Live() && logVdpView({ car: carId });
    // here the category of the size of the screen is set (isSmallDevice) and the path (imagePath) is set accordingly to the category
    const isTabletOrMobileWidth = window.matchMedia(
      "(max-device-width: 959px)"
    );
    const isTabletOrMobileHeight = window.matchMedia(
      "(max-device-height: 959px)"
    );
    if (isTabletOrMobileHeight.matches && isTabletOrMobileWidth.matches) {
      setIsSmallDevice(true);
      // setImagePath("/imageview");
    } else {
      setIsSmallDevice(false);
      // setImagePath("");
    }
  }, []);

  useEffect(() => {
    if (activeImage < exteriorImages.length || exteriorImages.length == 0) {
      setPositionImages(exteriorImages);
      setPositionActiveImage(activeImage);
      setSwitchState(true);
    } else {
      setPositionImages(interiorImages);
      setPositionActiveImage(activeImage - exteriorImages.length);
      setSwitchState(false);
    }
    setLoadedCompleted(false);
  }, [openDialog, activeImage, exteriorImages, interiorImages]);

  useEffect(() => {
    if (swipeSliderRef?.current) {
      setSwipeSliderLoaded(true);
    }
  }, [swipeSliderRef?.current]);

  useEffect(() => {
    // adding style to body element to stop scrolling when image or visualisation is open and to return scrolling when closed
    openDialog
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [openDialog]);

  useEffect(() => {
    if (innerSlickRef?.current) {
      innerSlickRef.current.slickGoTo(activeImage);
    }
    if (swipeSliderRef?.current) {
      swipeSliderRef.current.slickGoTo(activeImage);
    }
  }, [activeImage, openDialog]);

  useEffect(() => {
    if (slickRef?.current) {
      setSliderLoaded(true);
    }
  }, [slickRef?.current, cardMediaLoaded]);

  // actions dispached from actions file
  var actions = HandleActions({
    images: images,
    setActiveImage: setActiveImage,
    setBackClicked: setBackClicked,
    setNextClicked: setNextClicked,
    setOpenDialog: setOpenDialog,
    setSwitchState: setSwitchState,
    setPositionActiveImage: setPositionActiveImage,
    positionImages: positionImages,
    hideAppBar: hideAppBar,
    activeImage: activeImage,
    backClicked: backClicked,
    switchState: switchState,
    isSmallDevice: isSmallDevice,
    mobDialog: mobDialog,
    setMobDialog: setMobDialog,
  });

  return (
    <div>
      {/* ////////////////////////////////////////////// */}
      <Grid container style={{ position: "relative" }}>
        <Hidden mdUp>
          {mobDialog && (
            <ViewImg setMobDialog={setMobDialog} showAppBar={showAppBar} />
          )}
        </Hidden>
        {openDialog && (
          <CarImagesDialog
            actions={actions}
            innerSlickRef={innerSlickRef}
            backgroundRef={backgroundRef}
            carImages={images}
            activeImage={activeImage}
            setSwitchState={setSwitchState}
            setActiveImage={setActiveImage}
            setPositionActiveImage={setPositionActiveImage}
            positionImages={positionImages}
            classes={classes}
            setOpenDialog={setOpenDialog}
            switchState={switchState}
            handleNextImage={actions?.handleNextImage}
            handlePreviousImage={actions?.handlePreviousImage}
            nextClicked={nextClicked}
            backClicked={backClicked}
            loadedCompleted={loadedCompleted}
            setLoadedCompleted={setLoadedCompleted}
          />
        )}
        <Hidden smDown>
          <Grid item className={classes.previosIcon}>
            <Button
              className={classes.imagePreviousButton}
              onClick={
                theme.direction === "ltr"
                  ? actions?.handlePreviousImage
                  : actions?.handleNextImage
              }
            >
              {theme.direction === "ltr" ? (
                <KeyboardArrowLeft
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: nextClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              ) : (
                <KeyboardArrowRight
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: backClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              )}
            </Button>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item className={classes.nextIcon}>
            <Button
              className={classes.imageNextButton}
              onClick={
                theme.direction === "ltr"
                  ? actions?.handleNextImage
                  : actions?.handlePreviousImage
              }
            >
              {theme.direction === "ltr" ? (
                <KeyboardArrowRight
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: backClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              ) : (
                <KeyboardArrowLeft
                  className={clsx(classes.arrowAnimation, {
                    [classes.arrowAnimationExit]: nextClicked,
                  })}
                  style={{ fill: "white", fontSize: "6vw" }}
                />
              )}
            </Button>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item className={classes.mobileGallaryImage}>
            <div className={classes.mobilegallarydiv}>
              <Typography className={classes.carImagesLengthTypography}>
                {switchState
                  ? positionActiveImage + 1
                  : positionActiveImage + exteriorImages.length + 1}
                / {images.length}
              </Typography>
            </div>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12} style={{ position: "relative", display: "flex" }}>
            {!swipeSliderLoaded && (
              <Skeleton
                variant="rect"
                style={{
                  transform: "none",
                  zIndex: 1,
                  position: "absolute",
                }}
                className={classes.swipeSlider}
              />
            )}
            <Slider
              style={{ position: "relative" }}
              rtl={localStorage.getItem("language") === "en" ? null : true}
              // afterChange={(index) => setPositionActiveImage(index)}
              afterChange={(index) => setActiveImage(index)}
              // beforeChange={(oldIndex, newIndex) =>
              //   setActiveImage(newIndex)
              // }
              ref={swipeSliderRef}
              {...fullScreenSettings3}
              initialSlide={localStorage.getItem("language") === "en" ? 0 : -1}
              className={classes.swipeSlider}
            >
              {images.map((image, i) => {
                return (
                  <div key={"car-image-container" + i}>
                    <Link
                      to={{
                        pathname: carId + "/carimages",
                        images: images,
                        imageObj: {
                          from: "new-vdp",
                          data: [exteriorImages, interiorImages],
                        },
                        carId: carId, //carId ||
                        previousPath: window.location.pathname,
                        imageId: i,
                      }}
                    >
                      <div style={{ position: "relative", width: "100vw" }}>
                        <ProgressiveImage
                          noRelativeParent={true}
                          className={classes.test}
                          alt={"image" + i}
                          overlaySrc={actions.imageLocalization(image)}
                          src={image}
                          onClick={() => {
                            actions.handleImgClick();
                          }}
                          setSmallImageLoaded={setSmallImageLoaded}
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </Slider>
            {swipeSliderLoaded ? (
              <PositionSwitch
                setPositionExternally={switchState}
                onChange={(event) => actions?.handleSwitchChange(event)}
                style={{
                  height: "fit-content",
                  width: "fit-content",
                  transform: "translate(-50%,20%)",
                  left: "50%",
                  position: "absolute",
                }}
              />
            ) : (
              ""
            )}
            <ImageActions car_id={carId} carCard={carData} />
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item xs={12} className={classes.mediaGrid}>
            <ProgressiveImage
              alt={images[activeImage]}
              overlaySrc={actions.imageLocalization(images[activeImage])}
              src={images[activeImage]}
              className={classes.media}
              onClick={actions?.handleImgClick}
              smallImageRef={cardMediaRef}
              setSmallImageLoaded={setSmallImageLoaded}
            />
            <PositionSwitch
              setPositionExternally={switchState}
              onChange={(event) => actions?.handleSwitchChange(event)}
              // outer position switch
              style={{
                position: "absolute",
                top: "23px",
                left: "35%",
              }}
            />
            <Grid item className={classes.imageStepCount}>
              <div>
                <Typography
                  style={{
                    color: "white",
                    padding: "8px 12px",
                    zIndex: "10",
                  }}
                >
                  {activeImage + 1} {t("of")} {images.length}
                </Typography>
              </div>
            </Grid>
            {!cardMediaLoaded && (
              <div
                style={{
                  width: "100%",
                  marginBottom: "1px",
                  position: "absolute",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                  height: "calc(100% - 0.721392vw)",
                }}
              >
                <Skeleton className={classes.mediaSkeleton} />
              </div>
            )}
            <ImageActions car_id={carId} carCard={carData} />
          </Grid>
        </Hidden>
        <Paper
          style={{
            width: "100%",
            boxShadow: "none",
            zIndex: 2,
            position: "relative",
          }}
        >
          <Hidden smDown>
            {images.length === 0 && ( // still problem unmounts before sliderImageSkeleton mounts
              <Skeleton className={classes.sliderSkeleton} />
            )}
            <Slider
              {...fullScreenSettings2}
              initialSlide={localStorage.getItem("language") === "en" ? 0 : -1}
              rtl={localStorage.getItem("language") === "en" ? null : true}
              ref={slickRef}
              style={{ marginRight: "-1px" }}
              beforeChange={(oldIndex, newIndex) => {
                if (newIndex > 0) {
                  setActiveImage(newIndex);
                }
              }}
              onReInit={() => {
                slickRef.current.slickGoTo(activeImage);
              }}
            >
              {images.map((image, i) => {
                // add exterior and interior
                return (
                  <div key={"car-image-container" + i}>
                    <img
                      width={"80%"}
                      src={actions.imageLocalization(image)}
                      alt={"car-image-" + i}
                      className={
                        i != activeImage ? classes.carImage3 : classes.carImage4
                      }
                      onClick={() => setActiveImage(i)}
                    />
                    {
                      // true
                      !sliderLoaded && (
                        <div
                          style={{
                            position: "absolute",
                            // width: `calc(100% / ${carImages.length * 2})`,
                            // width: "3%", //## check
                            width: "145px", //## check
                            top: 0,
                            backgroundColor: "white",
                          }}
                        >
                          <Skeleton className={classes.sliderImageSkeleton} />
                        </div>
                      )
                    }
                  </div>
                );
              })}
            </Slider>
          </Hidden>
        </Paper>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getCarDetailImages(payload) {
    dispatch(getCarDetailImages(payload));
  },
  VHCarInfo(payload) {
    dispatch(VHCarInfo(payload));
  },
  logVdpView(payload) {
    dispatch(logVdpView(payload));
  },
});

export default withRouter(connect(null, mapDispatchToProps)(CarImages));
