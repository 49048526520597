import React from "react";

const Locked = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0866 7.95828H10.95V5.49774C10.95 3.1141 9.01765 1.15337 6.66855 1.15337C4.31946 1.15337 2.38715 3.1141 2.38715 5.49774V7.95828H1.25049V5.49774C1.25049 2.46052 3.67535 0 6.66855 0C9.66175 0 12.0866 2.46052 12.0866 5.49774V7.95828Z"
        fill="#07303E"
      />
      <path
        d="M11.5181 18.4161H1.81866C0.833556 18.4161 0 17.5703 0 16.5707V9.18914C0 8.18955 0.833556 7.34375 1.81866 7.34375H11.5181C12.5032 7.34375 13.3368 8.18955 13.3368 9.18914V16.5707C13.3368 17.6088 12.5032 18.4161 11.5181 18.4161ZM1.81866 8.57401C1.47766 8.57401 1.21244 8.84313 1.21244 9.18914V16.5707C1.21244 16.9167 1.47766 17.1859 1.81866 17.1859H11.5181C11.8591 17.1859 12.1243 16.9167 12.1243 16.5707V9.18914C12.1243 8.84313 11.8591 8.57401 11.5181 8.57401H1.81866Z"
        fill="#07303E"
      />
      <path
        d="M7.88239 11.035C7.88239 11.727 7.35195 12.2652 6.66995 12.2652C5.98796 12.2652 5.45752 11.727 5.45752 11.035C5.45752 10.3429 5.98796 9.80469 6.66995 9.80469C7.35195 9.80469 7.88239 10.3814 7.88239 11.035Z"
        fill="#07303E"
      />
      <path
        d="M6.97073 11.0352H6.36451L5.7583 14.7259H7.57695L6.97073 11.0352Z"
        fill="#07303E"
      />
    </svg>
  );
};

export default Locked;
