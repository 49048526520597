import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  GET_CITIES_REQUEST,
  GET_CITIES_REQUEST_SUCCEED,
  GET_CITIES_REQUEST_FAILURE,
  GET_AREAS_REQUEST,
  GET_AREAS_REQUEST_SUCCEED,
  GET_AREAS_REQUEST_FAILURE,
  GET_MAKE_REQUEST,
  GET_MAKE_REQUEST_SUCCEED,
  GET_MAKE_REQUEST_FAILURE,
  GET_MODELS_REQUEST,
  GET_MODELS_REQUEST_SUCCEED,
  GET_MODELS_REQUEST_FAILURE,
  GET_AVAILABLE_APPOINTMENTS,
  GET_AVAILABLE_APPOINTMENTS_SUCCEED,
  GET_AVAILABLE_APPOINTMENTS_FAILURE,
  GET_YEARS,
  GET_YEARS_SUCCEED,
  GET_YEARS_FAILURE,
  ADD_APPOINTMENT,
  ADD_APPOINTMENT_SUCCEED,
  ADD_APPOINTMENT_FAILURE,
} from "../constants";
import API_URLS from "../../api";

export function* getCities(action) {
  try {
    let data = {
      url: API_URLS(action.payload).TIME_SLOTS.GET_CITIES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: GET_CITIES_REQUEST_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_CITIES_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getAreas(action) {
  console.log("ffffffrrw1");
  try {
    let data = {
      url: API_URLS(action.payload).TIME_SLOTS.GET_AREAS,
      method: "GET",
    };
    console.log("ffffffrrw2", data);
    const response = yield requestAPI(data);
    console.log("ffffffrrw", response);
    yield put({
      type: GET_AREAS_REQUEST_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_AREAS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getMake(action) {
  console.log("ffffffrrw1");
  try {
    let data = {
      url: API_URLS(action.payload).TIME_SLOTS.GET_MAKE,
      method: "GET",
    };
    console.log("ffffffrrw2", data);
    const response = yield requestAPI(data);
    console.log("ffffffrrw", response);
    yield put({
      type: GET_MAKE_REQUEST_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_MAKE_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getModels(action) {
  console.log("ffffffrrw1");
  try {
    let data = {
      url: API_URLS(action.payload).TIME_SLOTS.GET_MODELS,
      method: "GET",
    };
    console.log("ffffffrrw2", data);
    const response = yield requestAPI(data);
    console.log("ffffffrrw", response);
    yield put({
      type: GET_MODELS_REQUEST_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_MODELS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getAvailableAppointments(action) {
  console.log("ffffffrrw1");
  try {
    let data = {
      url: API_URLS(action.payload).TIME_SLOTS.GET_AVAILABLE_APPOINTMENTS,
      method: "GET",
    };
    console.log("ffffffrrw2", data);
    const response = yield requestAPI(data);
    console.log("ffffffrrw", response);
    yield put({
      type: GET_AVAILABLE_APPOINTMENTS_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_AVAILABLE_APPOINTMENTS_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getYears(action) {
  try {
    let data = {
      url: API_URLS(230).CAR_FORM.YEARS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: GET_YEARS_SUCCEED,
      payload: response.results,
    });
    // action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: GET_YEARS_FAILURE, error: new Error(e) });
  }
}

// export function* addAppointment(action) {
//   console.log("ffffffrrw1");
//   // try {
//   let data = {
//     url: API_URLS(action.payload).TIME_SLOTS.ADD_APPOINTMENT,
//     method: "POST",
//   };
//   console.log("ffffffrrw2", data);
//   const response = yield requestAPI(data);
//   console.log("ffffffrrw", response);
//   yield put({
//     type: ADD_APPOINTMENT_SUCCEED,
//     payload: response,
//   });
//   // } catch (e) {
//   // yield put({
//   //   type: ADD_APPOINTMENT_FAILURE,
//   //   error: new Error(e),
//   // });
//   // }
// }

export function* addAppointment(action) {
  try {
    let data = {
      url: API_URLS("").TIME_SLOTS.ADD_APPOINTMENT,
      method: "POST",
      // body: action.payload,
      body: {
        city: action.payload.city,
        area: action.payload.area.region_name,
        year: action.payload.year,
        date: action.payload.date,
        buyer_name: action.payload.buyerName,
        buyer_phone_number: action.payload.buyerPhone,
        seller_name: action.payload.sellerName,
        seller_phone_number: action.payload.sellerPhone,
        make: action.payload.carMake,
        model: action.payload.carModel,
        time_slot: action.payload.timeSlot.id,
      },
    };
    const response = yield requestAPI(data);
    console.log("dfghjk", response);
    yield put({ type: ADD_APPOINTMENT_SUCCEED, payload: response });
    action.payload.callback();
  } catch (e) {
    yield put({ type: ADD_APPOINTMENT_FAILURE, error: new Error(e) });
  }
}
export function* getCitiesWatcher() {
  yield takeLatest(GET_CITIES_REQUEST, getCities);
}

export function* getAreasWatcher() {
  yield takeLatest(GET_AREAS_REQUEST, getAreas);
}

export function* getMakeWatcher() {
  yield takeLatest(GET_MAKE_REQUEST, getMake);
}

export function* getModelsWatcher() {
  yield takeLatest(GET_MODELS_REQUEST, getModels);
}

export function* getAvailableAppointmentsWatcher() {
  yield takeLatest(GET_AVAILABLE_APPOINTMENTS, getAvailableAppointments);
}

export function* getYearsWatcher() {
  yield takeEvery(GET_YEARS, getYears);
}

export function* addAppointmentWatcher() {
  yield takeLatest(ADD_APPOINTMENT, addAppointment);
}
