// import BasicGem from "../images/SubscriptionPlans/BasicGem.png";
// import StandardGem from "../images/SubscriptionPlans/StandardGem.png";
// import PremiumGem from "../images/SubscriptionPlans/PremiumGem.png";
import FreePlan from "../images/SubscriptionPlans/FreePlan.png";

// import BasicGem from "../icons/BasicGem.svg";
// import StandardGem from "../icons/StandardGem.svg";
// import PremiumGem from "../icons/PremiumGem.svg";

import Confident from "../images/SubscriptionPlans/Confident.png";
import Cautious from "../images/SubscriptionPlans/Cautious.png";
import Explorer from "../images/SubscriptionPlans/Explorer.png";

// import FreePlan from "../images/SubscriptionPlans/FreePlan.svg";

export const planGems = [Confident, Cautious, Explorer];
export const planIcons = [Confident, Cautious, Explorer];
