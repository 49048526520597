import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const MetaDecorator = (props) => {
  const { title, description, image } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" itemprop="image" content={image} />
      <meta property="og:image:secure_url" itemprop="image" content={image} />
      <meta
        property="og:url"
        content={
          "https://gtr.markabte.com" +
          window.location.pathname +
          window.location.search
        }
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Markabte" />
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      {/* <meta name="twitter:image:alt" content="Alt text for image" /> */}
    </Helmet>
  );
};

MetaDecorator.defaultProps = {
  image: "https://markabte-static.s3.eu-central-1.amazonaws.com/Embelem.png",
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  // image: PropTypes.string.isRequired,
};

export default MetaDecorator;
